import {
  SET_LISTS,
  SET_LIST,
  SET_LIST_ENTRIES,
  SET_LISTS_WITH_CARD,
  UPDATE_LIST_ENTRY,
  ADD_LIST_ENTRY,
  DELETE_LIST_ENTRY,
  SET_DRY_RUN,
  SET_RETRY_DRY_RUN,
  REMOVE_DRY_RUN_ENTRY,
  UPDATE_DRY_RUN_ENTRY_QUANTITY,
  UPDATE_IMPORT_STATUS,
  CHANGE_DRY_RUN_VARIANT,
  SET_LIST_DELETED,
  SET_ARCHIVED_LOADED,
  CONSOLIDATE_DRY_RUN_ENTRY,
  CONFIRM_DRY_RUN_CORRECTION
} from './actionType'

import {
  fetchDryRun,
  fetchList,
  fetchLists,
  fetchListEntries,
  fetchUpdateList,
  fetchCreateList,
  fetchDeleteList,
  fetchListsWithCard,
  fetchUpdateListEntry,
  fetchCreateListEntry,
  fetchDeleteListEntry,
  fetchCreateListEntries,
  fetchDeleteListEntries
} from './requests'

import { handleSnackbar } from '../layout/actionCreator'

import { setCards } from '../cards/actionCreator'

export function consolidateDryRunEntry (entry) {
  return {
    type: CONSOLIDATE_DRY_RUN_ENTRY,
    entry,
  }
}

export function updateImportStatus ({
  loading, success, error
}) {
  return {
    type: UPDATE_IMPORT_STATUS,
    loading,
    success,
    error,
  }
}

export function setRetryDryRun (entry, key) {
  return {
    type: SET_RETRY_DRY_RUN,
    entry,
    key,
  }
}

export function updateDryRunEntryQuantity (quantity, index) {
  return {
    type: UPDATE_DRY_RUN_ENTRY_QUANTITY,
    quantity,
    index,
  }
}

export function removeDryRunEntry (key) {
  return {
    type: REMOVE_DRY_RUN_ENTRY,
    key,
  }
}

export function changeDryRunEntryVariant (scryfallID, key) {
  return {
    type: CHANGE_DRY_RUN_VARIANT,
    scryfallID,
    key,
  }
}

export function setDryRun (list, loaded, error) {
  return {
    type: SET_DRY_RUN,
    list,
    loaded,
    error,
  }
}

export function confirmDryRunCorrection (key) {
  return {
    type: CONFIRM_DRY_RUN_CORRECTION,
    key,
  }
}

export function setLists (lists) {
  return {
    type: SET_LISTS,
    lists,
  }
}

export function setListsWithCard (cardId, lists) {
  return {
    type: SET_LISTS_WITH_CARD,
    cardId,
    lists
  }
}

export function setList (list) {
  return {
    type: SET_LIST,
    list
  }
}

export function setListEntries (listId, entries, error = false) {
  return {
    type: SET_LIST_ENTRIES,
    listId,
    entries,
    error
  }
}

export function updateListEntry (listId, cardId, entry) {
  return {
    type: UPDATE_LIST_ENTRY,
    listId,
    cardId,
    entry,
  }
}

export function addListEntry (listId, cardId, entry) {
  return {
    type: ADD_LIST_ENTRY,
    listId,
    cardId,
    entry,
  }
}

export function deleteListEntry (listId, entryId, cardId) {
  return {
    type: DELETE_LIST_ENTRY,
    listId,
    entryId,
    cardId,
  }
}

export function getFetchList (listId) {
  return function (dispatch, getState) {
    dispatch(handleSnackbar({
      message: 'Refreshing list...',
      options: {
        variant: 'info',
        autoHideDuration: 2000,
      },
    }))
    let accessToken = getState().user.jwt
    return Promise.all([
      fetchList(listId, accessToken),
      fetchListEntries(listId, accessToken),
    ])
      .then(function (response) {
        let list = response[0]
        let {
          entries, cards
        } = response[1]
        dispatch(setList(list))
        dispatch(setCards(cards))
        dispatch(handleSnackbar({
          message: 'List refreshed.',
          options: {
            variant: 'success',
            autoHideDuration: 2000,
          },
        }))
        return dispatch(setListEntries(listId, entries))
      })
      .catch(function (error) {
        console.log(error)
        dispatch(handleSnackbar({
          message: 'Error loading list.',
          options: {
            variant: 'error',
            autoHideDuration: 3000,
          },
        }))
      })
  }
}

export function getFetchArchivedLists () {
  return function (dispatch, getState) {
    let userId = getState().user.id
    let accessToken = getState().user.jwt
    return fetchLists(userId, 1, accessToken)
      .then(function (response) {
        dispatch(setArchivedLoaded(true))
        return dispatch(setLists(response))
      })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export function updateList (list) {
  return function (dispatch, getState) {
    let accessToken = getState().user.jwt
    return fetchUpdateList(list.id, list, accessToken)
      .then(function (response) {
        dispatch(handleSnackbar({
          message: 'List updated.',
          options: {
            variant: 'success',
            autoHideDuration: 2000,
          },
        }))
        return dispatch(setList(response))
      })
      .catch(function (error) {
        dispatch(handleSnackbar({
          message: 'Error updating list.',
          options: {
            variant: 'error',
            autoHideDuration: 3000,
          },
        }))
        console.log(error)
      })
  }
}

export function createList (list) {
  return function (dispatch, getState) {
    let accessToken = getState().user.jwt
    return fetchCreateList(list, accessToken)
      .then(function (response) {
        dispatch(handleSnackbar({
          message: 'List created.',
          options: {
            variant: 'success',
            autoHideDuration: 2000,
          },
        }))
        return dispatch(setList(response))
      })
      .catch(function (error) {
        dispatch(handleSnackbar({
          message: 'Error creating list.',
          options: {
            variant: 'error',
            autoHideDuration: 3000,
          },
        }))
        console.log(error)
      })
  }
}

export function setArchivedLoaded (loaded) {
  return {
    type: SET_ARCHIVED_LOADED,
    loaded
  }
}

export function setListDeleted (listId, deleted) {
  return {
    type: SET_LIST_DELETED,
    listId,
    deleted,
  }
}

export function deleteList (listId) {
  return function (dispatch, getState) {
    let accessToken = getState().user.jwt
    dispatch(setListDeleted(listId, 1))
    return fetchDeleteList(listId, accessToken)
      .then(function (response) {
        dispatch(handleSnackbar({
          message: 'List archived.',
          options: {
            variant: 'success',
            autoHideDuration: 2000,
          },
        }))
        return dispatch(setList(response))
      })
      .catch(function (error) {
        dispatch(setListDeleted(listId, 0))
        dispatch(handleSnackbar({
          message: 'Error archiving list.',
          options: {
            variant: 'error',
            autoHideDuration: 3000,
          },
        }))
        console.log(error)
      })
  }
}

export function getListsWithCard (cardId, scryfallId, foil) {
  // TODO: Check if entry exists...
  return function (dispatch, getState) {
    let userId = getState().user.id
    let accessToken = getState().user.jwt
    if (userId) {
      return fetchListsWithCard(scryfallId, foil, userId, accessToken)
        .then(function (response) {
          return dispatch(setListsWithCard(cardId, response))
        })
        .catch(function (err) {
          return err
          // return dispatch(setCardError(cardName, setName, foil))
        })
    }
  }
}

export function postDryRun (payload) {
  return function (dispatch, getState) {
    dispatch(updateImportStatus({
      loading: false,
      error: false,
      success: false,
    }))
    dispatch(setDryRun([], false, false))
    let accessToken = getState().user.jwt
    return fetchDryRun(payload, accessToken)
      .then(function (results) {
        dispatch(setDryRun(results, true, false))
      })
      .catch(function (err) {
        dispatch(setDryRun([], true, true))
        return err
      })
  }
}

export function retryDryRun (payload) {
  return function (dispatch, getState) {
    let accessToken = getState().user.jwt
    return fetchDryRun(payload, accessToken)
      .then(function (results) {
        dispatch(setRetryDryRun(results[0], results[0].key, true, false))
      })
      .catch(function (err) {
        return err
      })
  }
}

export function saveListEntries (listId, payload) {
  return function (dispatch, getState) {
    dispatch(updateImportStatus({
      loading: true,
      error: false,
      success: false,
    }))
    let accessToken = getState().user.jwt
    return fetchCreateListEntries(listId, payload, accessToken)
      .then(() => {
        dispatch(updateImportStatus({
          loading: false,
          error: false,
          success: true,
        }))
      })
      .catch(() => {
        dispatch(updateImportStatus({
          loading: false,
          error: true,
          success: false,
        }))
      })
  }
}

export function saveListEntry (entryId, card, initialQty) {
  return function (dispatch, getState) {
    let userId = getState().user.id
    let accessToken = getState().user.jwt
    let {
      quantity, listId, cardId, scryfallId
    } = card
    if (initialQty > 0) {
      if (quantity > 0) {
        // TODO: remove this someday once all our lists have scryfall_ids
        return fetchUpdateListEntry ({
          listId,
          scryfallId,
          currentListId: listId,
          entryId,
          quantity,
          accessToken
        })
          .then(function (res) {
            dispatch(updateListEntry(listId, cardId, res))
            return dispatch(handleSnackbar({
              message: 'List entry updated.',
              options: {
                variant: 'success',
                autoHideDuration: 2000,
              },
            }))
          })
          .catch(function (err) {
            dispatch(handleSnackbar({
              message: 'Error updating list entry.',
              options: {
                variant: 'error',
                autoHideDuration: 3000,
              },
            }))
            return err
          })
      } else {
        return fetchDeleteListEntry (listId, entryId, accessToken)
          .then(function (res) {
            dispatch(deleteListEntry(listId, entryId, cardId, res))
            return dispatch(handleSnackbar({
              message: 'List entry deleted.',
              options: {
                variant: 'success',
                autoHideDuration: 2000,
              },
            }))
          })
          .catch(function (err) {
            dispatch(handleSnackbar({
              message: 'Error deleting list entry.',
              options: {
                variant: 'error',
                autoHideDuration: 3000,
              },
            }))
            return err
          })
      }
    } else {
      let payload = {
        userId,
        gathererId: card.gatherer_id,
        scryfallId: card.scryfallId,
        foil: card.foil,
        quantity,
      }
      return fetchCreateListEntry (listId, payload, accessToken)
        .then(function (res) {
          dispatch(addListEntry(listId, cardId, res))
          return dispatch(handleSnackbar({
            message: 'List entry updated.',
            options: {
              variant: 'success',
              autoHideDuration: 2000,
            },
          }))
        })
        .catch(function (err) {
          dispatch(handleSnackbar({
            message: 'Error creating list entry.',
            options: {
              variant: 'error',
              autoHideDuration: 3000,
            },
          }))
          return err
        })
    }
  }
}

export function updateListEntriesListID (listId, entries) {
  return function (dispatch, getState) {
    console.log(listId, entries)
    // let accessToken = getState().user.jwt
    // return fetchUpdateListEntriesListID(listId, entries, accessToken)
    //   .then((res) => {
    //     console.log(res)
    //   })
    //   .catch(() => {
    //     dispatch(updateImportStatus({
    //       loading: false,
    //       error: true,
    //       success: false,
    //     }))
    //   })
  }
}

export function deleteListEntries (listId, payload) {
  return function (dispatch, getState) {
    let accessToken = getState().user.jwt
    return fetchDeleteListEntries(listId, payload, accessToken)
      .then((res) => {
        console.log(res)
      })
      .catch(() => {
        dispatch(updateImportStatus({
          loading: false,
          error: true,
          success: false,
        }))
      })
  }
}