import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import {
  green, red
} from '@material-ui/core/colors'
import { Link } from 'react-router-dom'
import moment from 'moment'

import Loading from '../../Layout/Loading'
import { getFetchArchivedLists } from '../../../store/collection/actionCreator'
import EnhancedTablePagination from '../../shared/EnhancedTablePagination'
import { sliceTableData } from '../../../utils/tables'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import ViewIcon from '@material-ui/icons/Pageview'
import CheckIcon from '@material-ui/icons/Check'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import {
  listsSelector, archivedListsSelector
} from '../../../store/reselect'


import {
  LIST_TYPES, FORMATS
} from '../../../constants'

const styles = {
  positive: {
    color: green[500],
    fontWeight: 600,
  },
  negative: {
    color: red[500],
    fontWeight: 600,
  },
  cell: {
    padding: '0 0 0 5px',
  },
}

class EnhancedTableHead extends React.Component {

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const {
      order, orderBy
    } = this.props
    const columnData = [
      {
        id: 'name',
        align: 'left',
        disablePadding: false,
        label: 'Name',
        style: {
          whiteSpace: 'unset'
        }
      },
      {
        id: 'list_type_id',
        align: 'left',
        disablePadding: false,
        label: 'Type',
        style: {
          width: 60
        }
      },
      {
        id: 'format_id',
        disablePadding: false,
        label: 'Format',
        style: {
          width: 80
        }
      },
      {
        id: 'is_public',
        disablePadding: false,
        label: 'Public',
        style: {
          width: 50
        }
      },
      {
        id: 'created_at',
        disablePadding: false,
        label: 'Created',
        style: {
          width: 130
        }
      },
      {
        id: 'updated_at',
        disablePadding: false,
        label: 'Modified',
        style: {
          width: 130
        }
      },
      {
        id: 'view',
        disablePadding: false,
        label: 'View',
        style: {
          width: 40,
          textAlign: 'center'
        },
        disabled: true
      },
    ]

    return (
      <TableHead>
        <TableRow style={{
          height: 36
        }}>
          {columnData.map(column => { return (
            <TableCell
              key={column.id}
              align={column.align}
              padding={'none'}
              sortDirection={orderBy === column.id ? order : false}
              style={column.style}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={order}
                hideSortIcon={column.disabled}
                onClick={!column.disabled ? this.createSortHandler(column.id) : null}
              >
                {column.label}
              </TableSortLabel>
            </TableCell>
          )}, this)}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}


class ListsTable extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      data: props.data,
      showArchived: 0,
      order: 'desc',
      orderBy: 'updated_at',
      page: 0,
      rowsPerPage: 25,
      toggle: (props.toggle !== null) ? props.toggle : false,
      searchInput: '',
      loaded: props.data.length > 0
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        data: this.props.data,
        loaded: true,
      })
    }
  }

  handleSellCards(list) {
    console.log(list)
  }

  handleShowArchivedToggle() {
    let showArchived = this.state.showArchived === 0 ? 1 : 0
    if (!this.props.archivedLoaded) {
      this.props.getFetchArchivedLists()
    }
    this.setState({
      showArchived,
      page: 0
    })
  }

  sortTable(data, orderBy, order) {
    if (['created_at', 'updated_at'].includes(orderBy)) {
      return (order === 'desc')
        ? data.sort((a, b) => (b[orderBy] && new Date(b[orderBy])) - (a[orderBy] && new Date(a[orderBy])))
        : data.sort((a, b) => (a[orderBy] && new Date(a[orderBy])) - (b[orderBy] && new Date(b[orderBy])))
    } else {
      return (order === 'desc')
        ? data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1))
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }
    this.setState({
      order,
      orderBy
    })
  }

  handleChangePage = (event, page) => {
    this.setState({
      page
    })
  }

  handleChangeRowsPerPage = (event) => {
    // TODO: Move to redux?
    this.setState({
      page: 0,
      rowsPerPage: event.target.value
    })
  }

  handleSearch = event => {
    let input = event.target.value.toLowerCase()
    const data = this.props.data.filter((list) => {
      try {
        if (list.name.toLowerCase().includes(input)) {
          return true
        }
      } catch(e) {
      }
      return false
    })
      .map((list, index) => {
        return list
      }).sort((a, b) => (a.name < b.name ? -1 : 1))

    this.setState({
      data
    })
  }

  render () {
    const {
      data, order, orderBy, rowsPerPage, page, loaded, showArchived
    } = this.state
    const { archived } = this.props

    const filteredData = [...data, ...archived].filter(v => v.is_deleted === showArchived)
    // const filteredData = (showArchived) ? archived : data

    const tableData = sliceTableData(this.sortTable(filteredData, orderBy, order), page, rowsPerPage)

    return (
      <React.Fragment>
        {loaded ? (
          <Grid container>
            <Grid item xs={12}>
              <TextField
                id="full-width"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Search"
                margin="normal"
                style={{
                  float: 'right',
                  marginTop: 0
                }}
                onChange={this.handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <div style={{
                float: 'right',
                marginTop: 0
              }}>
                <FormGroup row style={{
                  float: 'right',
                  paddingBottom: 16,
                }}>
                  {/* TODO: Turn this into a dif page */}
                  <FormControlLabel
                    control={
                      <Switch checked={showArchived === 1 ? true : false} onChange={() => this.handleShowArchivedToggle()} value='Show Archived Lists' />
                    }
                    label='Show Archived Lists'
                  />
                </FormGroup>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Table style={{
                tableLayout: 'auto'
              }}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                  rowCount={data.length}
                />
                <TableBody>
                  {tableData.map((v, i) => {
                    return (
                      <TableRow style={{
                        height: 36
                      }} key={i}>
                        <TableCell padding={'none'} style={{
                          width: 250
                        }}>
                          <Link to={`/sell/${v.id}`}>
                            {v.name}
                          </Link>
                        </TableCell>
                        <TableCell padding={'none'}>
                          <span style={{
                            textAlign: 'left',
                            fontSize: '.8rem'
                          }}>{LIST_TYPES[v.list_type_id]}</span>
                        </TableCell>
                        <TableCell padding={'none'}>
                          <span style={{
                            textAlign: 'left',
                            fontSize: '.8rem'
                          }}>{v.format_id ? FORMATS[v.format_id]['name'] : ''}</span>
                        </TableCell>
                        <TableCell padding={'none'}>
                          <span style={{
                            textAlign: 'left',
                            fontSize: '.8rem'
                          }}>
                            {v.is_public === 1 ? <CheckIcon style={{
                              fontSize: '.8rem'
                            }} /> : ''}
                          </span>
                        </TableCell>
                        <TableCell padding={'none'}>
                          <span style={{
                            textAlign: 'left',
                            fontSize: '.8rem'
                          }}>
                            {v.created_at !== null && moment(v.created_at).local().format('MMMM Do YYYY, h:mm:ss a')}
                          </span>
                        </TableCell>
                        <TableCell padding={'none'}>
                          <span style={{
                            textAlign: 'left',
                            fontSize: '.8rem'
                          }}>
                            {v.updated_at !== null && moment(v.updated_at).local().format('MMMM Do YYYY, h:mm:ss a')}
                          </span>
                        </TableCell>
                        <TableCell padding={'none'} style={{
                          textAlign: 'center'
                        }}>
                          <Link to={`/collection/lists/${v.id}`}>
                            <ViewIcon />
                          </Link>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
              <EnhancedTablePagination
                filteredCount={filteredData.length}
                totalCount={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                totalText='total lists'
              />
            </Grid>
          </Grid>
        ) : (
          <Loading />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    data: listsSelector(state),
    archivedLoaded: state.collection.archivedLoaded,
    archived: archivedListsSelector(state),
    // archived: (state.collection.archived.loaded)
    //   ? Object.keys(state.collection.archived.data).map(v => state.collection.archived.data[v])
    //   : []
  }
}

const mapDispatchToProps = {
  getFetchArchivedLists,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ListsTable))
