import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Grid from '@material-ui/core/Grid'

const styles = {
  formControl: {
    width: '100%'
  },
  menuItemName: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  menuItemCount: {
    textAlign: 'right',
    paddingRight: 16
  },
  qtyText: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.875rem',
    fontWeight: 500,
  }
}

class ListSelect extends React.Component {

  handleOnSelect = (listId) => {
    this.props.onSelect(listId)
  }

  render () {
    const {
      classes, listsWithCard, listsWithoutCard, listId
    } = this.props
    // const { open } = this.state

    // const { card_name, set_code, set_name, rarity, foil, gatherer_id } = card

    return (
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor='grouped-select'>Select List To Edit</InputLabel>
          <Select
            displayEmpty={true}
            defaultValue={listId || 'no_list'}
            input={<Input id='grouped-select' />}
            onChange={e => this.handleOnSelect(e.target.value)}
            variant={'outlined'}
          >
            <MenuItem value='no_list'>
              <em>Select a List</em>
            </MenuItem>
            <ListSubheader disableSticky={true}>Lists With This Card</ListSubheader>
            {listsWithCard.length > 0 && listsWithCard.map(v => {
              let {
                list, quantity
              } = v
              if (!list || list === undefined) {
                return null
              }
              const {
                id, name
              } = list
              return <MenuItem value={id} key={id}>
                <Grid container>
                  <Grid item xs={10} className={classes.menuItemName}>
                    {name}
                  </Grid>
                  <Grid item xs={2}  className={classes.menuItemCount}>
                    <span className={classes.qtyText}>Qty: </span>{quantity}
                  </Grid>
                </Grid>
              </MenuItem>
            })}
            <ListSubheader disableSticky={true}>All Lists</ListSubheader>
            {listsWithoutCard.length > 0 && listsWithoutCard.map(v => {
              let {
                list, quantity
              } = v
              if (!list || list === undefined) {
                return null
              }
              const {
                id, name
              } = list
              return <MenuItem value={id} key={id}>
                <Grid container>
                  <Grid item xs={10} className={classes.menuItemName}>
                    {name}
                  </Grid>
                  <Grid item xs={2}  className={classes.menuItemCount}>
                    <span className={classes.qtyText}>Qty: </span>{quantity}
                  </Grid>
                </Grid>
              </MenuItem>
            })}
          </Select>
        </FormControl>
      </div>
    )
  }
}

export default withStyles(styles)(ListSelect)
