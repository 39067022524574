import React from 'react'
import {
  shape, func
} from 'prop-types'
import { Helmet } from 'react-helmet'
class NotFoundPage extends React.Component {
  static propTypes = {
    layoutActions: shape({
      setHeaderTitle: func,
    }),
  }

  render () {
    return (
      <div>
        <Helmet title={'Page Not Found - Trader Tools | Quiet Speculation'} />
        <div>Page not found.</div>
      </div>
    )
  }
}

export default (NotFoundPage)
