import React, {
  useEffect, useState
} from 'react'
import { Helmet } from 'react-helmet'
import {
  Link, useParams
} from 'react-router-dom'
import { useAuthMutation } from '../../../utils/requests'
import {
  useDispatch, useSelector
} from 'react-redux'

import { Switch } from '@material-ui/core'

import { setBreadcrumbs } from '../../../store/layout/actionCreator'
import ListSelect from './ListSelect'
import OriginListSelector from './OriginListSelector'

import Auth from '../../shared/Auth'
import Widget from '../../shared/Widget'
import CollectionNavigation from '../../shared/CollectionNavigation'

import axios from 'axios'

import {
  Grid,
  Button,
  makeStyles,
  Typography
} from '@material-ui/core'

const mergeLists = async (data) => {
  const jwt = useSelector((state) => {
    return state.cards.allPrintings[name] || []
  })
  const { data: response } = await axios.post('/api/collections/merge', data)
  return response.data
}

const useStyles = makeStyles({
  topNav: {
    marginBottom: 20,
    textAlign: 'center',
  },
  button: {
    textTransform: 'none',
  },
  formControl: {
    width: '100%',
  },
  menuItemName: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  menuItemCount: {
    textAlign: 'right',
    paddingRight: 16
  },
  qtyText: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.875rem',
    fontWeight: 500,
  }
})

export const MergeListsPage = (props) => {
  const classes = useStyles()
  const { listId } = useParams()
  const [originLists, setOriginLists] = useState([listId] || ['no_list'])
  const [destinationList, setDestinationList] = useState('no_list')
  const [archiveOriginLists, setArchiveOriginLists] = useState(true)
  const [keepOriginEntries, setKeepOriginEntries] = useState(false)

  const dispatch = useDispatch()

  const {
    mutate,
    isLoading,
    error,
    data,
    isSuccess,
    reset,
  } = useAuthMutation(mergeLists)

  useEffect(() => {
    dispatch(setBreadcrumbs([
      {
        text: 'Trader Tools',
        path: '/'
      },
      {
        text: 'Collection',
        path: '/collection'
      },
      {
        text: 'Merge Lists'
      },
    ]))
  }, [])

  useEffect(() => {
    reset()
  }, [destinationList, originLists])

  const handleMerge = () => {
    mutate({
      url: '/collections/merge',
      payload: {
        originLists: originLists.filter((val) => val !== 'no_list').map((val) => Number(val)),
        destinationList: Number(destinationList),
        archiveOriginLists,
        keepOriginEntries,
      }
    })
  }

  const handleSetOriginList = (newList, index) => {
    setOriginLists(originLists.map((val, i) => {
      if (index === i) {
        return newList
      }
      return val
    }))
  }

  const handleRemoveOriginList = (index) => {
    setOriginLists(originLists.filter((_val, i) => i !== index))
  }

  return (
    <div>
      <Helmet>
        <title>Merge Lists - Trader Tools | Quiet Speculation</title>
      </Helmet>
      <Auth
        Base={
          <Grid container spacing={1}>
            <Grid item xs={12}>
              You are required to be logged in to Quiet Speculation to manage a collection.
            </Grid>
          </Grid>
        }
        LoggedIn={
          <Grid container spacing={1}>
            <CollectionNavigation currentPage='merge' />
            <Grid item xs={12}>
              <Widget header='Merge Your Lists'>
                <Grid container>
                  <Grid item xs={12} sm={6} style={{
                    padding: '1rem'
                  }}>
                    <Typography variant='h4'>
                      Origin List
                    </Typography>
                    {originLists.map((list, index) => {
                      return (
                        <OriginListSelector
                          key={index}
                          removable={originLists.length > 1 ? true : false}
                          listId={list}
                          onSelect={(listId) => handleSetOriginList(listId, index)}
                          onRemove={() => handleRemoveOriginList(index)}
                        />
                      )
                    })}
                    <div style={{
                      width: '100%',
                      textAlign: 'right'
                    }}>
                      <Button
                        variant='outlined'style={{
                          marginTop: '.6rem'
                        }}
                        onClick={() => setOriginLists([...originLists, 'no_list'])}
                      >
                        Add Another List
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{
                    padding: '1rem'
                  }}>
                    <Typography variant='h4'>
                      Destination List
                    </Typography>
                    <div style={{
                      padding: '1rem'
                    }}>
                      <ListSelect
                        activeList={destinationList}
                        onSelect={(listId) => setDestinationList(listId)}
                        label='Cards will be moved from the origin list to this list.'
                      />
                      {destinationList !== 'no_list' && (
                        <Button variant='outlined' size='small' style={{
                          marginTop: '.6rem'
                        }} component={Link} to={`/collection/lists/${destinationList}`} target='_blank'>
                          View List
                        </Button>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} align='center' style={{
                    paddingTop: '2rem'
                  }}>
                    <div>
                      <Typography variant='caption' style={{
                        fontStyle: 'italic'
                      }}>
                        Copy entries (do not delete from origin lists)?
                      </Typography>
                      <Switch
                        checked={keepOriginEntries}
                        onChange={() => setKeepOriginEntries(!keepOriginEntries)}
                        disabled={isLoading || isSuccess}
                      />
                      <br />
                      <Typography variant='caption' style={{
                        fontStyle: 'italic'
                      }}>
                        Archive origin lists after merge?
                      </Typography>
                      <Switch
                        checked={archiveOriginLists}
                        onChange={() => setArchiveOriginLists(!archiveOriginLists)}
                        disabled={isLoading || isSuccess}
                      />
                    </div>
                    <Button
                      variant='outlined'
                      color='primary'
                      onClick={handleMerge}
                      disabled={
                        isLoading
                        || isSuccess
                        || originLists === 'no_list'
                        || destinationList === 'no_list'
                        || originLists.includes(destinationList)
                      }
                    >
                      {isSuccess ? 'Lists merged!' : 'Merge'}
                    </Button>
                    {error && (
                      <Typography color='error'>There was an error with the merge.</Typography>
                    )}
                    {isSuccess && (
                      <>
                        <Typography color='primary'>Lists were merged successfully.</Typography>
                        {destinationList !== 'no_list' && (
                          <Button variant='outlined' size='small' style={{
                            marginTop: '.6rem'
                          }} component={Link} to={`/collection/lists/${destinationList}`}>
                            View List
                          </Button>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Widget>
            </Grid>
          </Grid>
        }
      />

    </div>
  )

}

export default MergeListsPage