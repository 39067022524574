import { uniq } from 'lodash'

import {
  SET_LOADED,
  SET_BREADCRUMBS,
  SET_SNACKBAR,
  DISMISS_SNACKBAR,
  DISMISS_ALL_SNACKBARS,
  REMOVE_SNACKBAR,
  TOGGLE_SETTINGS,
  SET_SETS_TABLE_TYPES,
  SET_SETS_TABLE_SORT,
  SET_SET_TABLE_SORT,
  SET_LIST_TABLE_SORT,
  SET_COLLECTION_TABLE_SORT,
  SET_IMPORT_COLUMNS
} from './actionType'

import {
  IMPORT_COLUMN_TYPES, DEFAULT_IMPORT_COLUMN_TYPES
} from '../../constants'

import { get } from '../../utils/cache'

const getCachedCols = () => {
  const cached = get('importColumns')
  if (cached) {
    let validCols = cached.filter((col) => IMPORT_COLUMN_TYPES.includes(col))

    if (!validCols.includes('foil')) {
      validCols = [...validCols, 'foil']
    }

    if (!validCols.includes('quantity')) {
      validCols = [...validCols, 'quantity']
    }

    return uniq(validCols)
  }
  return DEFAULT_IMPORT_COLUMN_TYPES
}

export const initialState = {
  notifications: {},
  breadcrumbs: [
    {
      text: 'Trader Tools',
      path: '/'
    },
  ],
  loaded: false,
  settingsOpen: false,
  setTableSort: get('setTableSort') || {
    order: 'desc',
    orderBy: 'buy_best',
    rowsPerPage: 25,
  },
  setsTableSort: get('setsTableSort') || {
    order: 'desc',
    orderBy: 'publish_date',
    rowsPerPage: 25,
  },
  setsTableTypes: get('setsTableTypes') || {
    types: {
      expansion: true,
      box: true,
      funny: true,
      commander: true,
      core: true,
      spellbook: true,
      draft_innovation: true,
      planechase: true,
      masters: true,
      masterpiece: true,
      duel_deck: true,
      from_the_vault: true,
      archenemy: true,
      starter: true,
      promo: true,
      premium_deck: true,
      token: true,
      vanguard: true,
      memorabilia: true
    },
    allTypes: true,
  },
  listTableSort:  get('listTableSort') || {
    order: 'desc',
    orderBy: 'price',
    rowsPerPage: 25,
  },
  collectionTableSort: get('collectionTableSort') || {
    order: 'desc',
    orderBy: 'updated_at',
    rowsPerPage: 25,
  },
  importColumns: getCachedCols()
}

export default function layoutReducer (state = initialState, action = {}) {
  switch (action.type) {
  case SET_SETS_TABLE_SORT: {
    const { setsTableSort } = action.payload
    const newState = {
      ...state,
      setsTableSort,
    }
    return newState
  }
  case SET_SETS_TABLE_TYPES: {
    const { setsTableTypes } = action.payload
    const newState = {
      ...state,
      setsTableTypes,
    }
    return newState
  }
  case SET_IMPORT_COLUMNS: {
    const { importColumns } = action.payload
    const newState = {
      ...state,
      importColumns,
    }
    return newState
  }
  case SET_SET_TABLE_SORT: {
    const { setTableSort } = action.payload
    const newState = {
      ...state,
      setTableSort,
    }
    return newState
  }
  case SET_LIST_TABLE_SORT: {
    const { listTableSort } = action.payload
    const newState = {
      ...state,
      listTableSort,
    }
    return newState
  }
  case SET_COLLECTION_TABLE_SORT: {
    const { collectionTableSort } = action.payload
    const newState = {
      ...state,
      collectionTableSort,
    }
    return newState
  }
  case TOGGLE_SETTINGS: {
    const newState = {
      ...state,
      settingsOpen: !state.settingsOpen,
    }
    return newState
  }
  case SET_LOADED: {
    const newState = {
      ...state,
      loaded: true,
    }
    return newState
  }
  case SET_BREADCRUMBS: {
    const { breadcrumbs } = action.payload
    const newState = {
      ...state,
      breadcrumbs: breadcrumbs,
    }
    return newState
  }
  case SET_SNACKBAR: {
    const {
      key, notification
    } = action.payload
    let newState = {
      ...state,
      notifications: {
        ...state.notifications,
        [key]: {
          ...notification,
          dismissed: false
        }
      },
    }
    return newState
  }
  case DISMISS_SNACKBAR: {
    const { id } = action.payload
    let newState = {
      ...state,
      notifications: {
        ...state.notifications,
        [id]: {
          ...state.notifications[id],
          dismissed: true
        }
      }
    }
    return newState
  }
  case DISMISS_ALL_SNACKBARS: {
    let newState = {
      ...state,
      notifications: Object.keys(state.notifications).reduce((result, v) => {
        result[v] = {
          ...state.notifications[v],
          dismissed: true
        }
        return result
      }, {}),
    }
    return newState
  }
  case REMOVE_SNACKBAR: {
    const { id } = action.payload
    let newState = {
      ...state,
      notifications: Object.keys(state.notifications).reduce((result, v) => {
        if (v !== id) {
          result[v] = state.notifications[v]
        }
        return result
      }, {}),
    }
    return newState
  }
  default:
    return state
  }
}
