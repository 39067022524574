import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import {
  green, red
} from '@material-ui/core/colors'

import Skeleton from 'react-loading-skeleton'
import { getFetchArchivedLists } from '../../../store/collection/actionCreator'
import { setCollectionTableSort } from '../../../store/layout/actionCreator'
import {
  archivedListsSelector, listsSelector
} from '../../../store/reselect'
import EnhancedTablePagination from '../../shared/EnhancedTablePagination'
import { sliceTableData } from '../../../utils/tables'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'

import ListTableRow from './ListsTableRow'

const styles = {
  positive: {
    color: green[500],
    fontWeight: 600,
  },
  negative: {
    color: red[500],
    fontWeight: 600,
  },
  cell: {
    padding: '0 0 0 5px',
  },
}

const sortTable = (data, orderBy, order) => {
  if (['created_at', 'updated_at'].includes(orderBy)) {
    return (order === 'desc')
      ? data.sort((a, b) => (b[orderBy] && new Date(b[orderBy])) - (a[orderBy] && new Date(a[orderBy])))
      : data.sort((a, b) => (a[orderBy] && new Date(a[orderBy])) - (b[orderBy] && new Date(b[orderBy])))
  } else {
    return (order === 'desc')
      ? data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
      : data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1))
  }
}

class EnhancedTableHead extends React.Component {

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const {
      order, orderBy
    } = this.props
    const columnData = [
      {
        id: 'name',
        align: 'left',
        disablePadding: false,
        label: 'Name',
        style: {
          whiteSpace: 'unset'
        }
      },
      {
        id: 'list_type_id',
        align: 'left',
        disablePadding: false,
        label: 'Type',
        style: {
          width: 60
        }
      },
      {
        id: 'format_id',
        disablePadding: false,
        label: 'Format',
        style: {
          width: 80
        }
      },
      {
        id: 'is_public',
        disablePadding: false,
        label: 'Public',
        style: {
          width: 50
        }
      },
      {
        id: 'created_at',
        disablePadding: false,
        label: 'Created',
        style: {
          width: 130
        }
      },
      {
        id: 'updated_at',
        disablePadding: false,
        label: 'Modified',
        style: {
          width: 130
        }
      },
      {
        id: 'Edit',
        disablePadding: false,
        label: 'Edit',
        style: {
          width: 40
        },
        disabled: true
      },
    ]

    return (
      <TableHead>
        <TableRow style={{
          height: 36
        }}>
          {columnData.map(column => { return (
            <TableCell
              key={column.id}
              align={column.align}
              padding={'none'}
              sortDirection={orderBy === column.id ? order : false}
              style={column.style}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={order}
                hideSortIcon={column.disabled}
                onClick={!column.disabled ? this.createSortHandler(column.id) : null}
              >
                {column.label}
              </TableSortLabel>
            </TableCell>
          )}, this)}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

class ListsTable extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      showArchived: 0,
      page: 0,
      toggle: (props.toggle !== null) ? props.toggle : false,
      searchInput: '',
    }
  }

  handleWidgetToggle(e) {
    this.props.onWidgetToggle(e)
  }

  handleShowArchivedToggle() {
    let showArchived = this.state.showArchived === 0 ? 1 : 0
    if (!this.props.archivedLoaded) {
      this.props.getFetchArchivedLists()
    }
    this.setState({
      showArchived,
      page: 0,
      searchInput: '',
    })
  }

  handleRequestSort = (event, newOrderBy) => {
    const {
      order, orderBy, rowsPerPage, setCollectionTableSort
    } = this.props

    let newOrder = 'desc'
    if (orderBy === newOrderBy && order === 'desc') {
      newOrder = 'asc'
    }

    setCollectionTableSort({
      order: newOrder,
      orderBy: newOrderBy,
      rowsPerPage,
    })
  }

  handleChangePage = (event, page) => {
    this.setState({
      page
    })
  }

  handleChangeRowsPerPage = (event) => {
    const {
      order, orderBy, rowsPerPage, setCollectionTableSort
    } = this.props
    setCollectionTableSort({
      order,
      orderBy,
      rowsPerPage: event.target.value,
    })
  }

  handleSearch = event => {
    let input = event.target.value.toLowerCase()
    this.setState({
      searchInput: input,
    })
  }

  render () {
    const {
      page, showArchived, searchInput
    } = this.state
    const {
      data,
      archived,
      order,
      orderBy,
      rowsPerPage,
      archivedLoaded,
    } = this.props

    const loaded = data.length > 0

    const filteredData = [...data, ...archived].filter(v => v.is_deleted === showArchived)
      .filter((list) => {
        try {
          if (list.name.toLowerCase().includes(searchInput)) {
            return true
          }
        } catch(e) {
        }
        return false
      })

    const tableData = sliceTableData(sortTable(filteredData, orderBy, order), page, rowsPerPage)

    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              id="full-width"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Search"
              margin="normal"
              style={{
                float: 'right',
                marginTop: 0
              }}
              onChange={this.handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <div style={{
              float: 'right',
              marginTop: 0
            }}>
              <FormGroup row style={{
                float: 'right',
                paddingBottom: 16,
              }}>
                {/* TODO: Turn this into a dif page */}
                <FormControlLabel
                  control={
                    <Switch checked={showArchived === 1 ? true : false} onChange={() => this.handleShowArchivedToggle()} value='Show Archived Lists' />
                  }
                  label='Show Archived Lists'
                />
              </FormGroup>
            </div>
          </Grid>
          <Grid item xs={12}>
            {loaded && (!showArchived || (showArchived === 1 && archivedLoaded === true)) ? (
              <>
                <Table style={{
                  tableLayout: 'auto'
                }}>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    rowCount={data.length}
                  />
                  <TableBody>
                    {tableData.map((v, i) => {
                      return (
                        <ListTableRow key={i} v={v} />
                      )
                    })}
                  </TableBody>
                </Table>
                <EnhancedTablePagination
                  filteredCount={filteredData.length}
                  totalCount={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  totalText='total lists'
                />
              </>
            ) : (
              <Skeleton height={941} />
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const {
    order, orderBy, rowsPerPage
  } = state.layout.collectionTableSort
  return {
    data: listsSelector(state),
    order,
    orderBy,
    rowsPerPage,
    archivedLoaded: state.collection.archivedLoaded,
    archived: archivedListsSelector(state),
  }
}

const mapDispatchToProps = {
  getFetchArchivedLists,
  setCollectionTableSort,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ListsTable))
