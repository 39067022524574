import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import ListEditor from './ListEditor'
import { EMPTY_LIST } from '../../constants'
import { moveListEntries } from '../../store/tradeRoutes/actionCreator'
import { listsObjectSelector } from '../../store/reselect'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core'

const styles = {
  formControl: {
    width: '100%',
  },
  menuItemName: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}

class MoveListEntries extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      listOpen: false,
      selected: 'no_list',
      newListName: null,
    }
  }

  componentDidUpdate(prevProps) {
    const { lists } = this.props
    const { newListName } = this.state
    if (prevProps.lists !== lists && newListName !== null) {
      let newListId = Object.keys(lists).find(v => lists[v].name === newListName)
      this.setState({
        selected: newListId
      })
    }
  }

  // handleClickOpen() {
  //   this.props.handleOpen(true)
  // }

  handleClose() {
    this.props.handleOpen(false)
    this.setState({
      listOpen: false,
      selected: 'no_list',
      newListName: null
    })
  }

  handleSave() {
    let merchantId = this.props.merchantId
    let listId = parseInt(this.state.selected)
    let currentListId = parseInt(this.props.currentListId)

    let payload = this.props.entries.reduce((acc, entry) => {
      const {
        count, qty
      } = entry
      acc.toUpdate = [ ...acc.toUpdate, {
        id: parseInt(entry.id),
        quantity: qty - count,
        card_id: entry.card_id,
        listId: currentListId,
        scryfallId: entry.scryfall_id,
      }]
      acc.toCreate = [ ...acc.toCreate, {
        gid: entry.gid,
        foil: entry.foil,
        qty: count, // We use qty here becasue the API uses it
        listId,
        scryfallId: entry.scryfall_id,
      }]
      return acc
    }, {
      toUpdate: [],
      toCreate: [],
    })

    this.props.moveListEntries(listId, currentListId, merchantId, payload)
    this.handleClose()
  }

  handleNewListToggle(e) {
    if (!e) {
      this.setState({
        listOpen: false,
      })
    } else {
      this.setState({
        listOpen: e,
      })
    }
  }

  handleNewListSave = (list) => {
    this.setState({
      newListName: list.name,
    })
  }

  handleOnSelect = (id) => {
    this.setState({
      selected: id,
    })
  }

  render() {
    const {
      listOpen,
      selected,
    } = this.state

    const {
      entries, open, lists, classes,
    } = this.props

    return (
      <>
        <Dialog
          open={open}
          onClose={() => this.handleClose()}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>Split List Entries</DialogTitle>
          <DialogContent style={{
            minWidth: 500
          }}>
            <Grid container>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor='grouped-select'>Select List To Move Cards To</InputLabel>
                  <Select
                    displayEmpty={true}
                    value={selected}
                    input={<Input id='grouped-select' />}
                    onChange={e => this.handleOnSelect(e.target.value)}
                    variant={'outlined'}
                  >
                    <MenuItem value='no_list'>
                      <em>Select a List</em>
                    </MenuItem>
                    {Object.keys(lists).map(id => {
                      let { name } = lists[id]
                      return (
                        <MenuItem value={id} key={id}>
                          <Grid container>
                            <Grid item xs={10} className={classes.menuItemName}>
                              {name}
                            </Grid>
                          </Grid>
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{
                textAlign: 'right'
              }}>
                <Button onClick={() => this.handleNewListToggle(true)} color='primary'>
                  Create New List
                </Button>
              </Grid>
              <Grid item xs={12} style={{
                paddingTop: 16,
                paddingBottom: 16,
              }}>
                <Table style={{
                  tableLayout: 'auto'
                }}>
                  <TableHead>
                    <TableRow>
                      <TableCell padding={'none'}>
                        Card
                      </TableCell>
                      <TableCell padding={'none'}>
                        Set
                      </TableCell>
                      <TableCell padding={'none'}>
                        Foil
                      </TableCell>
                      <TableCell padding={'none'}>
                        Qty
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {entries.map(v => {
                      const {
                        card_name, set_name, foil, count, card_id
                      } = v
                      return (
                        <TableRow key={card_id}>
                          <TableCell padding={'none'}>
                            {card_name}
                          </TableCell>
                          <TableCell padding={'none'}>
                            {set_name}
                          </TableCell>
                          <TableCell padding={'none'}>
                            {foil === 1 ? 'Foil' : ''}
                          </TableCell>
                          <TableCell padding={'none'}>
                            {count}
                          </TableCell>
                        </TableRow>
                      )
                    }
                    )}

                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{
            display: 'flex'
          }}>
            <Button onClick={() => this.handleClose()} color='primary'>
            Cancel
            </Button>
            <Button onClick={() => this.handleSave()} color='primary' disabled={selected === 'no_list'}>
            Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <ListEditor
          list={EMPTY_LIST}
          open={listOpen}
          onListToggle={(e) => this.handleNewListToggle(e)}
          onListSave={(e) => this.handleNewListSave(e)}
          hideButton={true}
        />
      </>
    )
  }
}

MoveListEntries.propTypes = {
  classes: PropTypes.object,
  entries: PropTypes.array,
  open: PropTypes.bool,
}

const mapStateToProps = state => ({
  userId: state.user.id,
  lists: listsObjectSelector(state),
})

const mapDispatchToProps = {
  moveListEntries,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MoveListEntries))