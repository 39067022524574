import React from 'react'
import {
  Route, Switch
} from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { withCookies } from 'react-cookie'
import { indigo } from '@material-ui/core/colors'
import { connect } from 'react-redux'

import 'react-loading-skeleton/dist/skeleton.css'
import './keyrune-extra.css'

import Initialize from './Initialize'
import Header from './Header'
import Notifier from './Notifier'
import Breadcrumbs from './Breadcrumbs'
import Footer from './Footer'
import HomePage from '../pages/HomePage'
import PricesPage from '../pages/PricesPage'
import CardPage from '../pages/CardPage'
import CardsPage from '../pages/CardsPage'
import CollectionPage from '../pages/CollectionPage'
import ImportListPage from '../pages/ImportListPage'
import MergeListsPage from '../pages/MergeListsPage'
import ListPage from '../pages/ListPage'
import SellPage from '../pages/SellPage'
import TradeRoutes from '../pages/SellPage/TradeRoutes'
import MerchantsPage from '../pages/MerchantsPage'
import MerchantPage from '../pages/MerchantsPage/MerchantPage'
import HiddenMerchantPage from '../pages/HiddenMerchantPage'
import BestOffers from '../pages/MerchantsPage/BestOffers'
import BestDeals from '../pages/MerchantsPage/BestDeals'
import SetsPage from '../pages/SetsPage'
import SetPage from '../pages/SetPage'
import IONScannerPage from '../pages/IONScannerPage'
import NotFoundPage from '../pages/NotFoundPage/NotFoundPage'

import Grid from '@material-ui/core/Grid'

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: '#f5f5f5',
      margin: 0,
      fontFamily: 'Open Sans, Roboto, Helvetica, sans-serif',
      color: 'rgb(51, 51, 51)',
    },
    a: {
      textDecoration: 'none',
      color: indigo[500],
      '&:hover': {
        textDecoration: 'underline',
        color: indigo[300],
      },
      '&:visited': {
        color: indigo[300],
      },
    },
  },
  container: {
    maxWidth: 1140,
    margin: 'auto',
    paddingLeft: 15,
    paddingRight: 15,
  },
  breadcrumbs: {
    marginTop: 20,
  },
  body: {
    marginTop: 20,
    marginBottom: 60,
  },
})

export class Layout extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
    }
  }

  render () {
    const { classes } = this.props

    return (
      <React.Fragment>
        <Notifier />
        <Header />
        <Grid container>
          <Grid item xs={12} className={classes.breadcrumbs}>
            <div className={classes.container}>
              <Breadcrumbs />
            </div>
          </Grid>
          <Grid item xs={12} className={classes.body}>
            <div className={classes.container}>
              <Initialize>
                <Switch>
                  <Route
                    exact path='/'
                    component={HomePage}
                  />
                  <Route
                    exact path={'/home'}
                    component={HomePage}
                  />
                  <Route
                    path={'/cards/:cardName'}
                    component={CardsPage}
                  />
                  <Route
                    exact path={'/prices'}
                    component={PricesPage}
                  />
                  <Route
                    exact path={'/prices/sets'}
                    component={SetsPage}
                  />
                  <Route
                    exact path={'/prices/sets/:setName'}
                    component={SetPage}
                  />
                  <Route
                    exact path={'/prices/sets/:setName/foil'}
                    component={SetPage}
                  />
                  <Route
                    path={'/prices/sets/:setName/:cardName/:foil?'}
                    component={CardPage}
                  />
                  <Route
                    exact path={'/collection'}
                    component={CollectionPage}
                  />
                  <Route
                    exact path={'/collection/import'}
                    component={ImportListPage}
                  />
                  <Route
                    exact path={'/collection/merge/:listId?'}
                    component={MergeListsPage}
                  />
                  <Route
                    exact path={'/collection/lists/:listId'}
                    component={ListPage}
                  />
                  <Route
                    exact path={'/sell'}
                    component={SellPage}
                  />
                  <Route
                    exact path={'/sell/:listId'}
                    component={TradeRoutes}
                  />
                  <Route
                    exact path={'/merchants'}
                    component={MerchantsPage}
                  />
                  <Route
                    exact path={'/merchants/hidden'}
                    component={HiddenMerchantPage}
                  />
                  <Route
                    exact path={'/merchants/:merchantName'}
                    component={MerchantPage}
                  />
                  <Route
                    exact path={'/merchants/:merchantName/bestoffers'}
                    component={BestOffers}
                  />
                  <Route
                    exact path={'/merchants/:merchantName/bestdeals'}
                    component={BestDeals}
                  />
                  <Route
                    exact path={'/ionscanner'}
                    component={IONScannerPage}
                  />
                  <Route
                    component={NotFoundPage}
                  />
                </Switch>
              </Initialize>
            </div>
          </Grid>
        </Grid>
        <Footer />
      </React.Fragment>
    )
  }
}

// TODO: Remove later. Just for testing.
const mapStateToProps = (state) => {
  return {
    sets: state.sets,
    cards: state.cards,
  }
}

export default connect(mapStateToProps)(withCookies(withStyles(styles)(Layout)))