export function calculateSelected({
  volume, remaining
}) {
  if (remaining === 0) {
    return {
      selected: 0,
      remaining: 0
    }
  }
  if (volume >= remaining) {
    return {
      selected: remaining,
      remaining: 0
    }
  } else {
    return {
      selected: volume,
      remaining: remaining - volume
    }
  }
}

export function selectOffers({
  prices,
  quantity,
  ignoreMerchants = null,
  selectedMerchants = null
}) {
  var remainingTally = Number(quantity)
  var position = 1

  let sortedOffers = prices.slice()
    .sort((a, b) => {
      var priceA = a.price
      var priceB = b.price
      return (priceB < priceA) ? -1 : (priceB > priceA) ? 1 : 0
    })

  let offers = sortedOffers.reduce((acc, offer) => {
    const {
      volume, merchant_id
    } = offer

    // If there are selected merchants, and this isn't one, we don't add it
    if (selectedMerchants && !selectedMerchants.includes(merchant_id)) {
      acc[position] = {
        ...offer,
        selected: 0
      }
      position ++
      return acc
    }

    // If the merchant is disabled, we don't add it here
    if (ignoreMerchants && ignoreMerchants.includes(merchant_id)) {
      acc[position] = {
        ...offer,
        selected: 0
      }
      position ++
      return acc
    }

    let {
      selected, remaining
    } = calculateSelected({
      volume,
      remaining: remainingTally
    })

    acc[position] = {
      ...offer,
      selected: selected
    }

    remainingTally = remaining
    position ++

    return acc
  }, {})

  return {
    offers,
    quantity,
    remaining: remainingTally,
  }
}

export function buildInitialTraderTools ({
  cards, entries, disabledMerchants,
}) {

  return entries.reduce((obj, entry) => {
    let {
      card_id, quantity, id
    } = entry

    if(cards[card_id]) {
      let { buylist_prices } = cards[card_id]

      let result = selectOffers({
        prices: buylist_prices,
        quantity,
        ignoreMerchants: disabledMerchants
      })

      obj[card_id] = {
        ...result,
        id,
      }
    }

    return obj
  }, {})
}
