import React from 'react'
import classNames from 'classnames'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import {
  indigo, blue, deepPurple, teal, lightBlue, purple, grey
} from '@material-ui/core/colors'
import {
  setBreadcrumbs, toggleSettings
} from '../../../store/layout/actionCreator'
import {
  ROUTER_PATHS, URLS
} from '../../../constants'

import PriceResearchIcon from '@material-ui/icons/ViewCarousel'
import CollectionIcon from '@material-ui/icons/List'
import SellCardsIcon from '@material-ui/icons/AttachMoney'
import MerchantsIcon from '@material-ui/icons/Store'
import IONScannerIcon from '@material-ui/icons/Camera'
import SettingsIcon from '@material-ui/icons/Settings'

import {
  Grid,
  Paper,
  Typography
} from '@material-ui/core'

import Alert from '@material-ui/lab/Alert'

const styles = {
  homePage: {
    textAlign: 'left',
    width: '100%',
    margin: 0,
  },
  icon: {
    width: 167,
    height: 167,
    margin: '5px auto',
    borderRadius: '.4rem',
    padding: 20,
  },
  indigo: {
    background: `linear-gradient(135deg, ${indigo[500]} 60%, ${indigo[600]} 60%)`,
    color: indigo[200],
  },
  indigoText: {
    color: indigo[500],
  },
  blue: {
    background: `linear-gradient(135deg, ${blue[500]} 60%, ${blue[600]} 60%)`,
    color: blue[200],
  },
  blueText: {
    color: blue[500],
  },
  deepPurple: {
    background: `linear-gradient(135deg, ${deepPurple[500]} 60%, ${deepPurple[600]} 60%)`,
    color: deepPurple[200],
  },
  deepPurpleText: {
    color: deepPurple[500],
  },
  teal: {
    background: `linear-gradient(135deg, ${teal[500]} 60%, ${teal[600]} 60%)`,
    color: teal[200],
  },
  tealText: {
    color: teal[500],
  },
  lightBlue: {
    background: `linear-gradient(135deg, ${lightBlue[500]} 60%, ${lightBlue[600]} 60%)`,
    color: lightBlue[200],
  },
  lightBlueText: {
    color: lightBlue[500],
  },
  purple: {
    background: `linear-gradient(135deg, ${purple[500]} 60%, ${purple[600]} 60%)`,
    color: purple[200],
  },
  purpleText: {
    color: purple[500],
  },
  grey: {
    background: `linear-gradient(135deg, ${grey[500]} 60%, ${grey[600]} 60%)`,
    color: grey[200],
  },
  greyText: {
    color: grey[500],
  },
  item: {
    textAlign: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 50,
    '&:hover': {
      textDecoration: 'underline',
    },
  }
}

class HomePage extends React.Component {
  static propTypes = {
    classes: object,
  }

  constructor(props) {
    super(props)
    props.setBreadcrumbs([
      {
        text: 'Trader Tools'
      },
    ])
  }

  handleSettingsToggle() {
    if (this.props.userLoggedIn) {
      this.props.toggleSettings()
    }
  }

  render () {
    const {
      classes, userLoggedIn
    } = this.props

    return (
      <div className={classes.homePage}>
        <Helmet>
          <title>Trader Tools | Quiet Speculation</title>
        </Helmet>
        <Paper style={{
          padding: 15,
          paddingTop: 45,
          paddingBottom: 45
        }}>
          <Grid container>
            <Grid item xs={12} sm={6} lg={4} className={classes.item}>
              <Link to={ROUTER_PATHS.PRICES}>
                <PriceResearchIcon className={classNames(classes.icon, classes.indigo)} />
                <Typography variant='h5' gutterBottom className={classes.indigoText}>
                  Price Research
                </Typography>
                <Typography variant='body1' gutterBottom className={classes.indigoText}>
                  Use our years of historical price data to do research on any card in MTG.
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} className={classes.item}>
              <Link to={ROUTER_PATHS.COLLECTION}>
                <CollectionIcon className={classNames(classes.icon, classes.blue)} />
                <Typography variant='h5' gutterBottom className={classes.blueText}>
                  Collection
                </Typography>
                <Typography variant='body1' gutterBottom className={classes.blueText}>
                  Manage your collection with our cloud based inventory tools.
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} className={classes.item}>
              <Link to={ROUTER_PATHS.SELL}>
                <SellCardsIcon className={classNames(classes.icon, classes.teal)} />
                <Typography variant='h5' gutterBottom className={classes.tealText}>
                  Sell Cards
                </Typography>
                <Typography variant='body1' gutterBottom className={classes.tealText}>
                  Use Trade Routes to automatically select the best price from each buylist.
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} className={classes.item}>
              <Link to={ROUTER_PATHS.MERCHANTS}>
                <MerchantsIcon className={classNames(classes.icon, classes.deepPurple)} />
                <Typography variant='h5' gutterBottom className={classes.deepPurpleText}>
                  Merchants
                </Typography>
                <Typography variant='body1' gutterBottom className={classes.deepPurpleText}>
                  Explore the best prices each merchant has, both retail and buylist.
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} className={classes.item}>
              <Link to={ROUTER_PATHS.IONSCANNER}>
                <IONScannerIcon className={classNames(classes.icon, classes.lightBlue)} />
                <Typography variant='h5' gutterBottom className={classes.lightBlueText}>
                  ION Scanner
                </Typography>
                <Typography variant='body1' gutterBottom className={classes.lightBlueText}>
                  The first MTG card scanner on the market, free with your Insider membership.
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} className={classes.item}>
              <SettingsIcon className={classNames(classes.icon, (userLoggedIn) ? classes.purple : classes.grey)} onClick={() => this.handleSettingsToggle()} style={{
                cursor: (userLoggedIn) ? 'pointer' : 'no-drop'
              }}/>
              <div onClick={() => this.handleSettingsToggle()} style={{
                cursor: (userLoggedIn) ? 'pointer' : 'no-drop'
              }}>
                <Typography variant='h5' gutterBottom className={(userLoggedIn) ? classes.purpleText : classes.greyText} >
                  Settings
                </Typography>
                <Typography variant='body1' gutterBottom className={(userLoggedIn) ? classes.purpleText : classes.greyText}>
                  Set your default active list and adjust which merchants you see.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userLoggedIn: state.user.loggedIn,
})

const mapDispatchToProps = {
  setBreadcrumbs,
  toggleSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HomePage))
