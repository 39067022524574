import React from 'react'
import { object } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { getMerchantCardLink } from '../../../utils/helpers'
import StoreIcon from '@material-ui/icons/ExitToApp'

const styles = {
  listLinks: {
    paddingBottom: 5,
    paddingTop: 5,
  },
  button: {
    color: 'white',
    color: 'white',
    '&:hover': {
      color: 'white',
    },
    '&:visited': {
      color: 'white',
    },
  },
  image: {
    maxHeight: 80,
  },
}

class MerchantHeader extends React.Component {
  static propTypes = {
    classes: object,
  }

  render () {
    const {
      classes, merchant, mode
    } = this.props
    const {
      merchant_name: merchantName,
      merchant_code: merchantCode,
      img_url: imgURL,
      buy_count: buyCount,
      retail_count: retailCount,
      merchant_id,
    } = merchant

    return (
      <Grid container spacing={1} style={{
        textAlign: 'center'
      }}>
        <Grid item xs={12}>
          <Link to={'/merchants/' + merchantCode}>
            {/* {imgURL ? (
              <img src={imgURL} className={classes.image} alt={merchantName} />
            ) : (
              <> */}
            {merchantCode === 'tcgplayer' ? 'TCGplayer' : merchantName}
            {/* </>
            )} */}
          </Link>
        </Grid>
        <Grid item xs={6} style={{
          textAlign: 'right'
        }}>
          <Button
            href={getMerchantCardLink({
              merchantID: merchant_id,
              mode: 'buylist',
              medium: 'trader-tools-browse-shops',
              type: 'store'
            })}
            target='_blank'
            variant='contained'
            color='secondary'
            className={classes.button}
            size='small'
          >
            <StoreIcon style={{
              lineHeight: '18px',
              verticalAlign: 'middle',
              fontSize: 20,
              paddingRight: 6
            }} /> Merchant Buylist
          </Button>
        </Grid>
        <Grid item xs={6} style={{
          textAlign: 'left'
        }}>
          <Button
            href={getMerchantCardLink({
              merchantID: merchant_id,
              medium: 'trader-tools-browse-shops',
              type: 'store'
            })}
            target='_blank'
            variant='contained'
            color='primary'
            className={classes.button}
            size='small'
          >
            <StoreIcon style={{
              lineHeight: '18px',
              verticalAlign: 'middle',
              fontSize: 20,
              paddingRight: 6
            }} /> Merchant Listings
          </Button>
        </Grid>
        <Grid item xs={6} style={{
          textAlign: 'right'
        }}>
          {buyCount > 0 &&
            <Button
              component={Link}
              to={ `/merchants/${merchantCode}/bestoffers` }
              variant='contained'
              color='secondary'
              className={classes.button}
              size='small'
              disabled={mode === 'buy'}
            >
              Best Offers (Buylist): {buyCount}
            </Button>
          }
        </Grid>
        <Grid item xs={6} style={{
          textAlign: 'left'
        }}>
          {retailCount > 0 &&
            <Button
              component={Link}
              to={ `/merchants/${merchantCode}/bestdeals` }
              variant='contained'
              color='primary'
              className={classes.button}
              size='small'
              disabled={mode === 'sell'}
            >
              Best Deals (Retail): {retailCount}
            </Button>
          }
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(MerchantHeader)
