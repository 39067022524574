import { combineReducers } from '@reduxjs/toolkit'
import layout from '../store/layout/reducer'
import sets from '../store/sets/reducer'
import merchants from '../store/merchants/reducer'
import reports from '../store/reports/reducer'
import cards from '../store/cards/reducer'
import user from '../store/user/reducer'
import collection from '../store/collection/reducer'
import tradeRoutes from '../store/tradeRoutes/reducer'

const rootReducer = combineReducers({
  layout,
  sets,
  merchants,
  reports,
  cards,
  user,
  collection,
  tradeRoutes,
})

export default rootReducer
