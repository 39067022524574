const forge = require('node-forge')

function encode64(data) {
  return forge.util.encode64(data)
}

function decode64(data) {
  return forge.util.decode64(data)
}

export function generate() {
  try {
    var key = forge.random.getBytesSync(16)
    var iv = forge.random.getBytesSync(16)
    if (!key || !iv) {
      throw new Error('Missing shh.')
    }
    return encode64(key) + '.' + encode64(iv)
  } catch(err) {
    return err
  }
}

export function decrypt(encrypted, obj) {
  try {
    let {
      result, error
    } = encrypted
    if (error) {
      return {
        result: null,
        error
      }
    }
    let [ key, iv ] = obj.split('.')
    if (!key || !iv) {
      throw new Error('Missing shh.')
    }
    let decipher = forge.cipher.createDecipher('AES-GCM', decode64(key))
    decipher.start({
      iv: decode64(iv),
      tag: {
        ...result.tag,
        data: decode64(result.tag.data)
      }
    })
    decipher.update(forge.util.createBuffer(decode64(result.data)))
    decipher.finish()

    return {
      result: unescape(decipher.output.data),
      error: false
    }
  } catch(err) {
    return {
      result: null,
      error: err
    }
  }
}
