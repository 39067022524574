import React from 'react'

import {
  TablePagination,
  Typography
} from '@material-ui/core'

const EnhancedTablePagination = (props) => {
  const {
    filteredCount,
    totalCount,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    rowsPerPageOptions,
    totalText = 'total cards'
  } = props

  return (
    <>
      <TablePagination
        component={'div'}
        count={filteredCount}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions || [10, 25, 50, 100, {
          'label': 'All',
          value: -1
        }]}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        labelDisplayedRows={({
          from, to, count
        }) => {
          return `${from}-${to === -1 ? count : to} of ${count !== -1 ? count : `more than ${to}`}`
        }}
      />
      {totalCount !== null && (
        <Typography style={{
          textAlign: 'right'
        }}>
          {totalCount} {totalText}
        </Typography>
      )}
    </>
  )
}

export default EnhancedTablePagination