import React from 'react'
import { connect } from 'react-redux'
import {
  Button, Grid
} from '@material-ui/core'
import Skeleton from 'react-loading-skeleton'
import Widget from '../../shared/Widget'
import HistoricPricesGraph from './HistoricPricesGraph'

import {
  getHistoricPrices, setHistoricPricesLoading
} from '../../../store/cards/actionCreator'

class PriceGraphs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showGraph: props.card.historicPrices.loaded || false
    }
  }

  componentDidUpdate() {
    const { loaded } = this.props
    if (loaded) {
      this.handleShowGraph()
    }
  }

  handleShowGraph = () => {
    let {
      card_id, historicPrices
    } = this.props.card
    if (card_id !== '' && !historicPrices.loaded && !historicPrices.loading) {
      this.props.setHistoricPricesLoading(card_id)
      this.props.getHistoricPrices(card_id)
    }
  }

  render () {
    const {
      loaded, card
    } = this.props
    const { historicPrices } = card

    return (
      <>
        <Grid item xs={12}>
          <Widget
            header={'Historical Price Data'}
            style={{
              textAlign: 'center',
              overflow: 'hidden',
              margin: '1.78rem 0 .5rem'
            }}
          >
            <div style={{
              paddingBottom: 24
            }}>
              {loaded ? (
                <React.Fragment>
                  {(historicPrices.loaded === false || historicPrices.error === true) &&
                    <Skeleton height={572} count={1} />
                  }
                  {historicPrices.error === true &&
                    <React.Fragment>
                      <span>There was an error fetching prices. You can try again.</span>
                      <Button variant='outlined' color='primary' onClick={() => this.handleShowGraph()}>
                        Load Historical Price Data
                      </Button>
                    </React.Fragment>
                  }
                  {historicPrices.loaded === true && historicPrices.error === false &&
                    <HistoricPricesGraph data={historicPrices.data} />
                  }
                </React.Fragment>
              ) : (
                <Skeleton height={37} />
              )}

            </div>
          </Widget>
        </Grid>
      </>
    )
  }
}

const mapDispatchToProps = {
  getHistoricPrices,
  setHistoricPricesLoading,
}

export default connect(null, mapDispatchToProps)(PriceGraphs)
