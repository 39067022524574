import React from 'react'
import {
  Link, withRouter
} from 'react-router-dom'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import { withStyles } from '@material-ui/core/styles'

import { getListsWithCardResults } from '../../utils/helpers'
import {
  getListsWithCard,
  saveListEntry
} from '../../store/collection/actionCreator'
import { listsObjectSelector } from '../../store/reselect'
import Keyrune from '../shared/Keyrune'
import CardImageModal from '../shared/CardImageModal'
import ListSelect from './ListSelect'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const styles = {
  secondaryNavTab: {
    color: 'white',
    height: 50,
    fontFamily: 'Average Sans, Open Sans, Varela Round ,sans-serif',
    textTransform: 'none',
  },
  qtyGrid: {
    margin: 'auto',
    paddingTop: 8,
    paddingBottom: 8,
  },
  saveButton: {
    marginRight: 6,
  },
  paper: {
    overflow: 'visible',
  }
}

let getListStateData = (lists, listId) => {
  let {
    activeListId, entryId, initialQty, updatedQty
  } = (lists && listId && lists[listId]) ? {
    activeListId: parseInt(listId),
    entryId: lists[listId].entry_id,
    initialQty: lists[listId].quantity,
    updatedQty: lists[listId].quantity
  } : {
    entryId: null,
    activeListId: null,
    initialQty: 0,
    updatedQty: 0
  }
  return {
    entryId,
    activeListId,
    initialQty,
    updatedQty
  }
}

let getDefaultList = (listId, activeList) => {
  if (!listId) {
    if (activeList !== null && activeList !== 0) {
      return parseInt(activeList)
    }
  } else if (listId !== null && listId !== 0) {
    return parseInt(listId)
  }
  return 'no_list'
}

class ListEntryEditorModal extends React.Component {
  static propTypes = {
    classes: object,
  }

  constructor(props) {
    super(props)
    let {
      allListsWithQuantity, listId, activeList
    } = props

    let {
      activeListId, entryId, initialQty, updatedQty
    } = getListStateData(allListsWithQuantity, getDefaultList(listId, activeList))
    this.state = {
      hidden: true,
      open: false,
      loadingListsWithCard: false,
      entryId,
      activeListId,
      initialQty,
      updatedQty: (updatedQty > 0) ? updatedQty : 0,
    }
  }

  updateListStateData = (listId) => {
    const { allListsWithQuantity } = this.props
    let {
      activeListId, entryId, initialQty, updatedQty
    } = getListStateData(allListsWithQuantity, listId)
    this.setState({
      entryId,
      activeListId,
      initialQty,
      updatedQty: (updatedQty > 0) ? updatedQty : 0,
    })
  }

  componentDidUpdate(prevProps) {
    const {
      allListsWithQuantity, listId, activeList
    } = this.props
    if (allListsWithQuantity !== prevProps.allListsWithQuantity) {
      this.updateListStateData(getDefaultList(listId, activeList))
    }
  }

  handleOpen = () => {
    this.setState({
      hidden: false,
    }, () => {
      let {
        card, listsWithCardLoaded
      } = this.props
      let {
        card_id, scryfall_id, foil
      } = card
      if (!this.state.loadingListsWithCard && !listsWithCardLoaded && card_id) {
        this.setState({
          loadingListsWithCard: true
        }, () => {
          this.props.getListsWithCard(card_id, scryfall_id, foil)
        })
      }
      this.setState({
        open: true
      })
    })
  }

  handleClose = () => {
    this.setState({
      open: false,
      updatedQty: this.state.initialQty
    })
  }

  handleAddAndSave = (qty) => {
    let updatedQty = parseInt(this.state.initialQty) + parseInt(qty)
    this.setState({
      updatedQty: (updatedQty > 0) ? updatedQty : 0
    }, () => {
      this.handleSave()
    })
  }

  handleSave = () => {
    let { card } = this.props
    let {
      activeListId, updatedQty, entryId, initialQty
    } = this.state

    let cardObj = {
      gatherer_id: card.gatherer_id,
      scryfallId: card.scryfall_id,
      foil: card.foil,
      quantity: updatedQty,
      listId: activeListId,
      cardId: card.card_id,
    }

    this.props.saveListEntry(entryId, cardObj, initialQty)

    this.setState({
      open: false
    })
  }

  handleDelete = () => {
    let { card } = this.props
    let {
      activeListId, entryId, initialQty
    } = this.state

    let cardObj = {
      gatherer_id: card.gatherer_id,
      scryfallId: card.scryfall_id,
      foil: card.foil,
      quantity: 0,
      listId: activeListId,
      cardId: card.card_id,
    }

    this.props.saveListEntry(entryId, cardObj, initialQty)

    this.setState({
      open: false
    })
  }

  handleOnListChange = (listId) => {
    this.updateListStateData(listId)
  }

  updateQty = (val) => {
    let updatedQty = parseInt(val)
    this.setState({
      updatedQty: (updatedQty > 0) ? updatedQty : 0
    })
  }

  adjustQty = (val) => {
    let updatedQty = parseInt(this.state.updatedQty) + parseInt(val)
    this.setState({
      updatedQty: (updatedQty > 0) ? updatedQty : 0
    })
  }

  render () {
    const {
      card,
      classes,
      listId,
      activeList,
      listsWithCard,
      listsWithoutCard,
      listsWithCardLoaded,
      textButton
    } = this.props

    const {
      hidden,
      open,
      entryId,
      initialQty,
      updatedQty,
      activeListId,
    } = this.state

    const {
      card_name, set_code, set_name, rarity, foil, gatherer_id, scryfall_id, variant
    } = card

    const hasKey = scryfall_id !== '' || scryfall_id !== null || gatherer_id !== '' || gatherer_id !== null

    return (
      <React.Fragment>
        {textButton ? (
          <Button aria-label='Edit' disabled={!hasKey} size='small' onClick={this.handleOpen}>
            <AddIcon /> Open List Editor
          </Button>
        ) : (
          <Button aria-label='Edit' disabled={!hasKey} size='small' variant='outlined' color='primary' onClick={this.handleOpen} style={{
            minWidth: 32
          }}>
           +/-
          </Button>
        )}
        {!hidden &&
          <Dialog
            open={open}
            onClose={this.handleClose}
            aria-labelledby='form-dialog-title'
            maxWidth={'sm'}
            fullWidth
            PaperProps ={{
              classes: {
                root: classes.paper
              }
            }}
          >
            <DialogTitle>List Editor</DialogTitle>
            <DialogContent
              style={{
                overflow: 'visible',
              }}
            >
              <Grid container>
                <Grid item xs={12} style={{
                  paddingBottom: 32
                }}>
                  <Typography variant={'h4'} color='primary' style={{
                    margin: '0 0 .5rem'
                  }}>
                    <CardImageModal scryfallId={scryfall_id} style={{
                      paddingRight: 6
                    }} />
                    <Link to={`/prices/sets/${encodeURIComponent(set_name)}/${encodeURIComponent(card_name)}${(foil === 1) ? '/foil' : ''}?variant=${scryfall_id}`}>
                      {card_name} {variant === 1 && '(Variant)'}
                    </Link>
                  </Typography>
                  <Link to={`/prices/sets/${encodeURIComponent(set_name)}${(foil === 1) ? '/foil' : ''}`} onClick={() => this.handleNavigation()}>
                    <span style={{
                      paddingRight: 6
                    }}><Keyrune set={set_code} rarity={rarity} /></span> {set_name} {foil === 1 ? ' - Foil' : ' - Nonfoil'}
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  {listsWithCardLoaded ? (
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid
                          container
                          style={{
                            paddingBottom: 24,
                            textAlign: 'center'
                          }}
                        >
                          <Grid item xs={6} sm={2} className={classes.qtyGrid}>
                            <Button size='large' variant='outlined' disabled={activeListId === null}  onClick={() => this.adjustQty(-4)}>
                              -4
                            </Button>
                          </Grid>
                          <Grid item xs={6} sm={2} className={classes.qtyGrid}>
                            <Button size='large' variant='outlined' disabled={activeListId === null}  onClick={() => this.adjustQty(-1)}>
                              -1
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={4} className={classes.qtyGrid}>
                            <TextField
                              variant='outlined'
                              inputProps={{
                                style: {
                                  textAlign: 'center'
                                }
                              }}
                              value={updatedQty}
                              onChange={e => this.updateQty(e.target.value)}
                              type='number'
                              autoFocus={true}
                              disabled={activeListId === null}
                            />
                          </Grid>
                          <Grid item xs={6} sm={2} className={classes.qtyGrid}>
                            <Button size='large' variant='outlined' disabled={activeListId === null}  onClick={() => this.adjustQty(1)}>
                              +1
                            </Button>
                          </Grid>
                          <Grid item xs={6} sm={2} className={classes.qtyGrid}>
                            <Button size='large' variant='outlined' disabled={activeListId === null} onClick={() => this.adjustQty(4)}>
                              +4
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} style={{
                        paddingBottom: 32
                      }}>
                        <ListSelect
                          listId={getDefaultList(listId, activeList)}
                          listsWithCard={listsWithCard}
                          listsWithoutCard={listsWithoutCard}
                          onSelect={(listId) => this.handleOnListChange(listId)}
                        />
                        <div style={{
                          paddingTop: 6,
                          fontSize: '.9rem'
                        }}>
                          {activeListId && activeListId !== 'no_list' ? (
                            <Link to={`/collection/lists/${activeListId}`} title={activeListId} alt={activeListId}>Go to list</Link>
                          ) : (
                            <Link to={'/collection'}>Manage lists</Link>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  ) : (
                    <Skeleton
                      height={202}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container>
                <Grid item xs={12} sm={2} style={{
                  textAlign: 'left'
                }}>
                  <Button onClick={this.handleDelete} color='primary' disabled={!entryId || initialQty === 0 || activeListId === null}>
                    Delete
                  </Button>
                </Grid>
                <Grid item xs={12} sm={10}  style={{
                  textAlign: 'right'
                }}>
                  <Button
                    onClick={() => this.handleAddAndSave(2)}
                    variant='outlined'
                    color='secondary'
                    disabled={activeListId === null}
                    className={classes.saveButton}
                  >
                    Save+2
                  </Button>
                  <Button
                    onClick={() => this.handleAddAndSave(4)}
                    variant='outlined'
                    color='secondary'
                    disabled={activeListId === null}
                    className={classes.saveButton}
                  >
                    Save+4
                  </Button>
                  <Button
                    onClick={this.handleSave}
                    variant='outlined'
                    color='secondary'
                    disabled={initialQty === updatedQty || activeListId === null}
                    className={classes.saveButton}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={this.handleClose}
                    color='primary'
                  >
                    Close
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let lists = listsObjectSelector(state)
  let {
    listsWithCard, listsWithCardLoaded, listsWithoutCard
  } = getListsWithCardResults(true, lists, state.collection.listsWithCard, ownProps.cardId)
  let card = state.cards.entries[ownProps.cardId]
  return {
    card,
    listsWithCard,
    listsWithoutCard,
    allListsWithQuantity: [...listsWithCard, ...listsWithoutCard].reduce((obj, item) => {
      if (item?.list?.id) {
        obj[item.list.id] = item
      }
      return obj
    }, {}),
    listsWithCardLoaded,
    activeList: state.user.activeList,
  }
}

const mapDispatchToProps = {
  getListsWithCard,
  saveListEntry
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(ListEntryEditorModal)))
