export const getStatusCounts = (tableData) => {
  let successesCount = tableData.filter(v => v.success === true).length
  let duplicatesCount = tableData.filter(v => v.duplicate === true).length
  let correctionsCount = tableData.filter(v => v.corrected === true).length
  let errorsCount = tableData.filter(v => v.error === true).length
  return {
    successesCount,
    duplicatesCount,
    correctionsCount,
    errorsCount
  }
}

export const sortTable = (data, orderBy, order) => {
  if (['card_name', 'set_name'], 'success'.includes(orderBy)) {
    return (order === 'desc')
      ? data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1))
      : data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
  } else {
    return (order === 'desc')
      ? data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
      : data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1))
  }
}

export const categorizeTableData = (list) => {
  return list.map((v, i) => {
    let isDuplicate = false
    let { key } = v

    if (v.success && v.data && v.data.scryfall_id) {
      let duplicates = list.filter(item => {
        if (item.data && item.data.scryfall_id) {
          return item.data.scryfall_id === v.data.scryfall_id && parseInt(item.data.foil) === parseInt(v.data.foil)
        }
      })

      if (duplicates.length > 1) {
        isDuplicate = true
      }
    }

    return {
      ...v,
      duplicate: isDuplicate,
      error: !v.success
    }
  })
}

export const filterTableData = ({
  tableDataCategorized, successes, duplicates, corrections,errors
}) => {
  return tableDataCategorized.filter(v => {
    if (!successes && v.success) {
      if (duplicates && v.duplicate) {
        return true
      }
      if (corrections && v.corrected) {
        return true
      }
      return false
    }

    if (!duplicates && v.duplicate) {
      if (corrections && v.corrected) {
        return true
      }
      if (successes && v.success) {
        return true
      }
      return false
    }

    if (!corrections && v.corrected) {
      if (duplicates && v.duplicate) {
        return true
      }
      if (errors && v.error) {
        return true
      }
      if (successes && v.success) {
        return true
      }
      return false
    }

    if (!errors && v.error) {
      if (corrections && v.corrected) {
        return true
      }
      return false
    }

    return true
  })
}