import React from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import {
  BrowserRouter, Route, Switch
} from 'react-router-dom'
import Layout from './Layout/Layout'
import muiTheme from '../config/themeConfig'

export class App extends React.Component {
  render () {
    return (
      <MuiThemeProvider theme={muiTheme}>
        <BrowserRouter>
          <Switch>
            <Route path="/" component={Layout} />
          </Switch>
        </BrowserRouter>
      </MuiThemeProvider>
    )
  }
}

export default App