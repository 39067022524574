import React from 'react'
// import PropTypes, { object } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  green, red, lightBlue, indigo
} from '@material-ui/core/colors'
import { Link } from 'react-router-dom'

import ListEntryEditorModal from '../../ListEntryEditorModal'
import CardImageModal from '../../shared/CardImageModal'

import Keyrune from '../../shared/Keyrune'
import {
  convertToUSD, convertToPercent
} from '../../../utils/helpers'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

const styles = {
  positive: {
    color: green[500],
    fontWeight: 600,
  },
  negative: {
    color: red[500],
    fontWeight: 600,
  },
  cell: {
    padding: '0 0 0 5px',
  },
  buylist: {
    color: green[500],
  },
  retail: {
    color: lightBlue[400],
  },
  switch: {
    height: 25,
  },
  label: {
    fontSize: '.8em',
  },
  buylistLabel: {
    color: green[500],
    fontWeight: 600,
  },
  retailLabel: {
    color: lightBlue[400],
    fontWeight: 600,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 3,
  },
  buyMerchant: {
    color: green[500],
    textAlign: 'center',
    fontSize: '75%',
    verticalAlign: 'baseline',
    borderRadius: '.25em',
    padding: 3,
  },
  buyCell: {
    background: green[500],
    color: 'white',
    textAlign: 'center',
  },
  buyVolumeCell: {
    color: green[500],
    textAlign: 'center',
  },
  spreadCell: {
    background: indigo[300],
    color: 'white',
    textAlign: 'center',
  },
  spreadCellPositive: {
    background: indigo[200],
    color: 'white',
    textAlign: 'center',
  },
  retailCell: {
    background: lightBlue[400],
    color: 'white',
    textAlign: 'center',
  },
  retailMerchant: {
    color: lightBlue[400],
    textAlign: 'center',
    fontSize: '75%',
    verticalAlign: 'baseline',
    borderRadius: '.25em',
    padding: 3,
    marginLeft: 3,
  },
}

class Row extends React.Component {

  render () {
    const {
      classes,
      card,
      listId,
      isOwner,
    } = this.props

    const {
      set_name,
      card_name,
      card_id,
      foil,
      set_code,
      rarity,
      buy_merchant_name,
      buy_merchant_code,
      buy_volume,
      buy_best,
      spread_best_usd,
      spread_best_pct,
      retail_best,
      retail_merchant_name,
      retail_merchant_code,
      quantity,
      variant,
      collector_number,
      scryfall_id,
    } = card

    const variantURI = `?variant=${scryfall_id}`

    return (
      <TableRow style={{
        height: 36
      }}>
        <TableCell padding={'none'}>
          <CardImageModal scryfallId={scryfall_id} style={{
            paddingRight: 6
          }} />
          <Link to={`/prices/sets/${encodeURIComponent(set_name)}/${encodeURIComponent(card_name)}${(foil === 1) ? '/foil' : ''}${variantURI}`}>
            {card_name} {variant === 1 && '(Variant)'}
          </Link>
        </TableCell>
        <TableCell padding={'none'}>
          <Link to={`/prices/sets/${encodeURIComponent(set_name)}${(foil === 1) ? '/foil' : ''}`}>
            <Keyrune set={set_code} rarity={rarity} /> {set_name}
          </Link>
        </TableCell>
        <TableCell padding={'none'}>
          {rarity.charAt(0).toUpperCase()}
        </TableCell>
        <TableCell padding={'none'}>
          {collector_number}
        </TableCell>
        <TableCell padding={'none'} style={{
          textAlign: 'right'
        }}>
          {buy_merchant_name &&
            <Link to={`/merchants/${buy_merchant_code}`}>
              <span className={classes.buyMerchant}>{buy_merchant_name}</span>
            </Link>
          }
        </TableCell>
        <TableCell padding={'none'} className={classes.buyVolumeCell}>
          {buy_volume ? (parseInt(buy_volume) === 99 ? <>&infin;</> : parseInt(buy_volume)) : null}
        </TableCell>
        <TableCell padding={'none'} className={buy_best ? classes.buyCell : null} title={buy_merchant_name}>
          {buy_best ? convertToUSD(buy_best) : null}
        </TableCell>
        <TableCell padding={'none'} className={spread_best_usd ? (spread_best_usd > 0 ? classes.spreadCellPositive : classes.spreadCell) : null}>
          {spread_best_usd ? convertToUSD(spread_best_usd) : null}
        </TableCell>
        <TableCell padding={'none'} className={spread_best_pct ? (spread_best_pct > 0 ? classes.spreadCellPositive : classes.spreadCell) : null}>
          {spread_best_pct ? convertToPercent(spread_best_pct) : null}
        </TableCell>
        <TableCell padding={'none'} className={retail_best ? classes.retailCell : null} title={retail_merchant_name}>
          {retail_best ? convertToUSD(retail_best) : null}
        </TableCell>
        <TableCell padding={'none'}>
          {retail_merchant_name &&
            <Link to={`/merchants/${retail_merchant_code}`}>
              <span className={classes.retailMerchant}>{retail_merchant_name}</span>
            </Link>
          }
        </TableCell>
        <TableCell padding={'none'}>
          <span>{(foil === 1) && 'Foil'}</span>
        </TableCell>
        <TableCell padding={'none'}>
          <span>{quantity}</span>
        </TableCell>
        {isOwner &&
          <TableCell padding={'none'} align='center'>
            <ListEntryEditorModal
              cardId={card_id}
              listId={listId}
            />
          </TableCell>
        }
      </TableRow>
    )
  }
}

export default withStyles(styles)(Row)
