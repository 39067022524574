import React from 'react'
// import PropTypes, { object } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  green, lightBlue, indigo
} from '@material-ui/core/colors'
import { Link } from 'react-router-dom'
import Auth from '../../shared/Auth'

import ListEntryEditorModal from '../../ListEntryEditorModal'
import Keyrune from '../../shared/Keyrune'
import CardImageModal from '../../shared/CardImageModal'
import {
  convertToUSD, convertToPercent
} from '../../../utils/helpers'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Hidden from '@material-ui/core/Hidden'

const styles = {
  cell: {
    padding: '0 0 0 5px',
  },
  buyCell: {
    background: green[500],
    color: 'white',
    textAlign: 'center',
  },
  buyVolumeCell: {
    color: green[500],
    textAlign: 'center',
  },
  spreadCell: {
    background: indigo[300],
    color: 'white',
    textAlign: 'center',
  },
  spreadCellPositive: {
    background: indigo[200],
    color: 'white',
    textAlign: 'center',
  },
  retailCell: {
    background: lightBlue[400],
    color: 'white',
    textAlign: 'center',
  },
  buyMerchant: {
    color: green[500],
    textAlign: 'center',
    fontSize: '75%',
    verticalAlign: 'baseline',
    borderRadius: '.25em',
    padding: 3,
  },
}

class Row extends React.Component {

  handleNavigation = () => {
    this.props.onNavigation()
  }

  render () {
    const {
      classes, card, defaultList, userLoggedIn
    } = this.props

    if (card.error) {
      return (
        <TableRow >
          <TableCell colSpan={9} style={{
            padding: 0,
            height: 32,
            textAlign: 'right',
          }}>
            <em>Something went wrong with this card. We've made note of it.</em>
          </TableCell>
        </TableRow>
      )
    }

    const {
      gatherer_id,
      set_name,
      card_name,
      card_id,
      foil,
      set_code,
      rarity,
      buy_merchant_name,
      buy_volume,
      buy_best,
      spread_best_usd,
      spread_best_pct,
      retail_best,
      retail_merchant_name,
      variant,
      collector_number,
      scryfall_id,
    } = card

    return (
      <TableRow style={{
        height: 36
      }}>
        <TableCell padding={'none'}>
          <CardImageModal scryfallId={scryfall_id} style={{
            paddingRight: 6
          }} />
          <Link to={`/prices/sets/${encodeURIComponent(set_name)}/${encodeURIComponent(card_name)}${(foil === 1) ? '/foil' : ''}?variant=${scryfall_id}`}>
            {card_name} {variant === 1 && '(Variant)'}
          </Link>
        </TableCell>
        <TableCell padding={'none'}>
          <Link to={`/prices/sets/${encodeURIComponent(set_name)}${(foil === 1) ? '/foil' : ''}`} onClick={() => this.handleNavigation()}>
            <Keyrune set={set_code} rarity={rarity} />
            <Hidden smDown> {set_name}</Hidden>
          </Link>
        </TableCell>
        <TableCell padding={'none'}>
          <Hidden mdUp>
            <span>{(foil === 1) && 'F'}</span>
          </Hidden>
          <Hidden smDown>
            <span>{(foil === 1) && 'Foil'}</span>
          </Hidden>
        </TableCell>
        <TableCell padding={'none'}>
          {collector_number}
        </TableCell>
        <Hidden smDown>
          <TableCell padding={'none'} className={classes.buyVolumeCell}>
            {userLoggedIn ? (
              <>
                {buy_volume ? (parseInt(buy_volume) === 99 ? <>&infin;</> : parseInt(buy_volume)) : null}
              </>
            ) : (
              <Link to={'/merchants/hidden'}>
                <span className={classes.buyMerchant}>Hidden</span>
              </Link>
            )}
          </TableCell>
        </Hidden>
        <TableCell padding={'none'} className={buy_best ? classes.buyCell : null} title={buy_merchant_name}>
          {buy_best ? convertToUSD(buy_best) : null}
        </TableCell>
        <Hidden smDown>
          <TableCell padding={'none'} className={spread_best_usd ? (spread_best_usd > 0 ? classes.spreadCellPositive : classes.spreadCell) : null}>
            {spread_best_usd ? convertToUSD(spread_best_usd) : null}
          </TableCell>
          <TableCell padding={'none'} className={spread_best_pct ? (spread_best_pct > 0 ? classes.spreadCellPositive : classes.spreadCell) : null}>
            {spread_best_pct ? convertToPercent(spread_best_pct) : null}
          </TableCell>
        </Hidden>
        <TableCell padding={'none'} className={retail_best ? classes.retailCell : null} title={retail_merchant_name}>
          {retail_best ? convertToUSD(retail_best) : null}
        </TableCell>
        <Auth
          LoggedIn={
            <TableCell padding={'none'} style={{
              textAlign: 'center'
            }}>
              {card_id &&
                <ListEntryEditorModal
                  cardId={card_id}
                  listId={defaultList || null}
                  onSelect={() => null}
                />
              }
            </TableCell>
          }
        />
      </TableRow>
    )
  }
}

export default withStyles(styles)(Row)
