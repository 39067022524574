import { fetch } from '../../utils/requests'

export function fetchCardsByCardName ({
  cardName, setName, accessToken
}) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: `/cards/${encodeURIComponent(cardName)}`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      params: {
        setName,
      },
      baseURL: '/api',
      timeout: 120000,
    })
      .then(v => resolve(v))
      .catch(e => reject(e))
  })
}

export function fetchAllPrintings ({
  cardName, accessToken
}) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: `/cards/${encodeURIComponent(cardName)}/all`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      baseURL: '/api',
      timeout: 120000,
    })
      .then(v => resolve(v))
      .catch(e => reject(e))
  })
}

export function fetchCardsAutocomplete ({
  orderBy,
  order,
  page,
  perPage,
  type,
  seed,
  accessToken
}, signal) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: '/cards/auto',
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      baseURL: '/api',
      timeout: 120000,
      params: {
        orderBy,
        order,
        page,
        perPage,
        type,
        seed,
      },
      signal
    })
      .then(v => resolve(v))
      .catch(e => reject(e))
  })
}

export function fetchHistoricPrices (cardId, accessToken) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: `/cards/cid/${encodeURIComponent(cardId)}/prices/daily`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      baseURL: '/api',
      timeout: 120000,
    })
      .then(v => resolve(v))
      .catch(e => reject(e))
  })
}

// export function fetchAmazonResults (cardName, setName, foil, accessToken) {
//   return new Promise(function (resolve, reject) {
//     fetch({
//       url: `/amazon/${cardName} ${cardName} ${((foil !== 0 && foil !== false) ? ' foil' : '')}`,
//       method: 'get',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': accessToken,
//       },
//       baseURL: '/api',
//       timeout: 120000,
//     })
//       .then(v => resolve(v))
//       .catch(e => reject(e))
//   })
// }


export function fetchTcgplayerArticles ({
  cardName, accessToken
}) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: `/cards/tcgp/articles/${encodeURIComponent(cardName)}`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      baseURL: '/api',
      timeout: 120000,
    })
      .then(v => resolve(v))
      .catch(e => reject(e))
  })
}

export function fetchTcgplayerDecks ({
  cardName, accessToken
}) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: `/cards/tcgp/decks/${encodeURIComponent(cardName)}`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      baseURL: '/api',
      timeout: 120000,
    })
      .then(v => resolve(v))
      .catch(e => reject(e))
  })
}
