import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'

import {
  updateList,
  createList,
  deleteList
} from '../../store/collection/actionCreator'

import {
  LIST_TYPES, FORMATS, EMPTY_LIST
} from '../../constants'

const styles = {
  formControl: {
    width: '100%',
    paddingBottom: 28
  },
}

class ListEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      id: props.list.id || EMPTY_LIST.id,
      ogName: props.list.name || EMPTY_LIST.name,
      name: props.list.name || EMPTY_LIST.name,
      owner: props.list.owner || props.userId,
      format_id: props.list.format_id || EMPTY_LIST.format_id,
      notes: props.list.notes || EMPTY_LIST.notes,
      tags: props.list.tags || EMPTY_LIST.tags,
      is_public: props.list.is_public || EMPTY_LIST.is_public,
      is_deleted: props.list.is_deleted || EMPTY_LIST.is_deleted,
      created_at: props.list.is_public || EMPTY_LIST.created_at,
      updated_at: props.list.is_public || EMPTY_LIST.updated_at,
      list_type_id: props.list.is_public || EMPTY_LIST.list_type_id,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.open && prevProps.open !== this.props.open) {
      this.setState({
        open: true,
        id: this.props.list.id || EMPTY_LIST.id,
        ogName: this.props.list.name || EMPTY_LIST.name,
        name: this.props.list.name || EMPTY_LIST.name,
        owner: this.props.list.owner || this.props.userId,
        format_id: this.props.list.format_id || EMPTY_LIST.format_id,
        notes: this.props.list.notes || EMPTY_LIST.notes,
        tags: this.props.list.tags || EMPTY_LIST.tags,
        is_public: this.props.list.is_public || EMPTY_LIST.is_public,
        is_deleted: this.props.list.is_deleted || EMPTY_LIST.is_deleted,
        created_at: this.props.list.created_at || EMPTY_LIST.created_at,
        updated_at: this.props.list.updated_at || EMPTY_LIST.updated_at,
        list_type_id: this.props.list.list_type_id || EMPTY_LIST.list_type_id,
      })
    }
  }

  handleClickOpen() {
    this.setState({
      open: true
    })
  }

  handleClose() {
    this.setState({
      open: false
    }, () => {
      if (this.props.onListToggle) {
        this.props.onListToggle(false)
      }
    })
  }

  handleChange(key, e) {
    this.setState({
      [key]: e.target.value
    })
  }

  handleToggleChange(key) {
    this.setState({
      [key]: (!this.state[key] === true) ? 1 : 0
    })
  }

  handleSave() {
    let list = {
      id: this.state.id,
      format_id: parseInt(this.state.format_id),
      name: this.state.name,
      notes: this.state.notes,
      owner: this.state.owner,
      tags: this.state.tags,
      is_public: this.state.is_public,
      is_deleted: this.state.is_deleted,
      list_type_id: parseInt(this.state.list_type_id),
    }
    this.setState({
      open: false,
    }, () => {
      if (this.props.onListToggle) {
        this.props.onListToggle(false)
      }
      if (this.props.onListSave) {
        this.props.onListSave(list)
      }
      if (!list.id) {
        // If no ID, it's being created...
        this.props.createList(list)
      } else {
        // If there is an ID, we're updating it...
        this.props.updateList(list)
      }
    })
  }

  handleArchive() {
    // let list = {
    //   id: this.state.id,
    //   format_id: parseInt(this.state.format_id),
    //   name: this.state.name,
    //   notes: this.state.notes,
    //   owner: this.state.owner,
    //   tags: this.state.tags,
    //   is_public: this.state.is_public,
    //   is_deleted: 1,
    //   list_type_id: this.state.list_type_id,
    // }
    // this.setState({
    //   open: false,
    // }, () => {
    //   if (this.props.onListToggle) {
    //     this.props.onListToggle(false)
    //   }
    //   this.props.updateList(list)
    // })
    let listId = this.state.id
    this.setState({
      open: false,
    }, () => {
      if (this.props.onListToggle) {
        this.props.onListToggle(false)
      }
      this.props.deleteList(listId)
    })
  }

  handleRestore() {
    let list = {
      id: this.state.id,
      format_id: parseInt(this.state.format_id),
      name: this.state.name,
      notes: this.state.notes,
      owner: this.state.owner,
      tags: this.state.tags,
      is_public: this.state.is_public,
      is_deleted: 0,
      list_type_id: parseInt(this.state.list_type_id),
    }
    this.setState({
      open: false,
    }, () => {
      if (this.props.onListToggle) {
        this.props.onListToggle(false)
      }
      this.props.updateList(list)
    })
  }

  render() {
    const {
      id, open, name, notes, format_id, list_type_id, is_deleted, is_public, ogName
    } = this.state

    const {
      hideButton, classes
    } = this.props

    return (
      <div style={{
        display: 'inline-block',
        float: 'right'
      }}>
        {!hideButton &&
          <IconButton aria-label='Delete' size='small' onClick={() => this.handleClickOpen()}>
            <EditIcon />
          </IconButton>
        }
        <Dialog
          open={open}
          onClose={() => this.handleClose()}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>{ogName}</DialogTitle>
          <DialogContent style={{
            minWidth: 500
          }}>
            <Grid container>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <TextField
                    autoFocus
                    value={name}
                    onChange={(e) => this.handleChange('name', e)}
                    margin='dense'
                    label={'List Name'}
                    type='text'
                    fullWidth
                    multiline
                  />
                  <FormHelperText>You can change the name of your list anytime.</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <TextField
                    value={notes}
                    onChange={(e) => this.handleChange('notes', e)}
                    margin='dense'
                    label={'List Notes'}
                    type='text'
                    fullWidth
                    multiline
                  />
                  <FormHelperText>Anything you want to remember related to this list.</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor='list_type_id'>List Type</InputLabel>
                  <Select
                    value={list_type_id}
                    onChange={(e) => this.handleChange('list_type_id', e)}
                    input={<Input name='list_type_id' id='list_type_id' />}
                    fullWidth
                  >
                    {Object.keys(LIST_TYPES).map(v =>
                      <MenuItem value={v} key={'list_type_id_' + v}>
                        {LIST_TYPES[v]}
                      </MenuItem>)}
                  </Select>
                  <FormHelperText>You may indicate the type or purpose of this list.</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor='format_id'>Format</InputLabel>
                  <Select
                    value={format_id}
                    onChange={(e) => this.handleChange('format_id', e)}
                    input={<Input name='format_id' id='format_id' />}
                    fullWidth
                  >
                    <MenuItem value={0}>
                      <em>None</em>
                    </MenuItem>
                    {Object.keys(FORMATS).map(v =>
                      <MenuItem value={v} key={'format_' + v}>
                        {FORMATS[v].name}
                      </MenuItem>)}
                  </Select>
                  <FormHelperText>You may indicate the format this list is for.</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <FormLabel>Is Public</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={(is_public === 1 || is_public === true) ? true : false}
                          onChange={() => this.handleToggleChange('is_public')}
                          value='is_public'
                          color='primary'
                        />}
                    />
                  </FormGroup>
                  <FormHelperText>If a list is public, other members can view it by giving them a link.</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{
            display: 'flex'
          }}>
            {id !== null ? (
              is_deleted ? (
                <Button style={{
                  marginRight: 'auto'
                }} onClick={() => this.handleRestore()} color='default'>
                  Restore
                </Button>
              ) : (
                <Button style={{
                  marginRight: 'auto'
                }} onClick={() => this.handleArchive()} color='default'>
                  Archive
                </Button>
              )
            ) : (
              null
            )}
            <Button onClick={() => this.handleClose()} color='primary'>
              Cancel
            </Button>
            <Button onClick={() => this.handleSave()} color='primary'>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

ListEditor.propTypes = {
  classes: PropTypes.object,
  hideButton: PropTypes.bool,
  onListToggle: PropTypes.func,
  onListSave: PropTypes.func,
  open: PropTypes.bool,
  list: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    owner: PropTypes.number,
    format_id: PropTypes.number,
    notes: PropTypes.string,
    tags: PropTypes.string,
    is_public: PropTypes.number,
    is_deleted: PropTypes.number,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    list_type_id: PropTypes.number,
  }).isRequired,
  updateList: PropTypes.func,
  createList: PropTypes.func,
  deleteList: PropTypes.func,
  userId: PropTypes.number,
}

const mapStateToProps = state => ({
  userId: state.user.id
})

const mapDispatchToProps = {
  updateList,
  createList,
  deleteList
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ListEditor))