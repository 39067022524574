import React from 'react'
import { object } from 'prop-types'
import { connect } from 'react-redux'

class Auth extends React.Component {
  static propTypes = {
    classes: object,
  }

  render () {
    const {
      userId,
      userLoggedIn,
      userInsider,
      Base,
      LoggedIn,
      Insider,
    } = this.props


    if (Insider && userInsider && userLoggedIn && userId) {
      return (
        <>
          {Insider}
        </>
      )
    } else if (LoggedIn && userLoggedIn && userId) {
      return (
        <>
          {LoggedIn}
        </>
      )
    } else if (Base) {
      return (
        <>
          {Base}
        </>
      )
    } else {
      return null
    }

  }
}

const mapStateToProps = state => ({
  userId: state.user.id,
  userLoggedIn: state.user.loggedIn,
  userInsider: state.user.insider,
})

export default connect(mapStateToProps)(Auth)
