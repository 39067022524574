import React, { useState } from 'react'

import {
  formatCSV, getSelectedMerchantOffers
} from './helpers'
import DownloadCSV from '../../shared/DownloadCSV'
import { convertToUSD } from '../../../utils/helpers'

import IconButton from '@material-ui/core/IconButton'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import CardOffersTable from './CardOffersTable'
import DisplayToggleButton from '../../shared/DisplayToggleButton'

import {
  HighlightOff as RemoveIcon,
  CallSplit
} from '@material-ui/icons'

import { grey } from '@material-ui/core/colors'

const MerchantOffersTableRow = (props) => {
  const {
    row,
    onMoveList,
    onRemoveFromList,
    expanded,
    onExpand,
    onCollapse,
  } = props

  const {
    merchant_name,
    totalCount,
    totalPrice,
    merchant_code,
    merchant_id,
    offers,
  } = row

  const [show, setShow] = useState(expanded)

  const handleSetShow = (open) => {
    setShow(open)
    if (!open) {
      onCollapse()
    } else {
      onExpand()
    }
  }

  return (
    <>
      <TableRow>
        <TableCell>
          {merchant_name}
        </TableCell>
        <TableCell align="center" width={100}>
          {totalCount}
        </TableCell>
        <TableCell width={120}>
          {convertToUSD(totalPrice)}
        </TableCell>
        <TableCell align="center" width={100}>
          <DownloadCSV
            data={formatCSV(offers, merchant_id)}
            filename={'TradeRoutes-' + merchant_code + '-'}
          />
        </TableCell>
        <TableCell align="center" width={100}>
          <IconButton
            color='primary'
            onClick={() => onMoveList(offers, merchant_id)}
          >
            <CallSplit />
          </IconButton>
        </TableCell>
        <TableCell align="center" width={100}>
          <IconButton
            color='primary'
            onClick={() => onRemoveFromList(offers, merchant_id)}
          >
            <RemoveIcon />
          </IconButton>
        </TableCell>
        <TableCell align="right" width={80}>
          <DisplayToggleButton
            display={show}
            onToggleDisplay={() => handleSetShow(!show)}
            text=""
            style={{
              float: 'right',
            }}
          />
        </TableCell>
      </TableRow>
      {show && (
        <TableRow>
          <TableCell colSpan={7}  style={{
            padding: 0
          }}>
            <div style={{
              backgroundColor: grey[200],
              padding: 8
            }}>
              <CardOffersTable
                table={'offers_by_card_' + merchant_id}
                list={getSelectedMerchantOffers(offers, merchant_id)}
              />
            </div>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default MerchantOffersTableRow
