import React from 'react'
import { object } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Link, withRouter
} from 'react-router-dom'
import {
  green, indigo, lightBlue
} from '@material-ui/core/colors'
import {
  convertToUSD, convertToPercent
} from '../../../utils/helpers'

import Grid from '@material-ui/core/Grid'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const styles = {
  bestBuy: {
    background: green[500],
    color: 'white',
    padding: 10,
    textAlign: 'center',
  },
  bestSpread: {
    background: indigo[300],
    color: 'white',
    padding: 10,
    textAlign: 'center',
  },
  bestSpreadPositive: {
    background: indigo[200],
    color: 'white',
    padding: 10,
    textAlign: 'center',
  },
  bestRetail: {
    background: lightBlue[400],
    color: 'white',
    padding: 10,
    textAlign: 'center',
  },
  bestHeader: {
    fontSize: '.85rem'
  },
  bestValue: {
    fontSize: '1.2rem',
    paddingTop: 10,
    paddingBottom: 10,
  },
  bestSource: {
    '& a': {
      color: 'white',
      fontSize: '.85rem',
      fontStyle: 'italic',
      '&:hover': {
        color: 'white',
      },
      '&:visited': {
        color: 'white',
      },
    }
  },
  spreadSource: {
    color: 'white',
    fontSize: '.85rem',
    fontStyle: 'italic',
    '&:hover': {
      color: 'white',
    },
    '&:visited': {
      color: 'white',
    },
  },
  merchantQuantity: {
    color: 'white',
    fontSize: '.85rem',
    fontStyle: 'italic',
    paddingLeft: 8,
  },
}

class BestCardPrices extends React.Component {
  static propTypes = {
    classes: object,
  }

  render () {
    const {
      classes, bestCardPrices, loaded, hasPrices
    } = this.props

    if (!hasPrices) {
      return null
    }

    const {
      buy_merchant_name,
      buy_merchant_code,
      buy_volume,
      buy_best,
      spread_best_usd,
      spread_best_pct,
      retail_best,
      retail_volume,
      retail_merchant_name,
      retail_merchant_code
    } = bestCardPrices

    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={4} className={classes.bestBuy}>
              <div className={classes.bestHeader}>
              Best Buylist
              </div>
              <div className={classes.bestValue}>
                {loaded ? (
                  <React.Fragment>
                    {buy_best ? convertToUSD(buy_best) : null}
                  </React.Fragment>
                ) : (
                  <SkeletonTheme color='#93cf96'>
                    <Skeleton width={120} />
                  </SkeletonTheme>
                )}
              </div>
              <div className={classes.bestSource}>
                {loaded ? (
                  <Link to={`/merchants/${buy_merchant_code}`}>
                    {buy_merchant_name}
                    {buy_volume ?
                      <span className={classes.merchantQuantity}>
                        {parseInt(buy_volume) === 99 ?
                          <>&infin;</> :
                          <>x{buy_volume.toString()}</>
                        }
                      </span>
                      : null
                    }
                  </Link>
                ) : (
                  <SkeletonTheme color='#93cf96'>
                    <Skeleton width={120} />
                  </SkeletonTheme>
                )}
              </div>
            </Grid>
            <Grid item xs={4} className={(loaded && spread_best_usd > 0) ? classes.bestSpreadPositive : classes.bestSpread}>
              <div className={classes.bestHeader}>
              Spread
              </div>
              <div className={classes.bestValue}>
                {loaded ? (
                  <React.Fragment>
                    {spread_best_pct ? convertToPercent(spread_best_pct) : null}
                  </React.Fragment>
                ) : (
                  <SkeletonTheme color='#aeb6df'>
                    <Skeleton width={120} />
                  </SkeletonTheme>
                )}
              </div>
              <div className={classes.spreadSource}>
                {loaded ? (
                  <React.Fragment>
                    {spread_best_usd ? convertToUSD(spread_best_usd) : null}
                  </React.Fragment>
                ) : (
                  <SkeletonTheme color='#aeb6df'>
                    <Skeleton width={120} />
                  </SkeletonTheme>
                )}
              </div>
            </Grid>
            <Grid item xs={4} className={classes.bestRetail}>
              <div className={classes.bestHeader}>
              Best Retail
              </div>
              <div className={classes.bestValue}>
                {loaded ? (
                  <React.Fragment>
                    {retail_best ? convertToUSD(retail_best) : null}
                  </React.Fragment>
                ) : (
                  <SkeletonTheme color='#7ed3f9'>
                    <Skeleton width={120} />
                  </SkeletonTheme>
                )}
              </div>
              <div className={classes.bestSource}>
                {loaded ? (
                  <Link to={`/merchants/${retail_merchant_code}`}>
                    {retail_merchant_name}
                    {retail_volume ?
                      <span className={classes.merchantQuantity}>
                        {parseInt(retail_volume) === 99 ?
                          <>&infin;</> :
                          <>x{retail_volume.toString()}</>
                        }
                      </span>
                      : null
                    }
                  </Link>
                ) : (
                  <SkeletonTheme color='#7ed3f9'>
                    <Skeleton width={120} />
                  </SkeletonTheme>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(withRouter(BestCardPrices))
