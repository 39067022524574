import React from 'react'
import moment from 'moment'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'
import { setBreadcrumbs } from '../../../store/layout/actionCreator'
import {
  Link, withRouter
} from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

import AllPrintings from './AllPrintings'
import TCGplayerArticles from '../../TCGplayer/Articles'
import TCGplayerDecks from '../../TCGplayer/Decks'

import PriceGraphs from './PriceGraphs'

import { getCardsByCardName } from '../../../store/cards/actionCreator'

import { getListsWithCard } from '../../../store/collection/actionCreator'

import {
  disabledMerchantsSelector,
  setsDataObjSelector,
  listsObjectSelector
} from '../../../store/reselect'

import {
  getBestCardPrices,
  getHydratedBuylistPrices,
  getHydratedRetailPrices,
  getListsWithCardResults
} from '../../../utils/helpers'

import Auth from '../../shared/Auth'
import CardImage from '../../shared/CardImage'
import Keyrune from '../../shared/Keyrune'
import Widget from '../../shared/Widget'
import BestCardPrices from './BestCardPrices'
import ListsWithCard from './ListsWithCard'
import MarketPricesTable from './MarketPricesTable'
import TCGplayerLink from '../../TCGplayer/Link'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'

const styles = {
  subHeader: {
    marginRight: 10,
  },
  setsLink: {
    paddingBottom: 8
  },
}

class CardPage extends React.Component {
  static propTypes = {
    classes: object,
  }

  constructor(props) {
    super(props)
    this.state={
      loadingListsWithCard: false
    }
    this.initialize()
  }

  componentDidMount() {
    const {
      canBeFoil, foil, canBeNonfoil, history, setName, cardName
    } = this.props
    if (!canBeFoil && foil) {
      history.push(`/prices/sets/${encodeURIComponent(setName)}/${encodeURIComponent(cardName)}`)
    }
    if (!canBeNonfoil && !foil) {
      history.push(`/prices/sets/${encodeURIComponent(setName)}/${encodeURIComponent(cardName)}/foil`)
    }
  }

  initialize = () => {
    // TODO: Check if set exists
    let {
      getCardsByCardName, setBreadcrumbs, setName, cardName, foil
    } = this.props
    getCardsByCardName({
      cardName,
      setName
    })
    setBreadcrumbs([
      {
        text: 'Trader Tools',
        path: '/'
      },
      {
        text: 'Price Research',
        path: '/prices'
      },
      {
        text: 'Sets',
        path: '/prices/sets'
      },
      {
        text: setName,
        path: `/prices/sets/${encodeURIComponent(setName)}${(foil === 'foil' ? '/foil' : '')}`
      },
      {
        text: cardName + (foil === 'foil' ? ' (Foil)' : '')
      },
    ])
  }

  componentDidUpdate(prevProps) {
    const {
      cardName, setName, foil, card, listsWithCardLoaded, setBreadcrumbs
    } = this.props
    if (cardName !== prevProps.cardName || setName !== prevProps.setName || foil !== prevProps.foil) {
      if (cardName !== prevProps.cardName || setName !== prevProps.setName) {
        this.initialize()
      }
      if (setName !== prevProps.setName || foil !== prevProps.foil) {
        setBreadcrumbs([
          {
            text: 'Trader Tools',
            path: '/'
          },
          {
            text: 'Price Research',
            path: '/prices'
          },
          {
            text: 'Sets',
            path: '/prices/sets'
          },
          {
            text: setName,
            path: `/prices/sets/${encodeURIComponent(setName)}${(foil === 'foil' ? '/foil' : '')}`
          },
          {
            text: cardName + (foil === 'foil' ? ' (Foil)' : '')
          },
        ])
      }
      this.setState({
        loadingListsWithCard: false,
      })
    }
    if (!this.state.loadingListsWithCard && !listsWithCardLoaded && card.card_id) {
      this.setState({
        loadingListsWithCard: true
      }, () => {
        this.props.getListsWithCard(card.card_id, card.scryfall_id, card.foil)
      })
    }
  }

  render () {
    const {
      classes,
      canBeFoil,
      canBeNonfoil,
      merchants,
      card,
      loaded,
      bestCardPrices,
      listsWithCard,
      listsWithCardLoaded,
      error,
      hasPrices,
      setReleased,
      setReleasedDate,
      allPrintings,
    } = this.props

    if (error) {
      return (
        <>
          <Helmet>
            <title>Trader Tools | Quiet Speculation</title>
          </Helmet>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              There was an error loading this card.
            </Grid>
          </Grid>
        </>
      )
    }

    const {
      card_name,
      set_name,
      set_code,
      rarity,
      foil,
      buylist_prices,
      retail_prices,
      card_id,
      scryfall_id,
      variant,
      tcgplayer_id,
      // collector_number,
      // mana_cost,
      // cmc,
      // type_line,
      // oracle_text,
      // power,
      // toughness,
      // reserved,
      // flavor_text,
      // artist,
      // edhrec_rank,
    } = card

    const buylistPrices = getHydratedBuylistPrices(buylist_prices, merchants, {
      set_name,
      foil,
      rarity,
      set_code
    })

    const retailPrices = getHydratedRetailPrices(retail_prices, merchants, {
      set_name,
      foil,
      rarity,
      set_code
    })

    let variantURI = (variant === 1) ? `?variant=${scryfall_id}` : ''

    return (
      <React.Fragment>
        <Helmet>
          <title>{card_name && set_name ? `${card_name} ${foil === 1 ? '(Foil) ' : ''}- ${set_name} - Trader Tools | Quiet Speculation` : 'Trader Tools | Quiet Speculation'}</title>
        </Helmet>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Hidden mdUp>
              <Typography variant={'h2'} color='primary' style={{
                margin: '1.78rem 0 .5rem',
                fontSize: '2.5rem',
                fontWeight: 400
              }}>
                {card_name ? (
                  card_name
                ) : (
                  <Skeleton width={500} />
                )}
              </Typography>
            </Hidden>
            <Hidden smDown>
              <Typography variant={'h2'} color='primary' style={{
                margin: '1.78rem 0 .5rem'
              }}>
                {card_name ? (
                  card_name
                ) : (
                  <Skeleton width={500} />
                )}
              </Typography>
            </Hidden>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h5'} color='primary'>
              {set_name ? (
                <React.Fragment>
                  <Link to={`/prices/sets/${encodeURIComponent(set_name)}`} className={classes.subHeader}><Keyrune set={set_code} rarity={rarity} /> {set_name}</Link>
                  <Hidden mdUp>
                    <br />
                  </Hidden>
                  {canBeFoil === true &&
                    <Button component={Link} to={ `/prices/sets/${encodeURIComponent(set_name)}/${encodeURIComponent(card_name)}/foil${variantURI}` } variant='outlined' color='primary' size='small' disabled={foil === 1} className={classes.subHeader} >Foil</Button>
                  }
                  {canBeNonfoil === true &&
                    <Button component={Link} to={ `/prices/sets/${encodeURIComponent(set_name)}/${encodeURIComponent(card_name)}${variantURI}` } variant='outlined' color='primary' size='small' disabled={foil === 0} className={classes.subHeader} >Non-Foil</Button>
                  }
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Skeleton width={200} />
                  <span style={{
                    paddingLeft: 10
                  }}>
                    <Skeleton width={64} />
                  </span>
                  <span style={{
                    paddingLeft: 10
                  }}>
                    <Skeleton width={64} />
                  </span>
                </React.Fragment>
              )}
            </Typography>
          </Grid>
          <Hidden mdUp>
            <Grid item xs={12} style={{
              marginBottom: 16
            }}>
              <BestCardPrices
                bestCardPrices={bestCardPrices}
                loaded={loaded}
                hasPrices={hasPrices}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={4}>
            <Grid container spacing={1}>
              <Grid item xs={12} style={{
                textAlign: 'center',
                marginBottom: 16
              }}>
                <CardImage scryfallId={scryfall_id} cardName={card_name} />
              </Grid>
              <Grid item xs={12} style={{
                paddingBottom: 16
              }}>
                {[
                  'Type',
                  'Rarity',
                  'Gatherer ID',
                  'Collector Number',
                  'Reserved',
                  'Mana Cost',
                  'CMC',
                  'Power',
                  'Toughness',
                  'EDHREC Rank',
                  'Artist',
                  'Oracle Text',
                  'Flavor Text',
                ].map(v => {
                  if (card[v]) {
                    return <Typography key={v} variant='subtitle2'>
                      <strong>{v}: </strong>
                      {card[v]}
                    </Typography>
                  }
                })}
              </Grid>
              <Grid item xs={12} style={{
                marginBottom: 16
              }}>
                <Auth
                  LoggedIn={
                    <ListsWithCard
                      listsWithCardLoaded={listsWithCardLoaded}
                      listsWithCard={listsWithCard}
                      cardId={card_id}
                    />
                  }
                />
              </Grid>
              <Hidden smDown>
                <AllPrintings
                  card_name={card_name}
                  loaded={loaded}
                  sets={allPrintings}
                  classes={classes}
                />
              </Hidden>
              <TCGplayerDecks cardName={card_name} scryfallID={scryfall_id} />
              {/* <AmazonResults data={amazon} /> */}
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={1}>
              <Hidden smDown>
                <BestCardPrices
                  bestCardPrices={bestCardPrices}
                  loaded={loaded}
                  hasPrices={hasPrices}
                />
              </Hidden>
              {hasPrices ? (
                <Grid item xs={12}>
                  <Grid container style={{
                    margin: '0 0 .5rem',
                    minHeight: 68
                  }}>
                    <Grid item xs={6} style={{
                      marginTop: 'auto'
                    }}>
                      <Typography variant={'h4'} color='primary'>
                        Market Prices
                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{
                      textAlign: 'right',
                      marginTop: 'auto'
                    }}>
                      <TCGplayerLink cardName={card_name} setName={set_name} prices={retailPrices} tcgplayer_id={tcgplayer_id} />
                    </Grid>
                  </Grid>
                  <Widget header={card_name}>
                    { loaded ? (
                      <MarketPricesTable
                        data={[...buylistPrices, ...retailPrices]}
                        cardName={card_name}
                        foil={foil}
                        setName={set_name}
                        sets={allPrintings}
                        tcgplayer_id={tcgplayer_id}
                      />
                    ) : (
                      <Skeleton height={37} count={12} />
                    )}
                  </Widget>
                </Grid>
              ) : (
                <>
                  {setReleased ? (
                    <>
                      <Typography variant={'h4'} color='primary' style={{
                        margin: '0 0 .5rem'
                      }}>
                      This card has no current prices.
                      </Typography>
                      {/* <Typography>
                      We're actively working on expanding.
                    </Typography> */}
                    </>
                  ) : (
                    <>
                      <Typography variant={'h4'} color='primary' style={{
                        margin: '2rem 0 .5rem'
                      }}>
                      This set hasn't been released yet.
                      </Typography>
                      <Typography style={{
                        marginBottom: '4rem'
                      }}>
                      Prices are likey to start coming in around the release date: <strong>{moment(setReleasedDate).format('LLLL').replace(moment(setReleasedDate).format('LT'), '')}</strong>
                      </Typography>
                    </>
                  )}
                </>
              )}
              {setReleased &&
                <PriceGraphs
                  card={card}
                  loaded={loaded}
                />
              }
              <TCGplayerArticles cardName={card_name} scryfallID={scryfall_id} />
            </Grid>
          </Grid>
          <Hidden mdUp>
            <AllPrintings
              card_name={card_name}
              loaded={loaded}
              sets={allPrintings}
              classes={classes}
            />
          </Hidden>
        </Grid>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let cardName = decodeURIComponent(ownProps.match.params.cardName)
  let setName = decodeURIComponent(ownProps.match.params.setName)
  let foilVal = (ownProps.match.params.foil === 'foil') ? 1 : 0

  const params = new URLSearchParams(ownProps.location.search)
  const variant = params.get('variant')

  let cards = state.cards.entries
  let cardId = (!variant) ? Object.keys(cards).find(v => {
    return cards[v].card_name.toLowerCase() === cardName.toLowerCase()
      && cards[v].set_name.toLowerCase() === setName.toLowerCase()
      && cards[v].foil === foilVal
      && cards[v].variant === 0
  }) : Object.keys(cards).find(v => {
    return cards[v].scryfall_id === variant
      && cards[v].foil === foilVal
  })

  const card = cards[cardId]
  let cardLoaded = (card) ? true : false
  let loaded = state.cards.loaded
  let setsDataObj = setsDataObjSelector(state)
  let setInfo = setsDataObj[setName.toLowerCase()] ? setsDataObj[setName.toLowerCase()] : {}
  let merchants = state.merchants.loaded ? state.merchants.data : []
  let disabledMerchants = disabledMerchantsSelector(state)
  let bestCardPrices = cardLoaded ? getBestCardPrices(card.buylist_prices, card.retail_prices, merchants, disabledMerchants) : {}
  let {
    listsWithCard, listsWithCardLoaded
  } = getListsWithCardResults(cardLoaded, listsObjectSelector(state), state.collection.listsWithCard, card ? card.card_id : '')

  let canBeFoil = setInfo.can_be_foil === 1 ? true : false
  let canBeNonfoil = setInfo.can_be_nonfoil === 1 ? true : false
  let setReleased = new Date(setInfo.publish_date) < new Date(new Date().toDateString())

  let cardObj = {
    scryfall_id: cardLoaded ? card.scryfall_id : '',
    card_name: cardLoaded ? card.card_name : '',
    set_name: cardLoaded ? card.set_name : '',
    set_code: cardLoaded ? card.set_code : '',
    rarity: cardLoaded ? card.rarity : '',
    foil: cardLoaded ? card.foil : '',
    gatherer_id: cardLoaded ? card.gatherer_id : '',
    retail_id: cardLoaded ? card.retail_id : '',
    card_id: cardLoaded ? card.card_id : null,
    buylist_prices: cardLoaded ? card.buylist_prices : [],
    retail_prices: cardLoaded ? card.retail_prices : [],
    historicPrices: (cardLoaded && state.cards.historicPrices[card.card_id]) ? state.cards.historicPrices[card.card_id] : {
      loaded: false,
      error: null,
      data: []
    },
    variant: cardLoaded ? card.variant : 0,
    collector_number: cardLoaded ? card.collector_number : null,
    'Rarity': cardLoaded ?  card.rarity.charAt(0).toUpperCase() + card.rarity.slice(1) : '',
    'Gatherer ID': cardLoaded ? card.gatherer_id : null,
    'Collector Number': cardLoaded ? card.collector_number : null,
    'Mana Cost': cardLoaded ? card.mana_cost : null,
    'CMC': cardLoaded ? card.cmc : null,
    'Type': cardLoaded ? card.type_line : null,
    'Oracle Text': cardLoaded ? card.oracle_text : null,
    'Power': cardLoaded ? card.power : null,
    'Toughness': cardLoaded ? card.toughness : null,
    'Reserved': cardLoaded ? (card.reserved === 1 ? 'True' : null) : null,
    'Flavor Text': cardLoaded ? card.flavor_text : null,
    'Artist': cardLoaded ? card.artist : null,
    'EDHREC Rank': cardLoaded ? card.edhrec_rank : null,
    tcgplayer_id: cardLoaded ? card.tcgplayer_id : null,
  }

  return {
    cardName: cardLoaded ? card.card_name : cardName,
    setName: cardLoaded ? card.set_name : setName,
    canBeFoil,
    canBeNonfoil,
    foil: foilVal,
    loaded,
    bestCardPrices,
    card: cardObj,
    cardOrig: card,
    merchants,
    listsWithCard,
    listsWithCardLoaded,
    error: state.cards.error || (loaded && !cardLoaded),
    hasPrices: !loaded ? true : (cardObj.buylist_prices.length > 0 || cardObj.retail_prices.length > 0),
    setReleased,
    setReleasedDate: setInfo.publish_date,
    allPrintings: cardLoaded ? ( state.cards.allPrintings[card.card_name.toLowerCase()] || [] ) : []
  }
}

const mapDispatchToProps = {
  setBreadcrumbs,
  getCardsByCardName,
  getListsWithCard,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(CardPage)))
