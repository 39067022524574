import { fetch } from '../../utils/requests'

export function fetchReports (accessToken) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: '/reports',
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      baseURL: '/api',
      timeout: 120000,
    })
      .then(v => resolve(v))
      .catch(e => reject(e))
  })
}
