export const SET_CARDS = 'SET_CARDS'
export const SET_HISTORIC_PRICES_LOADING = 'SET_HISTORIC_PRICES_LOADING'
export const SET_HISTORIC_PRICES = 'SET_HISTORIC_PRICES'
export const SET_SEARCH_LOADED = 'SET_SEARCH_LOADED'
export const SET_PAGINATION = 'SET_PAGINATION'
export const SET_AUTOCOMPLETE_ENTRIES = 'SET_AUTOCOMPLETE_ENTRIES'
export const SET_CARD_ERROR = 'SET_CARD_ERROR'
export const SET_CARD_LOADED = 'SET_CARD_LOADED'
export const SET_ALL_PRINTINGS = 'SET_ALL_PRINTINGS'

export const SET_TCGPLAYER_ARTICLES = 'SET_TCGPLAYER_ARTICLES'
export const SET_TCGPLAYER_DECKS = 'SET_TCGPLAYER_DECKS'