// import Cookies from 'js-cookie'

import {
  SET_USER,
  SET_MERCHANT_OPTIONS,
  SET_MERCHANT_OPTION,
  SET_ERROR,
  SET_ACTIVE_LIST,
  SET_FILTERS,
  SET_USER_SETTING,
  SET_USER_SETTINGS
} from './actionType'

import {
  fetchUser,
  fetchUserBaseData,
  fetchPostMerchantOption,
  fetchSetUserActiveList,
  fetchSetUserSetting
} from './requests'

import { setLists } from '../collection/actionCreator'

import { handleSnackbar } from '../layout/actionCreator'

export function setUser (user) {
  return {
    type: SET_USER,
    payload: {
      user,
    }
  }
}

export function setUserSetting (settingID, value) {
  return {
    type: SET_USER_SETTING,
    payload: {
      settingID,
      value,
    }
  }
}

export function setUserSettings (settings) {
  return {
    type: SET_USER_SETTINGS,
    payload: {
      settings
    }
  }
}

export function setActiveList (listId) {
  return {
    type: SET_ACTIVE_LIST,
    payload: {
      listId,
    }
  }
}

export function setError (error) {
  return {
    type: SET_ERROR,
    payload: {
      error,
    }
  }
}

export function setMerchantOptions (merchantOptions) {
  return {
    type: SET_MERCHANT_OPTIONS,
    payload: {
      merchantOptions,
    }
  }
}

export function setMerchantOption (merchantOption) {
  return {
    type: SET_MERCHANT_OPTION,
    payload: {
      merchantOption,
    }
  }
}

export function setFilters (filters) {
  return {
    type: SET_FILTERS,
    filters
  }
}

export function postMerchantOption (payload) {
  return function (dispatch, getState) {
    let userId = getState().user.id
    let accessToken = getState().user.jwt

    return fetchPostMerchantOption(userId, payload, accessToken)
      .then(function () {
        dispatch(setMerchantOption(payload))
        return dispatch(handleSnackbar({
          message: 'Settings saved.',
          options: {
            variant: 'success',
            autoHideDuration: 2000,
          },
        }))
      })
      .catch(function () {
        return dispatch(handleSnackbar({
          message: 'Error saving settings.',
          options: {
            variant: 'error',
            autoHideDuration: 3000,
          },
        }))
      })
  }
}

// let rp = Cookies.get('amember_rp')
// let ru = Cookies.get('amember_ru')

export function getFetchUser () {
  return function (dispatch) {

    // If local development and there's no cookies, just set blank user
    // if ((!rp || !ru) && process.env.REACT_APP_ENV !== 'development') {
    //   return dispatch(setUser({}))
    // }

    let userId = ''

    return fetchUser()
      .then(function (response) {
        dispatch(setUser(response))
        userId = response.id
        if (userId) {
          return dispatch(initializeUserBaseData(userId))
        } else {
          // TODO: Set user merchant options as loaded...
          return dispatch(setMerchantOptions([]))
        }
      })
      .catch(function (error) {
        dispatch(setError(true))
        let message = (error && error.data && error.data.message) ? error.data.message : 'error logging in. try logging out and in again.'
        dispatch(handleSnackbar({
          message: message,
          options: {
            variant: 'error',
            persist: true
          },
        }))
        return error
      })
  }
}

export function initializeUserBaseData (userId) {
  return function (dispatch, getState) {
    let accessToken = getState().user.jwt

    fetchUserBaseData(userId, accessToken)
      .then((data) => {
        dispatch(handleSnackbar({
          message: 'Retrieved your lists and settings!',
          options: {
            variant: 'success',
            autoHideDuration: 1000,
          },
        }))
        const {
          lists, merchantOptions, activeList, userSettings
        } = data
        dispatch(setLists(lists))
        dispatch(setActiveList(activeList.list_id))
        dispatch(setUserSettings(userSettings))
        return dispatch(setMerchantOptions(merchantOptions))
      })
      .catch(err => {
        console.log(err)
        // dispatch(dismissSnackbar(key))
        return err
      })
  }

}


export function setUserActiveList (listId) {
  return function (dispatch, getState) {
    let userId = getState().user.id
    let accessToken = getState().user.jwt

    return fetchSetUserActiveList(userId, listId, accessToken)
      .then(function (data) {
        dispatch(setActiveList(data.list_id))
        return dispatch(handleSnackbar({
          message: 'Active list saved.',
          options: {
            variant: 'success',
            autoHideDuration: 2000,
          },
        }))
      })
      .catch(function () {
        return dispatch(handleSnackbar({
          message: 'Error saving active list.',
          options: {
            variant: 'error',
            autoHideDuration: 3000,
          },
        }))
      })
  }
}

export function updateUserSetting (settingID, value) {
  return function (dispatch, getState) {
    let userId = getState().user.id
    let accessToken = getState().user.jwt
    let initialValue = getState().user.userSettings[settingID]

    dispatch(setUserSetting(settingID, value))

    return fetchSetUserSetting(userId, {
      setting_id: settingID,
      value: value
    }, accessToken)
      .then(function () {
        return dispatch(handleSnackbar({
          message: 'User setting saved.',
          options: {
            variant: 'success',
            autoHideDuration: 2000,
          },
        }))
      })
      .catch(function () {
        dispatch(setUserSetting(settingID, initialValue))
        return dispatch(handleSnackbar({
          message: 'Error saving user setting.',
          options: {
            variant: 'error',
            autoHideDuration: 3000,
          },
        }))
      })
  }
}
