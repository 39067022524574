import React, {
  useState,
  useEffect
} from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import ListEditor from './ListEditor'

import { EMPTY_LIST } from '../../constants'

import {
  Grid,
  Button,
  makeStyles
} from '@material-ui/core'

const useStyles = makeStyles({
  topNav: {
    marginBottom: 20,
    textAlign: 'center',
  },
  button: {
    textTransform: 'none',
  }
})

export const MergeListsPage = (props) => {
  const {
    currentPage,
    externalList,
    externalListOpen = false,
    setExternalListOpen,
  } = props
  const classes = useStyles()
  const [activeList, setActiveList] = useState(EMPTY_LIST)
  const [listOpen, setListOpen] = useState(false)

  const handleNewListToggle = () => {
    setActiveList({
      ...EMPTY_LIST,
      name: 'New List: ' + moment(Date.now()).local().format('MMMM Do YYYY, h:mm:ss a')
    })
    setListOpen(true)
  }

  const handleListToggle = (e) => {
    if (!e) {
      setActiveList(EMPTY_LIST)
      setListOpen(false)
      if (externalListOpen && setExternalListOpen) {
        setExternalListOpen(false)
      }
    }
  }

  return (
    <Grid item xs={12} className={classes.topNav}>
      <ListEditor
        list={externalList || activeList}
        open={listOpen || externalListOpen}
        onListToggle={(e) => handleListToggle(e)}
        hideButton={true}
      />
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Button variant='outlined' size='large' color='primary' className={classes.button} onClick={handleNewListToggle} >
            New List
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button variant='outlined' size='large' color='primary' disabled={currentPage === 'import'} component={Link} to={'/collection/import'} className={classes.button}>
            Import List
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button variant='outlined' size='large' color='primary' disabled={currentPage === 'merge'} component={Link} to={'/collection/merge'} className={classes.button}>
            Merge Lists
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )

}

export default MergeListsPage