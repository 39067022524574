import { fetch } from '../../utils/requests'

export function fetchMerchants (accessToken) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: '/merchants',
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      baseURL: '/api',
      timeout: 120000,
    })
      .then(v => resolve(v))
      .catch(e => reject(e))
  })
}

export function fetchMerchantBestBuylist ({
  merchantId,
  orderBy,
  order,
  page,
  perPage,
  type,
  seed,
  accessToken
}) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: `/merchants/${encodeURIComponent(merchantId)}`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      baseURL: '/api',
      timeout: 120000,
      params: {
        orderBy,
        order,
        page,
        perPage,
        type,
        seed,
      }
    })
      .then(v => resolve(v))
      .catch(e => reject(e))
  })
}
