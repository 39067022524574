export const ENV = 'prod'

export const BASE_URL = '//tradertools.quietspeculation.com'

export const IMG_URL_BASE = 'https://images.quietspeculation.net/mtg/normal'

export const ROUTER_PATHS = {
  PRICES: '/prices',
  COLLECTION: '/collection',
  SELL: '/sell',
  MERCHANTS: '/merchants',
  SETTINGS: '/settings',
  IONSCANNER: '/ionscanner',
  SETS: '/prices/sets'
}

export const URLS = {
  HOME: 'https://www.quietspeculation.com/',
  MERCHANTS: 'https://www.quietspeculation.com/tradertools/merchants',
  SELL: 'https://www.quietspeculation.com/tradertools/sell',
  DISCORD: 'https://www.quietspeculation.com/discord',
  LOGIN: 'https://www.quietspeculation.com/membership/login',
  PROFILE: 'https://www.quietspeculation.com/membership/member',
  SIGNUP: 'https://www.quietspeculation.com/membership/signup',
  LOGOUT: 'https://www.quietspeculation.com/membership/logout',
  SCANNER: 'https://www.quietspeculation.com/ioncore',
  DOWNLOAD_SCANNER: 'https://ion-scanner-service.prod.quietspeculation.net/installer/win32',
  BUG_REPORT: 'https://trello.com/b/Xn9d6SYC/trader-tools-bug-reporting',
  DEVELOPMENT_TRELLO: 'https://trello.com/b/VQhGATwJ/trader-tools-development',
}

export const EMPTY_LIST = {
  id: null,
  name: '',
  owner: null,
  format_id: 0,
  notes: '',
  tags: '',
  is_public: 0,
  is_deleted: 0,
  created_at: '',
  updated_at: '',
  list_type_id: 0,
}

export const LIST_TYPES = {
  0:	'basic',
  1:	'deck',
  2:	'want',
  3:	'have',
  4:	'ION Scanner Import',
  6:	'generated',
  8:	'for_trade',
  10:	'incoming',
  11:	'outgoing',
}

export const FORMATS = {
  1: {
    name: 'Standard',
    abbr: 'STN'
  },
  2: {
    name: 'Modern',
    abbr: 'MOD'
  },
  3: {
    name: 'Legacy',
    abbr: 'LEG'
  },
  4: {
    name: 'Vintage',
    abbr: 'VNT'
  },
  5: {
    name: 'Pauper',
    abbr: 'PPR'
  },
  6: {
    name: 'Two-Headed Giant',
    abbr: '2HG'
  },
  7: {
    name: 'Sealed Deck',
    abbr: 'SLD'
  },
  8: {
    name: 'Booster Draft',
    abbr: 'BOO'
  },
  9: {
    name: 'Commander',
    abbr: 'CMD'
  },
  10: {
    name: 'Frontier',
    abbr: 'FRN'
  },
  11: {
    name: 'Cube Draft',
    abbr: 'CUB'
  },
}

export const LANGUAGES = {
  1: {
    name: 'English',
    code: 'en'
  }
}

export const CONDITIONS = {
  1: {
    name: 'Near Mint',
    abbr: 'NM'
  },
  2: {
    name: 'Light Play',
    abbr: 'LP'
  },
  3: {
    name: 'Moderate Play',
    abbr: 'MP'
  },
  4: {
    name: 'Heavy Play',
    abbr: 'HP'
  },
  5: {
    name: 'Damaged',
    abbr: 'DM'
  },
}

export const USER_SETTINGS_ARTICLES_OF_INTEREST_ID = 0
export const USER_SETTINGS_RELATED_DECKS_ID = 1
export const USER_SETTINGS_TCGPLAYER_LINK_ID = 2

export const USER_SETTINGS = [
  {
    id: USER_SETTINGS_ARTICLES_OF_INTEREST_ID,
    name: 'Articles of Interest',
    description: 'Show articles from TCGplayer related to the card or set being viewed.',
  },
  {
    id: USER_SETTINGS_RELATED_DECKS_ID,
    name: 'Related Decks',
    description: 'Show decks from TCGplayer containing the card being viewed.',
  },
  {
    id: USER_SETTINGS_TCGPLAYER_LINK_ID,
    name: 'TCGplayer Link',
    description: 'Show link to TCGplayer when viewing card prices.',
  },
]

export const DEFAULT_IMPORT_COLUMN_TYPES = ['card_name', 'set_name', 'foil', 'quantity']

export const IMPORT_COLUMN_TYPES = [
  'card_name',
  'set_name',
  'scryfall_id',
  // 'tcgplayer_id',
  // 'condition',
  // 'language',
  'foil',
  'quantity',
]

export const IMPORT_COLUMN_PAIRINGS = {
  'scryfall_id': {
    disallowed: ['card_name', 'set_name'],
    required: [],
  },
  'card_name': {
    disallowed: ['scryfall_id'],
    required: ['set_name'],
  },
  'set_name': {
    disallowed: ['scryfall_id'],
    required: ['card_name'],
  },
}