import React from 'react'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  TableCell,
  TableRow,
  TextField,
  FormControl,
  Select
} from '@material-ui/core'
import {
  red, orange, blue
} from '@material-ui/core/colors'
import {
  Warning as WarningIcon,
  BrokenImage as CorrectedIcon,
  Error as ErrorIcon
} from '@material-ui/icons'

const styles = {
  rowCell: {
    padding: 8,
  },
}

class ErrorRow extends React.Component {
  static propTypes = {
    classes: object,
  }

  constructor(props) {
    super(props)
    const {
      card_name, set_name, quantity, original_set_name
    } = props.card
    this.state = {
      cardName: card_name,
      setName: set_name,
      quantity,
      quantityEdit: false,
      setNameOriginal: original_set_name || set_name,
    }
  }

  handleRetry = () => {
    const {
      setName,  cardName
    } = this.state
    this.props.onRetry({
      setName,
      cardName
    })
  }

  handleRemove = () => {
    this.props.onRemove()
  }

  handleUpdate = ({
    field, value
  }) => {
    this.setState({
      [field]: value
    })
  }

  handleSelectSet = (event) => {
    this.setState({
      setName:  event.target.value,
    })
  }

  render () {
    const {
      cardName, setName, quantity, setNameOriginal
    } = this.state
    const {
      classes, card, duplicate, sets
    } = this.props
    const {
      foil, corrected
    } = card

    return (
      <TableRow height={36} style={{
        background: red[100]
      }}>
        {card.scryfall_id ? (
          <TableCell colSpan={2} className={classes.rowCell}>
            <ErrorIcon
              aria-label='errored entry'
              alt='errored entry'
              style={{
                fontSize: '1rem',
                verticalAlign: 'middle',
                color: red[500],
                paddingRight: 8,
              }}
            />{card.scryfall_id}
          </TableCell>
        ) : (
          <>
            <TableCell className={classes.rowCell}>
              <ErrorIcon
                aria-label='errored entry'
                alt='errored entry'
                style={{
                  fontSize: '1rem',
                  verticalAlign: 'middle',
                  color: red[500],
                }}
              />
              {duplicate &&
            <WarningIcon
              aria-label='duplicate entry'
              alt='duplicate entry'
              style={{
                fontSize: '1rem',
                cursor: 'pointer',
                verticalAlign: 'middle',
                color: orange[500],
              }}
            />
              }
              {corrected &&
            <CorrectedIcon
              aria-label='corrected entry'
              alt='corrected entry'
              style={{
                fontSize: '1rem',
                verticalAlign: 'middle',
                color: blue[500],
              }}
            />
              }
              <TextField
                value={cardName}
                onChange={(e) => this.handleUpdate({
                  field: 'cardName',
                  value: e.target.value
                })}
              />
            </TableCell>
            <TableCell className={classes.rowCell}>
              <div>
                <small>Original: {setNameOriginal}</small>
              </div>
              <FormControl>
                <Select
                  native
                  value={setName}
                  onChange={(e) => this.handleSelectSet(e)}
                >
                  <option aria-label="None" value="" />
                  {sets.map(v => <option key={v} value={v.toLowerCase()}>{v}</option>)}
                </Select>
              </FormControl>
            </TableCell>
          </>
        )}
        <TableCell className={classes.rowCell}>
          {foil == true ? 'True' : 'False'}
        </TableCell>
        <TableCell className={classes.rowCell}>
          {quantity}
          {/* {quantityEdit ? (
            <TextField
              value={quantity}
              onChange={(e) => this.handleUpdate({field: 'quantity', value: e.target.value})}
            />
          ) : (
            <span>
              {quantity}
            </span>
          )} */}
        </TableCell>
        <TableCell className={classes.rowCell} style={{
          textAlign: 'center'
        }}>
          {!card.scryfall_id &&
            <Button variant='contained' color='primary' onClick={() => this.handleRetry()}>
              Retry
            </Button>
          }
        </TableCell>
        <TableCell className={classes.rowCell} style={{
          textAlign: 'center'
        }}>
          <Button variant='contained' color='primary' onClick={() => this.handleRemove()}>
            Remove
          </Button>
        </TableCell>
      </TableRow>
    )
  }
}

const mapStateToProps = state => ({
  list: state.collection.dryRunList,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ErrorRow))
