import React from 'react'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { setBreadcrumbs } from '../../../store/layout/actionCreator'

import Widget from '../../shared/Widget'

import { URLS } from '../../../constants'

import {
  Typography,
  Grid,
  Button
} from '@material-ui/core'


const styles = {}

class HiddenMerchantPage extends React.Component {
  static propTypes = {
    classes: object,
  }

  constructor(props) {
    super(props)
    this.props.setBreadcrumbs([
      {
        text: 'Trader Tools',
        path: '/'
      },
      {
        text: 'Merchants',
        path: '/merchants'
      },
      {
        text: 'Hidden'
      }
    ])
  }

  render () {
    return (
      <div>
        <Helmet>
          <title>Hidden Merchant - Trader Tools | Quiet Speculation</title>
        </Helmet>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Widget header={'Why was the merchant hidden?'}>
              <Grid item xs={12}>
                <Typography variant='h5' color='primary' gutterBottom>
                  It's one of the benefits of being an Insider.
                </Typography>
                <Typography style={{
                  paddingBottom: 24
                }}>
                  In addition to having access to Insider articles, ION Scanner and Trade Routes, Insiders have full information about which merchants are buying and selling cards.
                </Typography>
                <Typography style={{
                  paddingBottom: 24
                }}>
                  Plus, with ION Scanner and Trade Routes, scan and upload your collection and we'll tell you which merchants you should sell your cards to, based on your settings.
                </Typography>
              </Grid>
              <Grid item xs={12} style={{
                textAlign: 'center'
              }}>
                <Button href={URLS.SIGNUP} color='primary' variant='outlined' size='large'>Sign Up Now</Button>
              </Grid>
            </Widget>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapDispatchToProps = {
  setBreadcrumbs,
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(withRouter(HiddenMerchantPage)))
