import { IMG_URL_BASE } from '../constants'

export function cardImageURL (scryfallID) {
  return `${IMG_URL_BASE}/${scryfallID}.jpg`
}

const USDFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
})

export function convertToUSD(price) {
  return price ? USDFormat.format(parseFloat(price)) : null
}

export function convertToPercent(percent) {
  return parseInt(percent) + '%'
}

export function getHydratedBuylistPrices(prices, merchants, card) {
  return prices.map(v => {
    let merchant = findMerchant(v.merchant_id, merchants)
    const { merchant_name } = merchant
    return {
      merchant_id: v.merchant_id,
      merchant_name,
      set_name: card.set_name,
      set_code: card.set_code,
      foil: card.foil,
      buylist_price: v.price.toFixed(2),
      retail_price: null,
      volume: v.volume,
      rarity: card.rarity,
    }
  }).sort((a, b) => {
    var priceA = a.buylist_price
    var priceB = b.buylist_price
    return (priceA < priceB) ? -1 : (priceA > priceB) ? 1 : 0
  })
}

export function getHydratedRetailPrices(prices, merchants, card) {
  return prices.map(v => {
    let merchant = findMerchant(v.merchant_id, merchants)
    const { merchant_name } = merchant
    return {
      merchant_id: v.merchant_id,
      merchant_name,
      set_name: card.set_name,
      set_code: card.set_code,
      foil: card.foil,
      buylist_price: null,
      retail_price: v.price.toFixed(2),
      volume: v.volume,
      rarity: card.rarity,
    }
  }).sort((a, b) => {
    var priceA = a.retail_price
    var priceB = b.retail_price
    return (priceA < priceB) ? -1 : (priceA > priceB) ? 1 : 0
  })
}

export function findMerchant(id, merchants) {
  if (id !== null) {
    let results = merchants.find(m => m.merchant_id === id)
    if (results) {
      return results
    }
  }
  return {
    merchant_name: '',
    merchant_code: ''
  }
}

export function getBestCardPrices(buylistPrices, retailPrices, merchants, disabledMerchants) {
  // TODO: Spread and merchant info
  let filteredBuylist = buylistPrices
    .filter(offer => !disabledMerchants.buylist.includes(offer.merchant_id))
    .sort((a, b) => {
      var priceA = a.price
      var priceB = b.price
      return (priceA < priceB) ? -1 : (priceA > priceB) ? 1 : 0
    })
  let bestBuylistOffer = filteredBuylist.length > 0 ? filteredBuylist.pop() : {
    merchant_id: null,
    price: null,
    volume: null
  }

  let filteredRetail = retailPrices
    .filter(offer => !disabledMerchants.retail.includes(offer.merchant_id))
    .sort((a, b) => {
      var priceA = a.price
      var priceB = b.price
      return (priceA < priceB) ? -1 : (priceA > priceB) ? 1 : 0
    })

  let bestRetailOffer = filteredRetail.length > 0 ? filteredRetail[0] : {
    merchant_id: null,
    price: null,
    volume: null
  }

  const spreadUSD = (bestBuylistOffer.price && bestRetailOffer.price) ? bestRetailOffer.price - bestBuylistOffer.price : null
  const spreadPct = (bestBuylistOffer.price && bestRetailOffer.price) ? Math.round(((bestRetailOffer.price - bestBuylistOffer.price) / bestBuylistOffer.price) * 100) : null

  const buylistMerchant = findMerchant(bestBuylistOffer.merchant_id, merchants)

  const retailMerchant = findMerchant(bestRetailOffer.merchant_id, merchants)

  return {
    buy_merchant_name: buylistMerchant.merchant_name,
    buy_merchant_code: buylistMerchant.merchant_code,
    buy_volume: bestBuylistOffer.volume,
    buy_best: bestBuylistOffer.price,
    spread_best_usd: spreadUSD,
    spread_best_pct: spreadPct,
    retail_best: bestRetailOffer.price,
    retail_volume: bestRetailOffer.volume,
    retail_merchant_name: retailMerchant.merchant_name,
    retail_merchant_code: retailMerchant.merchant_code,
  }
}


export function getBestBuylistPrice(prices, disabledMerchants) {
  let filtered = prices.filter(offer => !disabledMerchants.buylist.includes(offer.merchant_id))
  return filtered.length > 0 ? filtered.pop() : {
    merchant_name: 'None',
    buylist_price: 0
  }
}

export function getBestRetailPrice(prices, disabledMerchants) {
  let filtered = prices.filter(offer => !disabledMerchants.retail.includes(offer.merchant_id))
  return filtered.length > 0 ? filtered[0] : {
    merchant_name: 'None',
    retail_price: 0
  }
}

export function getSpread(retailBest, buylistBest) {
  return (buylistBest.buylist_price !== 0 && retailBest.retail_price !== 0) ? Math.round(((retailBest.retail_price - buylistBest.buylist_price) / buylistBest.buylist_price) * 100) : 0
}

export function getCardHoarderLink({ cardName }) {
  return `https://www.cardhoarder.com/cards?data%5Bsearch%5D=${cardName}?affiliate_id=modernnexus&utm_source=tradertools&utm_campaign=affiliate&utm_medium=card&ref=tradertools-${cardName}`
}

export function getMerchantCardLink({
  cardName, setName, merchantID, mode, medium, tcgplayer_id, type
}) {
  switch (merchantID) {
  // Card Kingdom
  case 2:
    if (mode === 'buylist') {
      if (type === 'store') {
        return 'https://www.cardkingdom.com/purchasing/mtg_singles'
      }
      return `https://www.cardkingdom.com/purchasing/mtg_singles?filter%5Bsearch%5D=mtg_advanced&filter%5Bname%5D=${cardName}`
    }
    if (type === 'store') {
      return 'https://www.cardkingdom.com/catalog'
    }
    return `https://www.cardkingdom.com/catalog/search?search=header&filter%5Bname%5D=${cardName}`
    // Troll and Toad
  case 5:
    if (mode === 'buylist') {
      if (type === 'store') {
        return 'https://www2.trollandtoad.com/buylist/#!/'
      }
      return `https://www2.trollandtoad.com/buylist/#!/search/All/${cardName}`
    }
    if (type === 'store') {
      return 'https://www.trollandtoad.com/magic-the-gathering/1041'
    }
    return `https://www.trollandtoad.com/category.php?selected-cat=0&search-words=${cardName}`
    // ABU Games
  case 7:
    if (mode === 'buylist') {
      if (type === 'store') {
        return 'https://abugames.com/buylist/singles'
      }
      return `https://abugames.com/buylist/singles?search=${cardName}&language=%5B%22English%22%5D`
    }
    if (type === 'store') {
      return 'https://abugames.com/magic-the-gathering/singles'
    }
    return `https://abugames.com/magic-the-gathering/singles?search=${cardName}&language=%5B%22English%22%5D`
    // Mything MTG
  case 10:
    if (mode === 'buylist') {
      if (type === 'store') {
        return 'https://mythicmtg.com/public-buylist'
      }
      return `https://mythicmtg.com/public-buylist/?filter_buylist=true&top_level_cat=1&card_name=${cardName}`
    }
    if (type === 'store') {
      return 'https://mythicmtg.com/'
    }
    return `https://mythicmtg.com/?match=all&subcats=Y&pcode_from_q=Y&pshort=Y&pfull=Y&pname=Y&pkeywords=Y&search_performed=Y&q=${cardName}+${setName}&dispatch=products.search`
    // MTG Seattle
  case 18:
    if (mode === 'buylist') {
      if (type === 'store') {
        return 'https://www.mtgseattle.com/buylist'
      }
      return `https://www.mtgseattle.com/buylist/search?q=${cardName}+${setName}&c=1`
    }
    if (type === 'store') {
      return 'https://www.mtgseattle.com'
    }
    return `https://www.mtgseattle.com/products/search?q=${cardName}+${setName}&c=1`
    // Miniature Market
  case 19:
    if (mode === 'buylist') {
      return 'https://www.miniaturemarket.com/buyback/'
    }
    if (type === 'store') {
      return 'https://www.miniaturemarket.com/card-games/magic-the-gathering.html'
    }
    return `https://www.miniaturemarket.com/searchresults/?q=${cardName}+&filter[]=categoryPath1_fq:%22Magic%20The%20Gathering%22`
    // Channel Fireball
  case 20:
    if (mode === 'buylist') {
      return 'https://shop.channelfireball.com/pages/sell-your-cards'
    }
    if (type === 'store') {
      return 'https://shop.channelfireball.com/collections/mtg-singles'
    }
    return `https://shop.channelfireball.com/search?q=${cardName}+${setName}&type=product`
    // TCGPlayer
  case 8:
  case 16:
  case 21:
  case 22:
  case 23:
    if (mode === 'buylist') {
      return 'https://store.tcgplayer.com/buylist'
    }
    if (type === 'store') {
      return 'https://www.tcgplayer.com/search/magic/product?productLineName=magic&page=1'
    }
    return `https://shop.tcgplayer.com/product/productsearch?id=${tcgplayer_id}&utm_campaign=affiliate&utm_medium=${medium}&utm_source=ionstudios`
  default:
    return null
  }
}

export function getListsWithCardResults(loaded, collectionData, listsWithCardData, cardId) {
  if (loaded && listsWithCardData[cardId]) {
    let listIds = Object.keys(collectionData).map(v => parseInt(v))
    let listsWithCardObjArr = listsWithCardData[cardId]
    let listsWithCardListIds = listsWithCardData[cardId].map(v => v.list_id)
    // TODO: .sort((a, b) => (b[orderBy] && new Date(b[orderBy])) - (a[orderBy] && new Date(a[orderBy])))
    // let orderBy = 'updated_at'

    let listsWithCard = (listsWithCardObjArr) ? listsWithCardObjArr.filter(v => listIds.includes(v.list_id))
      .map(v => {
        return {
          list: collectionData[v.list_id],
          quantity: v.quantity,
          entry_id: v.entry_id,
        }
      }) : []

    let listsWithoutCard = (listsWithCardObjArr) ? listIds.filter(v => !listsWithCardListIds.includes(v))
      .map(v => {
        return {
          list: collectionData[v],
          quantity: 0,
          entry_id: v,
        }
      }) : []

    return {
      listsWithCard,
      listsWithCardLoaded: (listsWithCardObjArr) ? true : false,
      listsWithoutCard
    }

  } else {
    return {
      listsWithCard: [],
      listsWithCardLoaded: false,
      listsWithoutCard: []
    }
  }
}

export function prettifyType(type) {
  if (type) {
    return type.split('_').map(name => name.charAt(0).toUpperCase() + name.slice(1)).join(' ')
  }
  return ''
}

export const filterCardOfferData = ({
  data = [],
  searchSeed = '',
  foil = null,
  nonfoil = null,
  buylist = null,
  retail = null,
  orderBy = null,
  order = null,
}) => {
  const fields = [
    'card_name',
    'set_name',
    'buy_best',
    'retail_best',
    'quantity',
    'buy_merchant_name',
    'retail_merchant_name',
    'rarity',
    'buy_volume',
    'spread_best_usd',
    'spread_best_pct',
  ]
  let filtered = data.filter((offer) => {
    if (searchSeed !== '') {
      let match = false
      fields.some(field => {
        if (String(offer[field]).toLowerCase().includes(searchSeed)) {
          match = true
          return true
        }
      })
      return match
    }
    if (foil && foil === false && offer.foil === 1) {
      return false
    }
    if (nonfoil && nonfoil === false && offer.foil === 0) {
      return false
    }
    if (buylist && buylist === false && offer.buylist_price) {
      return false
    }
    if (retail && retail === false && offer.retail_price) {
      return false
    }
    return true
  })
  if (order && orderBy) {
    if (['buy_volume', 'buy_best', 'spread_best_usd', 'spread_best_pct', 'retail_best'].includes(orderBy)) {
      return (order === 'desc')
        ? filtered.sort((a, b) => (parseFloat(b[orderBy] || 0) < parseFloat(a[orderBy] || 0) ? -1 : 1))
        : filtered.sort((a, b) => (parseFloat(a[orderBy] || 0) < parseFloat(b[orderBy] || 0) ? -1 : 1))
    } else {
      return (order === 'desc')
        ? filtered.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : filtered.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1))
    }
  } else {
    return filtered
  }
}

export function formatListCSV(data) {
  return data.map(v => {
    const {
      card_name,
      set_name,
      foil,
      quantity,
      set_code,
      gatherer_id,
      collector_number,
      rarity,
      buy_merchant_name,
      buy_volume,
      buy_best,
      spread_best_usd,
      spread_best_pct,
      retail_best,
      retail_merchant_name
    } = v
    return {
      card_name,
      set_name,
      foil,
      quantity,
      set_code,
      gatherer_id,
      collector_number,
      rarity,
      buy_merchant_name,
      buy_volume,
      buy_best,
      spread_best_usd,
      spread_best_pct,
      retail_best,
      retail_merchant_name,
    }
  })
}

export const selectedVariantImageCss = (selectedId, scryfallid) => {
  return {
    maxWidth: '25%',
    opacity: selectedId !== scryfallid ? '0.7' : '1',
    cursor: selectedId === scryfallid ? 'no-drop' : 'pointer',
    borderRadius: '4.75% / 3.5%'
  }
}