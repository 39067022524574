import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import {
  lightBlue, green
} from '@material-ui/core/colors'
import { postMerchantOption } from '../../../store/user/actionCreator'
import CommentEditor from './CommentEditor'

const styles = {}

const RetailSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: lightBlue[500],
    },
    '&$checked + $track': {
      backgroundColor: lightBlue[500],
    },
  },
  checked: {},
  track: {},
})(Switch)

const BuylistSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: green[400],
    },
    '&$checked + $track': {
      backgroundColor: green[400],
    },
  },
  checked: {},
  track: {},
})(Switch)

class MerchantOption extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      option: props.option
    }
  }

  handleToggle(key) {
    this.setState({
      option: {
        ...this.state.option,
        [key]: (!this.state.option[key] === true) ? 1 : 0
      }
    }, () => {
      this.handleSaveMerchantOption()
    })
  }

  handleCommentsSave(comments) {
    this.setState({
      option: {
        ...this.state.option,
        comments,
      }
    }, () => {
      this.handleSaveMerchantOption()
    })
  }

  handleSaveMerchantOption() {
    this.props.postMerchantOption(this.state.option)
  }

  render () {
    const { merchant } = this.props
    const {
      merchant_name, merchant_code
    } = merchant
    const {
      is_retail_visible, is_buylist_visible, comments, merchant_id
    } = this.state.option

    if (merchant_id === 0) {
      return null
    }

    return (
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={4}>
            <Link to={`/merchants/${merchant_code}`}>{merchant_name}</Link>
          </Grid>
          <Grid item xs={2}>
            <FormGroup>
              <FormControlLabel
                control={
                  <BuylistSwitch
                    checked={is_buylist_visible ? true : false}
                    onChange={() => this.handleToggle('is_buylist_visible')}
                    value='is_buylist_visible'
                  />
                }
              />
            </FormGroup>
          </Grid>
          <Grid item xs={2}>
            <FormGroup>
              <FormControlLabel
                control={
                  <RetailSwitch
                    checked={is_retail_visible ? true : false}
                    onChange={() => this.handleToggle('is_retail_visible')}
                    value='is_retail_visible'
                  />
                }
              />
            </FormGroup>
          </Grid>
          <Grid item xs={4}>
            {comments} <CommentEditor
              merchantName={merchant_name}
              comments={comments}
              onCommentsSave={(e) => this.handleCommentsSave(e)}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let merchantId = ownProps.merchant.settings_id
  let option = state.user.merchantOptions.data.filter(v => v.merchant_id === merchantId)
  return {
    merchantOption: state.user.merchantOptions,
    option: (option.length > 0) ? option[0] : {
      comments: '',
      is_buylist_visible: 1,
      is_retail_visible: 1,
      merchant_id: merchantId,
      user_id: state.user.id
    }
  }
}

const mapDispatchToProps = {
  postMerchantOption
}

MerchantOption.propTypes = {
  merchant: PropTypes.object,
  option: PropTypes.object,
  postMerchantOption: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MerchantOption))
