import React from 'react'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { setBreadcrumbs } from '../../../store/layout/actionCreator'
import {
  getInitialSetEntries, setSelectedSets
} from '../../../store/sets/actionCreator'
import { setsDataObjSelector } from '../../../store/reselect'
import Keyrune from '../../shared/Keyrune'
import FiltersModal from '../../shared/FiltersModal'
import SetSelectorModal from './SetSelectorModal'

import SetTable from './SetTable'
import Widget from '../../shared/Widget'

import Grid from '@material-ui/core/Grid'
import Skeleton from 'react-loading-skeleton'
import { Typography } from '@material-ui/core'
import { prettifyType } from '../../../utils/helpers'

const styles = {}

class SetPage extends React.Component {
  static propTypes = {
    classes: object,
  }

  constructor(props) {
    super(props)
    const {
      setBreadcrumbs, setName, foil, getInitialSetEntries
    } = this.props
    setBreadcrumbs([
      {
        text: 'Trader Tools',
        path: '/'
      },
      {
        text: 'Price Research',
        path: '/prices'
      },
      {
        text: 'Sets',
        path: '/prices/sets'
      },
      {
        text: setName + (foil ? ' (Foil)' : '')
      },
    ])
    this.initialize()
  }

  initialize = () => {
    const {
      setName, foil, getInitialSetEntries, entriesLoaded, setSelectedSets
    } = this.props
    if (!entriesLoaded) {
      getInitialSetEntries(setName, foil)
    } else {
      setSelectedSets([{
        set_name: setName.toLowerCase(),
        foil: (foil === true) ? 1 : 0,
        nonfoil: (foil === false) ? 1 : 0,
      }])
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      this.initialize()
    }
  }

  componentDidMount() {
    const {
      canBeFoil, canBeNonfoil, foil, history, setName
    } = this.props
    if (!canBeFoil && foil) {
      history.push(`/prices/sets/${encodeURIComponent(setName)}`)
    }
    if (!canBeNonfoil && !foil) {
      history.push(`/prices/sets/${encodeURIComponent(setName)}/foil`)
    }
  }

  render () {
    const {
      set, foil, entriesLoaded, setEntriesArray, error
    } = this.props
    const {
      set_code, set_name, set_type, publish_date
    } = set

    return (
      <div>
        <Helmet>
          <title>{set_name} - Trader Tools | Quiet Speculation</title>
        </Helmet>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Widget header={<span><Keyrune set={set_code} /> {set_name + (foil ? ' (Foil)' : '')}</span>}>
              {!error ? (
                <>
                  <Grid container style={{
                    paddingBottom: 8
                  }}>
                    <Grid item xs={8}>
                      <Typography variant='subtitle2'>
                        <span>
                          <strong>Code:</strong> {set_code.toUpperCase()}
                        </span>
                        <span style={{
                          paddingLeft: 16
                        }}>
                          <strong>Type:</strong> {prettifyType(set_type)}
                        </span>
                        <span style={{
                          paddingLeft: 16
                        }}>
                          <strong>Published:</strong> {publish_date}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={4} style={{
                      textAlign: 'right'
                    }}>
                      <SetSelectorModal setName={set_name} />
                      <FiltersModal />
                    </Grid>
                  </Grid>
                  {(entriesLoaded > 0) ? (
                    <SetTable
                      loaded={entriesLoaded}
                      set={set}
                      entries={setEntriesArray}
                      foil={foil}
                    />
                  ) : (
                    <Skeleton height={43} count={25} />
                  )}
                </>
              ) : (
                <>
                  There was an error loading this set.
                </>
              )}
            </Widget>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let setName = decodeURIComponent(ownProps.match.params.setName).toLowerCase()
  let foil =  ownProps.match.path === '/prices/sets/:setName/foil'
  let foilKey = (foil) ? 1 : 0
  let setsDataObj = setsDataObjSelector(state)
  let setInfo = setsDataObj[setName] || {
    set_code: '',
    set_name: setName,
    set_type: '',
    publish_date: '',
  }

  let canBeFoil = setInfo.can_be_foil === 1 ? true : false
  let canBeNonfoil = setInfo.can_be_nonfoil === 1 ? true : false

  return {
    error: state.sets.error,
    set: setInfo,
    setName: decodeURIComponent(ownProps.match.params.setName),
    foil: foil,
    canBeFoil,
    canBeNonfoil,
    entriesLoaded: state.sets.entries[foilKey][setName] ? true : false,
    setEntriesArray: (state.sets.selectedSets.length > 0) ? state.sets.selectedSets.reduce((acc, set) => {
      if (set) {
        const {
          set_name, foil, nonfoil
        } = set
        let entries = [
          ...((foil === 1 && state.sets.entries[1][set_name]) ? state.sets.entries[1][set_name] : []),
          ...((nonfoil === 1 && state.sets.entries[0][set_name]) ? state.sets.entries[0][set_name] : []),
        ]
        acc = [...acc, ...entries]
      }
      return acc
    }, []) : [],
    selectedSets: state.sets.selectedSets,
  }
}

const mapDispatchToProps = {
  setBreadcrumbs,
  getInitialSetEntries,
  setSelectedSets,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(SetPage)))
