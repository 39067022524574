import React from 'react'
import PropTypes from 'prop-types'
import {
  Link, withRouter
} from 'react-router-dom'
import ListEntryEditorModal from '../../ListEntryEditorModal'

import Widget from '../../shared/Widget'
import Grid from '@material-ui/core/Grid'
import Skeleton from 'react-loading-skeleton'

class ListsWithCard extends React.Component {

  render () {
    const {
      listsWithCardLoaded, listsWithCard, cardId
    } = this.props

    return (
      <Widget header='Lists With This Card'>
        {listsWithCardLoaded ? (
          <React.Fragment>
            <Grid container>
              <Grid item xs={12} style={{
                textAlign: 'right',
                paddingBottom: 16
              }}>
                <ListEntryEditorModal
                  cardId={cardId}
                  listId={null}
                  textButton={true}
                  onSelect={() => this.props.onSelect(null)}
                />
              </Grid>
              {listsWithCard.length > 0 ? (
                <React.Fragment>
                  <Grid item xs={12} style={{
                    paddingBottom: 16
                  }}>
                    <Grid container>
                      <Grid item xs={8} style={{
                        fontSize: 14
                      }}>
                      List Name
                      </Grid>
                      <Grid item xs={2} style={{
                        textAlign: 'right',
                        fontSize: 14
                      }}>
                      Qty
                      </Grid>
                      <Grid item xs={2} style={{
                        textAlign: 'right',
                        fontSize: 14
                      }}>
                      Edit
                      </Grid>
                    </Grid>
                  </Grid>
                  {listsWithCard.map((v, i) =>
                    <Grid item xs={12} style={{
                      paddingBottom: 8
                    }} key={i}>
                      <Grid container>
                        <Grid item xs={8} style={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}>
                          <Link to={`/collection/lists/${v.list.id}`} title={v.list.name} alt={v.list.name}>{v.list.name}</Link>
                        </Grid>
                        <Grid item xs={2} style={{
                          textAlign: 'right'
                        }}>
                          {v.quantity}
                        </Grid>
                        <Grid item xs={2} style={{
                          textAlign: 'right'
                        }}>
                          <ListEntryEditorModal
                            cardId={cardId}
                            listId={v.list.id}
                            onSelect={(listId) => this.props.onSelect(listId)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </React.Fragment>
              ) : (
                <Grid item xs={12} >
                This card isn't in any of your lists.
                </Grid>
              )}
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Skeleton height={37} count={1} />
          </React.Fragment>
        )}
      </Widget>
    )
  }
}

ListsWithCard.propTypes = {
  listsWithCardLoaded: PropTypes.bool,
  listsWithCard: PropTypes.array,
  cardId: PropTypes.number,
  onSelect: PropTypes.func,
}

export default withRouter(ListsWithCard)
