import React from 'react'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'
import { setBreadcrumbs } from '../../../store/layout/actionCreator'
import { getMerchantBestPrices } from '../../../store/merchants/actionCreator'
import MerchantHeader from './MerchantHeader'
import Auth from '../../shared/Auth'

import Widget from '../../shared/Widget'
import MerchantTable from './MerchantTable'

import Grid from '@material-ui/core/Grid'

const TYPE = 'sell'

const styles = {
  listLinks: {
    paddingBottom: 5,
    paddingTop: 5,
  }
}

class BestDeals extends React.Component {
  static propTypes = {
    classes: object,
  }

  constructor(props) {
    super(props)
    props.setBreadcrumbs([
      {
        text: 'Trader Tools',
        path: '/'
      },
      {
        text: 'Merchants',
        path: '/merchants'
      },
      {
        text: props.merchantName,
        path: `/merchants/${props.merchantCode}`
      },
      {
        text: 'Best Deals'
      },
    ])
    if (props.merchantId && props.insider) {
      props.getMerchantBestPrices({
        merchantId: props.merchantId,
        orderBy: 'retail_best',
        order: 'ASC',
        page: 1,
        perPage: 25,
        type: TYPE,
      })
    }
  }

  handleChangeData = (pagination) => {
    const {
      merchantId, getMerchantBestPrices
    } = this.props
    getMerchantBestPrices({
      ...pagination,
      merchantId: merchantId,
      type: TYPE,
    })
  }

  render () {
    const {
      merchantName, pagination, entries, merchant
    } = this.props

    return (
      <div>
        <Helmet>
          <title>{merchantName} Deals - Trader Tools | Quiet Speculation</title>
        </Helmet>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Widget header={`Best Deals by ${merchantName}`}>
              <MerchantHeader merchant={merchant} mode={TYPE} />
              <Auth
                Base={
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      You must be an Insider in order to see merchant prices.
                    </Grid>
                  </Grid>
                }
                Insider={
                  <MerchantTable
                    entries={entries}
                    pagination={pagination}
                    onChangeData={(pagination) => this.handleChangeData(pagination)}
                  />
                }
              />
            </Widget>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {

  let merchantCode = ownProps.match.params.merchantName
  let merchant = state.merchants.data.find(v => v.merchant_code === merchantCode)
  return {
    merchant,
    merchantName: merchant.merchant_name || 'Not found',
    merchantCode: merchant.merchant_code || '',
    merchantId: merchant.merchant_id || null,
    hasBuylist: merchant.merchant_name || null,
    hasRetail: merchant.merchant_name || null,
    imgURL: merchant.merchant_name || null,
    pagination: state.merchants.pagination,
    entries: state.merchants.entries,
    insider: state.user.insider,
  }
}

const mapDispatchToProps = {
  setBreadcrumbs,
  getMerchantBestPrices
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BestDeals))
