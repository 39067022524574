import React from 'react'
import { object } from 'prop-types'
import CardImageModal from '../../shared/CardImageModal'
import {
  cardImageURL, selectedVariantImageCss
} from '../../../utils/helpers'

import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core'
import { orange } from '@material-ui/core/colors'
import WarningIcon from '@material-ui/icons/Warning'

const styles = {
  rowCell: {
    padding: 8,
  }
}

class SuccessRow extends React.Component {
  static propTypes = {
    classes: object,
  }

  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }

  handleRemove = () => {
    this.props.onRemove()
  }

  handleUpdateVariant = (scryfallID) => {
    this.props.onSelectVariant(scryfallID)
  }

  render () {
    const { open } = this.state
    const {
      classes, card, duplicate, options, onConsolidateEntry
    } = this.props
    const {
      card_name, set_name, foil, quantity, scryfall_id, key
    } = card

    return (
      <>
        <TableRow height={36}>
          <TableCell className={classes.rowCell}>
            <div style={{
              float: 'left',
              lineHeight: '36px'
            }}>
              {duplicate &&
              <WarningIcon
                aria-label='duplicate entry'
                label='duplicate entry'
                style={{
                  fontSize: '1rem',
                  cursor: 'pointer',
                  verticalAlign: 'middle',
                  color: orange[500],
                }}
                onClick={() => onConsolidateEntry({
                  scryfall_id,
                  foil,
                  key,
                })} // TODO: Create popup confirmation
              />
              }
              <CardImageModal scryfallId={scryfall_id} style={{
                paddingRight: 6
              }} />
              {card_name}
            </div>
            {options && options.length > 1 && (
              <div style={{
                float: 'right'
              }}>
                <Button color='primary' variant='outlined' onClick={() => this.setState({
                  open: !open
                })}>
                  {open ? 'Hide' : 'Show'} {options.length} variants
                </Button>
              </div>
            )}
          </TableCell>
          <TableCell className={classes.rowCell}>
            {set_name}
          </TableCell>
          <TableCell className={classes.rowCell}>
            {foil == true ? 'True' : 'False'}
          </TableCell>
          <TableCell className={classes.rowCell}>
            {quantity}
          </TableCell>
          <TableCell className={classes.rowCell} style={{
            textAlign: 'center'
          }}>
          Success
          </TableCell>
          <TableCell className={classes.rowCell} style={{
            textAlign: 'center'
          }}>
            <Button variant='outlined' color='primary' onClick={() => this.handleRemove()}>
            Remove
            </Button>
          </TableCell>
        </TableRow>
        {open &&
      <TableRow height={36}>
        <TableCell colSpan={6}>
          <div>
            <Typography variant='h5'>Select Which Variant to Import</Typography>
            {options.map(v => {
              return (
                <span key={v.scryfall_id}>
                  <img
                    src={cardImageURL(v.scryfall_id)}
                    alt={v.card_name}
                    style={selectedVariantImageCss(scryfall_id, v.scryfall_id)}
                    onClick={() => this.handleUpdateVariant(v.scryfall_id)}
                  />
                </span>
              )
            }
            )}
          </div>
        </TableCell>
      </TableRow>
        }
      </>
    )
  }
}

export default withStyles(styles)(SuccessRow)
