import {
  SET_CARDS,
  SET_HISTORIC_PRICES_LOADING,
  SET_HISTORIC_PRICES,
  SET_SEARCH_LOADED,
  SET_PAGINATION,
  SET_AUTOCOMPLETE_ENTRIES,
  SET_CARD_ERROR,
  SET_CARD_LOADED,
  SET_ALL_PRINTINGS,
  SET_TCGPLAYER_ARTICLES,
  SET_TCGPLAYER_DECKS
} from './actionType'

export const initialState = {
  error: false,
  loaded: false,
  historicPrices: {},
  entries: {},
  searchLoaded: true,
  autocompleteEntries: [],
  pagination: {
    numRows: 0,
    numPages: 0,
    perPage: 10,
    previous: null,
    next: null,
    loaded: true,
    orderBy: 'card_name',
    order: 'ASC',
    page: 1,
  },
  allPrintings: {},
  tcgplayer_articles: {},
  tcgplayer_decks: {},
}

export default function cardsReducer (state = initialState, action = {}) {
  switch (action.type) {
  case SET_ALL_PRINTINGS: {
    const {
      cardName,
      printings
    } = action
    const newState = {
      ...state,
      allPrintings: {
        ...state.allPrintings,
        [cardName]: printings,
      }
    }
    return newState
  }
  case SET_TCGPLAYER_ARTICLES: {
    const { articles } = action
    const newState = {
      ...state,
      tcgplayer_articles: articles
    }
    return newState
  }
  case SET_TCGPLAYER_DECKS: {
    const { decks } = action
    const newState = {
      ...state,
      tcgplayer_decks: decks
    }
    return newState
  }
  case SET_CARD_ERROR: {
    const { error } = action
    const newState = {
      ...state,
      error
    }
    return newState
  }
  case SET_CARD_LOADED: {
    const { loaded } = action
    const newState = {
      ...state,
      loaded
    }
    return newState
  }
  case SET_CARDS: {
    const { cards } = action
    let newState = {
      ...state,
      entries: {
        ...state.entries,
        ...cards
      }
    }
    return newState
  }
  case SET_AUTOCOMPLETE_ENTRIES: {
    const { autocompleteEntries } = action
    const newState = {
      ...state,
      autocompleteEntries
    }
    return newState
  }
  case SET_PAGINATION: {
    const { pagination } = action
    const newState = {
      ...state,
      pagination
    }
    return newState
  }
  case SET_HISTORIC_PRICES_LOADING: {
    const { cardId } = action
    let newState = {
      ...state,
      historicPrices: {
        ...state.historicPrices,
        [cardId]: {
          loading: true,
          loaded: false,
          error: false,
          data: [],
        }
      },
    }
    return newState
  }
  case SET_HISTORIC_PRICES: {
    const {
      cardId, data, error
    } = action
    let newState = {
      ...state,
      historicPrices: {
        ...state.historicPrices,
        [cardId]: {
          loading: false,
          loaded: true,
          error,
          data,
        }
      },
    }
    return newState
  }
  case SET_SEARCH_LOADED: {
    const { loaded } = action
    let newState = {
      ...state,
      searchLoaded: loaded
    }
    return newState
  }
  default:
    return state
  }
}

