import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { disabledMerchantsSelector } from '../../../store/reselect'
import {
  green, lightBlue
} from '@material-ui/core/colors'
import ImagesGrid from '../../shared/ImagesGrid'
import { filterCardOfferData } from '../../../utils/helpers'
import { setListTableSort } from '../../../store/layout/actionCreator'
import { filterData } from '../../shared/FiltersModal'
import EnhancedTablePagination from '../../shared/EnhancedTablePagination'
import { sliceTableData } from '../../../utils/tables'

import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  InputAdornment,
  Typography
} from '@material-ui/core'

import SearchIcon from '@material-ui/icons/Search'

import Row from './Row'

const styles = {
  buylist: {
    color: green[500],
  },
  retail: {
    color: lightBlue[400],
  },
}

const columnData = [
  {
    id: 'card_name',
    align: 'left',
    disablePadding: false,
    label: 'Card Name',
    hiddenFromNonOwner: false
  },
  {
    id: 'set_name',
    align: 'left',
    disablePadding: false,
    label: 'Set Name',
    hiddenFromNonOwner: false
  },
  {
    id: 'rarity',
    align: 'left',
    disablePadding: false,
    label: 'Rarity',
    hiddenFromNonOwner: false
  },
  {
    id: 'collector_number',
    align: 'left',
    disablePadding: false,
    label: '#',
    hiddenFromNonOwner: false,
    hiddenFromNonOwner: false
  },
  {
    id: 'buy_merchant_name',
    align: 'left',
    textAlign: 'right',
    disablePadding: false,
    label: 'Buyer',
    hiddenFromNonOwner: false
  },
  {
    id: 'buy_volume',
    align: 'left',
    textAlign: 'center',
    disablePadding: false,
    label: 'Qty',
    hiddenFromNonOwner: false
  },
  {
    id: 'buy_best',
    align: 'left',
    textAlign: 'center',
    disablePadding: false,
    label: 'Best Buylist',
    hiddenFromNonOwner: false
  },
  {
    id: 'spread_best_usd',
    align: 'left',
    textAlign: 'center',
    disablePadding: false,
    label: 'Spread $',
    hiddenFromNonOwner: false
  },
  {
    id: 'spread_best_pct',
    align: 'left',
    textAlign: 'center',
    disablePadding: false,
    label: 'Spread %',
    hiddenFromNonOwner: false
  },
  {
    id: 'retail_best',
    align: 'left',
    textAlign: 'center',
    disablePadding: false,
    label: 'Best Retail',
    hiddenFromNonOwner: false
  },
  {
    id: 'retail_merchant_name',
    align: 'left',
    paddingLeft: 3,
    disablePadding: false,
    label: 'Seller',
    hiddenFromNonOwner: false
  },
  {
    id: 'foil',
    align: 'left',
    disablePadding: false,
    label: 'Foil',
    hiddenFromNonOwner: false
  },
  {
    id: 'quantity',
    align: 'left',
    disablePadding: false,
    label: 'Qty',
    hiddenFromNonOwner: false
  },
  {
    id: 'edit',
    align: 'center',
    disablePadding: false,
    label: 'Edit',
    hiddenFromNonOwner: true
  },
]

class EnhancedTableHead extends React.Component {

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const {
      order, orderBy, isOwner
    } = this.props

    return (
      <TableHead>
        <TableRow style={{
          height: 36
        }}>
          {columnData.filter(col => {
            if (!isOwner && col.hiddenFromNonOwner === true) {
              return false
            }
            return true
          }).map(column => { return (
            <TableCell
              key={column.id}
              alight={column.align}
              padding={'none'}
              sortDirection={orderBy === column.id ? order : false}
              style={{
                width: column.width
              }}
            >
              {column.id === 'edit' ? (
                <>
                  {column.label}
                </>
              ) : (
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={order}
                  onClick={this.createSortHandler(column.id)}
                >
                  {column.label}
                </TableSortLabel>

              )}
            </TableCell>
          )}, this)}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

class ListEntriesTable extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      page: 0,
      toggle: (props.toggle !== null) ? props.toggle : false,
      searchInput: '',
      filtersOpen: false,
      searchSeed: '',
      imagesMode: false
    }
  }

  handleRequestSort = (event, newOrderBy) => {
    const {
      order, orderBy, rowsPerPage, setListTableSort
    } = this.props

    let newOrder = 'desc'
    if (orderBy === newOrderBy && order === 'desc') {
      newOrder = 'asc'
    }

    setListTableSort({
      order: newOrder,
      orderBy: newOrderBy,
      rowsPerPage,
    })
  }

  handleChangePage = (event, page) => {
    this.setState({
      page
    })
  }

  handleChangeRowsPerPage = (event) => {
    const {
      order, orderBy, rowsPerPage, setListTableSort
    } = this.props
    setListTableSort({
      order,
      orderBy,
      rowsPerPage: event.target.value,
    })
  }

  handleClickOpen = () => {
    this.setState({
      filtersOpen: true
    })
  }

  handleClose = () => {
    this.setState({
      filtersOpen: false
    })
  }

  handleChangeToggle = name => event => {
    this.setState({
      [name]: event.target.checked
    })
  }

  handleSearch = event => {
    let input = event.target.value.toLowerCase()
    this.setState({
      searchSeed: input.toLowerCase(),
    })
  }

  render () {
    const {
      order,
      orderBy,
      rowsPerPage,
      listId,
      totalRetailValue,
      totalBuyValue,
      totalQuantity,
      classes,
      listEntries,
      filters,
      isOwner,
    } = this.props

    const {
      page,
      searchSeed,
      imagesMode,
    } = this.state

    const filteredData = filterData({
      filters,
      data: filterCardOfferData({
        data: listEntries,
        searchSeed,
        order,
        orderBy,
      })
    })

    const tableData = sliceTableData(filteredData, page, rowsPerPage)

    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={2}>
            <Button variant='outlined' color='primary' size='small' onClick={() => this.setState({
              imagesMode: !imagesMode
            })} >
              {imagesMode ? 'Table' : 'Images'}
            </Button>
          </Grid>
          <Grid item xs={7}>
            <Grid container>
              <Grid item xs={4} style={{
                textAlign: 'center'
              }}>
                <Typography variant='subtitle2'>
                  <strong>Total Cards:</strong> {totalQuantity}
                </Typography>
              </Grid>
              <Grid item xs={4} style={{
                textAlign: 'center'
              }}>
                <Typography variant='subtitle2' className={classes.buylist}>
                  <strong>Total Buylist:</strong> ${totalBuyValue.toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={4} style={{
                textAlign: 'center'
              }}>
                <Typography variant='subtitle2' className={classes.retail}>
                  <strong>Total Retail:</strong> ${totalRetailValue.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <TextField
              id='full-width'
              InputLabelProps={{
                shrink: true,
              }}
              placeholder='Search'
              margin='normal'
              style={{
                float: 'right',
                marginTop: 5
              }}
              onChange={this.handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Table style={{
          tableLayout: 'auto'
        }}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={this.handleRequestSort}
            rowCount={filteredData.length}
            isOwner={isOwner}
          />
          <TableBody>
            {imagesMode ? (
              <TableRow>
                <TableCell
                  padding={'none'}
                  colSpan={columnData.length}
                >
                  <ImagesGrid data={tableData} />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {tableData.map((v, i) => {
                  return (
                    <Row
                      card={v}
                      key={i + '_' + v.card_id}
                      listId={listId}
                      isOwner={isOwner}
                    />
                  )
                })}
              </>
            )}
          </TableBody>
        </Table>
        <EnhancedTablePagination
          filteredCount={filteredData.length}
          totalCount={listEntries.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          totalText='total entries'
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    order, orderBy, rowsPerPage
  } = state.layout.listTableSort
  return {
    disabledMerchants: disabledMerchantsSelector(state),
    order,
    orderBy,
    rowsPerPage,
    filters: state.user.filters
  }
}

const mapDispatchToProps = {
  setListTableSort,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ListEntriesTable))