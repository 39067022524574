import React from 'react'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'
import { setBreadcrumbs } from '../../../store/layout/actionCreator'
import {
  setNamesSelector, setsDataArraySelector
} from '../../../store/reselect'

import Widget from '../../shared/Widget'
import AllSetsTable from './AllSetsTable'

import Grid from '@material-ui/core/Grid'
import Skeleton from 'react-loading-skeleton'

// import Typography from '@material-ui/core/Typography'
// import { Link } from 'react-router-dom'

const styles = {}

class SetsPage extends React.Component {
  static propTypes = {
    classes: object,
  }

  constructor(props) {
    super(props)
    props.setBreadcrumbs([
      {
        text: 'Trader Tools',
        path: '/'
      },
      {
        text: 'Price Research',
        path: '/prices'
      },
      {
        text: 'Sets'
      },
    ])
  }

  render () {
    const {
      setNames, setsDataArray
    } = this.props

    return (
      <div>
        <Helmet>
          <title>Sets - Trader Tools | Quiet Speculation</title>
        </Helmet>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Widget header='Magic: the Gathering Sets'>
              {setNames.length > 0 ? (
                <AllSetsTable data={setsDataArray} loaded={setNames.length > 0} />
              ) : (
                <Skeleton height={52} count={10} />
              )}
            </Widget>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  setNames: setNamesSelector(state),
  setsDataArray: setsDataArraySelector(state)
})

const mapDispatchToProps = {
  setBreadcrumbs,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SetsPage))
