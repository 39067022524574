import React from 'react'

import {
  Box,
  Button
} from '@material-ui/core'

import {
  ExpandLess,
  ExpandMore
} from '@material-ui/icons'

const DisplayToggleButton = (props) => {
  const {
    onToggleDisplay, display, text, style = {}, hidden = false
  } = props

  if (hidden) {
    return null
  }

  return (
    <Box style={style}>
      {display ? (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => onToggleDisplay()}
          size="small"
          endIcon={<ExpandLess />}
        >
          Hide {text}
        </Button>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => onToggleDisplay()}
          size="small"
          endIcon={<ExpandMore />}
        >
          Show {text}
        </Button>
      )}
    </Box>
  )
}

export default DisplayToggleButton
