
import {
  SET_INITIAL_DATA,
  SET_CARD_OFFERS,
  SET_EXPANDED_TABLE_ROWS,
  RESET_DATA,
  SET_CARD_UPDATE
} from './actionType'

import { disabledMerchantsSelector } from '../reselect'

import {
  setList,
  setListEntries,
  updateListEntry
} from '../collection/actionCreator'

import { setCards } from '../cards/actionCreator'

import { handleSnackbar } from '../layout/actionCreator'

import {
  fetchList,
  fetchListEntries,
  fetchUpdateListEntry,
  fetchCreateListEntries
} from '../collection/requests'

import {
  buildInitialTraderTools,
  selectOffers
} from './helpers'

export function setExpandedTableRows(table, expanded) {
  return {
    type: SET_EXPANDED_TABLE_ROWS,
    table,
    expanded,
  }
}

export function resetData() {
  return {
    type: RESET_DATA,
  }
}

export function setInitialData(data) {
  return {
    type: SET_INITIAL_DATA,
    data,
  }
}

export function setCardOffers({
  card, cardId
}) {
  return {
    type: SET_CARD_OFFERS,
    card,
    cardId,
  }
}

export function setCardUpdate({
  card, cardId
}) {
  return {
    type: SET_CARD_UPDATE,
    card,
    cardId,
  }
}

export function splitTradeRoutesMerchantCount (cardId, merchantId, qty) {
  return function (dispatch, getState) {
    let card = getState().tradeRoutes.data[cardId]
    let { offers } = card

    let newCard = {
      ...card,
      quantity: qty,
      offers: Object.keys(offers).reduce((acc, key) => {
        let offer = offers[key]
        if (offer.merchant_id === merchantId) {
          acc[key] = {
            ...offer,
            selected: 0
          }
        } else {
          acc[key] = offer
        }
        return acc
      }, {})
    }

    return dispatch(setCardUpdate({
      card: newCard,
      cardId,
    }))
  }
}

export function updateTradeRoutesCard (cardId, merchantIds) {
  return function (dispatch, getState) {
    let card = getState().tradeRoutes.data[cardId]
    let {
      offers, quantity
    } = card

    let newOffers = selectOffers({
      prices: Object.keys(offers).map(v => offers[v]),
      quantity,
      selectedMerchants: merchantIds
    })

    return dispatch(setCardOffers({
      card: newOffers,
      cardId,
    }))
  }
}

// Thunk action creator for fetching list results
export function initializeTradeRoutes (listId) {
  return function (dispatch, getState) {
    dispatch(resetData())
    let state = getState()
    let accessToken = state.user.jwt
    let { buylist } = disabledMerchantsSelector(state)
    return Promise.all([
      fetchList(listId, accessToken),
      fetchListEntries(listId, accessToken),
    ])
      .then(function (response) {
        let {
          entries, cards
        } = response[1]
        dispatch(setList([response[0]]))
        dispatch(setCards(cards))
        dispatch(setListEntries(listId, entries))
        let initial = buildInitialTraderTools({
          cards,
          entries,
          disabledMerchants: buylist,
        })
        return dispatch(setInitialData(initial))
      })
      .catch(function (error) {
        console.log(error)
      })
  }
}

// TODO: Convert to scryfall ID
export function moveListEntries (listId, currentListId, merchantId, payload) {
  return function (dispatch, getState) {
    let accessToken = getState().user.jwt
    const {
      toUpdate, toCreate
    } = payload

    let toCreatePromise = toCreate.length > 0 ? [ fetchCreateListEntries(listId, toCreate, accessToken) ] : []

    let updatePromise = toUpdate.length > 0 ? toUpdate.map((entry, i) => {
      let {
        id: entryId, scryfallId, quantity, listId: targetListId
      } = entry
      return fetchUpdateListEntry ({
        listId: targetListId,
        scryfallId,
        currentListId,
        entryId,
        quantity,
        accessToken
      })
    }) : []

    let promises = [
      ...toCreatePromise,
      ...updatePromise,
    ]

    dispatch(handleSnackbar({
      message: 'Move list entries...',
      options: {
        variant: 'info',
        autoHideDuration: 2000,
      },
    }))

    Promise.all(promises)
      .then(function (res) {

        toUpdate.map(v => {
          let {
            listId, card_id, quantity, id
          } = v
          dispatch(updateListEntry(listId, card_id, {
            quantity,
            id
          }))
          return dispatch(splitTradeRoutesMerchantCount(card_id, merchantId, quantity))
        })

        return dispatch(handleSnackbar({
          message: 'Entries successfully moved.',
          options: {
            variant: 'success',
            autoHideDuration: 2000,
          },
        }))
      })
      .catch(function (err) {
        dispatch(handleSnackbar({
          message: 'Error moving entries.',
          options: {
            variant: 'error',
            autoHideDuration: 3000,
          },
        }))
      })

  }
}


export function removeListEntries (currentListId, merchantId, toUpdate) {
  return function (dispatch, getState) {
    let accessToken = getState().user.jwt

    let promises = toUpdate.length > 0 ? toUpdate.map((entry, i) => {
      let {
        id: entryId, scryfallId, quantity, listId: targetListId
      } = entry
      return fetchUpdateListEntry ({
        listId: targetListId,
        scryfallId,
        currentListId,
        entryId,
        quantity,
        accessToken
      })
    }) : []

    dispatch(handleSnackbar({
      message: 'Removing entries from list...',
      options: {
        variant: 'info',
        autoHideDuration: 2000,
      },
    }))

    Promise.all(promises)
      .then(function (res) {
        toUpdate.map(v => {
          let {
            listId, card_id, quantity, id
          } = v
          dispatch(updateListEntry(listId, card_id, {
            quantity,
            id
          }))
          return dispatch(splitTradeRoutesMerchantCount(card_id, merchantId, quantity))
        })

        return dispatch(handleSnackbar({
          message: 'Entries successfully removed.',
          options: {
            variant: 'success',
            autoHideDuration: 2000,
          },
        }))
      })
      .catch(function (err) {
        dispatch(handleSnackbar({
          message: 'Error removing entries.',
          options: {
            variant: 'error',
            autoHideDuration: 3000,
          },
        }))
      })

  }
}
