import React, {
  useState, useCallback, useEffect
} from 'react'

import { uniq } from 'lodash'

import { DndProvider } from 'react-dnd'
import update from 'immutability-helper'
import { HTML5Backend } from 'react-dnd-html5-backend'

import CSVHeaderCard from './CSVHeaderCard'

import { makeStyles } from '@material-ui/core/styles'
import {
  Box, Button, Grid, TextField, Typography, Checkbox, FormControlLabel
} from '@material-ui/core'

import Widget from '../../shared/Widget'

import {
  IMPORT_COLUMN_TYPES, IMPORT_COLUMN_PAIRINGS
} from '../../../constants'

const useStyles = makeStyles(() => ({
  gridContainer: {
    padding: 16,
  },
  columnItem: {
    textAlign: 'center',
    border: '1px solid gray',
  },
  nextButtonWrapper: {
    textAlign: 'center',
    paddingTop: 16,
  },
  optionBox: {
    border: '1px dashed gray',
    padding: '0.5rem 1rem',
    backgroundColor: 'white',
    textAlign: 'center',
  }
}))

const Step1 = (props) => {
  const {
    hidden, onHandleUpload, importColumns, onUpdateColumns, errorText
  } = props
  const classes = useStyles()

  const [importListCsvValue, setImportListCsvValue] = useState('')
  const [localColumns, setLocalColumns] = useState(importColumns)

  const [optionalColumns, setOptionalColumns] = useState(IMPORT_COLUMN_TYPES.map((type) => ({
    enabled: importColumns.includes(type),
    name: type,
  })))

  const handleOptionalColumnsChange = (name) => {
    setOptionalColumns((prev) => {
      let prevCol = prev.find((val) => val.name === name)

      if (prevCol) {
        let isEnabled = !prevCol.enabled
        let toAdd = isEnabled ? [name, ...IMPORT_COLUMN_PAIRINGS[name].required] : IMPORT_COLUMN_PAIRINGS[name].disallowed
        let toRemove = isEnabled ? IMPORT_COLUMN_PAIRINGS[name].disallowed : [name, ...IMPORT_COLUMN_PAIRINGS[name].required]

        setLocalColumns((prevCols) => {
          return uniq([...toAdd, ...prevCols.filter((v) => !toRemove.includes(v))])
        })

        return uniq(prev.map((col) => {
          if (toAdd.includes(col.name)) {
            return {
              ...col,
              enabled: true,
            }
          }
          if (toRemove.includes(col.name)) {
            return {
              ...col,
              enabled: false,
            }
          }
          return col
        }))
      }
      return prev
    })
  }


  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setLocalColumns((prevCards) => update(prevCards, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, prevCards[dragIndex]],
      ],
    }))
  }, [])

  useEffect(() => {
    onUpdateColumns(localColumns)
  }, [localColumns])

  const renderCard = useCallback((card, index) => {
    return (
      <CSVHeaderCard key={card} index={index} id={card} text={(card === 'set_name') ? 'set_name/set_code' : card} moveCard={moveCard}/>
    )
  }, [])

  const importValid = () => {
    return importListCsvValue.length !== 0 && ((importColumns.includes('card_name') && (importColumns.includes('set_name') || importColumns.includes('set_code'))) || importColumns.includes('scryfall_id') || importColumns.includes('tcgplayer_id'))
  }

  return (
    <Widget header='Step 1: Upload' contentHidden={hidden}>
      <ul>
        <li>Format your spreadsheet to contain only the columns selected below.</li>
        <li>Each column in your card row should be seperated by either a <b>tab</b> or <b>semicolon</b>. Commas are not supported because some card names feature them.</li>
        <li><b>Copy</b> the columns <i>directly from the spreadsheet</i> and <b>paste it below</b>, then follow the step buttons.</li>
        <li>You can change the column order if your spreadsheet does not match the default order. </li>
        <li>Trader Tools will scan your list for errors and help you fix them before proceeding.</li>
        <li>Once the list is <b>error-free</b>, you'll be able to import it into a new or existing list.</li>
      </ul>
      <div className={classes.gridContainer}>
        <Typography variant='h5'>
          Column Order
        </Typography>
        <Grid container className={classes.gridContainer}>
          <DndProvider backend={HTML5Backend}>
            <Box sx={{
              display: 'flex',
              width: '100%',
            }}>
              {localColumns.map((card, i) => renderCard(card, i))}
            </Box>
          </DndProvider>
        </Grid>
        <Grid container className={classes.gridContainer}>
          <Box sx={{
            display: 'flex',
            width: '100%',
          }}>
            <Box sx={{
              flex: 1
            }} />
            {optionalColumns.map((col) => {
              return (
                <Box key={col.name}>
                  <FormControlLabel control={
                    <Checkbox
                      checked={col.enabled}
                      onChange={() => handleOptionalColumnsChange(col.name)}
                      disabled={col.name === 'foil' || col.name === 'quantity'}
                    />
                  } label={(col.name === 'set_name') ? 'set_name/set_code' : col.name} />
                </Box>
              )
            })}
          </Box>
        </Grid>
        <Box style={{
          textAlign: 'right'
        }}>
          <Typography variant='subtitle2'>
            Note: <b>card_name</b> & <b>set_name/set_code</b> OR <b>scryfall_id</b> are required to import.
          </Typography>
        </Box>
      </div>
      <TextField
        autoFocus
        value={importListCsvValue}
        onChange={(e) => setImportListCsvValue(e.target.value)}
        placeholder={importColumns.reduce((acc, key) => acc + key + '; ', '')}
        variant='outlined'
        margin='dense'
        type='text'
        fullWidth
        multiline
        label='Paste your copied CSV here'
        id='importTextArea'
        rows={5}
      />
      <div className={classes.nextButtonWrapper}>
        <Button variant='outlined' disabled={!importValid()} color='primary' onClick={() => onHandleUpload(importListCsvValue)}>
          Proceed to Step 2: Validate
        </Button>
      </div>
      <div style={{
        textAlign: 'center',
        width: '100%'
      }}>
        <Typography variant='caption' style={{
          color: 'red',
        }}>
          {errorText}
        </Typography>
      </div>
    </Widget>
  )
}

export default Step1