import React from 'react'
import { connect } from 'react-redux'
import PropTypes, { object } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  green, red, lightBlue, indigo
} from '@material-ui/core/colors'
import { Link } from 'react-router-dom'
import {
  getBestCardPrices, filterCardOfferData
} from '../../../utils/helpers'
import { disabledMerchantsSelector } from '../../../store/reselect'
import ImagesGrid from '../../shared/ImagesGrid'
import { setSetTableSort } from '../../../store/layout/actionCreator'
import { filterData } from '../../shared/FiltersModal'
import EnhancedTablePagination from '../../shared/EnhancedTablePagination'
import { sliceTableData } from '../../../utils/tables'

import {
  Grid,
  Table,
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  InputAdornment,
  Typography
} from '@material-ui/core'

import SearchIcon from '@material-ui/icons/Search'

import Row from './Row'

const styles = {
  buylist: {
    color: green[500],
  },
  retail: {
    color: lightBlue[400],
  },
}

const columnData = [
  {
    id: 'card_name',
    align: 'left',
    disablePadding: false,
    label: 'Card Name',
    userLoggedIn: false
  },
  {
    id: 'set_name',
    align: 'left',
    disablePadding: false,
    label: 'Set Name',
    userLoggedIn: false
  },
  {
    id: 'rarity',
    align: 'left',
    disablePadding: false,
    label: 'Rarity',
    userLoggedIn: false
  },
  {
    id: 'foil',
    align: 'left',
    disablePadding: false,
    label: 'Foil',
    userLoggedIn: false
  },
  {
    id: 'collector_number',
    align: 'left',
    disablePadding: false,
    label: '#',
    userLoggedIn: false
  },
  {
    id: 'buy_merchant_name',
    align: 'left',
    textAlign: 'right',
    disablePadding: false,
    label: 'Buyer',
    userLoggedIn: false
  },
  {
    id: 'buy_volume',
    align: 'left',
    textAlign: 'center',
    disablePadding: false,
    label: 'Qty',
    userLoggedIn: false
  },
  {
    id: 'buy_best',
    align: 'left',
    textAlign: 'center',
    disablePadding: false,
    label: 'Best Buylist',
    userLoggedIn: false
  },
  {
    id: 'spread_best_usd',
    align: 'left',
    textAlign: 'center',
    disablePadding: false,
    label: 'Spread $',
    userLoggedIn: false
  },
  {
    id: 'spread_best_pct',
    align: 'left',
    textAlign: 'center',
    disablePadding: false,
    label: 'Spread %',
    userLoggedIn: false
  },
  {
    id: 'retail_best',
    align: 'left',
    textAlign: 'center',
    disablePadding: false,
    label: 'Best Retail',
    userLoggedIn: false
  },
  {
    id: 'retail_merchant_name',
    align: 'left',
    paddingLeft: 3,
    disablePadding: false,
    label: 'Seller',
    userLoggedIn: false
  },
  {
    id: 'card_id',
    align: 'left',
    textAlign: 'center',
    disablePadding: false,
    label: 'Edit',
    userLoggedIn: true
  },
]

class EnhancedTableHead extends React.Component {

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const {
      order, orderBy, userLoggedIn
    } = this.props

    return (
      <TableHead>
        <TableRow style={{
          height: 36
        }}>
          {columnData
            .filter(v => userLoggedIn === false ? v.userLoggedIn === userLoggedIn : true)
            .map(column => { return (
              <TableCell
                key={column.id}
                align={column.align}
                padding={'none'}
                sortDirection={orderBy === column.id ? order : false}
                style={{
                  width: column.width,
                  textAlign: (column.textAlign) ? column.textAlign: 'left',
                  paddingLeft: (column.paddingLeft) ? column.paddingLeft : 0
                }}
              >
                {column.id === 'card_id' ? (
                  <>
                    {column.label}
                  </>
                ) : (
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                )}
              </TableCell>
            )}, this)}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

class SetTable extends React.Component {
  static propTypes = {
    classes: object,
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      page: 0,
      searchInput: '',
      foilFilter: 1,
      nonfoil: 1,
      filtersOpen: false,
      reloaded: true,
      imagesMode: false
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data || this.props.foil !== prevProps.foil) {
      this.setState({
        page: 0,
        searchInput: ''
      })
    }
  }

  handleRequestSort = (event, newOrderBy) => {
    const {
      order, orderBy, rowsPerPage, setSetTableSort
    } = this.props

    let newOrder = 'desc'
    if (orderBy === newOrderBy && order === 'desc') {
      newOrder = 'asc'
    }

    setSetTableSort({
      order: newOrder,
      orderBy: newOrderBy,
      rowsPerPage,
    })
  }

  handleChangePage = (event, page) => {
    this.setState({
      page
    })
  }

  handleChangeRowsPerPage = (event) => {
    const {
      order, orderBy, setSetTableSort
    } = this.props
    this.setState({
      page: 0
    })
    setSetTableSort({
      order,
      orderBy,
      rowsPerPage: event.target.value,
    })
  }

  handleSearch = event => {
    let input = event.target.value.toLowerCase()
    this.setState({
      searchSeed: input,
    })
  }

  handleChangeToggle = () => {
    return null
  }

  render () {
    const {
      data, loaded, set, foil, totalRetailValue, totalBuyValue, classes, order, orderBy, rowsPerPage, userLoggedIn, filters
    } = this.props
    const {
      can_be_foil, set_name, foil_only, can_be_nonfoil
    } = set
    const {
      page, searchSeed, foilFilter, nonfoil, filtersOpen, imagesMode
    } = this.state

    if (!loaded || !this.props.data) {
      return <div>Loading...</div>
    }

    const filteredData = filterData({
      filters,
      data: filterCardOfferData({
        data,
        searchSeed,
        order,
        orderBy,
      })
    })

    const tableData = sliceTableData(filteredData, page, rowsPerPage)

    return (
      <React.Fragment>
        {loaded ? (
          <React.Fragment>
            <Grid container>
              <Grid item xs={3}>
                {can_be_foil === 1 &&
                  <Button component={Link} to={ `/prices/sets/${encodeURIComponent(set_name)}/foil` } variant='outlined' color='primary' size='small' disabled={foil === true} style={{
                    marginRight: 6
                  }}>Foil</Button>
                }
                {can_be_nonfoil== 1 &&
                  <Button component={Link} to={ `/prices/sets/${encodeURIComponent(set_name)}` } variant='outlined' color='primary' size='small' disabled={foil === false} style={{
                    marginRight: 6
                  }}>Non-Foil</Button>
                }
                <Button variant='outlined' color='primary' size='small' onClick={() => this.setState({
                  imagesMode: !imagesMode
                })} >
                  {imagesMode ? 'Table' : 'Images'}
                </Button>
              </Grid>
              <Grid item xs={3} style={{
                textAlign: 'center'
              }}>
                <Typography variant='subtitle2' className={classes.buylist}>
                  <strong>Total Buylist:</strong> ${totalBuyValue.toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={3} style={{
                textAlign: 'center'
              }}>
                <Typography variant='subtitle2' className={classes.retail}>
                  <strong>Total Retail:</strong> ${totalRetailValue.toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id='full-width'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder='Search'
                  margin='normal'
                  style={{
                    float: 'right',
                    marginTop: 5
                  }}
                  onChange={this.handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Table style={{
              tableLayout: 'auto'
            }}>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
                userLoggedIn={userLoggedIn}
              />
              <TableBody>
                {imagesMode ? (
                  <TableRow>
                    <TableCell padding={'none'} colSpan={columnData.filter(v => userLoggedIn === false ? v.userLoggedIn !== userLoggedIn : true).length}>
                      <ImagesGrid data={tableData} />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {tableData.map((v, i) => {
                      return (
                        <Row card={v} key={i + '_' + v.card_id} userLoggedIn={userLoggedIn} />
                      )
                    })}
                  </>
                )}
              </TableBody>
            </Table>
            <EnhancedTablePagination
              filteredCount={filteredData.length}
              totalCount={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </React.Fragment>
        ) : (
          <div>Loading</div>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    order, orderBy, rowsPerPage
  } = state.layout.setTableSort

  let entries = ownProps.entries ? ownProps.entries : []
  let cards = state.cards.entries
  let merchants = state.merchants.data
  let disabledMerchants = disabledMerchantsSelector(state)

  var totalRetailValue = 0.00
  var totalBuyValue = 0.00
  let data = entries.map(cardId => {
    return cards[cardId] || null
  })
    .filter(v => v != null)
    .map(card => {
      let {
        buy_merchant_name,
        buy_merchant_code,
        buy_volume,
        buy_best,
        spread_best_usd,
        spread_best_pct,
        retail_best,
        retail_merchant_name,
        retail_merchant_code
      } = getBestCardPrices(card.buylist_prices, card.retail_prices, merchants, disabledMerchants)

      if (!Number.isNaN(parseFloat(retail_best))) {
        totalRetailValue = parseFloat(totalRetailValue) + parseFloat(retail_best)
      }
      if (!Number.isNaN(parseFloat(buy_best))) {
        totalBuyValue = parseFloat(totalBuyValue) + parseFloat(buy_best)
      }

      return {
        card_id: card.card_id,
        set_name: card.set_name,
        card_name: card.card_name,
        foil: card.foil,
        set_code: card.set_code,
        rarity: card.rarity,
        buy_merchant_name,
        buy_merchant_code,
        buy_volume,
        buy_best,
        spread_best_usd,
        spread_best_pct,
        retail_best,
        retail_merchant_name,
        retail_merchant_code,
        scryfall_id: card.scryfall_id,
        variant: card.variant,
        collector_number: card.collector_number,
      }
    })

  return {
    merchants,
    data,
    totalRetailValue,
    totalBuyValue,
    order,
    orderBy,
    rowsPerPage,
    userLoggedIn: state.user.loggedIn,
    filters: state.user.filters
  }
}

const mapDispatchToProps = {
  setSetTableSort,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SetTable))
