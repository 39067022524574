import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { setBreadcrumbs } from '../../../store/layout/actionCreator'
import MerchantHeader from './MerchantHeader'

import Widget from '../../shared/Widget'
import Grid from '@material-ui/core/Grid'

const TCGPLAYER_REDIRECTS = ['tcgplayer_high', 'tcgplayer_direct_low', 'tcgplayer_low', 'tcgplayer_market', 'tcgplayer' ]

class MerchantPage extends React.Component {

  constructor(props) {
    super(props)
    this.initialize()
  }

  initialize() {
    const {
      merchant, merchantName, setBreadcrumbs
    } = this.props

    setBreadcrumbs([
      {
        text: 'Trader Tools',
        path: '/'
      },
      {
        text: 'Merchants',
        path: '/merchants'
      },
      {
        text: TCGPLAYER_REDIRECTS.includes(merchant.merchant_code) ? 'TCGplayer' : merchantName
      },
    ])
  }

  componentDidUpdate(prevProps) {
    if (this.props.merchantName !== prevProps.merchantName) {
      this.initialize()
    }
  }

  render () {
    const {
      merchantName, merchant, tcgplayer
    } = this.props

    let cleanedMerchant = TCGPLAYER_REDIRECTS.includes(merchant.merchant_code) ? {
      ...tcgplayer,
      merchant_name: 'TCGplayer',
    } : merchant

    return (
      <div>
        <Helmet>
          <title>{cleanedMerchant.merchant_name} - Trader Tools | Quiet Speculation</title>
        </Helmet>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Widget header={`${cleanedMerchant.merchant_name}`} style={{
              textAlign: 'center'
            }}>
              <MerchantHeader merchant={cleanedMerchant} />
            </Widget>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {

  let merchantCode = ownProps.match.params.merchantName
  let merchant = state.merchants.data.find(v => v.merchant_code === merchantCode)
  let tcgplayer = state.merchants.data.find(v => v.merchant_code === 'tcgplayer')
  return {
    tcgplayer,
    merchant,
    merchantName: merchant.merchant_name || 'Not found',
    merchantCode: merchant.merchant_code || '',
    merchantId: merchant.merchant_id || null,
    hasBuylist: merchant.merchant_name || null,
    hasRetail: merchant.merchant_name || null,
    imgURL: merchant.merchant_name || null,
    pagination: state.merchants.pagination,
    entries: state.merchants.entries,
    buyCount: merchant.buy_count,
    retailCount: merchant.retail_count,
  }
}

const mapDispatchToProps = {
  setBreadcrumbs,
}

export default connect(mapStateToProps, mapDispatchToProps)(MerchantPage)
