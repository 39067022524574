import React, { useRef } from 'react'

import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ListSubheader from '@material-ui/core/ListSubheader'
import {
  useTheme, makeStyles
} from '@material-ui/core/styles'
import { VariableSizeList } from 'react-window'
import { Typography } from '@material-ui/core'

const LISTBOX_PADDING = 8 // px

function renderRow(props) {
  const {
    data, index, style
  } = props
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  })
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data) {
  const ref = React.useRef(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const {
    children, ...other
  } = props
  const itemData = React.Children.toArray(children)
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true
  })
  const itemCount = itemData.length
  const itemSize = smUp ? 36 : 48

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48
    }

    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

ListboxComponent.propTypes = {
  children: PropTypes.node,
}

const useStyles = makeStyles({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
})

export default function SetSelectorList(props) {
  const {
    options, onChange
  } = props
  const classes = useStyles()

  const ref = useRef(null)

  const handleOnChange = (_, val) => {
    onChange(val)
    setTimeout(() => {
      ref.current.focus()
    }, 200)
  }

  return (
    <>
      <input
        ref={ref}
        style={{
          opacity: 0,
          width: 0,
          height: 0,
          overflow: 'hidden',
          marginBottom: -12,
        }}
      />
      <Autocomplete
        id="additional-sets-autocomplete"
        fullWidth={true}
        disableListWrap
        classes={classes}
        ListboxComponent={ListboxComponent}
        options={options}
        value={null}
        renderInput={(params) => <TextField {...params} variant="outlined" label="Select a set to add" />}
        renderOption={(option) => <Typography noWrap>{option}</Typography>}
        onChange={handleOnChange}
      />
    </>
  )
}