import React from 'react'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'
import { setBreadcrumbs } from '../../../store/layout/actionCreator'
import { Link } from 'react-router-dom'
import { getReports } from '../../../store/reports/actionCreator'
import {
  setNamesSelector, setsDataObjSelector
} from '../../../store/reselect'

import ReportTable from './ReportTable'
import Keyrune from '../../shared/Keyrune'
import Widget from '../../shared/Widget'

import Grid from '@material-ui/core/Grid'
import Skeleton from 'react-loading-skeleton'

const styles = {
  listLinks: {
    paddingBottom: 5,
    paddingTop: 5,
  }
}

class PricesPage extends React.Component {
  static propTypes = {
    classes: object,
  }

  constructor(props) {
    super(props)
    props.setBreadcrumbs([
      {
        text: 'Trader Tools',
        path: '/'
      },
      {
        text: 'Price Research'
      },
    ])
    if (!props.loaded) {
      props.getReports()
    }
  }

  render () {
    const {
      classes,
      setNames,
      setsDataObj,
      merchants,
      BuylistIncreases24hr,
      RetailIncreases24hr,
      BuylistIncreases7d,
      RetailIncreases7d,
      loaded
    } = this.props

    return (
      <div>
        <Helmet>
          <title>Price Research - Trader Tools | Quiet Speculation</title>
        </Helmet>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Widget header='Magic Sets'>
                  {setNames.map((v, i) => {
                    return <div className={classes.listLinks} key={i}><Link to={'/prices/sets/' + encodeURIComponent(setsDataObj[v].set_name)}><Keyrune set={setsDataObj[v].set_code} rarity='mythic' /> {setsDataObj[v].set_name}</Link></div>
                  })}
                  <div style={{
                    textAlign: 'right'
                  }}>
                    <Link to='/prices/sets'>
                      All Sets
                    </Link>
                  </div>
                </Widget>
              </Grid>
              <Grid item xs={12}>
                <Widget header='Merchants'>
                  {merchants.length > 0 && merchants.filter(v => ![0, 16, 21].includes(v.merchant_id))
                    .filter(v => (v.buy_count !== 0 || v.retail_count !== 0))
                    .map((v, i) => {
                      return <div className={classes.listLinks} key={i}><Link to={'/merchants/' + v.merchant_code}>{v.merchant_name}</Link></div>
                    })}
                  <div style={{
                    textAlign: 'right'
                  }}>
                    <Link to='/merchants'>
                      All Merchants
                    </Link>
                  </div>
                </Widget>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Widget header='Buylist Increases (24hr)'>
                  {loaded ? (
                    <ReportTable data={BuylistIncreases24hr} name='Buylist Increases (24hr)' />
                  ) : (
                    <Skeleton height={37} count={12} />
                  )}
                </Widget>
              </Grid>
              <Grid item xs={12}>
                <Widget header='Retail Increases (24hr)'>
                  {loaded ? (
                    <ReportTable data={RetailIncreases24hr} name='Retail Increases (24hr)' />
                  ) : (
                    <Skeleton height={37} count={12} />
                  )}
                </Widget>
              </Grid>
              <Grid item xs={12}>
                <Widget header='Buylist Changes (7d)'>
                  {loaded ? (
                    <ReportTable data={BuylistIncreases7d} name='Buylist Changes (7d)' />
                  ) : (
                    <Skeleton height={37} count={12} />
                  )}
                </Widget>
              </Grid>
              <Grid item xs={12}>
                <Widget header='Retail Changes (7d)'>
                  {loaded ? (
                    <ReportTable data={RetailIncreases7d} name='Retail Changes (7d)' />
                  ) : (
                    <Skeleton height={37} count={12} />
                  )}
                </Widget>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  setNames: setNamesSelector(state).sort((a, b) => (b['publish_date'] < a['publish_date'] ? -1 : 1)).slice(0, 10) || [],
  setsDataObj: setsDataObjSelector(state) || {},
  merchants: state.merchants.data,
  loaded: state.reports.loaded,
  BuylistIncreases24hr: state.reports.BuylistIncreases24hr,
  RetailIncreases24hr: state.reports.RetailIncreases24hr,
  BuylistIncreases7d: state.reports.BuylistIncreases7d,
  RetailIncreases7d: state.reports.RetailIncreases7d,
})

const mapDispatchToProps = {
  setBreadcrumbs,
  getReports,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PricesPage))
