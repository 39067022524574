import { SET_REPORTS } from './actionType'

import { fetchReports } from './requests'

export function setReports (reports) {
  return {
    type: SET_REPORTS,
    reports
  }
}

export function getReports () {
  return function (dispatch, getState) {
    let accessToken = getState().user.jwt
    return fetchReports(accessToken)
      .then(function (response) {
        dispatch(setReports(response))
      })
      .catch(function (error) {
        return error
      })
  }
}
