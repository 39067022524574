import React from 'react'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { setBreadcrumbs } from '../../../store/layout/actionCreator'
import ListsTable from './ListsTable'
import Auth from '../../shared/Auth'

import Widget from '../../shared/Widget'
import CollectionNavigation from '../../shared/CollectionNavigation'

import Grid from '@material-ui/core/Grid'

class CollectionPage extends React.Component {
  static propTypes = {
    classes: object,
  }

  constructor(props) {
    super(props)
    props.setBreadcrumbs([
      {
        text: 'Trader Tools',
        path: '/'
      },
      {
        text: 'Collection'
      },
    ])
    this.state={
      activeList: undefined,
      listOpen: false,
      showArchived: 0,
    }
  }

  handleWidgetToggle(e) {
    this.setState({
      activeList: e,
      listOpen: true
    })
  }

  render () {
    const {
      activeList, listOpen, showArchived
    } = this.state

    return (
      <div>
        <Helmet>
          <title>Collection - Trader Tools | Quiet Speculation</title>
        </Helmet>
        <Auth
          Base={
            <Grid container spacing={1}>
              <Grid item xs={12}>
                You are required to be logged in to Quiet Speculation to manage a collection.
              </Grid>
            </Grid>
          }
          LoggedIn={
            <Grid container spacing={1}>
              <CollectionNavigation
                externalList={activeList}
                externalListOpen={listOpen}
                setExternalListOpen={() => {
                  this.setState({
                    activeList: undefined,
                    listOpen: false
                  })
                }}
              />
              <Grid item xs={12}>
                <Widget header='Manage Your Lists'>
                  <ListsTable
                    onWidgetToggle={(e) => this.handleWidgetToggle(e)}
                    showArchived={showArchived}
                  />
                </Widget>
              </Grid>
            </Grid>
          }
        />

      </div>
    )
  }
}

const mapDispatchToProps = {
  setBreadcrumbs,
}

export default connect(null, mapDispatchToProps)(CollectionPage)
