import React, {
  useState, useEffect
} from 'react'
import {
  Grid, Typography
} from '@material-ui/core'
import {
  Link, withRouter
} from 'react-router-dom'
import Widget from '../../shared/Widget'
import Keyrune from '../../shared/Keyrune'
import CardImageModal from '../../shared/CardImageModal'
import Skeleton from 'react-loading-skeleton'


const AllPrintings = (props) => {
  const {
    card_name, loaded, sets, classes
  } = props

  const [all, setAll] = useState(false)

  useEffect(() => {
    if (all === true) {
      setAll(false)
    }
  }, [card_name, sets])

  if (sets.length < 2) {
    return null
  }


  const displayedSets = all ? sets : sets.slice(0, 5)

  return (
    <Grid item xs={12} style={{
      marginBottom: 16
    }}>
      <Widget header='All Printings'>
        {loaded ? (
          <>
            {displayedSets.map((v, i) => {
              let {
                scryfall_id, set_code, set_name, foil, collector_number, variant
              } = v
              return <div className={classes.setsLink} key={i}>
                <CardImageModal scryfallId={scryfall_id} style={{
                  paddingRight: 6
                }} />
                <Link to={`/prices/sets/${encodeURIComponent(set_name)}/${encodeURIComponent(card_name)}${(foil === 1) ? '/foil' : ''}?variant=${scryfall_id}`}>
                  <Keyrune set={set_code} /> {set_name} {(foil === 1) && '(Foil)'} {(variant === 1) && '(Variant)'} - #{collector_number}
                </Link>
              </div>
            })}
            {sets.length > 5 && !all ? (
              <div
                style={{
                  textAlign: 'right',
                  fontSize: '.8rem',
                  color: '#7986cb',
                  cursor: 'pointer',
                  paddingTop: 16,
                }}
                onClick={() => setAll(true)}
              >
              Show All Printings ({sets.length})
              </div>
            ) : null}
          </>
        ) : (
          <Skeleton height={37} count={5} />
        )}
      </Widget>
    </Grid>
  )
}

export default withRouter(AllPrintings)
