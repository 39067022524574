import React from 'react'
import { Link } from 'react-router-dom'

import {
  Grid, Button
} from '@material-ui/core'

import ListSelect from './ListSelect'

export const OriginListSelector = ({
  listId, onSelect, removable, onRemove
}) => {

  return (
    <div style={{
      padding: '1rem'
    }}>
      <ListSelect
        activeList={listId}
        onSelect={(listId) => onSelect(listId)}
        label='Cards from this list will be moved to the destination list.'
      />
      <Grid container>
        <Grid item xs={12} sm={6}>
          {listId !== 'no_list' && (
            <Button variant='outlined' size='small' style={{
              marginTop: '.6rem'
            }} component={Link} to={`/collection/lists/${listId}`} target='_blank'>
              View List
            </Button>
          )}

        </Grid>
        <Grid item xs={12} sm={6} style={{
          textAlign: 'right'
        }}>
          {removable === true && (
            <Button
              variant='outlined'
              size='small'
              style={{
                marginTop: '.6rem',
                marginLeft: 'auto'
              }}
              onClick={onRemove}
            >
              Remove List
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  )

}

export default OriginListSelector