import {
  SET_SETS,
  SET_SET_ENTRIES,
  SET_SET_ENTRIES_ERROR,
  SET_SELECTED_SETS
} from './actionType'

import {
  fetchSets,
  fetchSetEntries
} from './requests'

import { setCards } from '../cards/actionCreator'
import { handleSnackbar } from '../layout/actionCreator'

export function setSets (sets) {
  return {
    type: SET_SETS,
    sets,
  }
}

export function setSetEntriesError (error) {
  return {
    type: SET_SET_ENTRIES_ERROR,
    error,
  }
}

export function setSetEntries (setName, foil, entries) {
  return {
    type: SET_SET_ENTRIES,
    setName,
    foil,
    entries,
  }
}

export function setSelectedSets (sets) {
  return {
    type: SET_SELECTED_SETS,
    sets,
  }
}

export function getSets () {
  return function (dispatch, getState) {
    let accessToken = getState().user.jwt
    return fetchSets(accessToken)
      .then(function (response) {
        return dispatch(setSets(response))
      })
      .catch(function (error) {
        return error
      })
  }
}

export function getSetEntries (setName, foil) {
  return function (dispatch, getState) {
    dispatch(setSetEntriesError(false))
    let accessToken = getState().user.jwt
    return fetchSetEntries(setName, foil, accessToken)
      .then(function (response) {
        const {
          entries, cards
        } = response
        let foilKey = (foil === true || foil === 1 || foil === '1') ? 1 : 0
        dispatch(setSetEntries(setName.toLowerCase(), foilKey, entries))
        dispatch(setCards(cards))
        return
      })
      .catch(function (error) {
        dispatch(setSetEntriesError(true))
        return error
      })
  }
}

export function getInitialSetEntries (setName, foil) {
  return function (dispatch, getState) {
    let foilKey = (foil === true || foil === 1 || foil === '1') ? 1 : 0
    let name = setName.toLowerCase()
    dispatch(setSetEntriesError(false))
    dispatch(setSelectedSets([
      {
        set_name: name,
        foil: (foilKey === 1) ? 1 : 0,
        nonfoil: (foilKey === 0) ? 1 : 0,
      }
    ]))
    let accessToken = getState().user.jwt
    return fetchSetEntries(setName, foil, accessToken)
      .then(function (response) {
        const {
          entries, cards
        } = response
        dispatch(setSetEntries(name, foilKey, entries))
        dispatch(setCards(cards))
        return
      })
      .catch(function (error) {
        dispatch(setSetEntriesError(true))
        return error
      })
  }
}

export function getAdditionalSetEntries (setName, foil, active) {
  return function (dispatch, getState) {
    let foilKey = (foil === true || foil === 1 || foil === '1') ? 1 : 0
    let name = setName.toLowerCase()
    let selectedSets = getState().sets.selectedSets

    dispatch(setSetEntriesError(false))

    let exists = selectedSets.find(v => v.set_name === name)
    if (exists) {
      let newSelectedSets = selectedSets.map(v => {
        if (v.set_name === name) {
          return {
            set_name: name,
            foil: (foilKey === 1) ? ((active === true) ? 1 : 0) : v.foil,
            nonfoil: (foilKey === 0) ? ((active === true) ? 1 : 0) : v.nonfoil,
          }
        }
        return v
      })
      dispatch(setSelectedSets(newSelectedSets))
    } else {
      dispatch(setSelectedSets([
        ...selectedSets,
        {
          set_name: name,
          foil: (foilKey === 1) ? 1 : 0,
          nonfoil: (foilKey === 0) ? 1 : 0,
        }
      ]))
    }

    if (!active) {
      return
    }

    let foilString = foilKey === 1 ? 'Foil' : 'Non-Foil'

    dispatch(handleSnackbar({
      message: `Loading ${foilString} ${setName}...`,
      options: {
        variant: 'info',
        autoHideDuration: 2000,
      },
    }))

    let accessToken = getState().user.jwt
    return fetchSetEntries(setName, foil, accessToken)
      .then(function (response) {
        const {
          entries, cards
        } = response
        dispatch(setSetEntries(name, foilKey, entries))
        dispatch(setCards(cards))
        return dispatch(handleSnackbar({
          message: `${foilString} ${setName} loaded.`,
          options: {
            variant: 'success',
            autoHideDuration: 2000,
          },
        }))
      })
      .catch(function (error) {
        dispatch(handleSnackbar({
          message: `Error loading ${foilString} ${setName}.`,
          options: {
            variant: 'error',
            autoHideDuration: 3000,
          },
        }))
        dispatch(setSetEntriesError(true))
        return error
      })
  }
}


export function setAdditionalSet (setName) {
  return function (dispatch, getState) {
    let name = setName.toLowerCase()
    let selectedSets = getState().sets.selectedSets
    dispatch(setSelectedSets([
      {
        set_name: name,
        foil: 0,
        nonfoil: 0,
      },
      ...selectedSets
    ]))
  }
}

export function removeAdditionalSet (setName) {
  return function (dispatch, getState) {
    let name = setName.toLowerCase()
    let selectedSets = getState().sets.selectedSets
    dispatch(setSelectedSets(selectedSets.filter((set) => set.set_name.toLowerCase() !== name)))
  }
}

