import React from 'react'
import { object } from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
// import { SocialIcon } from 'react-social-icons'

const styles = theme => ({
  footer: {
    padding: 15,
  },
  social: {
    textAlign: 'center',
  },
  socialIcon: {
    margin: 20,
  },
})

export class Footer extends React.Component {
  static propTypes = {
    classes: object,
    images: object,
  }

  static defaultProps = {
    classes: {},
  }


  render () {
    const { classes } = this.props

    return (
      <div>
        <footer className={classes.footer}>
          <div className={classes.social}>
            {/* <SocialIcon className={classes.socialIcon} color='#bbb' url='https://www.facebook.com/TCIBJJ' />
            <SocialIcon className={classes.socialIcon} color='#bbb' url='https://www.youtube.com/channel/UCTRaUpgFpw8wPXwUMaT2IxQ' />
            <SocialIcon className={classes.socialIcon} color='#bbb' url='https://www.instagram.com/tcibjj' />
            <SocialIcon className={classes.socialIcon} color='#bbb' url='mailto:info@tcibjj.com' /> */}
          </div>
          <Typography variant='subtitle1' align='center' style={{
            color:'#bbb'
          }} component='p'>
            © {(new Date().getFullYear())} Quiet Speculaton LLC. All Rights Reserved.
          </Typography>
        </footer>
      </div>
    )
  }
}

export default withStyles(styles)(Footer)
