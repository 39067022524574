import React from 'react'
// import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import ViewIcon from '@material-ui/icons/Image'
import { grey } from '@material-ui/core/colors'
import { cardImageURL } from '../../utils/helpers'

const styles = {
  formControl: {
    width: '100%',
    paddingBottom: 28
  },
  root: {
    backgroundColor: 'transparent'
  },
  paper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden'
  },
  icon: {
    display: 'inline-block',
    position: 'relative',
  },
  floatingImage: {
    position: 'absolute',
    top: -235,
    left: '100%',
    zIndex: 99999,
  }
}

class CardImageModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      imageLoaded: false
    }
  }

  handleClickOpen() {
    this.setState({
      open: true,
      showFloating: false,
    })
  }

  handleClose() {
    this.setState({
      open: false,
      showFloating: false,
    }, () => {
      if (this.props.onEntryToggle) {
        this.props.onEntryToggle(false)
      }
    })
  }

  render() {
    const {
      open, imageLoaded, showFloating
    } = this.state

    const {
      gathererId,
      style,
      scryfallId,
      classes
    } = this.props

    let url = (scryfallId)
      ? cardImageURL(scryfallId)
      : (gathererId)
        ? `https://gatherer.wizards.com/Handlers/Image.ashx?multiverseid=${gathererId}&type=card`
        :  null

    if (!url) return null

    return (
      <>
        <div className={classes.icon}>
          <ViewIcon
            aria-label='View Card Image'
            onClick={() => this.handleClickOpen()}
            style={{
              fontSize: '1rem',
              cursor: 'pointer',
              verticalAlign: 'middle',
              color: grey[500],
              ...style,
            }}
            onMouseEnter={() => this.setState({
              showFloating: true
            })}
            onMouseLeave={() => this.setState({
              showFloating: false
            })}
          />
          {showFloating &&
            <div className={classes.floatingImage}>
              <img
                src={url}
                alt={url}
                onLoad={() => this.setState({
                  imageLoaded: true
                })}
                style={!imageLoaded ? {
                  height: 0
                } : {
                  maxWidth: 338,
                  borderRadius: '4.75% / 3.5%'
                }}
              />
            </div>
          }
        </div>
        {open &&
          <Dialog
            open={open}
            onClose={() => this.handleClose()}
            PaperProps ={{
              classes: {
                root: classes.paper
              }
            }}
          >
            <DialogContent
              style={{
                minWidth: 338,
                minHeight: 471,
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
              onClick={() => this.handleClose()}
            >
              <img
                src={url}
                alt={url}
                onLoad={() => this.setState({
                  imageLoaded: true
                })}
                style={!imageLoaded ? {
                  height: 0
                } : {
                  maxWidth: 338,
                  borderRadius: '4.75% / 3.5%'
                }}
                onClick={() => this.handleClose()}
              />
              {!imageLoaded &&
                <Skeleton height={471} width={338} count={1} />
              }
            </DialogContent>
          </Dialog>
        }
      </>
    )
  }
}

export default withStyles(styles)(CardImageModal)