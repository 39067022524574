import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ListEditor from './ListEditor'

const styles = {
  topNav: {
    marginBottom: 20,
    textAlign: 'center',
  },
  button: {
    textTransform: 'none',
  }
}

class ListOptions extends React.Component {
  constructor(props) {
    super(props)
    this.state={
      listOpen: false,
    }
  }

  handleWidgetToggle() {
    this.setState({
      listOpen: true
    })
  }

  handleListToggle(e) {
    if (!e) {
      this.setState({
        listOpen: false,
      })
    }
  }

  render () {
    const { listOpen } = this.state

    const {
      classes, mode, list, count
    } = this.props

    const { id } = list

    return (
      <>
        <ListEditor
          list={list}
          open={listOpen}
          // onListSave={(e) => this.handleSave(e)}
          onListToggle={(e) => this.handleListToggle(e)}
          hideButton={true}
        />
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.topNav}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={3}>
                <Button
                  variant='outlined'
                  size='large'
                  color='primary'
                  className={classes.button}
                  component={Link}
                  to={`/collection/lists/${id}`}
                  disabled={mode === 'view'}
                >
                  View List
                </Button>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Button
                  variant='outlined'
                  size='large'
                  color='primary'
                  className={classes.button}
                  component={Link}
                  to={`/sell/${id}`}
                  disabled={mode === 'sell' || (count !== null && count === 0 ? true : false)}
                >
                  Sell to Merchants
                </Button>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Button
                  variant='outlined'
                  size='large'
                  color='primary'
                  component={Link}
                  to={`/collection/merge/${list.id}`}
                  className={classes.button}>
                  Merge List
                </Button>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Button
                  variant='outlined'
                  size='large'
                  color='primary'
                  onClick={() => this.handleWidgetToggle()}
                  className={classes.button}
                >
                  Edit List
                </Button>
              </Grid>
              {/* <Grid item xs={3}>
                <Button variant='outlined' size='large' color='primary' disabled className={classes.button}>
                  Filters
                </Button>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }
}

export default withStyles(styles)(ListOptions)
