import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import Keyrune from '../../shared/Keyrune'
import { prettifyType } from '../../../utils/helpers'

import {
  TableCell,
  TableRow
} from '@material-ui/core'

import ChevronRightIcon from '@material-ui/icons/ChevronRight'

export const SetRow = ({
  set, showIndent
}) => {

  const {
    set_code, set_name, set_type, can_be_foil, publish_date, parent_set_code, second_pass
  } = set

  return (
    <TableRow style={{
      height: 36
    }}>
      <TableCell padding={'none'}>
        {(showIndent && parent_set_code) && <ChevronRightIcon style={{
          fontSize: '.8rem',
          color: 'grey',
          paddingLeft: second_pass === true ? 10 : 0
        }} />}
        <Link to={`/prices/sets/${encodeURIComponent(set_name)}`}>
          <Keyrune set={set_code}/> {set_name}
        </Link>
      </TableCell>
      <TableCell padding={'none'}>
        <Keyrune set={set_code}/> <span>{(set_code) ? set_code.toUpperCase() : ''}</span>
      </TableCell>
      <TableCell padding={'none'}>
        {prettifyType(set_type)}
      </TableCell>
      <TableCell padding={'none'}>
        { can_be_foil ?
          <Link to={`/prices/sets/${encodeURIComponent(set_name)}/foil`}>
            Foil
          </Link> : null
        }
      </TableCell>
      <TableCell padding={'none'}>
        <span>{moment(publish_date).format('LLL').replace(moment(publish_date).format('LT'), '')}</span>
      </TableCell>
    </TableRow>
  )
}

export default SetRow