import { fetch } from '../../utils/requests'

export function fetchSets (accessToken) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: '/sets',
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      baseURL: '/api',
      timeout: 120000,
    })
      .then(v => resolve(v))
      .catch(e => reject(e))
  })
}

export function fetchSetEntries (setName, foil, accessToken) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: `/sets/${encodeURIComponent(setName)}/cards`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      params: {
        foil,
      },
      baseURL: '/api',
      timeout: 120000,
    })
      .then(v => resolve(v))
      .catch(e => reject(e))
  })
}
