export const SET_BREADCRUMBS = 'SET_BREADCRUMBS'
export const SET_LOADED = 'SET_LOADED'

export const SET_SNACKBAR = 'SET_SNACKBAR'
export const DISMISS_SNACKBAR = 'DISMISS_SNACKBAR'
export const DISMISS_ALL_SNACKBARS = 'DISMISS_ALL_SNACKBARS'
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR'

export const TOGGLE_SETTINGS = 'TOGGLE_SETTINGS'

export const SET_SETS_TABLE_TYPES = 'SET_SETS_TABLE_TYPES'
export const SET_SETS_TABLE_SORT = 'SET_SETS_TABLE_SORT'
export const SET_SET_TABLE_SORT = 'SET_SET_TABLE_SORT'
export const SET_LIST_TABLE_SORT = 'SET_LIST_TABLE_SORT'
export const SET_COLLECTION_TABLE_SORT = 'SET_COLLECTION_TABLE_SORT'

export const SET_IMPORT_COLUMNS = 'SET_IMPORT_COLUMNS'