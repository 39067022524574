import React from 'react'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import { disabledMerchantsSelector } from '../../../store/reselect'
import { setUserActiveList } from '../../../store/user/actionCreator'
import { toggleSettings } from '../../../store/layout/actionCreator'
import { listsSelector } from '../../../store/reselect'
import UserPreferences from './UserPreferences'
import MerchantSettings from './MerchantSettings'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import ListSelect from './ListSelect'

class SettingsModal extends React.Component {
  static propTypes = {
    classes: object,
  }

  handleClose = () => {
    this.props.toggleSettings()
  }

  handleActiveListUpdate = (listId) => {
    this.props.setUserActiveList(listId)
  }

  render () {
    const {
      open, lists, activeList
    } = this.props

    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
          maxWidth={'md'}
        >
          <DialogTitle>Settings</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant='h5'>
                  Active List
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ListSelect
                  lists={lists}
                  activeList={activeList}
                  onSelect={(listId) => this.handleActiveListUpdate(listId)}
                />
              </Grid>
              <UserPreferences />
              <MerchantSettings />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary'>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  disabledMerchants: disabledMerchantsSelector(state),
  open: state.layout.settingsOpen,
  activeList: state.user.activeList,
  lists: listsSelector(state)
})

const mapDispatchToProps = {
  toggleSettings,
  setUserActiveList,
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsModal)
