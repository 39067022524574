import {
  SET_MERCHANTS, SET_ENTRIES, SET_PAGINATION
} from './actionType'

import {
  fetchMerchants,
  fetchMerchantBestBuylist
} from './requests'

import { setCards } from '../cards/actionCreator'

export function setMerchants (merchants) {
  return {
    type: SET_MERCHANTS,
    merchants,
  }
}

export function setEntries (entries = []) {
  return {
    type: SET_ENTRIES,
    entries,
  }
}

export function setPagination (pagination) {
  return {
    type: SET_PAGINATION,
    pagination: {
      numRows: pagination.numRows || 0,
      numPages: pagination.numPages || 0,
      previous: pagination.previous || null,
      next: pagination.next || null,
      loaded: pagination.loaded || false,
      orderBy: pagination.orderBy || 'card_name',
      order: pagination.order || 'ASC',
      page: pagination.page || 1,
      type: pagination.type || null,
      seed: pagination.seed || null,
      perPage: pagination.perPage || 25,
    }
  }
}

export function getMerchants () {
  return function (dispatch, getState) {
    let accessToken = getState().user.jwt
    return fetchMerchants(accessToken)
      .then(function (response) {
        dispatch(setMerchants(response))
      })
      .catch(function (error) {
        return error
      })
  }
}

export function getMerchantBestPrices ({
  merchantId,
  orderBy,
  order,
  page,
  type,
  perPage,
  numRows,
  numPages,
  previous,
  next,
  seed,
}) {
  return function (dispatch, getState) {
    dispatch(setPagination({
      loaded: false,
      orderBy,
      order,
      page,
      type,
      perPage,
      numRows,
      numPages,
      previous,
      next,
      seed,
    }))
    let accessToken = getState().user.jwt
    return fetchMerchantBestBuylist({
      merchantId,
      orderBy,
      order,
      page,
      type,
      perPage,
      seed,
      accessToken
    })
      .then(function (response) {
        const {
          entries, cards, pagination
        } = response
        dispatch(setEntries(entries))
        dispatch(setCards(cards))
        return dispatch(setPagination({
          ...pagination,
          type,
          loaded: true,
        }))
      })
      .catch(function (error) {
        return error
      })
  }
}
