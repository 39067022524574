import {
  SET_LISTS,
  SET_LIST,
  SET_LIST_ENTRIES,
  SET_LISTS_WITH_CARD,
  UPDATE_LIST_ENTRY,
  ADD_LIST_ENTRY,
  DELETE_LIST_ENTRY,
  SET_DRY_RUN,
  SET_RETRY_DRY_RUN,
  REMOVE_DRY_RUN_ENTRY,
  UPDATE_DRY_RUN_ENTRY_QUANTITY,
  UPDATE_IMPORT_STATUS,
  CHANGE_DRY_RUN_VARIANT,
  SET_LIST_DELETED,
  SET_ARCHIVED_LOADED,
  CONSOLIDATE_DRY_RUN_ENTRY,
  CONFIRM_DRY_RUN_CORRECTION
} from './actionType'

// let lists = [{
//   "id": 129957,
//   "name": "Newest List Around ",
//   "user_id": 541,
//   "note": "describe your list",
//   "public": 1,
//   "active": 1,
//   "trash": 0,
//   "type": 10,
//   "created_on": "2017-10-12T17:12:44.000Z",
//   "updated_on": "2018-09-28T17:15:26.000Z",
//   "parent_id": null,
//   "batch_id": null,
//   "variant_relevant": 1
// }]

export const initialState = {
  status: '',
  data: {},
  archivedLoaded: false,
  loaded: false,
  error: false,
  listEntries: {},
  listsWithCard: {},
  dryRunList: [],
  dryRunLoaded: false,
  dryRunError: false,
  importLoading: false,
  importSuccess: false,
  importError: false,
}

export default function collectionReducer (state = initialState, action = {}) {
  switch (action.type) {
  case CONFIRM_DRY_RUN_CORRECTION: {
    const { key } = action
    const newState = {
      ...state,
      dryRunList: state.dryRunList.map((v) => {
        if (v.key === key) {
          return {
            ...v,
            corrected: false
          }
        }
        return v
      }),
    }
    return newState
  }
  case CONSOLIDATE_DRY_RUN_ENTRY: {
    const { dryRunList } = state
    const { entry } = action
    const {
      scryfall_id, foil, key
    } = entry

    let newQuantity = dryRunList.filter((v) => v.data && v.data.scryfall_id && v.data.scryfall_id === scryfall_id && v.data.foil === foil)
      .reduce((total, entry) => {
        total += entry.quantity
        return total
      }, 0)

    let newList = dryRunList.map((v) => {
      if (v.data && v.data.scryfall_id === scryfall_id && v.data.foil === foil && v.key !== key) {
        return null
      }
      if (v.key === key) {
        return {
          ...v,
          quantity: newQuantity,
        }
      }
      return v
    }).filter((v) => v !== null)

    const newState = {
      ...state,
      dryRunList: newList,
    }

    return newState
  }
  case SET_ARCHIVED_LOADED: {
    const { loaded } = action
    const newState = {
      ...state,
      archivedLoaded: loaded
    }
    return newState
  }
  case UPDATE_IMPORT_STATUS: {
    const {
      loading, success, error
    } = action
    const newState = {
      ...state,
      importLoading: loading,
      importSuccess: success,
      importError: error,
    }
    return newState
  }
  case UPDATE_DRY_RUN_ENTRY_QUANTITY: {
    const {
      quantity, index
    } = action
    const newState = {
      ...state,
      dryRunList: state.dryRunList.map((v, i) => {
        if (i === index) {
          return {
            ...v,
            quantity,
          }
        }
        return v
      }),
    }
    return newState
  }
  case REMOVE_DRY_RUN_ENTRY: {
    const { key } = action
    const newState = {
      ...state,
      dryRunList: state.dryRunList.filter((v) => v.key !== key),
    }
    return newState
  }
  case CHANGE_DRY_RUN_VARIANT: {
    const {
      key, scryfallID
    } = action
    const newState = {
      ...state,
      dryRunList: state.dryRunList.map((v) => {
        if (v.key === key) {
          let option = v.options.find(v => v.scryfall_id === scryfallID)
          return {
            ...v,
            data: option
          }
        }
        return v
      }),
    }
    return newState
  }
  case SET_RETRY_DRY_RUN: {
    const {
      entry, key
    } = action
    const newState = {
      ...state,
      dryRunList: state.dryRunList.map((v) => {
        if (v.key === key) {
          return entry
        }
        return v
      }),
    }
    return newState
  }
  case SET_DRY_RUN: {
    const {
      list, loaded, error
    } = action
    const newState = {
      ...state,
      dryRunList: list,
      dryRunLoaded: loaded,
      dryRunError: error,
    }
    return newState
  }
  case SET_LISTS_WITH_CARD: { // TODO: On updating a list, update this too
    const {
      cardId, lists
    } = action
    const newState = {
      ...state,
      listsWithCard: {
        ...state.listsWithCard,
        [cardId]: lists
      }
    }
    return newState
  }
  case SET_LISTS: {
    const { lists } = action
    const newState = {
      ...state,
      data: {
        ...state.data,
        ...lists.reduce((acc, cur) => {
          acc[cur.id] = cur
          return acc
        }, {})
      },
      loaded: true,
      error: false,
    }
    return newState
  }
  case SET_LIST_DELETED: {
    const {
      listId, deleted
    } = action
    const newState = {
      ...state,
      data: {
        ...state.data,
        [listId]: {
          ...state.data[listId],
          'is_deleted': deleted
        }
      }
    }
    return newState
  }
  case SET_LIST: {
    const { list } = action
    const newState = {
      ...state,
      data: {
        ...state.data,
        [list.id]: list
      }
    }
    return newState
  }
  case SET_LIST_ENTRIES: {
    const {
      listId, entries, error
    } = action
    const newState = {
      ...state,
      listEntries: {
        ...state.listEntries,
        [listId]: {
          data: entries,
          loaded: true,
          error,
        }
      }
    }
    return newState
  }
  case UPDATE_LIST_ENTRY: {
    const {
      listId, entry, cardId
    } = action
    const entryId = entry.id
    const listsWithCardEntry = {
      entry_id: entryId,
      list_id: listId,
      quantity: entry.quantity
    }
    const newState = {
      ...state,
      listEntries: {
        ...state.listEntries,
        [listId]: state.listEntries[listId] ? {
          ...state.listEntries[listId],
          data: state.listEntries[listId].data.map(v => {
            if (v.id === entryId) {
              return {
                ...v,
                quantity: entry.quantity
              }
            }
            return v
          }),
        } : {
          data: [entry],
          loaded: false,
          error: false,
        }
      },
      listsWithCard: {
        ...state.listsWithCard,
        [cardId]: (state.listsWithCard[cardId] && state.listsWithCard[cardId].filter(v => v.entry_id === entryId).length > 0)
          ? state.listsWithCard[cardId]
            .map(v => {
              if (v.entry_id === entryId) {
                return {
                  ...v,
                  quantity: entry.quantity
                }
              }
              return v
            })
          : (state.listsWithCard[cardId]) ? [  ...state.listsWithCard[cardId], listsWithCardEntry ] : [ listsWithCardEntry ]
      }
    }
    return newState
  }
  case ADD_LIST_ENTRY: {
    const {
      listId, entry, cardId
    } = action
    const entryId = entry.id
    const listsWithCardEntry = {
      entry_id: entryId,
      list_id: listId,
      quantity: entry.quantity
    }
    const newEntry = {
      ...entry,
      card_id: cardId,
    }
    const newState = {
      ...state,
      listEntries: {
        ...state.listEntries,
        [listId]: state.listEntries[listId] ? {
          ...state.listEntries[listId],
          data: [ ...state.listEntries[listId].data, newEntry ]
        } : {
          data: [newEntry],
          loaded: false,
          error: false,
        }
      },
      listsWithCard: {
        ...state.listsWithCard,
        [cardId]: [ ...state.listsWithCard[cardId], listsWithCardEntry ]
      }
    }
    return newState
  }
  case DELETE_LIST_ENTRY: {
    const {
      listId, entryId, cardId
    } = action
    const newState = {
      ...state,
      listEntries: {
        ...state.listEntries,
        [listId]: state.listEntries[listId] ? {
          ...state.listEntries[listId],
          data: state.listEntries[listId].data.filter(v => v.id !== entryId),
        } : {
          data: [],
          loaded: false,
          error: false,
        }
      },
      listsWithCard: {
        ...state.listsWithCard,
        [cardId]: state.listsWithCard[cardId].filter(v => v.entry_id !== entryId)
      }
    }
    return newState
  }
  default:
    return state
  }
}
