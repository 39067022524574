import React from 'react'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import MerchantOption from './MerchantOption'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'

class MerchantSettings extends React.Component {
  static propTypes = {
    classes: object,
  }

  render () {
    const {
      merchants, loaded
    } = this.props

    return (
      <>
        <Grid item xs={12}>
          <Typography variant='h5' style={{
            marginTop: 32
          }}>
            Merchant Settings
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container style={{
            fontSize: '0.75rem',
            fontWeight: '500',
            paddingBottom: 8,
            color: 'rgba(0, 0, 0, 0.54)'
          }}>
            <Grid item xs={4}>
              Merchant
            </Grid>
            <Grid item xs={2}>
              Buylist
            </Grid>
            <Grid item xs={2}>
              Retail
            </Grid>
            <Grid item xs={4}>
              Comments
            </Grid>
          </Grid>
        </Grid>
        {loaded ? (
          merchants.map((v, i) => {
            return <MerchantOption key={i} merchant={v} />
          })
        ) : (
          <div>Loading your merchant settings...</div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  merchants: state.merchants.data,
  loaded: state.user.merchantOptions.loaded,
})

export default connect(mapStateToProps)(MerchantSettings)
