import React from 'react'
import moment from 'moment'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { getFeatureImageStyle } from '../../utils/tcgplayer'

const styles = {
  wrap: {
    position: 'relative',
    float: 'left',
    textAlign: 'left',
    width: '100%',
    backgroundColor: '#fff',
    borderWidth: 0,
    overflow: 'hidden',
    boxShadow: '0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%)',
    transition: 'all .2s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
      boxShadow: '0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%)',
    }
  },
  top: {
    height: 220,
    overflow: 'hidden',
  },
  featureImage: {
    top: 0,
    left: 0,
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    minHeight: 220,
  },
  title: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '24px',
    marginBottom: 12,
    lineHeight: 1.2,
    fontWeight: 500,
    padding: '8px 8px 8px 15px',
    backgroundColor: 'rgba(0,0,0,.6)',
  },
  byline: {
    display: 'block',
    position: 'absolute',
    top: 220,
    left: 0,
    width: '100%',
  },
  bylineText: {
    fontSize: '.8em',
    textAlign: 'right',
    padding: 5,
    backgroundColor: 'rgba(0,0,0,.6)',
    color: '#fff',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    paddingLeft: 0,
    marginLeft: -10,
    paddingRight: 10,
  }
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
})

class Deck extends React.Component {

  render () {
    const {
      classes, imageURL,  dateTime, title, deckData, scryfallID, url
    } = this.props

    const {
      colors,
      format,
      marketPrice,
      ownerName,
    } = deckData

    const featureImageStyle = getFeatureImageStyle(imageURL, scryfallID)

    return (
      <a className={classes.wrap} href={url} target='_blank' rel="noreferrer">
        <div className={classes.top}>
          <div className={classes.featureImage} style={featureImageStyle}>
            <Typography variant='h3' className={classes.title}>
              {title}
            </Typography>
            <div className={classes.byline}>
              <div className={classes.bylineText}>
                <div>
                  {format && <>{format} | </>}
                  {colors && <>{colors} | </>}
                  {marketPrice && <>Market Price: {formatter.format(marketPrice)} </>}
                </div>
                <div>
                  By {ownerName} |  {moment(new Date(dateTime)).format('ddd M/D/YY')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    )
  }
}

export default withStyles(styles)(Deck)
