export const getSelectedMerchantOffers = (offers, merchantId) => {
  return (offers && offers.length > 0) ? offers.map((card) => {
    let offerId = Object.keys(card.offers).find(v => card.offers[v].merchant_id === parseInt(merchantId))
    const {
      price, selected
    } = card.offers[offerId]
    return {
      ...card,
      count: selected,
      price: '$' + price.toFixed(2),
      total: '$' + (price * selected).toFixed(2)
    }
  }) : []
}

export const formatCSV = (offers, merchantId) => {
  return getSelectedMerchantOffers(offers, merchantId).map(card => {
    return {
      card_name: card.card_name,
      set_name: card.set_name,
      rarity: card.rarity,
      foil: card.foil,
      gatherer_id: card.gid,
      trader_tools_id: card.card_id,
      count: card.count,
      price: card.price,
      total: card.total,
    }
  })
}
