import React, { Component } from 'react'
import Select from 'react-select'
import { connect } from 'react-redux'

function labelize(offers) {
  try {
    return offers.map(v => {
      return {
        label: `${v.merchant_name}: ${v.volume} @ $${v.price.toFixed(2)}`,
        value: v.merchant_id
      }
    })

  } catch (err) {
    console.log(err)
    return []
  }
}

class CardOffersSelect extends Component {
  handleChange (newList) {
    if (!newList || newList.length === 0) {
      this.props.onChange([])
    } else {
      this.props.onChange(newList.map(v => v.value))
    }
  }

  render() {
    const {
      options, selectedOffers
    } = this.props

    const optionsLabels = labelize(options)
    const selectedOffersLabels = labelize(selectedOffers)

    return (
      <div style={{
        padding: 16
      }}>
        {optionsLabels.length > 0 &&
          <Select
            name="card"
            value={selectedOffersLabels}
            valueKey={'merchant_id'}
            disabled={this.props.locked}
            onChange={(newList) => this.handleChange(newList)}
            options={optionsLabels}
            isMulti={true}
          />
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  locked: state.locked
})

export default connect(
  mapStateToProps
)(CardOffersSelect)
