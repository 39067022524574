import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import {
  useDispatch, useSelector
} from 'react-redux'
import { setBreadcrumbs } from '../../../store/layout/actionCreator'
import { getAllPrintings } from '../../../store/cards/actionCreator'

import { Grid } from '@material-ui/core'
import Widget from '../../shared/Widget'
import CardsPageTable from './CardsPageTable'

export const CardsPage = () => {
  const { cardName } = useParams()
  const name = cardName.toLowerCase()

  const dispatch = useDispatch()
  const printings = useSelector((state) => {
    return state.cards.allPrintings[name] || []
  })

  useEffect(() => {
    dispatch(setBreadcrumbs([
      {
        text: 'Trader Tools',
        path: '/'
      },
      {
        text: 'Cards'
      },
      {
        text: name
      },
    ]))
    dispatch(getAllPrintings(name))
  }, [name])

  return (
    <div>
      <Helmet>
        <title>{name} - Trader Tools | Quiet Speculation</title>
      </Helmet>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Widget header={<span>Results for "{name}"</span>}>
            <CardsPageTable
              data={printings}
              cardName={name}
            />
          </Widget>
        </Grid>
      </Grid>
    </div>
  )
}

export default CardsPage