import React from 'react'
import PropTypes, { object } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  green, red
} from '@material-ui/core/colors'
import { Link } from 'react-router-dom'

import Keyrune from '../../shared/Keyrune'
import Loading from '../../Layout/Loading'
import CardImageModal from '../../shared/CardImageModal'
import EnhancedTablePagination from '../../shared/EnhancedTablePagination'
import { sliceTableData } from '../../../utils/tables'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import Hidden from '@material-ui/core/Hidden'

const styles = {
  positive: {
    color: green[500],
    fontWeight: 600,
  },
  negative: {
    color: red[500],
    fontWeight: 600,
  },
  cell: {
    padding: '0 0 0 5px',
  },
}

class EnhancedTableHead extends React.Component {

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const {
      order, orderBy
    } = this.props
    const columnData = [
      {
        id: 'card_name',
        align: 'left',
        disablePadding: false,
        label: 'Card Name',
        width: 250
      },
      {
        id: 'foil',
        align: 'left',
        disablePadding: false,
        label: 'Foil'
      },
      {
        id: 'change_usd',
        align: 'right',
        disablePadding: false,
        label: '$Δ'
      },
      {
        id: 'change_pct',
        align: 'right',
        disablePadding: false,
        label: '%Δ'
      },
      {
        id: 'price_current',
        align: 'right',
        disablePadding: false,
        label: '$'
      },
    ]

    return (
      <TableHead>
        <TableRow style={{
          height: 36
        }}>
          {columnData.map(column => { return (
            <TableCell
              key={column.id}
              align={column.align}
              padding={'none'}
              sortDirection={orderBy === column.id ? order : false}
              style={{
                width: column.width
              }}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={order}
                onClick={this.createSortHandler(column.id)}
              >
                {column.label}
              </TableSortLabel>
            </TableCell>
          )}, this)}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}


class ReportTable extends React.Component {
  static propTypes = {
    classes: object,
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      data: this.props.data,
      order: 'desc',
      orderBy: 'change_usd',
      page: 0,
      rowsPerPage: 10,
      toggle: (this.props.toggle !== null) ? this.props.toggle : false,
      searchInput: '',
      loaded: this.props.data.length > 0
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        data: this.props.data,
        loaded: true,
      })
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    if (['price_current', 'change_pct', 'change_usd'].includes(orderBy)) {
      const data = (order === 'desc')
        ? this.props.data.sort((a, b) => (parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1))
        : this.props.data.sort((a, b) => (parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1))

      this.setState({
        data,
        order,
        orderBy
      })
    } else {
      const data = (order === 'desc')
        ? this.props.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.props.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1))

      this.setState({
        data,
        order,
        orderBy
      })
    }
  }

  handleChangePage = (event, page) => {
    this.setState({
      page
    })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: event.target.value
    })
  }

  handleSearch = event => {
    let input = event.target.value.toLowerCase()
    const data = this.props.data.filter((card) => {
      try {
        if (card.card_name.toLowerCase().includes(input)) {
          return true
        } else if (card.set_name.toLowerCase().includes(input)) {
          return true
        } else if (card.change_pct.toLowerCase().includes(input)) {
          return true
        } else if (card.change_usd.toLowerCase().includes(input)) {
          return true
        } else if (card.price_current.toLowerCase().includes(input)) {
          return true
        } else if (card.rarity.toLowerCase().includes(input)) {
          return true
        }  else if (card.foil === 1 & 'foil'.includes(input)) {
          return true
        }
      } catch(e) {
      }
      return false
    })
      .map((card, index) => {
        return card
      }).sort((a, b) => (a.name < b.name ? -1 : 1))

    this.setState({
      data
    })
  }

  render () {
    const {
      classes, name
    } = this.props
    const {
      data, order, orderBy, rowsPerPage, page, loaded
    } = this.state

    const tableData = sliceTableData(data, page, rowsPerPage)

    if (tableData.length === 0) {
      return <div>There are no entries for report at this time. Check back soon.</div>
    }

    return (
      <React.Fragment>
        {loaded ? (
          <React.Fragment>
            <TextField
              id={name}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder='Search'
              margin='normal'
              style={{
                float: 'right',
                marginTop: 0
              }}
              onChange={this.handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Table style={{
              tableLayout: 'auto'
            }}>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {tableData.map((v, i) => {
                  return (
                    <TableRow style={{
                      height: 36
                    }} key={i}>
                      <TableCell padding={'none'} style={{
                        width: 250
                      }}>
                        {/* TODO: Convert these reports to include scryfall IDs and fix these images */}
                        <CardImageModal gathererId={v.gid} style={{
                          paddingRight: 6
                        }} />
                        <Link to={`/prices/sets/${encodeURIComponent(v.set_name)}/${encodeURIComponent(v.card_name)}${(v.foil === 1 ? '/foil' : '')}`}>
                          <Keyrune set={v.set_code} rarity={v.rarity} /> {v.card_name}
                        </Link>
                      </TableCell>
                      <TableCell padding={'none'}>
                        <Hidden mdUp>
                          <span>{(v.foil === 1) && 'F'}</span>
                        </Hidden>
                        <Hidden smDown>
                          <span>{(v.foil === 1) && 'Foil'}</span>
                        </Hidden>
                      </TableCell>
                      <TableCell padding={'none'} align='right'>
                        {(v.change_usd > 0) ? <span className={classes.positive}>{v.change_usd}</span> : <span className={classes.negative}>{v.change_usd}</span>}
                      </TableCell>
                      <TableCell padding={'none'} align='right'>
                        {(v.change_pct > 0) ? <span className={classes.positive}>{Math.round(v.change_pct)}</span> : <span className={classes.negative}>{Math.round(v.change_pct)}</span>}
                      </TableCell>
                      <TableCell padding={'none'} align='right'>
                        {v.price_current}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            <EnhancedTablePagination
              filteredCount={data.length}
              totalCount={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </React.Fragment>
        ) : (
          <Loading />
        )}
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(ReportTable)
