import { fetch } from '../../utils/requests'

export function fetchUser () {
  return new Promise(function (resolve, reject) {
    fetch({
      url: '/auth/login',
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
      baseURL: '/api',
      timeout: 120000,
    })
      .then(v => resolve(v))
      .catch(e => reject(e.response))
  })
}

export function fetchMerchantOptions (userId, accessToken) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: `/settings/${encodeURIComponent(userId)}/merchant-options`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      baseURL: '/api',
      timeout: 120000,
    })
      .then(v => resolve(v))
      .catch(e => reject(e.response))
  })
}

export function fetchPostMerchantOption (userId, payload, accessToken) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: `/settings/${encodeURIComponent(userId)}/merchant-options`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      data: payload,
      baseURL: '/api',
      timeout: 120000,
    })
      .then(v => resolve(v))
      .catch(e => reject(e.response))
  })
}

export function fetchUserBaseData (userId, accessToken) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: `/initialize/${encodeURIComponent(userId)}`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      baseURL: '/api',
      timeout: 120000,
    })
      .then(v => resolve(v))
      .catch(e => reject(e.response))
  })
}

export function fetchSetUserActiveList (userId, listId, accessToken) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: `/settings/${encodeURIComponent(userId)}/active-list`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      params: {
        listId
      },
      baseURL: '/api',
      timeout: 120000,
    })
      .then(v => resolve(v))
      .catch(e => reject(e.response))
  })
}

export function fetchSetUserSetting (userId, payload, accessToken) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: `/settings/${encodeURIComponent(userId)}/user-settings`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      data: payload,
      baseURL: '/api',
      timeout: 120000,
    })
      .then(v => resolve(v))
      .catch(e => reject(e.response))
  })
}
