import React from 'react'
import { connect } from 'react-redux'
import {
  Grid, Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { USER_SETTINGS_ARTICLES_OF_INTEREST_ID } from '../../constants'
import { getSyndicatedLink } from '../../utils/tcgplayer'

import { getTcgplayerArticles } from '../../store/cards/actionCreator'

import Article from './Article'
import PoweredBy from './PoweredBy'

const styles = {
  itemWrap: {
    padding: 6,
  },
}

class TCGplayerArticles extends React.Component {
  constructor(props) {
    super(props)
    const {
      cardName, getTcgplayerArticles, enabled
    } = props
    if (enabled && cardName) {
      getTcgplayerArticles({
        cardName
      })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      cardName, getTcgplayerArticles, enabled
    } = this.props
    if ((enabled !== prevProps.enabled && enabled) || cardName !== prevProps.cardName) {
      if (cardName) {
        getTcgplayerArticles({
          cardName
        })
      }
    }
  }

  render () {
    const {
      classes, tcgplayer_articles, cardName, scryfallID, enabled
    } = this.props

    if (!enabled) {
      return null
    }

    if (!tcgplayer_articles || Object.keys(tcgplayer_articles).length === 0) {
      return null
    }

    const {
      result, count
    } = tcgplayer_articles

    if (count === 0) {
      return null
    }

    return (
      <>
        <Grid item xs={6}>
          <Typography variant={'h4'} color='primary' style={{
            marginTop: '1.78rem'
          }}>
            Relevant Articles
          </Typography>
        </Grid>
        <Grid item xs={6} style={{
          marginTop: 'auto',
          paddingRight: 8,
          textAlign: 'right'
        }} >
          <PoweredBy />
        </Grid>
        <Grid item xs={12}>
          <Grid container>

            {result.map((article) => {
              const {
                authorImageURL,
                authorName,
                date,
                imageURL,
                teaser,
                title,
                uuid,
                canonicalURL
              } = article

              return (
                <Grid item xs={12} md={6} key={uuid} className={classes.itemWrap}>
                  <Article
                    authorName={authorName}
                    imageURL={imageURL}
                    authorImageURL={authorImageURL}
                    dateTime={date}
                    title={title}
                    teaser={teaser}
                    scryfallID={scryfallID}
                    url={getSyndicatedLink(canonicalURL)}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    tcgplayer_articles: state.cards.tcgplayer_articles,
    enabled: state.user.userSettings[USER_SETTINGS_ARTICLES_OF_INTEREST_ID] === 1 ? true : false,
  }
}

const mapDispatchToProps = {
  getTcgplayerArticles,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TCGplayerArticles))
