import React from 'react'
import { indigo } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const styles = {
  widgetHeader: {
    backgroundColor: indigo[400],
    padding:' 8px 15px',
    color:' #fff',
    fontSize: 24,
    textAlign: 'center',
    margin: 0,
    borderRadius:' 2px 2px 0 0',
  }
}

class Widget extends React.Component {
  render () {
    const {
      classes, children, header, style, contentHidden
    } = this.props

    return (
      <Card style={{
        overflow: 'visible',
        ...style
      }}>
        <div className={classes.widgetHeader}>
          {header ? (
            header
          ) : (
            <SkeletonTheme color='#7986cb'>
              <Skeleton width={250} />
            </SkeletonTheme>
          )}
        </div>
        {!contentHidden &&
          <CardContent>
            {children}
          </CardContent>
        }
      </Card>
    )
  }
}

export default withStyles(styles)(Widget)
