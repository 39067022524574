import React from 'react'
import TCGplayerImage from '../../assets/images/TCGplayer-Infinite-Primary-black.png'

const PoweredBy = () => {
  return (
    <>
      <img src={TCGplayerImage} style={{
        height: 18,
      }} />
    </>
  )
}

export default PoweredBy
