import React from 'react'
import {
  useSelector, useDispatch
} from 'react-redux'

import { setExpandedTableRows } from '../../../store/tradeRoutes/actionCreator'
import { tradeRoutesMerchantsListSelector } from '../../../store/reselect'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import CardOffersTableRow from './CardOffersTableRow'

const CardOffersTable = (props) => {
  const {
    table, list
  } = props

  const dispatch = useDispatch()
  const expanded = useSelector((state) => state.tradeRoutes.expanded[table] || [])

  const handleExpandRow = (identifier) => {
    dispatch(setExpandedTableRows(table, [...expanded, identifier]))
  }

  const handleCollapseRow = (identifier) => {
    dispatch(setExpandedTableRows(table, expanded.filter((val) => val !== identifier)))
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{
            fontWeight: 600
          }}>
            Card Name
          </TableCell>
          <TableCell style={{
            fontWeight: 600
          }}>
            Set Name
          </TableCell>
          <TableCell style={{
            fontWeight: 600
          }} width={40}>
            Foil
          </TableCell>
          <TableCell style={{
            fontWeight: 600
          }} width={80} align="center">
            Qty
          </TableCell>
          <TableCell style={{
            fontWeight: 600
          }} width={80} align="center">
            Qty Remain
          </TableCell>
          <TableCell style={{
            fontWeight: 600
          }} width={80} align="center">
            Merchants
          </TableCell>
          <TableCell style={{
            fontWeight: 600
          }} width={100}>
            Total Price
          </TableCell>
          <TableCell width={80} />
        </TableRow>
      </TableHead>
      <TableBody>
        {list.map((row, i) =>{
          const identity = row.card_id + row.variant + row.foil + '_' + i
          return (
            <CardOffersTableRow
              key={identity}
              index={i}
              row={row}
              expanded={expanded.includes(identity)}
              onExpand={() => handleExpandRow(identity)}
              onCollapse={() => handleCollapseRow(identity)}
            />
          )
        })}
      </TableBody>
    </Table>
  )

}

export default CardOffersTable
