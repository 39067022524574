import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  FormGroup,
  FormControlLabel,
  Switch
} from '@material-ui/core'

import {
  Warning as WarningIcon,
  BrokenImage as CorrectedIcon,
  Error as ErrorIcon
} from '@material-ui/icons'

import {
  orange, blue, red, grey
} from '@material-ui/core/colors'

import ListValidationTableHeader from './ListValidationTableHeader'
import ListConsolidation from './ListConsolidation'
import SuccessRow from './SuccessRow'
import CorrectedRow from './CorrectedRow'
import ErrorRow from './ErrorRow'
import {
  retryDryRun,
  removeDryRunEntry,
  updateDryRunEntryQuantity,
  changeDryRunEntryVariant,
  consolidateDryRunEntry,
  confirmDryRunCorrection
} from '../../../store/collection/actionCreator'

import { setsDataArraySelector } from '../../../store/reselect'
import EnhancedTablePagination from '../../shared/EnhancedTablePagination'
import { sliceTableData } from '../../../utils/tables'
import {
  getStatusCounts, sortTable, categorizeTableData, filterTableData
} from '../../../utils/listImport'

// TODO: Fix based on foil and condition

class ListValidation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      perPage: 25,
      page: 0,
      open: false,
      entryToConsolidate: {
        scryfall_id: null,
        foil: null,
      },
      successes: true,
      duplicates: true,
      corrections: true,
      errors: true,
      order: 'desc',
      orderBy: '',
    }
  }

  handleOnRetry = ({
    key, original, setName, cardName
  }) => {
    let payload = [
      {
        key,
        card_name:  cardName.toLowerCase(),
        set_name: setName.toLowerCase(),
        original_set_name: original.original_set_name,
        quantity: original.quantity,
        foil: original.foil,
      }
    ]
    this.props.retryDryRun(payload)
  }

  handleChangeVariant = (scryfallID, key) => {
    this.props.changeDryRunEntryVariant(scryfallID, key)
  }

  handleRemove = (index) => {
    this.props.removeDryRunEntry(index)
  }

  handleUpdateQuantity = (quantity, index) => {
    this.props.updateDryRunEntryQuantity(quantity, index)
  }

  // List consolidation
  handleOpenListConsolidation(entryToConsolidate) {
    this.setState({
      open: true,
      entryToConsolidate,
    })
  }

  handleToggleType = (selected) => {
    this.setState({
      [selected]: !this.state[selected],
    })
  }

  handleOnConfirm = (key) => {
    this.props.confirmDryRunCorrection(key)
  }

  handleRequestSort = (event, orderBy) => {
    let order = 'desc'
    if (this.state.orderBy === orderBy && this.state.order === 'desc') {
      order = 'asc'
    }
    this.setState({
      order,
      orderBy
    })
  }

  render () {
    const {
      list, sets
    } = this.props

    const {
      perPage, page, entryToConsolidate, open, successes, duplicates, corrections, errors, order, orderBy
    } = this.state

    const numRows = list.length

    const tableDataCategorized = categorizeTableData(list)
    const tableDataFiltered = filterTableData({
      tableDataCategorized,
      successes,
      duplicates,
      corrections,
      errors,
    })
    const tableData = sliceTableData(sortTable(tableDataFiltered, orderBy, order), page, perPage)

    let {
      successesCount, duplicatesCount, correctionsCount, errorsCount
    } = getStatusCounts(tableDataCategorized)

    return (
      <>
        <div>
          <FormGroup row style={{
            float: 'right'
          }}>
            <FormControlLabel
              control={<Switch
                checked={successesCount === 0 ? false : successes}
                disabled={successesCount === 0}
                onChange={() => this.handleToggleType('successes')}
                name="successes-enabled" />}
              label={`Success (${successesCount})`}
            />
            <FormControlLabel
              control={<Switch
                checked={duplicatesCount === 0 ? false : duplicates}
                disabled={duplicatesCount === 0}
                onChange={() => this.handleToggleType('duplicates')}
                name="duplicates-enabled"
              />}
              label={<>
                <WarningIcon
                  aria-label='duplicate entry'
                  alt='duplicate entry'
                  style={{
                    fontSize: '1rem',
                    verticalAlign: 'middle',
                    color: duplicatesCount === 0 ? grey[400] : orange[500],
                    paddingRight: 4,
                  }}
                />
                Duplicate ({duplicatesCount})
              </>}
            />
            <FormControlLabel
              control={<Switch
                checked={correctionsCount === 0 ? false : corrections}
                disabled={correctionsCount === 0}
                onChange={() => this.handleToggleType('corrections')}
                name="corrections-enabled"
              />}
              label={<>
                <CorrectedIcon
                  aria-label='corrected entry'
                  alt='corrected entry'
                  style={{
                    fontSize: '1rem',
                    verticalAlign: 'middle',
                    color: correctionsCount === 0 ? grey[400] : blue[400],
                    paddingRight: 4,
                  }}
                />
                Corrected ({correctionsCount})
              </>}
            />
            <FormControlLabel
              control={<Switch
                checked={errorsCount === 0 ? false : errors}
                disabled={errorsCount === 0}
                onChange={() => this.handleToggleType('errors')}
                name="errors-enabled" />}
              label={<>
                <ErrorIcon
                  aria-label='errored entry'
                  alt='errored entry'
                  style={{
                    fontSize: '1rem',
                    verticalAlign: 'middle',
                    color: errorsCount === 0 ? grey[400] : red[300],
                    paddingRight: 4,
                  }}
                />
                Error ({errorsCount})
              </>}
            />
          </FormGroup>
        </div>
        <Table style={{
          tableLayout: 'auto'
        }}>
          <ListValidationTableHeader
            order={order}
            orderBy={orderBy}
            onRequestSort={this.handleRequestSort}
            rowCount={tableData.length}
          />
          <TableBody>
            {tableDataFiltered.map((v) => {
              let { key } = v
              return (
                v.success == true ? (
                  v.corrected ? (
                    <CorrectedRow
                      card={{
                        ...v.data,
                        original_set_name: v.original_set_name,
                        quantity: v.quantity,
                        key,
                      }}
                      options={v.options}
                      key={key}
                      sets={sets}
                      onRemove={() => this.handleRemove(key)}
                      onRetry={
                        ({
                          setName, cardName
                        }) => this.handleOnRetry({
                          key,
                          original: v,
                          setName: setName,
                          cardName: cardName
                        })
                      }
                      onConfirm={() => this.handleOnConfirm(key)}
                      // onUpdateQuantity={(quantity) => this.handleRemove(quantity, key)} // TODO: Convert to key
                      duplicate={v.duplicate}
                      onSelectVariant={(scryfallID) => this.handleChangeVariant(scryfallID, key)}
                      onConsolidateEntry={(entryToConsolidate) => this.handleOpenListConsolidation(entryToConsolidate)}
                    />
                  ) : (
                    <SuccessRow
                      card={{
                        ...v.data,
                        quantity: v.quantity,
                        key,
                      }}
                      options={v.options}
                      key={key}
                      onRemove={() => this.handleRemove(key)}
                      // onUpdateQuantity={(quantity) => this.handleRemove(quantity, key)} // TODO: Convert to key
                      duplicate={v.duplicate}
                      onSelectVariant={(scryfallID) => this.handleChangeVariant(scryfallID, key)}
                      onConsolidateEntry={(entryToConsolidate) => this.handleOpenListConsolidation(entryToConsolidate)}
                    />
                  )
                ) : (
                  <ErrorRow
                    card={v}
                    key={key}
                    onRetry={
                      ({
                        setName, cardName
                      }) => this.handleOnRetry({
                        key,
                        original: v,
                        setName: setName,
                        cardName: cardName
                      })
                    }
                    sets={sets}
                    onRemove={() => this.handleRemove(key)}
                    // onUpdateQuantity={(quantity) => this.handleUpdateQuantity(quantity, i)} // TODO: Convert to key
                    duplicate={v.duplicate}
                  />
                )
              )
            }
            )}
          </TableBody>
        </Table>
        <EnhancedTablePagination
          filteredCount={tableDataFiltered.length}
          totalCount={numRows}
          rowsPerPage={perPage}
          page={page}
          onChangePage={(event, page) => {
            this.setState({
              page
            })
          }}
          onChangeRowsPerPage={(event) => {
            this.setState({
              perPage: event.target.value
            })
          }}
          totalText='total results'
        />
        <ListConsolidation
          open={open}
          tableData={tableData}
          entryToConsolidate={entryToConsolidate}
          onClose={() => {
            this.setState({
              open: false,
              entryToConsolidate: {
                scryfall_id: null,
                foil: null,
              }
            })
          }}
          onConfirm={() => {
            this.props.consolidateDryRunEntry(entryToConsolidate)
            this.setState({
              open: false,
              entryToConsolidate: {
                scryfall_id: null,
                foil: null,
              }
            })
          }}
          aria-labelledby='entries-to-consolidate'
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  list: state.collection.dryRunList,
  sets: state.sets.loaded ? setsDataArraySelector(state).map(v => v.set_name).sort((a, b) => a < b ? -1 : 1) : [],
})

const mapDispatchToProps = {
  retryDryRun,
  removeDryRunEntry,
  updateDryRunEntryQuantity,
  changeDryRunEntryVariant,
  consolidateDryRunEntry,
  confirmDryRunCorrection,
}

export default connect(mapStateToProps, mapDispatchToProps)(ListValidation)
