import lscache from 'lscache'

export function set(key, val = true, exp) {
  let keyStr = JSON.stringify(key)
  lscache.set(keyStr, val, exp)
}

export function get(key) {
  let keyStr = JSON.stringify(key)
  return lscache.get(keyStr)
}
