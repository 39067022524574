export const SET_LISTS = 'SET_LISTS'
export const SET_LIST = 'SET_LIST'
export const SET_LIST_ENTRIES = 'SET_LIST_ENTRIES'
export const SET_LISTS_WITH_CARD = 'SET_LISTS_WITH_CARD'
export const UPDATE_LIST_ENTRY = 'UPDATE_LIST_ENTRY'
export const ADD_LIST_ENTRY = 'ADD_LIST_ENTRY'
export const DELETE_LIST_ENTRY = 'DELETE_LIST_ENTRY'
export const SET_DRY_RUN = 'SET_DRY_RUN'
export const SET_RETRY_DRY_RUN = 'SET_RETRY_DRY_RUN'
export const REMOVE_DRY_RUN_ENTRY = 'REMOVE_DRY_RUN_ENTRY'
export const UPDATE_DRY_RUN_ENTRY_QUANTITY = 'UPDATE_DRY_RUN_ENTRY_QUANTITY'
export const UPDATE_IMPORT_STATUS = 'UPDATE_IMPORT_STATUS'
export const CHANGE_DRY_RUN_VARIANT = 'CHANGE_DRY_RUN_VARIANT'
export const SET_LIST_DELETED = 'SET_LIST_DELETED'
export const SET_ARCHIVED_LOADED = 'SET_ARCHIVED_LOADED'
export const CONSOLIDATE_DRY_RUN_ENTRY = 'CONSOLIDATE_DRY_RUN_ENTRY'
export const CONFIRM_DRY_RUN_CORRECTION = 'CONFIRM_DRY_RUN_CORRECTION'
