import React from 'react'
import {
  useSelector, useDispatch
} from 'react-redux'

import { setExpandedTableRows } from '../../../store/tradeRoutes/actionCreator'
import { tradeRoutesMerchantsListSelector } from '../../../store/reselect'
import { getSelectedMerchantOffers } from './helpers'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import MerchantOffersTableRow from './MerchantOffersTableRow'

const MerchantOffersTable = (props) => {
  const {
    onRemove, onSplit
  } = props

  const dispatch = useDispatch()
  const merchantOffers = useSelector((state) => tradeRoutesMerchantsListSelector(state))
  const expanded = useSelector((state) => state.tradeRoutes.expanded['offers_by_merchant'])

  const handleMoveListToggle = (cards, merchantId) => {
    let offers = getSelectedMerchantOffers(cards, merchantId)
    onSplit(offers, merchantId)
  }

  const handleRemoveFromListToggle = (cards, merchantId) => {
    let offers = getSelectedMerchantOffers(cards, merchantId)
    onRemove(offers, merchantId)
  }

  const handleExpandRow = (identifier) => {
    dispatch(setExpandedTableRows('offers_by_merchant', [...expanded, identifier]))
  }

  const handleCollapseRow = (identifier) => {
    dispatch(setExpandedTableRows('offers_by_merchant', expanded.filter((val) => val !== identifier)))
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            Merchant
          </TableCell>
          <TableCell align="center">
            Count
          </TableCell>
          <TableCell>
            Total
          </TableCell>
          <TableCell align="center">
            Download
          </TableCell>
          <TableCell align="center">
            Split Cards
          </TableCell>
          <TableCell align="center">
            Remove Cards
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {merchantOffers.map((row) =>{
          const identity = row.merchant_code
          return (
            <MerchantOffersTableRow
              key={identity}
              row={row}
              onMoveList={handleMoveListToggle}
              onRemoveFromList={handleRemoveFromListToggle}
              expanded={expanded.includes(identity)}
              onExpand={() => handleExpandRow(identity)}
              onCollapse={() => handleCollapseRow(identity)}
            />
          )
        })}
      </TableBody>
    </Table>
  )
}

export default MerchantOffersTable
