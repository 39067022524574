import React from 'react'
import PropTypes from 'prop-types'

import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel
} from '@material-ui/core'


class ListValidationTableHeader extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const {
      order, orderBy
    } = this.props
    const columnData = [
      {
        id: 'card_name',
        align: 'left',
        disablePadding: false,
        label: 'Card Name'
      },
      {
        id: 'set_name',
        align: 'left',
        disablePadding: false,
        label: 'Set Name'
      },
      {
        id: 'foil',
        align: 'left',
        disablePadding: false,
        label: 'Foil',
        style: {
          textAlign: 'center'
        }
      },
      {
        id: 'quantity',
        align: 'left',
        disablePadding: false,
        label: 'Quantity'
      },
      {
        id: 'success',
        align: 'left',
        disablePadding: false,
        label: 'Success',
        style: {
          textAlign: 'center'
        }
      },
      {
        id: 'remove',
        align: 'left',
        disablePadding: false,
        label: 'Remove',
        disabled: true,
        style: {
          textAlign: 'center'
        }
      },
    ]

    return (
      <TableHead>
        <TableRow style={{
          height: 36
        }}>
          {columnData.map(column => { return (
            <TableCell
              key={column.id}
              alight={column.align}
              padding={'none'}
              sortDirection={orderBy === column.id ? order : false}
              style={column.style}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={order}
                onClick={!column.disabled ? this.createSortHandler(column.id) : null}
                hideSortIcon={column.disabled}
              >
                {column.label}
              </TableSortLabel>
            </TableCell>
          )}, this)}
        </TableRow>
      </TableHead>
    )
  }
}

ListValidationTableHeader.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

export default ListValidationTableHeader