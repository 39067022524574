
import {
  SET_INITIAL_DATA,
  SET_CARD_OFFERS,
  SET_EXPANDED_TABLE_ROWS,
  RESET_DATA,
  SET_CARD_UPDATE
} from './actionType'

export const initialState = {
  data: {},
  expanded: {
    'offers_by_card': [],
    'offers_by_merchant': [],
    'unassigned_offers': [],
  },
  loaded: false
}

export default function tradeRoutesReducer (state = initialState, action = {}) {
  switch (action.type) {
  case RESET_DATA: {
    return initialState
  }
  case SET_INITIAL_DATA: {
    const { data } = action
    const newState = {
      ...state,
      data: data,
      loaded: true,
    }
    return newState
  }
  case SET_CARD_OFFERS: {
    const {
      card, cardId
    } = action
    const newState = {
      ...state,
      data: {
        ...state.data,
        [cardId]: card
      },
    }
    return newState
  }
  case SET_CARD_UPDATE: {
    const {
      card, cardId
    } = action
    const newState = {
      ...state,
      data: {
        ...state.data,
        [cardId]: card
      },
    }
    return newState
  }
  case SET_EXPANDED_TABLE_ROWS: {
    const {
      table, expanded
    } = action
    const newState = {
      ...state,
      expanded: {
        ...state.expanded,
        [table]: expanded
      },
    }
    return newState

  }
  default:
    return state
  }
}
