import React from 'react'

import { Button } from '@material-ui/core'
import { orange } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import WarningIcon from '@material-ui/icons/Warning'

import ListValidation from './ListValidation'
import Widget from '../../shared/Widget'

const useStyles = makeStyles(() => ({
  legend: {
    textAlign: 'right',
    fontSize: '.85rem',
    paddingLeft: 10,
  },
  backButtonWrapper: {
    textAlign: 'center',
  },
  nextButtonWrapper: {
    textAlign: 'center',
    paddingTop: 16,
  },
  warningIcon: {
    verticalAlign: 'middle',
    color: orange[500],
    fontSize: '.75rem',
  },
}))

const Step2 = (props) => {
  const {
    hidden, onGoBack, loaded, error, valid, onHandleImport
  } = props
  const classes = useStyles()

  return (
    <Widget header='Step 2: Validate' contentHidden={hidden}>
      <div className={classes.backButtonWrapper}>
        <Button variant='outlined' color='primary' onClick={() => onGoBack()}>
          Back
        </Button>
      </div>
      <div className={classes.legend}>
        <WarningIcon
          aria-label='duplicate entry'
          label='duplicate entry'
          className={classes.warningIcon}
        /> indicates a duplicate entry. Click to consolidate.
      </div>
      <div>
        {loaded ? (
          error ? (
            <div>Error...</div>
          ) : (
            <ListValidation />
          )
        ) : (
          <div>Loading...</div>
        )}
      </div>
      <div className={classes.nextButtonWrapper}>
        <Button disabled={!valid} variant='outlined' color='primary' onClick={() => onHandleImport()}>
          Proceed to Step 3: Import
        </Button>
      </div>
    </Widget>
  )
}

export default Step2