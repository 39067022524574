import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'

const styles = {}

class CommentEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      comments: props.comments || ''
    }
  }

  handleClickOpen() {
    this.setState({
      open: true
    })
  }

  handleClose() {
    this.setState({
      open: false
    })
  }

  handleCommentsChange(e) {
    this.setState({
      comments: e.target.value
    })
  }

  handleSave() {
    this.setState({
      open: false,
    }, () => {
      this.props.onCommentsSave(this.state.comments)
    })
  }

  render() {
    const {
      open, comments
    } = this.state
    const { merchantName } = this.props

    return (
      <div style={{
        display: 'inline-block',
        float: 'right'
      }}>
        <IconButton aria-label='Delete' size='small' onClick={() => this.handleClickOpen()}>
          <EditIcon />
        </IconButton>
        <Dialog
          open={open}
          onClose={() => this.handleClose()}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>{merchantName} Comments</DialogTitle>
          <DialogContent style={{
            minWidth: 400
          }}>
            <TextField
              autoFocus
              value={comments}
              onChange={(e) => this.handleCommentsChange(e)}
              margin='dense'
              id={`${merchantName} Comments`}
              label={`${merchantName} Comments`}
              type='text'
              fullWidth
              multiline
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose()} color='primary'>
              Cancel
            </Button>
            <Button onClick={() => this.handleSave()} color='primary'>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

CommentEditor.propTypes = {
  comments: PropTypes.string,
  merchantName: PropTypes.string,
  onCommentsSave: PropTypes.func
}

export default withStyles(styles)(CommentEditor)
