import React from 'react'
import Widget from '../../shared/Widget'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%'
  },
  menuItemName: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}))

const Step3 = (props) => {
  const {
    hidden,
    onGoBack,
    onHandleNewListToggleloaded,
    onHandleSelectList,
    importLoading,
    importSuccess,
    importError,
    list,
    lists,
    selectedListId,
    onUploadList,
  } = props
  const classes = useStyles()

  return (
    <Widget header='Step 3: Import' contentHidden={hidden}>
      <div style={{
        textAlign: 'center'
      }}>
        <Button variant='outlined' color='primary' onClick={() => onGoBack()}>
          Back
        </Button>
      </div>
      <Grid container>
        <Grid item xs={12} style={{
          marginBottom: 16
        }}>
          <Typography variant='h5' color='primary'>
            Select List to Import Into
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <FormControl className={classes.formControl}>
            <Select
              displayEmpty={true}
              value={selectedListId}
              input={<Input id='grouped-select' />}
              onChange={e => onHandleSelectList(e.target.value)}
              variant={'outlined'}
              fullWidth
              disabled={importLoading || importSuccess || importError}
            >
              <MenuItem value='no_list'>
                <em>Select a List</em>
              </MenuItem>
              <ListSubheader disableSticky={true}>All Lists</ListSubheader>
              {lists && lists.length > 0 && lists.map((v, index) => {
                const {
                  id, name
                } = v
                return <MenuItem value={id} key={index}>
                  <Grid container>
                    <Grid item xs={12} className={classes.menuItemName}>
                      {name}
                    </Grid>
                  </Grid>
                </MenuItem>
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2} style={{
          textAlign: 'center'
        }}>
          <Button variant='outlined' color='primary' onClick={() => onHandleNewListToggleloaded()}>
            Create New List
          </Button>
        </Grid>
      </Grid>

      <div style={{
        textAlign: 'center',
        paddingTop: 24
      }}>
        <Button
          disabled={selectedListId === 'no_list' || importLoading || importSuccess}
          size='large'
          variant='contained'
          color='primary'
          onClick={() => onUploadList()}
        >
          Final Step: Upload {list.length} Entries
        </Button>
        {importLoading && <div>Saving...</div>}
        {importError && <div>There was an error importing your list.</div>}
      </div>

      {importSuccess &&
        <div style={{
          textAlign: 'center',
          paddingTop: 24
        }}>
          <Button component={Link} to={`/collection/lists/${selectedListId}`} variant='contained' color='primary'>
            Success! Visit Your List
          </Button>
        </div>
      }
    </Widget>
  )
}

export default Step3