import React from 'react'
import { connect } from 'react-redux'
import {
  Grid, Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { USER_SETTINGS_RELATED_DECKS_ID } from '../../constants'

import { getSyndicatedLink } from '../../utils/tcgplayer'
import PoweredBy from './PoweredBy'

import { getTcgplayerDecks } from '../../store/cards/actionCreator'

import Deck from './Deck'

const styles = {
  itemWrap: {
    paddingTop: 8,
    paddingBottom: 16,
  },
}

class TCGplayerDecks extends React.Component {
  constructor(props) {
    super(props)
    const {
      cardName, getTcgplayerDecks, enabled
    } = props
    if (enabled && cardName) {
      getTcgplayerDecks({
        cardName
      })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      cardName, getTcgplayerDecks, enabled
    } = this.props
    if ((enabled !== prevProps.enabled && enabled) || cardName !== prevProps.cardName) {
      if (cardName) {
        getTcgplayerDecks({
          cardName
        })
      }
    }
  }

  render () {
    const {
      classes, tcgplayer_decks, scryfallID, enabled
    } = this.props

    if (!enabled) {
      return null
    }

    if (!tcgplayer_decks || Object.keys(tcgplayer_decks).length === 0) {
      return null
    }

    const {
      result, count
    } = tcgplayer_decks

    if (count === 0) {
      return null
    }

    return (
      <>
        <Grid item xs={12}>
          <Typography variant={'h4'} color='primary' style={{
            marginTop: '1.78rem'
          }}>
            Related Decks
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <PoweredBy />
        </Grid>
        <Grid item xs={12}>
          <Grid container>

            {result.map((deck) => {
              const {
                canonicalURL,
                date,
                deckData,
                imageURL,
                title,
              } = deck

              // TODO: Fix once TCGP fixes CORS issues with these URLs

              return (
                <Grid item xs={12} key={canonicalURL} className={classes.itemWrap}>
                  <Deck
                    imageURL={imageURL}
                    dateTime={date}
                    title={title}
                    deckData={deckData}
                    scryfallID={scryfallID}
                    url={getSyndicatedLink(canonicalURL)}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    tcgplayer_decks: state.cards.tcgplayer_decks,
    enabled: state.user.userSettings[USER_SETTINGS_RELATED_DECKS_ID] === 1 ? true : false,
  }
}

const mapDispatchToProps = {
  getTcgplayerDecks,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TCGplayerDecks))
