import React from 'react'
import classNames from 'classnames'

class Keyrune extends React.Component {
  render () {
    const {
      set, rarity, gradient = false, foil = false, className = ''
    } = this.props
    let setClass = (set) ? 'ss-' + set.toLowerCase() : ''
    let rarityClass = (rarity) ? 'ss-' + rarity.toLowerCase() : ''

    return (
      <i
        className={classNames(className, {
          'ss': true,
          [setClass]: set,
          [rarityClass]: rarity,
          'ss-grad': gradient || foil,
          'ss-foil': foil
        })}
        aria-hidden
        style={{
          verticalAlign: 'baseline'
        }}
      />
    )
  }
}

export default Keyrune
