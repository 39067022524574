import { SET_REPORTS } from './actionType'

export const initialState = {
  loaded: false,
  BuylistIncreases24hr: [],
  RetailIncreases24hr: [],
  BuylistIncreases7d: [],
  RetailIncreases7d: [],
}

export default function reportsReducer (state = initialState, action = {}) {
  switch (action.type) {
  case SET_REPORTS: {
    const { reports } = action
    const newState = {
      ...state,
      ...reports,
      loaded: true
    }
    return newState
  }
  default:
    return state
  }
}
