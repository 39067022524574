import {
  SET_USER,
  SET_MERCHANT_OPTIONS,
  SET_MERCHANT_OPTION,
  SET_ERROR,
  SET_ACTIVE_LIST,
  SET_FILTERS,
  SET_USER_SETTING,
  SET_USER_SETTINGS
} from './actionType'

export const initialState = {
  id: null,
  email: null,
  username: null,
  loggedIn: false,
  insider: false,
  jwt: null,
  loaded: false,
  error: false,
  registrationKey: null,
  merchantOptions: {
    data: [],
    loaded: false
  },
  userSettings: {
    0: 1,
    1: 1,
    2: 1,
  },
  activeList: null,
  filters: {
    enabled: false,
    rarities: {
      common: true,
      uncommon: true,
      rare: true,
      mythic: true,
    },
    minPercentSpread: null,
    maxPercentSpread: null,
    minUsdSpread: null,
    maxUsdSpread: null,
    minBuyPrice: null,
    maxBuyPrice: null,
    minSellPrice: null,
    maxSellPrice: null,
    foil: true,
    nonfoil: true,
  }
}

export default function userReducer (state = initialState, action = {}) {
  switch (action.type) {
  case SET_FILTERS: {
    const { filters } = action
    const newState = {
      ...state,
      filters
    }
    return newState
  }
  case SET_USER_SETTING: {
    const {
      settingID,
      value,
    } = action.payload
    const newState = {
      ...state,
      userSettings: {
        ...state.userSettings,
        [settingID]: value,
      }
    }
    return newState
  }
  case SET_USER_SETTINGS: {
    const { settings } = action.payload
    const newState = {
      ...state,
      userSettings: settings
    }
    return newState
  }
  case SET_USER: {
    const { user } = action.payload
    const {
      id, email, username, insider, loggedIn, jwt, registrationKey, error
    } = user
    const newState = {
      ...state,
      id,
      email,
      username,
      insider,
      loggedIn,
      jwt,
      registrationKey,
      error,
      loaded: true,
    }
    return newState
  }
  case SET_ACTIVE_LIST: {
    const { listId } = action.payload
    const newState = {
      ...state,
      activeList: listId,
    }
    return newState
  }
  case SET_ERROR: {
    const { error } = action.payload
    const newState = {
      ...state,
      error,
      loaded: true,
    }
    return newState
  }
  case SET_MERCHANT_OPTIONS: {
    const { merchantOptions } = action.payload
    const newState = {
      ...state,
      merchantOptions: {
        data: merchantOptions,
        loaded: true
      }
    }
    return newState
  }
  case SET_MERCHANT_OPTION: {
    const { merchantOption } = action.payload
    const {
      merchant_id, is_buylist_visible, is_retail_visible
    } = merchantOption
    const newState = {
      ...state,
      merchantOptions: {
        data: state.merchantOptions.data.map(v => {
          if (v.merchant_id === merchant_id) {
            return {
              ...v,
              is_buylist_visible,
              is_retail_visible,
            }
          } else {
            return v
          }
        }),
        loaded: true
      }
    }
    return newState
  }
  default:
    return state
  }
}
