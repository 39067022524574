import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'

const styles = {
  formControl: {
    width: '100%'
  },
  menuItemName: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  menuItemCount: {
    textAlign: 'right',
    paddingRight: 16
  },
  qtyText: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.875rem',
    fontWeight: 500,
  }
}

class ListSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeList: props.activeList
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeList !== prevProps.activeList) {
      this.setState({
        activeList: this.props.activeList,
      })
    }
  }

  handleOnSelect = (listId) => {
    this.setState({
      activeList: listId,
    }, () => {
      this.props.onSelect((listId === 'no_list' ) ? null : listId)
    })
  }

  render () {
    const {
      classes, lists
    } = this.props

    const { activeList } = this.state

    return (
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor='default-active-list'>When adding a new card to your collection, this list will be used as the default selected list.</InputLabel>
          <Select
            displayEmpty={true}
            value={activeList || 'no_list'}
            input={<Input id='default-active-list' />}
            onChange={e => this.handleOnSelect(e.target.value)}
            variant={'outlined'}
          >
            <MenuItem value='no_list'>
              <em>No Active List</em>
            </MenuItem>
            {lists.map((list, i) => {
              if (!list || list === undefined) {
                return null
              }
              const {
                id, name
              } = list
              return <MenuItem value={id} key={i + '-' + id}>
                {name}
              </MenuItem>
            })}
          </Select>
        </FormControl>
      </div>
    )
  }
}

export default withStyles(styles)(ListSelect)
