import React from 'react'
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { cardImageURL } from '../../utils/helpers'

class ImagesGrid extends React.Component {
  render () {
    const { data } = this.props
    return (
      <Grid container>
        {data.map((card, i) => {
          const {
            scryfall_id, set_name, foil, card_name
          } = card
          return <Grid item key={`${scryfall_id}-${i}`} xs={6} sm={4} md={3}>
            <Link to={`/prices/sets/${encodeURIComponent(set_name)}/${encodeURIComponent(card_name)}${(foil === 1) ? '/foil' : ''}?variant=${scryfall_id}`}>
              <img
                src={cardImageURL(scryfall_id)}
                alt={card_name}
                style={{
                  maxWidth: '100%',
                  borderRadius: '4.75% / 3.5%'
                }}
              />
            </Link>
          </Grid>
        })}
      </Grid>
    )
  }
}

export default ImagesGrid
