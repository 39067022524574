import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  wrap: {
    padding: 100,
    paddingTop: 100,
    textAlign: 'center',
  },
  toolbarTitle: {
    flex: 1,
  },
})

export class Loading extends React.Component {
  render () {
    const {
      classes,
      children,
    } = this.props

    return  (
      <div className={classes.wrap}>
        <Typography variant='h6' color='inherit' noWrap className={classes.toolbarTitle}>
          {children &&
            <div>{children}</div>
          }
        </Typography>
      </div>
    )
  }
}

export default withStyles(styles)(Loading)
