import React, { useState } from 'react'
import { convertToUSD } from '../../../utils/helpers'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import CardOffers from './CardOffers'
import Keyrune from '../../shared/Keyrune'
import CardImageModal from '../../shared/CardImageModal'
import { Link } from 'react-router-dom'
import DisplayToggleButton from '../../shared/DisplayToggleButton'

function getQuantity(row) {
  const {
    count, qty
  } = row
  return String(((count) ? count : qty))
}

function getTotal(row) {
  let {
    total, offers_total_price
  } = row
  if (total) {
    return total
  }
  return (offers_total_price) ? convertToUSD(Math.round(offers_total_price * 100) / 100) : ''
}

const CardOffersTableRow = (props) => {
  const {
    row,
    index,
    expanded,
    onExpand,
    onCollapse,
  } = props

  const {
    card_id,
    card_name,
    scryfall_id,
    set_name,
    foil,
    variant,
    set_code,
    rarity,
    qty_remain,
    offers_merchant_count,
  } = row

  const [show, setShow] = useState(expanded)

  const handleSetShow = (open) => {
    setShow(open)
    if (!open) {
      onCollapse()
    } else {
      onExpand()
    }
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <CardImageModal scryfallId={scryfall_id} style={{
            paddingRight: 6
          }} />
          <Link
            to={`/prices/sets/${encodeURIComponent(set_name)}/${encodeURIComponent(card_name)}${(foil === 1) ? '/foil' : ''}?variant=${scryfall_id}`}
            style={{
              maxHeight: 35
            }}
            target='__blank'
          >
            {card_name} {variant === 1 && '(Variant)'}
          </Link>
        </TableCell>
        <TableCell>
          <Link
            to={`/prices/sets/${encodeURIComponent(set_name)}${(foil) ? '/foil' : ''}`}
            style={{
              maxHeight: 35
            }}
            target='__blank'
          >
            <Keyrune set={set_code} rarity={rarity} /> {set_name}
          </Link>
        </TableCell>
        <TableCell>
          {((foil) ? 'Foil' : '')}
        </TableCell>
        <TableCell align="center">
          {getQuantity(row)}
        </TableCell>
        <TableCell align="center">
          {qty_remain}
        </TableCell>
        <TableCell align="center">
          {offers_merchant_count}
        </TableCell>
        <TableCell>
          {getTotal(row)}
        </TableCell>
        <TableCell align="right">
          <DisplayToggleButton
            display={show}
            onToggleDisplay={() => handleSetShow(!show)}
            text=""
            style={{
              float: 'right',
            }}
          />
        </TableCell>
      </TableRow>
      {show && (
        <TableRow>
          <TableCell colSpan={8}  style={{
            padding: 0
          }}>
            <div style={{
              padding: '50px 0',
            }}>
              <CardOffers
                cardId={card_id}
                cardIndex={index}
              />
            </div>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default CardOffersTableRow
