import React from 'react'
import Button from '@material-ui/core/Button'
import { useSelector } from 'react-redux'
import { USER_SETTINGS_TCGPLAYER_LINK_ID } from '../../constants'
import TCGplayerImage from '../../assets/images/TCGplayer-Primary-RGB_100px.png'

const TCGplayerLink = ({
  prices, tcgplayer_id
}) => {
  const enabled = useSelector(state => state.user.userSettings[USER_SETTINGS_TCGPLAYER_LINK_ID] === 1 ? true : false)

  if (!enabled) {
    return null
  }

  var marketPrice = null
  var midPrice = null

  prices.forEach((price) => {
    switch (price.merchant_id) {
    case 0:
      break
    case 8:
      midPrice = '$' + price.retail_price
      break
    case 21:
      marketPrice = '$' + price.retail_price
      break
    default:
      break
    }
  })

  let link = `https://shop.tcgplayer.com/product/productsearch?id=${tcgplayer_id}&utm_campaign=affiliate&utm_medium=trader-tools-card-page-tcgplayer-link&utm_source=ionstudios`
  if (link) {
    return (
      <Button variant='outlined' href={link} color='primary' size='large' component='a' target='_blank' style={{
        padding: 10
      }}>
        <img src={TCGplayerImage} style={{
          height: 38,
          paddingRight: (marketPrice || midPrice) ? 8 : 0,
        }}></img>
        <span style={{
          padding: (marketPrice || midPrice) ? 10 : 0
        }}>
          {marketPrice} {marketPrice && midPrice ? '|' : null} {midPrice}
        </span>
      </Button>
    )
  }
  return null
}

export default TCGplayerLink
