import {
  SET_LOADED,
  SET_BREADCRUMBS,
  SET_SNACKBAR,
  DISMISS_SNACKBAR,
  DISMISS_ALL_SNACKBARS,
  REMOVE_SNACKBAR,
  TOGGLE_SETTINGS,
  SET_SETS_TABLE_TYPES,
  SET_SETS_TABLE_SORT,
  SET_SET_TABLE_SORT,
  SET_LIST_TABLE_SORT,
  SET_COLLECTION_TABLE_SORT,
  SET_IMPORT_COLUMNS
} from './actionType'

import { set } from '../../utils/cache'

import { fetchInitializeGlobalData } from './requests'

import {
  setSets
  // getSets
} from '../sets/actionCreator'

import {
  setMerchants
  // getMerchants
} from '../merchants/actionCreator'

export function setLoaded () {
  return {
    type: SET_LOADED,
  }
}

export function toggleSettings () {
  return {
    type: TOGGLE_SETTINGS,
  }
}

export function setBreadcrumbs (breadcrumbs) {
  return {
    type: SET_BREADCRUMBS,
    payload: {
      breadcrumbs,
    },
  }
}

export function setImportColumns (importColumns) {
  set('importColumns', importColumns)
  return {
    type: SET_IMPORT_COLUMNS,
    payload: {
      importColumns,
    },
  }
}

// AllSetsTable actions

export function setSetsTableTypes (setsTableTypes) {
  set('setsTableTypes', setsTableTypes)
  return {
    type: SET_SETS_TABLE_TYPES,
    payload: {
      setsTableTypes,
    },
  }
}

export function setSetsTableSort (setsTableSort) {
  set('setsTableSort', setsTableSort)
  return {
    type: SET_SETS_TABLE_SORT,
    payload: {
      setsTableSort,
    },
  }
}

// SetTable actions

export function setSetTableSort (setTableSort) {
  set('setTableSort', setTableSort)
  return {
    type: SET_SET_TABLE_SORT,
    payload: {
      setTableSort,
    },
  }
}

// List table actions

export function setListTableSort (listTableSort) {
  set('listTableSort', listTableSort)
  return {
    type: SET_LIST_TABLE_SORT,
    payload: {
      listTableSort,
    },
  }
}

export function setCollectionTableSort (collectionTableSort) {
  set('collectionTableSort', collectionTableSort)
  return {
    type: SET_COLLECTION_TABLE_SORT,
    payload: {
      collectionTableSort,
    },
  }
}

export function initializeGlobalData () {
  return function (dispatch, getState) {
    let accessToken = getState().user.jwt
    fetchInitializeGlobalData(accessToken)
      .then(data => {
        dispatch(setSets(data.sets))
        dispatch(setMerchants(data.merchants))
        return dispatch(setLoaded())
      })
      .catch(() => {
        dispatch(handleSnackbar({
          message: 'Error loading. Please refresh.',
          options: {
            variant: 'error',
            persist: true
          },
        }))
      })
  }
}

export function handleSnackbar (notification, keyToDismiss = null) {
  let key = String(new Date().getTime() + Math.random())
  return function (dispatch) {
    if (keyToDismiss) {
      dispatch(dismissSnackbar(keyToDismiss))
    }
    dispatch(setSnackbar(notification, key))
    return key
  }
}

// Calling this action setSnackbar because enqueueSnackbar is already in use by notistack
export const setSnackbar = (notification, key = String(new Date().getTime() + Math.random())) => {
  return {
    type: SET_SNACKBAR,
    key,
    payload: {
      key,
      notification: {
        ...notification,
        options: {
          ...notification.options,
          key,
        }
      },
    }
  }
}

export const dismissSnackbar = id => ({
  type: DISMISS_SNACKBAR,
  payload: {
    id,
  }
})

export const dismissAllSnackbars = () => ({
  type: DISMISS_ALL_SNACKBARS,
})

export const removeSnackbar = id => ({
  type: REMOVE_SNACKBAR,
  payload: {
    id,
  }
})