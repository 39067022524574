import React, { useMemo } from 'react'
import {
  green, lightBlue
} from '@material-ui/core/colors'
import { Chart } from 'react-google-charts'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'

const HistoricPricesGraph = ({ data }) => {
  const formattedData = useMemo(() => (data && data.length > 0 ? data : []), [data])
  const today = useMemo(() => new Date(moment.now()), [])
  const begin = useMemo(() => new Date('2010-01-01'), [])

  if (!data) {
    return <Skeleton height={572} count={1} />
  }

  return (
    <div style={{
      height: 500,
      textAlign: 'left'
    }}>
      {formattedData.length > 0 ? (
        <Chart
          width="100%"
          height="400px"
          chartType="LineChart"
          loader={<div>Loading...</div>}
          data={formattedData}
          options={{
            selectionMode: 'multiple',
            tooltip: {
              showColorCode: true,
              format: 'mm'
            },
            focusTarget: 'category',
            chartArea: {
              height: '90%',
              width: '90%'
            },
            hAxis: {
              format: 'MM-dd-yyyy'
            },
            legend: {
              position: 'none'
            },
            colors: [green[500], green[800], lightBlue[400], lightBlue[800]],
          }}
          rootProps={{
            'data-testid': '3'
          }}
          chartPackages={['corechart', 'controls']}
          controls={[
            {
              controlType: 'ChartRangeFilter',
              options: {
                filterColumnIndex: 0,
                ui: {
                  chartType: 'LineChart',
                  chartOptions: {
                    chartArea: {
                      width: '90%',
                      height: '50%'
                    },
                    hAxis: {
                      baselineColor: 'none'
                    },
                    colors: [green[500], green[800], lightBlue[400], lightBlue[800]],
                    animation: {
                      startup: false,
                      duration: 0,
                    },
                  },
                },
              },
              controlPosition: 'bottom',
              controlWrapperParams: {
                state: {
                  range: {
                    start: begin,
                    end: today
                  }
                },
              },
            },
          ]}
        />
      ) : (
        <>There are no historical prices for this card right now.</>
      )}
    </div>
  )
}

export default HistoricPricesGraph
