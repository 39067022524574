import React from 'react'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from '@material-ui/core'

import {
  retryDryRun,
  removeDryRunEntry,
  updateDryRunEntryQuantity,
  changeDryRunEntryVariant,
  consolidateDryRunEntry,
  confirmDryRunCorrection
} from '../../../store/collection/actionCreator'

const styles = {
  rowCell: {
    padding: 8,
  }
}

// TODO: Fix based on foil and condition

const getConsolidatedSum = ({
  tableData, entryToConsolidate
}) => {
  var sum = 0
  tableData.filter(v => v.data && v.data.scryfall_id && v.data.scryfall_id === entryToConsolidate.scryfall_id && v.data.foil === entryToConsolidate.foil).forEach(v => {
    sum += v.quantity
  })
  return sum
}

class ListConsolidation extends React.Component {
  static propTypes = {
    classes: object,
  }

  render () {
    const {
      classes, tableData, entryToConsolidate, open, onClose, onConfirm
    } = this.props

    return (
      <Dialog
        open={open}
        onClose={() => onClose()}
        aria-labelledby='entries-to-consolidate'
      >
        <DialogTitle id='entries-to-consolidate'>Consolidate Entries</DialogTitle>
        <DialogContent style={{
          minWidth: 400
        }}>
          Consolidate the following entries to a single entry with quantity {getConsolidatedSum({
            tableData,
            entryToConsolidate
          })}:
          <div>
            <Table style={{
              tableLayout: 'auto'
            }}>
              <TableBody>
                {tableData.filter(v => v.data && v.data.scryfall_id && v.data.scryfall_id === entryToConsolidate.scryfall_id && v.data.foil === entryToConsolidate.foil).map((v, i) => {
                  const {
                    data, quantity, foil
                  } = v
                  const {
                    card_name, set_name
                  } = data
                  return <TableRow height={36} key={i}>
                    <TableCell className={classes.rowCell}>
                      {card_name}
                    </TableCell>
                    <TableCell className={classes.rowCell}>
                      {set_name}
                    </TableCell>
                    <TableCell className={classes.rowCell}>
                      {foil}
                    </TableCell>
                    <TableCell className={classes.rowCell}>
                      {quantity}
                    </TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} color='primary'>
            Cancel
          </Button>
          <Button onClick={() => onConfirm()} color='primary'>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {
  retryDryRun,
  removeDryRunEntry,
  updateDryRunEntryQuantity,
  changeDryRunEntryVariant,
  consolidateDryRunEntry,
  confirmDryRunCorrection,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ListConsolidation))
