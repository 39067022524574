import React, {
  useState, useEffect, useCallback, useRef
} from 'react'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import {
  getCardsAutocomplete, setPagination
} from '../../../store/cards/actionCreator'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import SearchTable from './SearchTable'
import debounce from 'lodash/debounce'

const styles = {
  secondaryNavTab: {
    color: 'white',
    height: 50,
    fontFamily: 'Average Sans, Open Sans, Varela Round ,sans-serif',
    textTransform: 'none',
  },
  container: {
    maxWidth: 1140,
    margin: 'auto',
    padding: 16,
  },
}

const CardSearchModal = ({
  classes, defaultList, pagination, getCardsAutocomplete, setPagination, onNavigation
}) => {
  const [seed, setSeed] = useState('')
  const [lastSeed, setLastSeed] = useState('')
  const abortControllerRef = useRef(null)

  const handleUpdateSeed = useCallback(
    debounce((currentSeed) => {
      if (currentSeed !== lastSeed) {

        if (abortControllerRef.current) {
          abortControllerRef.current.abort()
        }
        abortControllerRef.current = new AbortController()

        getCardsAutocomplete({
          ...pagination,
          seed: currentSeed,
          page: 1,
        }, abortControllerRef.current.signal)

        setLastSeed(currentSeed)
      }
    }, 500),
    [lastSeed, pagination, getCardsAutocomplete]
  )

  useEffect(() => {
    if (seed !== lastSeed) {
      handleUpdateSeed(seed)
    }
    return () => handleUpdateSeed.cancel()
  }, [seed, lastSeed, handleUpdateSeed])

  const handleChangeData = (pagination) => {
    const { numPages } = pagination
    if (numPages > 1) {
      getCardsAutocomplete({
        ...pagination,
      }, abortControllerRef.current.signal)
    } else {
      setPagination({
        ...pagination,
      })
    }
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} style={{
        paddingBottom: 16
      }}>
        <TextField
          className={classes.margin}
          label="Search Cards"
          variant="outlined"
          value={seed}
          onChange={(e) => setSeed(e.target.value)}
          fullWidth
          inputRef={(input) => input && input.focus()}
        />
      </Grid>
      <Grid item xs={12} style={{
        minHeight: 460,
        width: 1206
      }}>
        <SearchTable
          loaded={pagination.loaded}
          seed={seed}
          defaultList={defaultList}
          onNavigation={onNavigation}
          onChangeData={handleChangeData}
        />
      </Grid>
    </Grid>
  )
}

CardSearchModal.propTypes = {
  classes: object.isRequired,
  defaultList: object.isRequired,
  pagination: object.isRequired,
  getCardsAutocomplete: object.isRequired,
  setPagination: object.isRequired,
  onNavigation: object.isRequired,
}

const mapStateToProps = (state) => ({
  pagination: state.cards.pagination,
  autocompleteEntries: state.cards.autocompleteEntries,
})

const mapDispatchToProps = {
  getCardsAutocomplete,
  setPagination,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CardSearchModal))
