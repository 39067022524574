import React from 'react'
import { useSelector } from 'react-redux'

import { listsSelector } from '../../../store/reselect'

import {
  FormControl,
  Select,
  Input,
  MenuItem,
  FormHelperText
} from '@material-ui/core'

const ListSelect = ({
  activeList, onSelect, label
}) => {
  const lists = useSelector((state) => listsSelector(state))

  return (
    <FormControl style={{
      width: '100%',
    }}>
      <Select
        displayEmpty={true}
        value={activeList || 'no_list'}
        input={<Input id='default-active-list' />}
        onChange={e => onSelect(e.target.value)}
        variant={'outlined'}
      >
        <MenuItem value='no_list'>
          <em>No Selected List</em>
        </MenuItem>
        {lists.map((list, i) => {
          if (!list || list === undefined) {
            return null
          }
          const {
            id, name
          } = list
          return <MenuItem value={id} key={i + '-' + id}>
            {name}
          </MenuItem>
        })}
      </Select>
      <FormHelperText>{label}</FormHelperText>
    </FormControl>
  )

}

export default ListSelect