import React from 'react'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import AdditionalSetsIcon from '@material-ui/icons/Compare'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'

import { Checkbox } from '@material-ui/core'

import { HighlightOff as RemoveIcon } from '@material-ui/icons'

import {
  getAdditionalSetEntries, setAdditionalSet, removeAdditionalSet
} from '../../../store/sets/actionCreator'
import {
  setNamesSelector, setsDataArraySelector
} from '../../../store/reselect'
import SetSelectorList from './SetSelectorList'

const styles = {
  paper: {
    overflow: 'visible',
    maxHeight: 600,
  },
  section: {
    paddingBottom: 16
  },
  leftField: {
    paddingRight: 2
  },
  rightField: {
    paddingLeft: 2
  },
  formControl: {
    width: '100%'
  },
}

class SetSelectorModal extends React.Component {
  static propTypes = {
    classes: object,
  }

  constructor(props) {
    super(props)
    this.state = {
      open: false,
      selected: '',
    }
  }


  handleOpen = () => {
    this.setState({
      open: true,
    })
  }

  handleClose = () => {
    this.setState({
      open: false,
    })
  }

  handleChange = (setName, foil, e) => {
    this.props.getAdditionalSetEntries(setName, foil, e.target.checked)
  }

  handleOnSelect = (setName) => {
    this.props.setAdditionalSet(setName)
  }

  render () {
    const {
      classes, setNames, selectedSets, setsDataArray, setOptions
    } = this.props
    const {
      open, selected
    } = this.state

    return (
      <React.Fragment>
        <Button aria-label='Edit' size='small' onClick={this.handleOpen}>
          <AdditionalSetsIcon /> Additional Sets
        </Button>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
          maxWidth={'sm'}
          fullWidth
          PaperProps ={{
            classes: {
              root: classes.paper
            }
          }}
        >
          <DialogTitle>Additional Sets</DialogTitle>
          <DialogContent
            style={{
              overflowY: 'scroll',
            }}
          >
            <Grid container>
              <Grid item xs={12} className={classes.section}>
                <FormControl className={classes.formControl}>
                  <SetSelectorList
                    options={setOptions}
                    onChange={setName => this.handleOnSelect(setName)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{
                fontSize: '.85rem',
                paddingBottom: 8
              }}>
                <Grid container>
                  <Grid item xs={1}>
                  </Grid>
                  <Grid item xs={7}>
                    Set Name
                  </Grid>
                  <Grid item xs={2} style={{
                    textAlign: 'center'
                  }}>
                    Foil
                  </Grid>
                  <Grid item xs={2} style={{
                    textAlign: 'center'
                  }}>
                    Non-Foil
                  </Grid>
                </Grid>
              </Grid>
              {setsDataArray.map(set => {
                const { set_name } = set
                let setName = set_name.toLowerCase()
                let match = selectedSets.find(v => v.set_name === setName)
                if (match) {
                  const {
                    can_be_foil, can_be_nonfoil
                  } = set
                  let {
                    foil, nonfoil
                  } = match
                  let nonFoilChecked = nonfoil === 1 ? true : false
                  let foilChecked = foil === 1 ? true : false
                  return <Grid item xs={12} key={set_name}>
                    <Grid container>
                      <Grid item xs={1}>
                        <RemoveIcon
                          style={{
                            color: 'gray',
                            cursor: 'pointer',
                          }}
                          onClick={() => this.props.removeAdditionalSet(set_name)}
                        />
                      </Grid>
                      <Grid item xs={7}>
                        {set_name}
                      </Grid>
                      <Grid item xs={2} style={{
                        textAlign: 'center'
                      }}>
                        {can_be_foil === 1 &&
                          <Checkbox
                            checked={foilChecked}
                            onChange={(e) => this.handleChange(set_name, 1, e)}
                            inputProps={{
                              'aria-label': `${set_name}_foil`
                            }}
                          />
                        }
                      </Grid>
                      <Grid item xs={2} style={{
                        textAlign: 'center'
                      }}>
                        {can_be_nonfoil === 1 &&
                          <Checkbox
                            checked={nonFoilChecked}
                            onChange={(e) => this.handleChange(set_name, 0, e)}
                            inputProps={{
                              'aria-label': `${set_name}_nonfoil`
                            }}
                          />
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                } else {
                  return null
                }
              })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid item xs={12} style={{
                textAlign: 'right'
              }}>
                <Button
                  onClick={this.handleClose}
                  color='primary'
                >
                  Done
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let selectedSetNames = state.sets.selectedSets.map(v => v.set_name)
  let setsDataArray = setsDataArraySelector(state)
  return {
    setsDataArray,
    selectedSets: state.sets.selectedSets,
    selectedSetNames,
    setOptions: setsDataArray.filter(set => !selectedSetNames.includes(set.set_name.toLowerCase())).map(v => v.set_name)
  }
}

const mapDispatchToProps = {
  getAdditionalSetEntries,
  setAdditionalSet,
  removeAdditionalSet,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SetSelectorModal))
