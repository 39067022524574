import React from 'react'
import { object } from 'prop-types'
import UserSetting from './UserSetting'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import { USER_SETTINGS } from '../../../constants'

class UserPreferences extends React.Component {
  static propTypes = {
    classes: object,
  }


  render () {
    return (
      <>
        <Grid item xs={12}>
          <Typography variant='h5' style={{
            marginTop: 32
          }}>
            User Preferences
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container style={{
            fontSize: '0.75rem',
            fontWeight: '500',
            paddingBottom: 8,
            color: 'rgba(0, 0, 0, 0.54)'
          }}>
            <Grid item xs={4}>
              Name
            </Grid>
            <Grid item xs={6}>
              Description
            </Grid>
            <Grid item xs={2} style={{
              textAlign: 'right'
            }}>
              Enabled
            </Grid>
          </Grid>
        </Grid>
        {
          USER_SETTINGS.map((v, i) => {
            return <UserSetting key={v.name} setting={v} />
          })}
      </>
    )
  }
}

export default UserPreferences
