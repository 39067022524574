import React from 'react'
import { connect } from 'react-redux'
import PropTypes, { object } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  green, red, lightBlue
} from '@material-ui/core/colors'

import SetRow from './SetRow'
import { prettifyType } from '../../../utils/helpers'
import {
  setSetsTableTypes,
  setSetsTableSort
} from '../../../store/layout/actionCreator'

import EnhancedTablePagination from '../../shared/EnhancedTablePagination'
import { sliceTableData } from '../../../utils/tables'

import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TextField,
  InputAdornment,
  Typography,
  FormControlLabel,
  Switch,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableRow
} from '@material-ui/core'


import {
  Search as SearchIcon,
  FilterList as FilterListIcon
} from '@material-ui/icons'

const styles = {
  positive: {
    color: green[500],
    fontWeight: 600,
  },
  negative: {
    color: red[500],
    fontWeight: 600,
  },
  cell: {
    padding: '0 0 0 5px',
  },
  buylist: {
    color: green[500],
  },
  retail: {
    color: lightBlue[400],
  },
  switch: {
    height: 25,
  },
  label: {
    fontSize: '.8em',
  },
  buylistLabel: {
    color: green[500],
    fontWeight: 600,
  },
  retailLabel: {
    color: lightBlue[400],
    fontWeight: 600,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 3,
  },
}

const SHOW_PARENT_SORT_COLS = ['publish_date']

const nestParentData = (data, orderBy) => {
  if (!SHOW_PARENT_SORT_COLS.includes(orderBy)) {
    return data
  }

  let {
    results, parented
  } = data.reduce((acc, set) => {
    if (set.parent_set_code) {
      acc.parented = [...acc.parented, set]
      return acc
    }
    acc.results = [...acc.results, set]
    return acc
  }, {
    results: [],
    parented: [],
  })

  var secondPass = []
  parented.forEach((set) => {
    let {
      parent_set_code, set_code
    } = set
    let index = results.findIndex(v => v.set_code === parent_set_code)
    if (index > -1) {
      results.splice(index + 1, 0, set)
    } else {
      secondPass = [...secondPass, {
        ...set,
        second_pass: true
      }]
    }
  })
  secondPass.forEach((set) => {
    let { parent_set_code } = set
    let index = results.findIndex(v => v.set_code === parent_set_code)
    if (index) {
      results.splice(index, 0, set)
    } else {
      results = [ ...results, set ]
    }
  })
  return results
}

class EnhancedTableHead extends React.Component {

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const {
      order, orderBy
    } = this.props
    const columnData = [
      {
        id: 'set_name',
        align: 'left',
        disablePadding: false,
        label: 'Name'
      },
      {
        id: 'set_code',
        align: 'left',
        disablePadding: false,
        label: 'Code'
      },
      {
        id: 'set_type',
        align: 'left',
        disablePadding: false,
        label: 'Type'
      },
      {
        id: 'can_be_foil',
        align: 'left',
        disablePadding: false,
        label: 'Foil'
      },
      {
        id: 'publish_date',
        align: 'left',
        disablePadding: false,
        label: 'Publish Date'
      },
    ]

    return (
      <TableHead>
        <TableRow style={{
          height: 36
        }}>
          {columnData.map(column => { return (
            <TableCell
              key={column.id}
              align={column.align}
              padding={'none'}
              sortDirection={orderBy === column.id ? order : false}
              style={{
                width: column.width
              }}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={order}
                onClick={this.createSortHandler(column.id)}
              >
                {column.label}
              </TableSortLabel>
            </TableCell>
          )}, this)}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

class AllSetsTable extends React.Component {
  static propTypes = {
    classes: object,
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      page: 0,
      searchInput: '',
      filtersOpen: false,
      searchInput: '',
    }
  }

  handleRequestSort = (event, newOrderBy) => {
    const {
      order, orderBy, rowsPerPage, setSetsTableSort
    } = this.props

    let newOrder = 'desc'
    if (orderBy === newOrderBy && order === 'desc') {
      newOrder = 'asc'
    }

    setSetsTableSort({
      order: newOrder,
      orderBy: newOrderBy,
      rowsPerPage,
    })
  }

  handleChangePage = (event, page) => {
    this.setState({
      page
    })
  }

  handleChangeRowsPerPage = (event) => {
    const {
      order, orderBy, setSetsTableSort
    } = this.props
    this.setState({
      page: 0
    })
    setSetsTableSort({
      order,
      orderBy,
      rowsPerPage: event.target.value,
    })
  }

  handleSearch = event => {
    let input = event.target.value.toLowerCase()
    return this.setState({
      searchInput: input,
    })
  }

  handleFiltersOpen = (open) => {
    this.setState({
      filtersOpen: open
    })
  }

  handleTypeToggle = (type) => {
    let {
      allTypes, types, setSetsTableTypes
    } = this.props
    setSetsTableTypes({
      allTypes,
      types: {
        ...types,
        [type]: !types[type],
      }
    })
  }

  handleTypeToggleAll = () => {
    let {
      allTypes, types, setSetsTableTypes
    } = this.props
    setSetsTableTypes({
      allTypes: !allTypes,
      types: Object.keys(types).reduce((acc, type) => {
        acc[type] = !allTypes
        return acc
      }, {})
    })
  }

  render () {
    const {
      data,
      loaded,
      types,
      allTypes,
      order,
      orderBy,
      rowsPerPage,
    } = this.props

    const {
      filtersOpen, page, searchInput
    } = this.state

    const enabledTypes = Object.keys(types).filter(type => types[type] === true)
    const typeFilteredData = [...data].filter(set => enabledTypes.includes(set.set_type))
      .filter((offer) => {
        if (String(offer.volume).includes(searchInput)) {
          return true
        } else if (String(offer.set_name).toLowerCase().includes(searchInput)) {
          return true
        } else if (String(offer.set_type).toLowerCase().includes(searchInput)) {
          return true
        } else if (String(offer.set_code).toLowerCase().includes(searchInput)) {
          return true
        } else if (String(offer.publish_date).toLowerCase().includes(searchInput)) {
          return true
        }
        return false
      })

    const orderFilteredData = (order === 'desc')
      ? typeFilteredData.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
      : typeFilteredData.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1))

    const parentedData = nestParentData(orderFilteredData, orderBy)

    const dynamicPage = (parentedData.length <= (page * rowsPerPage + rowsPerPage)) ? 0 : page

    const tableData = sliceTableData(parentedData, dynamicPage, rowsPerPage)

    return (
      <React.Fragment>
        {loaded ? (
          <React.Fragment>
            <Grid container>
              <Grid item xs={6}>
                <IconButton
                  style={{
                    marginTop: 0
                  }}
                  aria-label='Filter list'
                  onClick={() => this.handleFiltersOpen(true)}
                >
                  <FilterListIcon />
                </IconButton>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id='full-width'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder='Search'
                  margin='normal'
                  style={{
                    float: 'right',
                    marginTop: 5
                  }}
                  onChange={this.handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Table style={{
              tableLayout: 'auto'
            }}>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {tableData.map((v, i) => {
                  return (
                    <SetRow set={v} key={v.set_name} showIndent={SHOW_PARENT_SORT_COLS.includes(orderBy)} />
                  )
                })}
              </TableBody>
            </Table>
            <EnhancedTablePagination
              filteredCount={typeFilteredData.length}
              totalCount={this.props.data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
            <Dialog
              open={filtersOpen}
              onClose={() => this.handleFiltersOpen(false)}
              aria-labelledby='form-dialog-title'
              maxWidth={'md'}
            >
              <DialogTitle id='form-dialog-title'>Filter</DialogTitle>
              <DialogContent>
                <Grid container style={{
                  textAlign: 'left',
                  minWidth: 350,
                  maxWidth: 350
                }}>
                  <Typography variant='subtitle2'>
                    Types
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={allTypes}
                        onChange={() => this.handleTypeToggleAll()}
                        value={allTypes}
                        color='primary'
                      />
                    }
                    label={'All Types'}
                  />
                </Grid>
                {Object.keys(types).map(type =>
                  <Grid item xs={12} key={type}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={types[type]}
                          onChange={() => this.handleTypeToggle(type)}
                          value={type}
                          color='primary'
                        />
                      }
                      label={prettifyType(type)}
                    />
                  </Grid>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.handleFiltersOpen(false)} color='primary'>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        ) : (
          <div>Loading</div>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    types, allTypes
  } = state.layout.setsTableTypes
  const {
    order, orderBy, rowsPerPage
  } = state.layout.setsTableSort
  return {
    types,
    allTypes,
    order,
    orderBy,
    rowsPerPage,
  }
}

const mapDispatchToProps = {
  setSetsTableTypes,
  setSetsTableSort,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AllSetsTable))