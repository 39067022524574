import { fetch } from '../../utils/requests'

export function fetchDryRun (payload, accessToken) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: '/collections/cards/dry_run',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      baseURL: '/api',
      timeout: 120000,
      data: payload,
    })
      .then(v => resolve(v))
      .catch(e => reject(e))
  })
}

export function fetchList (listId, accessToken) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: `/collections/lists/${encodeURIComponent(listId)}`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      baseURL: '/api',
      timeout: 120000,
    })
      .then(v => resolve(v))
      .catch(e => reject(e))
  })
}

export function fetchUpdateList (listId, payload, accessToken) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: `/collections/lists/${encodeURIComponent(listId)}`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      baseURL: '/api',
      timeout: 120000,
      data: payload,
    })
      .then(v => resolve(v))
      .catch(e => reject(e))
  })
}

export function fetchCreateList (payload, accessToken) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: '/collections/lists',
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      baseURL: '/api',
      timeout: 120000,
      data: payload,
    })
      .then(v => resolve(v))
      .catch(e => reject(e))
  })
}

export function fetchDeleteList (listId, accessToken) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: `/collections/lists/${encodeURIComponent(listId)}`,
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      baseURL: '/api',
      timeout: 120000
    })
      .then(v => resolve(v))
      .catch(e => reject(e))
  })
}

export function fetchLists (userId, archived, accessToken) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: '/collections/lists',
      method: 'get',
      params: {
        userId,
        archived,
      },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      baseURL: '/api',
      timeout: 120000,
    })
      .then(v => resolve(v))
      .catch(e => reject(e))
  })
}

export function fetchListEntries (listId, accessToken) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: `/collections/lists/${encodeURIComponent(listId)}/cards`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      baseURL: '/api',
      timeout: 120000,
    })
      .then(v => resolve(v))
      .catch(e => reject(e))
  })
}

export function fetchListsWithCard (scryfallId, foil, userId, accessToken) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: '/collections/cards/lists',
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      params: {
        scryfallId,
        foil,
        userId
      },
      baseURL: '/api',
      timeout: 120000,
    })
      .then(v => resolve(v))
      .catch(e => reject(e))
  })
}

// TODO: remove this someday once all our lists have scryfall_ids
export function fetchUpdateListEntry ({
  listId,
  scryfallId,
  currentListId,
  entryId,
  quantity,
  accessToken
}) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: `/collections/lists/${encodeURIComponent(listId)}/cards`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      params: {
        quantity,
        entryId,
        currentListId,
        scryfallId
      },
      baseURL: '/api',
      timeout: 120000,
    })
      .then(v => resolve(v))
      .catch(e => {
        return reject(e)
      })
  })
}

export function fetchCreateListEntry (listId, payload, accessToken) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: `/collections/lists/${encodeURIComponent(listId)}/cards`,
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      baseURL: '/api',
      timeout: 120000,
      data: payload,
    })
      .then(v => resolve(v))
      .catch(e => reject(e))
  })
}

export function fetchCreateListEntries (listId, payload, accessToken) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: `/collections/lists/${encodeURIComponent(listId)}/cards/multi`,
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      baseURL: '/api',
      timeout: 120000,
      data: payload,
    })
      .then(v => resolve(v))
      .catch(e => reject(e))
  })
}

export function fetchDeleteListEntry (listId, entryId, accessToken) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: `/collections/lists/${encodeURIComponent(listId)}/cards`,
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      baseURL: '/api',
      timeout: 120000,
      params: {
        entryId,
      },
    })
      .then(v => resolve(v))
      .catch(e => reject(e))
  })
}

// export function fetchUpdateListEntriesListID (listId, entryIds, accessToken) {
//   return new Promise(function (resolve, reject) {
//     fetch({
//       url: `/collections/lists/${listId}/cards/multi`,
//       method: 'post',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': accessToken,
//       },
//       baseURL: '/api',
//       timeout: 120000,
//       data: entryIds,
//     })
//       .then(v => resolve(v))
//       .catch(e => reject(e))
//   })
// }

export function fetchDeleteListEntries (listId, payload, accessToken) {
  return new Promise(function (resolve, reject) {
    fetch({
      url: `/collections/lists/${encodeURIComponent(listId)}/cards/multi`,
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      baseURL: '/api',
      timeout: 120000,
      data: payload,
    })
      .then(v => resolve(v))
      .catch(e => reject(e))
  })
}