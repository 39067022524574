import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'

import { setBreadcrumbs } from '../../../store/layout/actionCreator'
import { initializeTradeRoutes } from '../../../store/tradeRoutes/actionCreator'
import { tradeRoutesListSelector } from '../../../store/reselect'

import { tradeRoutesMerchantsListSelector } from '../../../store/reselect'
import { listsObjectSelector } from '../../../store/reselect'

import Widget from '../../shared/Widget'
import TotalOffersContainer from './TotalOffersContainer'
import CardOffersTable from './CardOffersTable'
import MerchantOffersTable from './MerchantOffersTable'
import ListOptions from '../../shared/ListOptions'
import MoveListEntries from '../../shared/MoveListEntries'
import RemoveListEntries from '../../shared/RemoveListEntries'

import {
  Tab, Tabs, TabList, TabPanel
} from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

import Grid from '@material-ui/core/Grid'
import Skeleton from 'react-loading-skeleton'

const styles = {
  topNav: {
    marginBottom: 20,
    textAlign: 'center',
  },
  button: {
    textTransform: 'none',
  },
}

class TradeRoutes extends React.Component {
  constructor(props) {
    super(props)
    props.initializeTradeRoutes(props.listId)

    let name = (props.list && props.list.name) ? props.list.name : ''
    props.setBreadcrumbs([
      {
        text: 'Trader Tools',
        path: '/'
      },
      {
        text: 'Sell Cards',
        path: '/sell'
      },
      {
        text: name
      },
    ])
    this.state={
      entryEditOpen: false,
      activeEntry: '',
      moveListEntries: [],
      moveListOpen: false,
      moveListMerchantId: null,
      removeListEntries: [],
      removeListOpen: false,
      removeListMerchantId: null,
    }
  }

  componentDidUpdate(prevProps) {
    const { list } = this.props
    if (prevProps.list.name !== list.name) {
      let name = (list && list.name) ? list.name : ''
      this.props.setBreadcrumbs([
        {
          text: 'Trader Tools',
          path: '/'
        },
        {
          text: 'Sell Cards',
          path: '/sell'
        },
        {
          text: name
        },
      ])
    }
  }

  handleSplit = (cards, merchantId) => {
    this.setState({
      moveListEntries: cards,
      moveListOpen: true,
      moveListMerchantId: merchantId,
    })
  }

  handleRemove = (cards, merchantId) => {
    this.setState({
      removeListEntries: cards,
      removeListOpen: true,
      removeListMerchantId: merchantId,
    })
  }

  handleOpenMoveListEntries = (open) => {
    this.setState({
      moveListOpen: open,
    })
  }

  handleOpenRemoveListEntries = (open) => {
    this.setState({
      removeListOpen: open,
    })
  }

  render () {
    const {
      moveListEntries,
      moveListOpen,
      moveListMerchantId,
      removeListEntries,
      removeListOpen,
      removeListMerchantId,
    } = this.state

    const {
      listId,
      loaded,
      list,
      allOffers,
      unassignedOffers,
    } = this.props

    const { name } = list

    return (
      <div>
        <Helmet>
          <title>Trade Routes - Trader Tools | Quiet Speculation</title>
        </Helmet>
        {loaded ? (
          <ListOptions
            list={list}
            mode='sell'
          />
        ) : (
          <div  style={{
            marginBottom: 20,
            maxHeight: 50,
          }}>
            <Skeleton height={50} count={1}/>
          </div>
        )}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Widget header={name}>
              {loaded ? (
                !allOffers || allOffers.length === 0 ? (
                  <>
                    It appears this list has no cards.
                  </>
                ) : (
                  <>
                    <TotalOffersContainer
                      listName={name}
                    />
                    <Tabs defaultIndex={1} style={{
                      textAlign: 'center',
                      paddingTop: 24
                    }}>
                      <TabList>
                        <Tab disabled={unassignedOffers.length === 0}>Unassigned Cards</Tab>
                        <Tab default>Offers by Merchant</Tab>
                        <Tab default>Offers by Card</Tab>
                      </TabList>
                      <TabPanel>
                        <CardOffersTable
                          table='unassigned_offers'
                          list={unassignedOffers}
                        />
                      </TabPanel>
                      <TabPanel>
                        <MerchantOffersTable
                          onSplit={(cards, merchantId) => this.handleSplit(cards, merchantId)}
                          onRemove={(cards, merchantId) => this.handleRemove(cards, merchantId)}
                        />
                      </TabPanel>
                      <TabPanel>
                        <CardOffersTable
                          table='offers_by_card'
                          list={allOffers}
                        />
                      </TabPanel>
                    </Tabs>
                    <MoveListEntries
                      entries={moveListEntries}
                      open={moveListOpen}
                      currentListId={listId}
                      merchantId={moveListMerchantId}
                      handleOpen={(open) => this.handleOpenMoveListEntries(open)}
                    />
                    <RemoveListEntries
                      entries={removeListEntries}
                      open={removeListOpen}
                      currentListId={listId}
                      merchantId={removeListMerchantId}
                      handleOpen={(open) => this.handleOpenRemoveListEntries(open)}
                    />
                  </>
                )
              ) : (
                <Skeleton height={43} count={25} />
              )
              }
            </Widget>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let listId = ownProps.match.params.listId
  let lists = listsObjectSelector(state)
  let list = (listId && lists && lists[listId]) ? lists[listId] : {}

  let allOffers = tradeRoutesListSelector(state)

  return {
    state,
    listId,
    list,
    loaded: state.tradeRoutes.loaded,
    allOffers,
    unassignedOffers: allOffers.filter(v => v.qty_remain !== 0),
    tradeRoutesMerchantsListSelector: tradeRoutesMerchantsListSelector(state),
    tradeRoutes: state.tradeRoutes.data
  }
}

const mapDispatchToProps = {
  setBreadcrumbs,
  initializeTradeRoutes,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TradeRoutes))
