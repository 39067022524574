import React from 'react'
import {
  string, func, bool
} from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import { setBreadcrumbs } from '../../../store/layout/actionCreator'
import Widget from '../../shared/Widget'
import Auth from '../../shared/Auth'

import ListsTable from './ListsTable'

import {
  Typography,
  Grid
} from '@material-ui/core'

class SellPage extends React.Component {
  constructor(props) {
    super(props)
    props.setBreadcrumbs([
      {
        text: 'Trader Tools',
        path: '/'
      },
      {
        text: 'Sell Cards'
      },
    ])
  }

  render () {
    const { loaded } = this.props

    return (
      <div>
        <Helmet>
          <title>Sell Cards - Trader Tools | Quiet Speculation</title>
        </Helmet>
        <Auth
          Base={
            <Grid container spacing={1}>
              <Grid item xs={12}>
                You are required to be logged in to Quiet Speculation to sell cards.
              </Grid>
            </Grid>
          }
          LoggedIn={
            <Widget header='Sell Cards with Trade Routes'>
              <Grid container spacing={1}>
                <Grid item xs={12} style={{
                  paddingBottom: 30
                }}>
                  <Typography
                    variant='h5'
                    color='primary'
                  >
                  Tell us what you want to sell, and we'll help you sell it.
                  </Typography>
                  <Typography>
                  Once you submit your list, we'll search the market for offers and find the buyers who are paying the most.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant='h5'
                    color='primary'
                  >
                  Select a List
                  </Typography>
                  <Typography>
                    Select a list to run the Trade Routes buylist optimizer.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {loaded ? (
                    <ListsTable />
                  ) : (
                    <div>Loading...</div>
                  )}
                </Grid>
              </Grid>
            </Widget>
          }
        />
      </div>
    )
  }
}

SellPage.propTypes = {
  setBreadcrumbs: func,
  loaded: bool
}

const mapStateToProps = state => ({
  loaded: state.collection.loaded
})

const mapDispatchToProps = {
  setBreadcrumbs,
}

export default connect(mapStateToProps, mapDispatchToProps)(SellPage)
