import React from 'react'
import { getCardHoarderLink } from '../../../utils/helpers'
import Button from '@material-ui/core/Button'
import StoreIcon from '@material-ui/icons/ExitToApp'

const CardHoarderLink = ({ cardName }) => {
  let link = getCardHoarderLink({
    cardName
  })
  if (link) {
    return (
      <Button variant='outlined' href={link} component='a' target='_blank'>
        <StoreIcon style={{
          lineHeight: '18px',
          verticalAlign: 'middle',
          fontSize: 20,
          paddingRight: 8
        }} /> Buy on Cardhoarder (MTGO)
      </Button>
    )
  }
  return null
}

export default CardHoarderLink
