import React from 'react'
import { connect } from 'react-redux'
import PropTypes, { object } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import Skeleton from 'react-loading-skeleton'
import { getBestCardPrices } from '../../../utils/helpers'
import { disabledMerchantsSelector } from '../../../store/reselect'
import ImagesGrid from '../../shared/ImagesGrid'
import EnhancedTablePagination from '../../shared/EnhancedTablePagination'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'

import Row from './Row'

const styles = {}

const columnData = [
  {
    id: 'card_name',
    align: 'left',
    disablePadding: false,
    label: 'Card',
    userLoggedIn: false
  },
  {
    id: 'set_name',
    align: 'left',
    disablePadding: false,
    label: 'Set',
    userLoggedIn: false
  },
  {
    id: 'foil',
    align: 'left',
    disablePadding: false,
    label: 'Foil',
    userLoggedIn: false
  },
  {
    id: 'collector_number',
    align: 'left',
    disablePadding: false,
    label: '#',
    userLoggedIn: false
  },
  {
    id: 'buy_volume',
    align: 'left',
    textAlign: 'center',
    disablePadding: false,
    label: 'Qty',
    mobileHidden: true,
    userLoggedIn: false
  },
  {
    id: 'buy_best',
    align: 'left',
    textAlign: 'center',
    disablePadding: false,
    label: 'Best Buylist',
    userLoggedIn: false
  },
  {
    id: 'spread_best_usd',
    align: 'left',
    textAlign: 'center',
    disablePadding: false,
    label: 'Spread $',
    mobileHidden: true,
    userLoggedIn: false
  },
  {
    id: 'spread_best_pct',
    align: 'left',
    textAlign: 'center',
    disablePadding: false,
    label: 'Spread %',
    mobileHidden: true,
    userLoggedIn: false
  },
  {
    id: 'retail_best',
    align: 'left',
    textAlign: 'center',
    disablePadding: false,
    label: 'Best Retail',
    mobileHidden: false,
    userLoggedIn: false
  },
  {
    id: 'card_id',
    align: 'center',
    textAlign: 'center',
    disablePadding: false,
    label: 'Edit',
    mobileHidden: false,
    userLoggedIn: true
  },
]

const filterData = (data, orderBy, order) => {
  if (['buy_volume', 'buy_best', 'spread_best_usd', 'spread_best_pct', 'retail_best'].includes(orderBy)) {
    const result = (order === 'desc')
      ? data.sort((a, b) => (parseFloat(b[orderBy] || 0) < parseFloat(a[orderBy] || 0) ? -1 : 1))
      : data.sort((a, b) => (parseFloat(a[orderBy] || 0) < parseFloat(b[orderBy] || 0) ? -1 : 1))

    return result
  } else {
    const result = (order === 'desc')
      ? data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
      : data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1))

    return result
  }
}

class EnhancedTableHead extends React.Component {

  createSortHandler = (event, property) => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const {
      order, orderBy, userLoggedIn, isMobile
    } = this.props

    return (
      <TableHead>
        <TableRow style={{
          height: 36
        }}>
          {columnData
            .filter(v => userLoggedIn === false ? v.userLoggedIn === userLoggedIn : true)
            .filter(v => (isMobile === true) ? v.mobileHidden !== true : true)
            .map(column => { return (
              <TableCell
                key={column.id}
                align={column.align}
                padding={'none'}
                sortDirection={orderBy === column.id ? order : false}
                style={{
                  width: column.width,
                  textAlign: (column.textAlign) ? column.textAlign: 'left',
                  paddingLeft: (column.paddingLeft) ? column.paddingLeft : 0
                }}
              >
                {column.id !== 'card_name' && column.id !== 'set_name' ? (
                  <>
                    {column.label}
                  </>
                ) : (
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={(e) => this.createSortHandler(e, column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                )}
              </TableCell>
            )}, this)}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

class SearchTable extends React.Component {
  static propTypes = {
    classes: object,
  }

  constructor(props) {
    super(props)
    this.state = {
      pageDt: null,
      rowsDt: null,
      sortDt: null,
      imagesMode: false,
    }
  }

  handleChangePage = (event, page) => {
    let date = Date.now()
    this.setState({
      pageDt: date
    }, () => {
      setTimeout(() => {
        if (this.state.pageDt === date) {
          const { pagination } = this.props
          const { numPages } = pagination
          let actualPage = page + 1
          if (actualPage <= numPages) {
            this.props.onChangeData({
              ...pagination,
              page: actualPage,
            })
          }
        }
      }, 200)
    })
  }

  handleChangeRowsPerPage = (event) => {
    let date = Date.now()
    this.setState({
      rowsDt: date
    }, () => {
      setTimeout(() => {
        if (this.state.rowsDt === date) {
          const { pagination } = this.props
          this.props.onChangeData({
            ...pagination,
            perPage: event.target.value,
          })
        }
      }, 300)
    })
  }

  handleRequestSort = (event, orderBy) => {
    let date = Date.now()
    this.setState({
      sortDt: date
    }, () => {
      setTimeout(() => {
        if (this.state.sortDt === date) {
          const { pagination } = this.props
          if (orderBy !== pagination.orderBy) {
            this.props.onChangeData({
              ...pagination,
              orderBy,
              order: 'ASC',
            })
          } else {
            const order = (pagination.order === 'ASC') ? 'DESC' : 'ASC'
            this.props.onChangeData({
              ...pagination,
              orderBy,
              order,
            })
          }
        }
      }, 300)
    })
  }

  handleNavigation = () => {
    this.props.onNavigation()
  }

  render () {
    const { imagesMode } = this.state

    const {
      data,
      userLoggedIn,
      width,
      defaultList,
      pagination,
    } = this.props

    const {
      loaded,
      orderBy,
      order,
      page,
      perPage,
      numRows,
      numPages,
      previous,
      next,
      seed,
    } = pagination

    const tableData = filterData(data, orderBy, order.toLowerCase())

    const isMobile = width === 'xs' || width === 'sm'

    const columnLength = columnData
      .filter(v => userLoggedIn === false ? v.userLoggedIn === userLoggedIn : true)
      .filter(v => (isMobile === true) ? v.mobileHidden !== true : true)
      .length

    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12} style={{
            textAlign: 'right',
            paddingBottom: 8
          }}>
            <Button variant='outlined' color='primary' size='small' onClick={() => this.setState({
              imagesMode: !imagesMode
            })} >
              {imagesMode ? 'Table' : 'Images'}
            </Button>
          </Grid>
        </Grid>
        <Table style={{
          tableLayout: 'auto'
        }}>
          <EnhancedTableHead
            order={order.toLowerCase()}
            orderBy={orderBy}
            onRequestSort={this.handleRequestSort}
            rowCount={numRows}
            userLoggedIn={userLoggedIn}
            isMobile={isMobile}
          />
          <TableBody style={{
            minHeight: 365
          }}>
            {imagesMode ? (
              <TableRow>
                <TableCell
                  padding={'none'}
                  colSpan={columnLength}
                >
                  {loaded ? (
                    <ImagesGrid data={tableData} />
                  ) : (
                    <Skeleton height={385} />
                  )}
                </TableCell>
              </TableRow>
            ) : (
              <>
                {loaded ? (
                  tableData.map((v, i) => {
                    return (
                      <Row
                        defaultList={defaultList}
                        card={v}
                        key={i + '_' + v.card_id}
                        onNavigation={() => this.handleNavigation()}
                        userLoggedIn={userLoggedIn}
                      />
                    )
                  })
                ) : (
                  <TableRow >
                    <TableCell colSpan={columnLength} style={{
                      padding: 0
                    }}>
                      <Skeleton height={32} count={perPage} />
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
        <EnhancedTablePagination
          filteredCount={numRows}
          totalCount={numRows}
          rowsPerPage={perPage}
          page={page - 1}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          totalText='results'
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let entries = state.cards.autocompleteEntries
  let cards = state.cards.entries
  let merchants = state.merchants.data
  let disabledMerchants = disabledMerchantsSelector(state)

  let data = entries.map(cardId => {
    return cards[cardId] || {
      error: true
    }
  })
    .filter(v => v != null)
    .map(card => {
      if (card.error) {
        return card
      }
      let {
        buy_merchant_name,
        buy_merchant_code,
        buy_volume,
        buy_best,
        spread_best_usd,
        spread_best_pct,
        retail_best,
        retail_merchant_name,
        retail_merchant_code
      } = getBestCardPrices(card.buylist_prices, card.retail_prices, merchants, disabledMerchants)
      return {
        ...card,
        buy_merchant_name,
        buy_merchant_code,
        buy_volume,
        buy_best,
        spread_best_usd,
        spread_best_pct,
        retail_best,
        retail_merchant_name,
        retail_merchant_code,
      }
    })

  return {
    data,
    entries,
    cards,
    pagination: state.cards.pagination,
    userLoggedIn: state.user.loggedIn,
  }
}

export default withWidth()(connect(mapStateToProps)(withStyles(styles)(SearchTable)))
