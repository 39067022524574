import { Component } from 'react'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { removeSnackbar } from '../../store/layout/actionCreator'

class Notifier extends Component {
  constructor(props) {
    super(props)
    this.state = {
      enqueued: []
    }
  }

  shouldComponentUpdate(nextProps) {
    return (this.props.notifications !== nextProps.notifications)
  }

  componentDidUpdate() {
    const { notifications } = this.props

    Object.keys(notifications).forEach((notification) => {
      const {
        message, options, dismissed
      } = notifications[notification]

      if (!this.state.enqueued.includes(notification)) {
        this.setState({
          enqueued: [ ...this.state.enqueued, notification ]
        }, () => {
          return this.props.enqueueSnackbar(message, options)
        })
      }

      if (options.persist && !dismissed) {
        return
      }

      // If dismissed is set to true, the notification was manually triggered to be removed
      if (dismissed) {
        this.props.closeSnackbar(notification)
        return this.props.removeSnackbar(notification)
      }
    })
  }

  render() {
    return null
  }
}

const mapStateToProps = store => ({
  notifications: store.layout.notifications || {},
})

const mapDispatchToProps = {
  removeSnackbar,
}

export default withSnackbar(connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifier))
