import React from 'react'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import {
  Link, withRouter
} from 'react-router-dom'
import { indigo } from '@material-ui/core/colors'
import LogoImg from '../../assets/images/logo.png'
import DiscordImg from '../../assets/images/discord.png'
import SettingsModal from './SettingsModal'
import CardSearchModal from './CardSearchModal'
import Auth from '../shared/Auth'
import { toggleSettings } from '../../store/layout/actionCreator'

import {
  ROUTER_PATHS,
  URLS
} from '../../constants'

import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import Paper from '@material-ui/core/Paper'
import Collapse from '@material-ui/core/Collapse'
import Toolbar from '@material-ui/core/Toolbar'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import StarsIcon from '@material-ui/icons/Stars'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Hidden from '@material-ui/core/Hidden'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'

// import BugIcon from '@material-ui/icons/BugReport'
import ChangeIcon from '@material-ui/icons/ChangeHistory'

import ProfileIcon from '@material-ui/icons/AccountCircle'
import SignOutIcon from '@material-ui/icons/ExitToApp'
import ChatIcon from '@material-ui/icons/Chat'
import NewsIcon from '@material-ui/icons/Announcement'
import SettingsIcon from '@material-ui/icons/Settings'
import ScannerIcon from '@material-ui/icons/Scanner'
import MerchantsIcon from '@material-ui/icons/Storefront'
import SellIcon from '@material-ui/icons/Store'
import CollectionIcon from '@material-ui/icons/LibraryBooks'
import SetsIcon from '@material-ui/icons/Label'
import PriceResearchIcon from '@material-ui/icons/ZoomIn'


const styles = theme => ({
  container: {
    maxWidth: 1140,
    margin: 'auto',
    paddingLeft: 15,
    paddingRight: 15,
  },
  appBar: {
    position: 'relative',
    backgroundColor: indigo[500],
    color: 'white',
    height: 140,
  },
  toolbarTitle: {
    flex: 1,
  },
  navTab: {
    color: 'white',
    height: 90,
    fontFamily: 'Average Sans, Open Sans, Varela Round ,sans-serif',
    textTransform: 'none',
    '&:hover': {
      color: 'white',
    },
    '&:visited': {
      color: 'white',
    },
  },
  secondaryNavTab: {
    color: 'white',
    height: 50,
    fontFamily: 'Average Sans, Open Sans, Varela Round ,sans-serif',
    textTransform: 'none',
    '&:hover': {
      color: 'white',
    },
    '&:visited': {
      color: 'white',
    },
  },
  secondaryNav: {
    height: 50,
    background: indigo[300],
    width: '100%',
  },
  mobileHeader: {
    background: indigo[500],
    color: 'white',
    display: 'table',
  },
  mobileHeaderColumn: {
    display: 'table-cell',
    verticalAlign: 'middle',
    padding: 8,
  },
})

export class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showProps: false,
      drawer: false,
    }
  }

  static propTypes = {
    classes: object,
    images: object,
  }

  static defaultProps = {
    classes: {},
  }


  componentDidUpdate(prevProps) {
    let { key } = this.props.location
    if  (prevProps.location.key !== key) {
      this.setState({
        showSearch: false,
      })
    }
  }

  toggleShowSearch = () => {
    this.setState({
      showSearch: !this.state.showSearch
    })
  }

  toggleDrawer = (open) => () => {
    this.setState({
      drawer: open,
    })
  }

  render () {
    const { classes } = this.props

    const {
      showSearch, drawer
    } = this.state

    return (
      <React.Fragment>
        <Hidden mdUp>
          <Grid container className={classes.mobileHeader}>
            <Grid item xs={1} className={classes.mobileHeaderColumn}>
              <IconButton
                color='inherit'
                aria-label='Open drawer'
                onClick={this.toggleDrawer(true)}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs={10}  className={classes.mobileHeaderColumn} style={{
              textAlign: 'center'
            }}>
              <Link to='/' style={{
                maxHeight: 35
              }}>
                {LogoImg && <img src={LogoImg} style={{
                  maxHeight: 35
                }} alt='logo'></img>}
              </Link>
            </Grid>
            <Grid item xs={1}  className={classes.mobileHeaderColumn} style={{
              textAlign: 'right'
            }}>
              <IconButton
                color='inherit'
                aria-label='Open drawer'
                onClick={() => this.toggleShowSearch()}
                className={classes.menuButton}
              >
                {showSearch ? <CloseIcon /> : <SearchIcon />}
              </IconButton>
            </Grid>
          </Grid>
          <SwipeableDrawer
            open={drawer}
            onClose={this.toggleDrawer(false)}
            onOpen={this.toggleDrawer(true)}
          >
            <div
              tabIndex={0}
              role='button'
              onClick={this.toggleDrawer(false)}
              onKeyDown={this.toggleDrawer(false)}
            >
              <div className={classes.list}>
                <List>
                  <Auth
                    Base={<>
                      <a href={`${URLS.LOGIN}?amember_redirect_url=${window.location.href}`} className={classes.a}>
                        <MenuItem onClick={this.handleEventsClose} className={classes.menuItem}>
                          <ListItemIcon><ProfileIcon /></ListItemIcon>
                          <ListItemText
                            primary={<Typography type='body2' className={classes.menuText}>Log In</Typography>}
                            disableTypography
                          />
                        </MenuItem>
                      </a>
                      <a href={URLS.SIGNUP} className={classes.a}>
                        <MenuItem onClick={this.handleEventsClose} className={classes.menuItem}>
                          <ListItemIcon><ProfileIcon /></ListItemIcon>
                          <ListItemText
                            primary={<Typography type='body2' className={classes.menuText}>Sign Up</Typography>}
                            disableTypography
                          />
                        </MenuItem>
                      </a>
                    </>}
                    LoggedIn={<>
                      <a href={URLS.PROFILE} className={classes.a}>
                        <MenuItem onClick={this.handleEventsClose} className={classes.menuItem}>
                          <ListItemIcon><ProfileIcon /></ListItemIcon>
                          <ListItemText
                            primary={<Typography type='body2' className={classes.menuText}>Profile</Typography>}
                            disableTypography
                          />
                        </MenuItem>
                      </a>
                      <a href={URLS.SIGNUP} className={classes.a}>
                        <MenuItem onClick={this.handleEventsClose} className={classes.menuItem}>
                          <ListItemIcon><ProfileIcon /></ListItemIcon>
                          <ListItemText
                            primary={<Typography type='body2' className={classes.menuText}>Insider</Typography>}
                            disableTypography
                          />
                        </MenuItem>
                      </a>
                      <a href={URLS.LOGOUT} className={classes.a}>
                        <MenuItem onClick={this.handleEventsClose} className={classes.menuItem}>
                          <ListItemIcon><SignOutIcon /></ListItemIcon>
                          <ListItemText
                            primary={<Typography type='body2' className={classes.menuText}>Sign Out</Typography>}
                            disableTypography
                          />
                        </MenuItem>
                      </a>
                    </>}
                    Insider={<>
                      <a href={URLS.PROFILE} className={classes.a}>
                        <MenuItem onClick={this.handleEventsClose} className={classes.menuItem}>
                          <ListItemIcon><ProfileIcon /></ListItemIcon>
                          <ListItemText
                            primary={<Typography type='body2' className={classes.menuText}>Profile</Typography>}
                            disableTypography
                          />
                        </MenuItem>
                      </a>
                      <a href={URLS.LOGOUT} className={classes.a}>
                        <MenuItem onClick={this.handleEventsClose} className={classes.menuItem}>
                          <ListItemIcon><SignOutIcon /></ListItemIcon>
                          <ListItemText
                            primary={<Typography type='body2' className={classes.menuText}>Sign Out</Typography>}
                            disableTypography
                          />
                        </MenuItem>
                      </a>
                    </>}
                  />
                  <Divider />
                  <a href={URLS.DISCORD} className={classes.a}>
                    <ListItem button>
                      <ListItemIcon><ChatIcon /></ListItemIcon>
                      <ListItemText
                        primary={<Typography type='body2' className={classes.menuText}>Discord</Typography>}
                        disableTypography
                      />
                    </ListItem>
                  </a>
                  <Divider />
                  <a href={URLS.HOME} className={classes.a}>
                    <ListItem button>
                      <ListItemIcon><NewsIcon /></ListItemIcon>
                      <ListItemText
                        primary={<Typography type='body2' className={classes.menuText}>News</Typography>}
                        disableTypography
                      />
                    </ListItem>
                  </a>
                  <Divider />
                  <ListItem>
                    <ListItemIcon><StarsIcon /></ListItemIcon>
                    <ListItemText
                      primary={<Typography type='body2' className={classes.menuText}>Card Prices</Typography>}
                      disableTypography
                    />
                  </ListItem>
                  <Link to={ROUTER_PATHS.PRICES} style={{
                    textDecoration: 'none'
                  }}>
                    <ListItem button>
                      <ListItemIcon><PriceResearchIcon /></ListItemIcon>
                      <ListItemText
                        primary={<Typography type='body2' className={classes.menuText}>Price Research</Typography>}
                        disableTypography
                      />
                    </ListItem>
                  </Link>
                  <Link to={ROUTER_PATHS.SETS} style={{
                    textDecoration: 'none'
                  }}>
                    <ListItem button>
                      <ListItemIcon><SetsIcon /></ListItemIcon>
                      <ListItemText
                        primary={<Typography type='body2' className={classes.menuText}>Sets</Typography>}
                        disableTypography
                      />
                    </ListItem>
                  </Link>
                  <Link to={ROUTER_PATHS.COLLECTION} style={{
                    textDecoration: 'none'
                  }}>
                    <ListItem button>
                      <ListItemIcon><CollectionIcon /></ListItemIcon>
                      <ListItemText
                        primary={<Typography type='body2' className={classes.menuText}>Collection</Typography>}
                        disableTypography
                      />
                    </ListItem>
                  </Link>
                  <Link to={ROUTER_PATHS.SELL} style={{
                    textDecoration: 'none'
                  }}>
                    <ListItem button>
                      <ListItemIcon><SellIcon /></ListItemIcon>
                      <ListItemText
                        primary={<Typography type='body2' className={classes.menuText}>Sell Cards</Typography>}
                        disableTypography
                      />
                    </ListItem>
                  </Link>
                  <Link to={ROUTER_PATHS.MERCHANTS} style={{
                    textDecoration: 'none'
                  }}>
                    <ListItem button>
                      <ListItemIcon><MerchantsIcon /></ListItemIcon>
                      <ListItemText
                        primary={<Typography type='body2' className={classes.menuText}>Merchants</Typography>}
                        disableTypography
                      />
                    </ListItem>
                  </Link>
                  <Link to={ROUTER_PATHS.IONSCANNER} style={{
                    textDecoration: 'none'
                  }}>
                    <ListItem button>
                      <ListItemIcon><ScannerIcon /></ListItemIcon>
                      <ListItemText
                        primary={<Typography type='body2' className={classes.menuText}>ION Scanner</Typography>}
                        disableTypography
                      />
                    </ListItem>
                  </Link>
                  <ListItem button onClick={() => this.props.toggleSettings()} style={{
                    textDecoration: 'none'
                  }}>
                    <ListItemIcon><SettingsIcon /></ListItemIcon>
                    <ListItemText
                      primary={<Typography type='body2' className={classes.menuText} style={{
                        color: 'rgb(121, 134, 203)'
                      }}>Settings</Typography>}
                      disableTypography
                    />
                  </ListItem>
                  {/* <Divider />
                  <Link to={URLS.DEVELOPMENT_TRELLO} style={{
                    textDecoration: 'none'
                  }} target='_blank' aria-label='Development Progress'>
                    <ListItem button>
                      <ListItemIcon><ChangeIcon /></ListItemIcon>
                      <ListItemText
                        primary={<Typography type='body2' className={classes.menuText}>Development</Typography>}
                        disableTypography
                      />
                    </ListItem>
                  </Link> */}
                  {/* <Link to={URLS.BUG_REPORT} style={{ textDecoration: 'none' }} target='_blank' aria-label='Report a bug'>
                      <ListItem button>
                        <ListItemIcon><BugIcon /></ListItemIcon>
                        <ListItemText
                          primary={<Typography type='body2' className={classes.menuText}>Bug Report</Typography>}
                          disableTypography
                        />
                      </ListItem>
                    </Link> */}
                </List>
              </div>
            </div>
          </SwipeableDrawer>
        </Hidden>
        <Hidden smDown>
          <React.Fragment>
            <AppBar position='static' color='default' className={classes.appBar}>
              <div style={{
                height: 90,
                width: '100%'
              }}>
                <Toolbar style={{
                  height: 90
                }} className={classes.container}>
                  <Link to='/' style={{
                    height: 90
                  }}>
                    {LogoImg && <img src={LogoImg} style={{
                      height: 50,
                      marginTop: 20
                    }} alt='logo'></img>}
                  </Link>
                  <Typography variant='h6' color='inherit' noWrap className={classes.toolbarTitle}>
                  </Typography>
                  <Button className={classes.navTab} href={URLS.DISCORD}>
                    {DiscordImg && <img src={DiscordImg} style={{
                      height: 30,
                      marginBottom: -2
                    }} alt='logo'></img>}
                  </Button>
                  <Button className={classes.navTab} href={URLS.HOME}>
                    News
                  </Button>
                  <Link to='/'>
                    <Button className={classes.navTab} style={{
                      background: indigo['300'],
                      borderRadius: 0
                    }}>
                      Card Prices
                    </Button>
                  </Link>
                  <Auth
                    Base={<>
                      <Button className={classes.navTab} href={`${URLS.LOGIN}?amember_redirect_url=${window.location.href}`} >
                        Log In
                      </Button>
                      <Button className={classes.navTab} href={URLS.SIGNUP} >
                        Sign Up
                      </Button>
                    </>}
                    LoggedIn={<>
                      <Button className={classes.navTab} href={URLS.PROFILE} >
                        Profile
                      </Button>
                      <Button className={classes.navTab} href={URLS.SIGNUP} >
                        Insider
                      </Button>
                      <Button className={classes.navTab} href={URLS.LOGOUT} >
                        Sign Out
                      </Button>
                    </>}
                    Insider={<>
                      <Button className={classes.navTab} href={URLS.PROFILE} >
                        Profile
                      </Button>
                      <Button className={classes.navTab} href={URLS.LOGOUT} >
                        Sign Out
                      </Button>
                    </>}
                  />
                </Toolbar>
              </div>
              <div className={classes.secondaryNav}>
                <Toolbar className={classes.container} style={{
                  minHeight: 50,
                  height: 50
                }}>
                  <Grid container>
                    {/* <Grid item xs={3}> */}
                    {/* <Button href={URLS.DEVELOPMENT_TRELLO} target='_blank' className={classes.secondaryNavTab} style={{
                        minWidth: 40
                      }} aria-label='Development Progress'>
                        <ChangeIcon />
                      </Button> */}
                    {/* <Button href={URLS.BUG_REPORT} target='_blank' className={classes.secondaryNavTab} style={{ minWidth: 40 }} aria-label='Report a bug'>
                       <BugIcon />
                      </Button> */}
                    {/* </Grid> */}
                    <Grid item xs={12} style={{
                      textAlign: 'right'
                    }}>
                      <Button component={Link} to={ROUTER_PATHS.PRICES} className={classes.secondaryNavTab}>
                        Price Research
                      </Button>
                      <Button component={Link} to={ROUTER_PATHS.SETS} className={classes.secondaryNavTab}>
                        Sets
                      </Button>
                      <Button component={Link} to={ROUTER_PATHS.COLLECTION} className={classes.secondaryNavTab}>
                        Collection
                      </Button>
                      <Button component={Link} to={ROUTER_PATHS.SELL} className={classes.secondaryNavTab}>
                        Sell Cards
                      </Button>
                      <Button component={Link} to={ROUTER_PATHS.MERCHANTS} className={classes.secondaryNavTab}>
                        Merchants
                      </Button>
                      <Button component={Link} to={ROUTER_PATHS.IONSCANNER} className={classes.secondaryNavTab}>
                        ION Scanner
                      </Button>
                      <Auth
                        LoggedIn={
                          <Button onClick={() => this.props.toggleSettings()} className={classes.secondaryNavTab}>Settings</Button>
                        }
                      />
                      <Button onClick={() => this.toggleShowSearch()} className={classes.secondaryNavTab} style={{
                        minWidth: 40
                      }}>
                        {showSearch ? <CloseIcon /> : <SearchIcon />}
                      </Button>
                    </Grid>
                  </Grid>
                </Toolbar>
              </div>
            </AppBar>
          </React.Fragment>
        </Hidden>
        <div>
          <SettingsModal />
          <Collapse in={showSearch}>
            <Paper elevation={1} style={{
              marginBottom: 10,
              borderRadius: 0
            }}>
              <CardSearchModal visible={showSearch} onNavigation={() => this.toggleShowSearch()} />
            </Paper>
          </Collapse>
        </div>
      </React.Fragment>
    )
  }
}

export default connect(null, {
  toggleSettings
})(withStyles(styles)(withRouter(Header)))
