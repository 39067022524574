import { cardImageURL } from './helpers'

export const getFeatureImageStyle = (imageURL, scryfallID) => {
  if (imageURL && !imageURL.includes('http://magic.tcgplayer.com/images')) {
    return {
      backgroundImage:`url(${imageURL})`
    }
  }
  if (scryfallID) {
    return {
      backgroundImage:`url(${cardImageURL(scryfallID)})`,
      backgroundPosition: 'center 18%',
      backgroundSize: 'initial',
    }
  }
  return {}
}

export const getSyndicatedLink = (canonicalURL) => {
  return `https://infinite.tcgplayer.com${canonicalURL}?utm_source=ionstudios&utm_campaign=Magic&utm_medium=syndicated_article`
}