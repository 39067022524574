import React from 'react'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'
import { setBreadcrumbs } from '../../../store/layout/actionCreator'
import Widget from '../../shared/Widget'

import MerchantHeader from './MerchantHeader'

import Grid from '@material-ui/core/Grid'

const styles = {
  gridItem: {
    textAlign: 'center',
    padding: 16,
    marginBottom: 16,
    marginTop: 'auto',
  },
  image: {
    maxWidth: '100%',
    maxHeight: 80,
  },
}

class MerchantsPage extends React.Component {
  static propTypes = {
    classes: object,
  }

  constructor(props) {
    super(props)
    props.setBreadcrumbs([
      {
        text: 'Trader Tools',
        path: '/'
      },
      {
        text: 'Merchants'
      },
    ])
  }

  render () {
    const {
      classes, merchants
    } = this.props

    return (
      <div>
        <Helmet>
          <title>Merchants - Trader Tools | Quiet Speculation</title>
        </Helmet>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Widget header='Browse Shops'>
              <Grid container>
                {merchants.length > 0 && merchants.filter(v => ![0, 16, 21, 22, 23].includes(v.merchant_id))
                // .filter(v => (v.buy_count !== 0 || v.retail_count !== 0))
                  .map((v, i) => {
                    return <Grid item xs={12} sm={6} key={i} className={classes.gridItem}>
                      <MerchantHeader merchant={v} />
                    </Grid>
                  }
                  )}
              </Grid>
            </Widget>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  merchants: state.merchants.data,
})

const mapDispatchToProps = {
  setBreadcrumbs,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MerchantsPage))
