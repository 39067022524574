import React from 'react'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  breadcrumb:{
    color: '#777',
    padding: 10,
  }
})

export class Breadcrumbs extends React.PureComponent {
  static propTypes = {
    classes: object,
  }

  render () {
    const {
      classes,
      breadcrumbs
    } = this.props

    // return null

    if (!breadcrumbs) return null

    return (
      <div className={classes.breadcrumb}>
        <Typography style={{
          fontSize: '.85em'
        }}>
          {breadcrumbs.map((v, i) => {
            return <span key={i}>
              {(v.path)
                ? <Link to={v.path}>{v.text}</Link>
                : <span style={{
                  color: '#777'
                }}>{v.text}</span>
              }
              { (i + 1 < breadcrumbs.length) &&
              <span style={{
                color: '#777'
              }}> / </span>
              }
            </span>
          })}
        </Typography>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  breadcrumbs: state.layout.breadcrumbs,
})

export default connect(mapStateToProps)(withStyles(styles)(Breadcrumbs))
