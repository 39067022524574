import React from 'react'
import { connect } from 'react-redux'
import PropTypes, { object } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  green, red, lightBlue
} from '@material-ui/core/colors'
import { Link } from 'react-router-dom'
import { disabledMerchantsSelector } from '../../../store/reselect'
import {
  findMerchant, convertToUSD, getMerchantCardLink
} from '../../../utils/helpers'

import Keyrune from '../../shared/Keyrune'
import EnhancedTablePagination from '../../shared/EnhancedTablePagination'
import { sliceTableData } from '../../../utils/tables'

import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import Button from '@material-ui/core/Button'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'

import SearchIcon from '@material-ui/icons/Search'
import StoreIcon from '@material-ui/icons/ExitToApp'
import FilterListIcon from '@material-ui/icons/FilterList'

import CardHoarderLink from './CardHoarderLink'

const styles = {
  positive: {
    color: green[500],
    fontWeight: 600,
  },
  negative: {
    color: red[500],
    fontWeight: 600,
  },
  cell: {
    padding: '0 0 0 5px',
  },
  buylist: {
    color: green[500],
  },
  retail: {
    color: lightBlue[400],
  },
  switch: {
    height: 25,
  },
  label: {
    fontSize: '.8em',
  },
  buylistLabel: {
    color: green[500],
    fontWeight: 600,
  },
  retailLabel: {
    color: lightBlue[400],
    fontWeight: 600,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 3,
  },
}


const MerchantsLink = ({
  cardName, setName, merchantID, mode, tcgplayer_id
}) => {
  let link = getMerchantCardLink({
    cardName,
    setName,
    merchantID,
    mode,
    medium: 'trader-tools-market-prices-table',
    tcgplayer_id
  })
  if (link) {
    return <a href={link} target='_blank' rel="noreferrer"><StoreIcon style={{
      lineHeight: '18px',
      verticalAlign: 'middle',
      fontSize: 20,
      paddingLeft: 8
    }} /></a>
  }
  return null
}

class EnhancedTableHead extends React.Component {

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const {
      order, orderBy
    } = this.props
    const columnData = [
      {
        id: 'merchant_name',
        align: 'left',
        disablePadding: false,
        label: 'Merchant',
        width: 250
      },
      {
        id: 'set_name',
        align: 'left',
        disablePadding: false,
        label: 'Set'
      },
      {
        id: 'foil',
        align: 'left',
        disablePadding: false,
        label: 'Foil'
      },
      {
        id: 'price',
        align: 'left',
        disablePadding: false,
        label: 'Price'
      },
      {
        id: 'volume',
        align: 'left',
        disablePadding: false,
        label: 'Qty'
      },
    ]

    return (
      <TableHead>
        <TableRow style={{
          height: 36
        }}>
          {columnData.map(column => { return (
            <TableCell
              key={column.id}
              alight={column.align}
              padding={'none'}
              sortDirection={orderBy === column.id ? order : false}
              style={{
                width: column.width
              }}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={order}
                onClick={this.createSortHandler(column.id)}
              >
                {column.label}
              </TableSortLabel>
            </TableCell>
          )}, this)}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// }

class MarketPricesTable extends React.Component {
  static propTypes = {
    classes: object,
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      data: props.data.sort((a, b) => (parseFloat(b.buylist_price ? b.buylist_price : b.retail_price) < parseFloat(a.buylist_price ? a.buylist_price : a.retail_price) ? -1 : 1)),
      order: 'desc',
      orderBy: 'price',
      page: 0,
      rowsPerPage: 10,
      toggle: (props.toggle !== null) ? props.toggle : false,
      searchInput: '',
      filtersOpen: false,
      foil: (props.foil === 1),
      nonfoil: (props.foil === 0),
      buylist: true,
      retail: true,
      selectedSets: [props.setName],
      sets: props.sets.map(item => item.set_name).filter((value, index, self) => self.indexOf(value) === index),
    }
  }

  initialize() {
    this.setState({
      data: this.props.data.sort((a, b) => (parseFloat(b.buylist_price ? b.buylist_price : b.retail_price) < parseFloat(a.buylist_price ? a.buylist_price : a.retail_price) ? -1 : 1)),
      foil: (this.props.foil === 1),
      nonfoil: (this.props.foil === 0),
      selectedSets: [this.props.setName],
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.foil !== prevProps.foil || this.props.cardName !== prevProps.cardName || this.props.setName !== prevProps.setName) {
      this.initialize()
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    if (['qty'].includes(orderBy)) {
      const data = (order === 'desc')
        ? this.props.data.sort((a, b) => (parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1))
        : this.props.data.sort((a, b) => (parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1))

      this.setState({
        data,
        order,
        orderBy
      })
    } else if (['price'].includes(orderBy)) {
      const data = (order === 'desc')
        ? this.props.data.sort((a, b) => (parseFloat(b.buylist_price ? b.buylist_price : b.retail_price) < parseFloat(a.buylist_price ? a.buylist_price : a.retail_price) ? -1 : 1))
        : this.props.data.sort((a, b) => (parseFloat(a.buylist_price ? a.buylist_price : a.retail_price) < parseFloat(b.buylist_price ? b.buylist_price : b.retail_price) ? -1 : 1))

      this.setState({
        data,
        order,
        orderBy
      })
    } else {
      const data = (order === 'desc')
        ? this.props.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.props.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1))

      this.setState({
        data,
        order,
        orderBy
      })
    }
  }

  handleChangePage = (event, page) => {
    this.setState({
      page
    })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: event.target.value
    })
  }

  handleClickOpen = () => {
    this.setState({
      filtersOpen: true
    })
  }

  handleClose = () => {
    this.setState({
      filtersOpen: false
    })
  }

  handleChangeToggle = name => event => {
    this.setState({
      [name]: event.target.checked
    })
  }

  handleSearch = event => {
    let input = event.target.value.toLowerCase()
    if (input === '') {
      return this.setState({
        data: this.props.data,
      })
    }
    const data = this.props.data.filter((offer) => {
      if (String(offer.volume).includes(input)) {
        return true
      } else if (String(offer.set_name).toLowerCase().includes(input)) {
        return true
      } else if (String(offer.buylist_price).includes(input)) {
        return true
      } else if (String(offer.merchant_name).toLowerCase().includes(input)) {
        return true
      } else if (String(offer.rarity).toLowerCase().includes(input)) {
        return true
      } else if (String(offer.retail_price).includes(input)) {
        return true
      }  else if (offer.foil === 1 & 'foil'.includes(input)) {
        return true
      }
      return false
    })
      .map((offer, index) => {
        return offer
      }).sort((a, b) => (a.name < b.name ? -1 : 1))

    this.setState({
      data
    })
  }

  handleSelectedSetsetChange = event => {
    // TODO: Handle foil fetching..?
    // TODO: Fetch the set info for the selected card...
    let oldSets = this.state.selectedSets
    let newSets = event.target.value
    let newSet = newSets.filter(v => oldSets.indexOf(v) < 0)
    if (newSet.length === 1) {
      // Fetch the new set info...
    }
    this.setState({
      selectedSets: event.target.value
    })
  }

  // handleSelectAllSets = () => {
  //   this.setState({ selectedSets: this.state.sets })
  // }

  render () {
    const {
      classes, disabledMerchants, merchants, userLoggedIn, cardName, tcgplayer_id
    } = this.props
    const {
      data, order, orderBy, rowsPerPage, page, foil, nonfoil, buylist, retail, selectedSets
    } = this.state

    const filteredData = data.filter((offer) => {
      if (foil === false && offer.foil === 1) {
        return false
      }
      if (!selectedSets.includes(offer.set_name)) {
        return false
      }
      if (nonfoil === false && offer.foil === 0) {
        return false
      }
      if (buylist === false && offer.buylist_price) {
        return false
      }
      if (retail === false && offer.retail_price) {
        return false
      }
      if (offer.buylist_price && disabledMerchants.buylist.includes(offer.merchant_id)) {
        return false
      }
      if (offer.retail_price && disabledMerchants.retail.includes(offer.merchant_id)) {
        return false
      }
      return true
    })

    const tableData = sliceTableData(filteredData, page, rowsPerPage)

    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={6}>
          </Grid>
          <Grid item xs={2} style={{
            textAlign: 'right'
          }}>
            <IconButton
              style={{
                float: 'right',
                marginTop: 0
              }}
              aria-label='Filter list'
              onClick={this.handleClickOpen}
            >
              <FilterListIcon />
            </IconButton>
            <Dialog
              open={this.state.filtersOpen}
              onClose={this.handleClose}
              aria-labelledby='form-dialog-title'
              maxWidth={'md'}
            >
              <DialogTitle id='form-dialog-title'>Filter Market Prices</DialogTitle>
              <DialogContent>
                <Grid container style={{
                  textAlign: 'left',
                  maxWidth: 350
                }}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={buylist}
                          onChange={this.handleChangeToggle('buylist')}
                          value='buylist'
                          color='primary'
                        />
                      }
                      classes={{
                        label: classes.buylistLabel,
                      }}
                      label='Buylist'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={retail}
                          onChange={this.handleChangeToggle('retail')}
                          value='retail'
                          color='primary'
                        />
                      }
                      classes={{
                        label: classes.retailLabel,
                      }}
                      label='Retail'
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color='primary'>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id='full-width'
              InputLabelProps={{
                shrink: true,
              }}
              placeholder='Search'
              margin='normal'
              style={{
                float: 'right',
                marginTop: 5
              }}
              onChange={this.handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Table style={{
          tableLayout: 'auto'
        }}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={this.handleRequestSort}
            rowCount={filteredData.length}
          />
          <TableBody>
            {tableData.map((v, i) => {
              let mode = v.buylist_price ? 'buylist' : 'retail'
              let {
                merchant_name, merchant_code
              } = findMerchant(v.merchant_id, merchants)
              return (
                <TableRow style={{
                  height: 36
                }} key={i}>
                  <TableCell padding={'none'}>
                    <Link to={`/merchants/${merchant_code}`}>
                      <span>{merchant_name}</span>
                    </Link>
                    <MerchantsLink cardName={this.props.cardName} setName={v.set_name} merchantID={v.merchant_id} mode={mode} tcgplayer_id={tcgplayer_id} />
                  </TableCell>
                  <TableCell padding={'none'}>
                    <Link to={`/prices/sets/${encodeURIComponent(v.set_name)}`}>
                      <Keyrune set={v.set_code} rarity={v.rarity} />
                      <Hidden smDown> {v.set_name}</Hidden>
                    </Link>
                  </TableCell>
                  <TableCell padding={'none'}>
                    <Hidden mdUp>
                      <span>{(v.foil === 1) && 'F'}</span>
                    </Hidden>
                    <Hidden smDown>
                      <span>{(v.foil === 1) && 'Foil'}</span>
                    </Hidden>
                  </TableCell>
                  <TableCell padding={'none'} className={classes[mode]}>
                    {convertToUSD(v.buylist_price ? v.buylist_price : v.retail_price)}
                  </TableCell>
                  <TableCell padding={'none'}>
                    {userLoggedIn ? (
                      <>
                        {v.volume === 99 ? <>&infin;</> : v.volume}
                      </>
                    ) : (
                      <Link to={'/merchants/hidden'}>
                        <span>Hidden</span>
                      </Link>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <EnhancedTablePagination
          filteredCount={filteredData.length}
          totalCount={null}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
        <Grid container>
          <Grid item xs={6}>
            <CardHoarderLink cardName={cardName} />
          </Grid>
          <Grid item xs={6}>
            <Typography style={{
              textAlign: 'right'
            }}>
              {this.props.data.length} total offers
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    merchants: state.merchants.loaded ? state.merchants.data : [],
    disabledMerchants: disabledMerchantsSelector(state),
    userLoggedIn: state.user.loggedIn,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(MarketPricesTable))