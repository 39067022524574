import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import Skeleton from 'react-loading-skeleton'
import { withStyles } from '@material-ui/core/styles'
import { setBreadcrumbs } from '../../../store/layout/actionCreator'
import { getFetchList } from '../../../store/collection/actionCreator'
import { getBestCardPrices } from '../../../utils/helpers'
import {
  disabledMerchantsSelector, listsObjectSelector
} from '../../../store/reselect'

import CardSearchModal from '../../Layout/CardSearchModal'

import Widget from '../../shared/Widget'
import ListEntriesTable from './ListEntriesTable'
import ListOptions from '../../shared/ListOptions'
import FiltersModal from '../../shared/FiltersModal'
import DownloadCSV from '../../shared/DownloadCSV'
import { formatListCSV } from '../../../utils/helpers'

import Grid from '@material-ui/core/Grid'
import Collapse from '@material-ui/core/Collapse'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'

const styles = {
  topNav: {
    marginBottom: 20,
    textAlign: 'center',
  },
  button: {
    textTransform: 'none',
  }
}

class ListPage extends React.Component {
  constructor(props) {
    super(props)
    props.getFetchList(props.listId)
    let name = (props.list && props.list.name) ? props.list.name : ''
    props.setBreadcrumbs([
      {
        text: 'Trader Tools',
        path: '/'
      },
      {
        text: 'Collection',
        path: '/collection'
      },
      {
        text: name
      },
    ])
    this.state={
      entryEditOpen: false,
      activeEntry: '',
      showSearch: false
    }
  }

  componentDidUpdate(prevProps) {
    const { list } = this.props
    if (prevProps.list.name !== list.name) {
      let name = (list && list.name) ? list.name : ''
      this.props.setBreadcrumbs([
        {
          text: 'Trader Tools',
          path: '/'
        },
        {
          text: 'Collection',
          path: '/collection'
        },
        {
          text: name
        },
      ])
    }
  }

  handleEntryEdit(e) {
    this.setState({
      entryEditOpen: true,
      activeEntry: e
    })
  }

  handleEntryToggle(e) {
    if (!e) {
      this.setState({
        entryEditOpen: false,
        activeEntry: ''
      })
    }
  }

  toggleShowSearch = () => {
    this.setState({
      showSearch: !this.state.showSearch
    })
  }

  render () {
    const { showSearch } = this.state

    const {
      list,
      data,
      loaded,
      error,
      listId,
      listEntries,
      totalRetailValue,
      totalBuyValue,
      totalQuantity,
      isOwner,
    } = this.props

    if (!list) {
      return null
    }

    const { name } = list

    return (
      <div>
        <Helmet>
          <title>{`${name} - Trader Tools | Quiet Speculation`}</title>
        </Helmet>
        {loaded ? (
          <>
            {isOwner &&
            <ListOptions
              list={list}
              mode='view'
              count={listEntries.data.length}
            />
            }
          </>
        ) : (
          <div  style={{
            marginBottom: 20
          }}>
            <Skeleton height={50} count={1}/>
          </div>
        )}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Widget header={name}>
              {loaded ? (
                !error ? (
                  <React.Fragment>
                    <Grid container>
                      <Grid item xs={8}>
                        {isOwner &&
                          <div style={{
                            textAlign: 'left',
                            paddingBottom: 10
                          }}>
                            <Button onClick={() => this.toggleShowSearch()}>
                              {showSearch ? <CloseIcon /> : <SearchIcon />} Search for cards to add to {name}
                            </Button>
                          </div>
                        }
                      </Grid>
                      <Grid item xs={4} style={{
                        textAlign: 'right'
                      }}>
                        <span>
                          <DownloadCSV
                            data={formatListCSV(data)}
                            filename={`${name}-`}
                          />
                        </span>
                        <FiltersModal />
                      </Grid>
                      <Grid item xs={12}>
                        <Collapse in={showSearch}>
                          <Paper elevation={1} style={{
                            marginBottom: 10,
                            borderRadius: 0
                          }}>
                            <CardSearchModal
                              visible={showSearch}
                              onNavigation={() => this.toggleShowSearch()}
                              defaultList={listId}
                            />
                          </Paper>
                        </Collapse>
                      </Grid>
                    </Grid>
                    { data.length > 0 ? (
                      <ListEntriesTable
                        listEntries={data}
                        listId={listId}
                        totalRetailValue={totalRetailValue}
                        totalBuyValue={totalBuyValue}
                        totalQuantity={totalQuantity}
                        isOwner={isOwner}
                      />
                    ) : (
                      <div>This list has no cards yet.</div>
                    )}
                  </React.Fragment>
                ) : (
                  <div>There was an error retrieving this list.</div>
                )
              ) : (
                <Skeleton height={43} count={25} />
              )
              }
            </Widget>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let listId = ownProps.match.params.listId
  let lists = listsObjectSelector(state)
  let list = (listId && lists && lists[listId]) ? lists[listId] : {}

  let listEntries = (listId && state.collection.listEntries && state.collection.listEntries[listId]) ? state.collection.listEntries[listId] : {
    data: [],
    error: false,
    loaded: false
  }

  let cards = state.cards.entries
  let merchants = state.merchants.loaded ? state.merchants.data : []
  let disabledMerchants = disabledMerchantsSelector(state)

  var totalRetailValue = 0.00
  var totalBuyValue = 0.00
  var totalQuantity = 0

  let data = listEntries.data.map(entry => {
    return {
      card: cards[entry.card_id] || null,
      entry
    }
  })
    .filter(v => v.card != null)
    .map(v => {
      let {
        card, entry
      } = v
      let {
        buy_merchant_name,
        buy_merchant_code,
        buy_volume,
        buy_best,
        spread_best_usd,
        spread_best_pct,
        retail_best,
        retail_merchant_name,
        retail_merchant_code
      } = getBestCardPrices(card.buylist_prices, card.retail_prices, merchants, disabledMerchants)

      if (!Number.isNaN(parseFloat(retail_best))) {
        let qtyVal = entry.quantity * parseFloat(retail_best)
        totalRetailValue = parseFloat(totalRetailValue) + parseFloat(qtyVal)
      }
      if (!Number.isNaN(parseFloat(buy_best))) {
        let qtyVal = entry.quantity * parseFloat(buy_best)
        totalBuyValue = parseFloat(totalBuyValue) + parseFloat(qtyVal)
      }
      totalQuantity += entry.quantity

      return {
        ...entry,
        set_name: card.set_name,
        card_name: card.card_name,
        foil: card.foil,
        set_code: card.set_code,
        rarity: card.rarity,
        buy_merchant_name,
        buy_merchant_code,
        buy_volume,
        buy_best,
        spread_best_usd,
        spread_best_pct,
        retail_best,
        retail_merchant_name,
        retail_merchant_code,
        scryfall_id: card.scryfall_id,
        variant: card.variant,
        collector_number: card.collector_number,
      }
    })

  return {
    ownProps: ownProps,
    listId: listId,
    list: list,
    data: data || [],
    listEntries,
    error: listEntries.error || false,
    loaded: listEntries.loaded || false,
    isPublic: list ? list['is_public'] : null,
    isOwner: (list && list['owner']) ? list['owner'] === state.user.id : null,
    userId: state.user.id,
    totalRetailValue,
    totalBuyValue,
    totalQuantity,
  }
}

const mapDispatchToProps = {
  setBreadcrumbs,
  getFetchList,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ListPage))
