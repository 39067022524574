import React from 'react'
import { connect } from 'react-redux'
import Loading from './Loading'
import PropTypes from 'prop-types'
import { getFetchUser } from '../../store/user/actionCreator'
import { initializeGlobalData } from '../../store/layout/actionCreator'

import { URLS } from '../../constants'

import { initializedSelector } from '../../store/reselect'

import { Alert } from '@material-ui/lab'

export class Initialize extends React.Component {
  constructor(props) {
    super(props)
    props.getFetchUser()
  }

  componentDidUpdate = (oldProps) => {
    const {
      userLoaded, initializeGlobalData
    } = this.props
    if (oldProps.userLoaded === false && userLoaded === true) {
      initializeGlobalData()
    }
  }

  render () {
    const {
      globalDataLoaded, children, error
    } = this.props

    return (
      <React.Fragment>
        <div>
          {error &&
            <Alert
              severity='error'
              style={{
                marginBottom: 8
              }}
            >
              <span style={{
                display: 'inline-block'
              }}>
                There was an error with your login. <a href={URLS.LOGOUT}>Please log out and log in again.</a>
              </span>
            </Alert>
          }
        </div>
        <div>
          {globalDataLoaded ? (
            <React.Fragment>
              {children}
            </React.Fragment>
          ) : (
            <Loading>
              Initializing Trader Tools...
            </Loading>
          )}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  insider: state.user.insider,
  globalDataLoaded: initializedSelector(state),
  userLoaded: state.user.loaded,
  merchantOptionsLoaded: state.user.merchantOptions.loaded,
  error: state.user.error,
})

const mapDispatchToProps = {
  getFetchUser,
  initializeGlobalData
}

Initialize.propTypes = {
  globalDataLoaded: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  insider: PropTypes.bool,
  initializeGlobalData: PropTypes.func.isRequired,
  getFetchUser: PropTypes.func.isRequired,
  userLoaded: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Initialize)
