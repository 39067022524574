import React from 'react'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import FilterIcon from '@material-ui/icons/FilterList'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

import {
  Typography,
  FormControlLabel,
  Checkbox,
  Switch
} from '@material-ui/core'

import { setFilters } from '../../../store/user/actionCreator'

const styles = {
  secondaryNavTab: {
    color: 'white',
    height: 50,
    fontFamily: 'Average Sans, Open Sans, Varela Round ,sans-serif',
    textTransform: 'none',
  },
  qtyGrid: {
    margin: 'auto',
    paddingTop: 8,
    paddingBottom: 8,
  },
  saveButton: {
    marginRight: 6,
  },
  paper: {
    overflow: 'visible',
  },
  section: {
    paddingBottom: 16
  },
  leftField: {
    paddingRight: 2
  },
  rightField: {
    paddingLeft: 2
  },
  sectionHeader: {
    margin: '0 0 .5rem'
  },
  disabledSectionHeader: {
    color: 'rgba(0, 0, 0, 0.38)',
    margin: '0 0 .5rem'
  }
}

export const filterData = ({
  data, filters
}) => {
  const {
    enabled,
    rarities,
    minPercentSpread,
    maxPercentSpread,
    minUsdSpread,
    maxUsdSpread,
    minBuyPrice,
    maxBuyPrice,
    minSellPrice,
    maxSellPrice,
    foil,
    nonfoil,
  } = filters

  if (!enabled) {
    return data
  }

  return data.filter(card => {
    const {
      spread_best_pct,
      spread_best_usd,
      buy_best,
      retail_best,
      rarity,
      foil: cardFoil
    } = card

    if (foil === false && cardFoil === 1) {
      return false
    }
    if (nonfoil === false && cardFoil === 0) {
      return false
    }
    if (rarities[rarity] === false) {
      return false
    }
    if (minPercentSpread && spread_best_pct < minPercentSpread) {
      return false
    }
    if (maxPercentSpread && spread_best_pct > maxPercentSpread) {
      return false
    }
    if (minUsdSpread && spread_best_usd < minUsdSpread) {
      return false
    }
    if (maxUsdSpread && spread_best_usd > maxUsdSpread) {
      return false
    }
    if (minBuyPrice && buy_best < minBuyPrice) {
      return false
    }
    if (maxBuyPrice && buy_best > maxBuyPrice) {
      return false
    }
    if (minSellPrice && retail_best < minSellPrice) {
      return false
    }
    if (maxSellPrice && retail_best > maxSellPrice) {
      return false
    }

    return true
  })

}

class FiltersModal extends React.Component {
  static propTypes = {
    classes: object,
  }

  constructor(props) {
    super(props)
    this.state = {
      open: false,
      dt: null,
      filters: props.filters
    }
  }


  handleOpen = () => {
    this.setState({
      open: true,
    })
  }

  handleClose = () => {
    this.setState({
      open: false,
    })
  }

  handleChangeRarity = (rarity) => {
    this.setState({
      filters: {
        ...this.state.filters,
        rarities: {
          ...this.state.filters.rarities,
          [rarity]: !this.state.filters.rarities[rarity]
        },
      }
    }, () => {
      this.props.setFilters(this.state.filters)
    })
  }

  handleChangeFoil = (foilKey) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [foilKey]: !this.state.filters[foilKey]
      }
    }, () => {
      this.props.setFilters(this.state.filters)
    })
  }

  handleChange = (key, value) => {
    let date = Date.now()
    this.setState({
      filters: {
        ...this.state.filters,
        [key]: value
      },
      dt: date
    }, () => {
      setTimeout(() => {
        if (this.state.dt === date) {
          this.props.setFilters(this.state.filters)
        }
      }, 300)
    })
  }

  render () {
    const { classes } = this.props

    const {
      open, filters
    } = this.state

    const {
      enabled,
      rarities,
      minPercentSpread,
      maxPercentSpread,
      minUsdSpread,
      maxUsdSpread,
      minBuyPrice,
      maxBuyPrice,
      minSellPrice,
      maxSellPrice,
      foil,
      nonfoil,
    } = filters

    return (
      <React.Fragment>
        <Button aria-label='Edit' size='small' onClick={this.handleOpen}>
          <FilterIcon /> Open Filters
        </Button>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
          maxWidth={'sm'}
          fullWidth
          PaperProps ={{
            classes: {
              root: classes.paper
            }
          }}
        >
          <DialogTitle>Filters</DialogTitle>
          <DialogContent
            style={{
              overflow: 'visible',
            }}
          >
            <Grid container>
              <Grid item xs={12} className={classes.section}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={enabled}
                      onChange={() => this.handleChange('enabled', !enabled)}
                      name='enabled'
                      color='primary'
                    />
                  }
                  label='Filters Enabled'
                />
              </Grid>
              <Grid item xs={12} className={classes.section}>
                <Typography variant={'h5'} color='primary' className={enabled ? classes.sectionHeader : classes.disabledSectionHeader}>
                  Rarities and Foils:
                </Typography>
                {Object.keys(rarities).map(rarity =>
                  <FormControlLabel
                    key={rarity}
                    control={
                      <Checkbox
                        checked={rarities[rarity]}
                        onChange={() => this.handleChangeRarity(rarity)}
                        name={rarity}
                        color='primary'
                        disabled={!enabled}
                      />
                    }
                    label={rarity}
                  />
                )}
                <div>
                  <FormControlLabel
                    key={'nonfoil'}
                    control={
                      <Checkbox
                        checked={nonfoil}
                        onChange={() => this.handleChangeFoil('nonfoil')}
                        name={'nonfoil'}
                        color='primary'
                        disabled={!enabled}
                      />
                    }
                    label={'Non-foil'}
                  />
                  <FormControlLabel
                    key={'foil'}
                    control={
                      <Checkbox
                        checked={foil}
                        onChange={() => this.handleChangeFoil('foil')}
                        name={'foil'}
                        color='primary'
                        disabled={!enabled}
                      />
                    }
                    label={'Foil'}
                  />
                </div>
              </Grid>
              <Grid item xs={12} className={classes.section}>
                <Typography variant={'h5'} color='primary' className={enabled ? classes.sectionHeader : classes.disabledSectionHeader}>
                  Buylist $:
                </Typography>
                <Grid container>
                  <Grid item xs={6} className={classes.leftField}>
                    <TextField
                      label='Minimum'
                      id='minBuyPrice'
                      value={minBuyPrice || ''}
                      variant='outlined'
                      size='small'
                      fullWidth
                      onChange={(e) => this.handleChange('minBuyPrice', e.target.value)}
                      disabled={!enabled}
                      type='number'
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.rightField}>
                    <TextField
                      label='Maximum'
                      id='maxBuyPrice'
                      value={maxBuyPrice || ''}
                      variant='outlined'
                      size='small'
                      fullWidth
                      onChange={(e) => this.handleChange('maxBuyPrice', e.target.value)}
                      disabled={!enabled}
                      type='number'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.section}>
                <Typography variant={'h5'} color='primary' className={enabled ? classes.sectionHeader : classes.disabledSectionHeader}>
                  Retail $:
                </Typography>
                <Grid container>
                  <Grid item xs={6} className={classes.leftField}>
                    <TextField
                      label='Minimum'
                      id='minSellPrice'
                      value={minSellPrice || ''}
                      variant='outlined'
                      size='small'
                      fullWidth
                      onChange={(e) => this.handleChange('minSellPrice', e.target.value)}
                      disabled={!enabled}
                      type='number'
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.rightField}>
                    <TextField
                      label='Maximum'
                      id='maxSellPrice'
                      value={maxSellPrice || ''}
                      variant='outlined'
                      size='small'
                      fullWidth
                      onChange={(e) => this.handleChange('maxSellPrice', e.target.value)}
                      disabled={!enabled}
                      type='number'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.section}>
                <Typography variant={'h5'} color='primary' className={enabled ? classes.sectionHeader : classes.disabledSectionHeader}>
                  Spread %:
                </Typography>
                <Grid container>
                  <Grid item xs={6} className={classes.leftField}>
                    <TextField
                      label='Minimum'
                      id='minPercentSpread'
                      value={minPercentSpread || ''}
                      variant='outlined'
                      size='small'
                      fullWidth
                      onChange={(e) => this.handleChange('minPercentSpread', e.target.value)}
                      disabled={!enabled}
                      type='number'
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.rightField}>
                    <TextField
                      label='Maximum'
                      id='maxPercentSpread'
                      value={maxPercentSpread || ''}
                      variant='outlined'
                      size='small'
                      fullWidth
                      onChange={(e) => this.handleChange('maxPercentSpread', e.target.value)}
                      disabled={!enabled}
                      type='number'
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.section}>
                <Typography variant={'h5'} color='primary' className={enabled ? classes.sectionHeader : classes.disabledSectionHeader}>
                  Spread $:
                </Typography>
                <Grid container>
                  <Grid item xs={6} className={classes.leftField}>
                    <TextField
                      label='Minimum'
                      id='minUsdSpread'
                      value={minUsdSpread || ''}
                      variant='outlined'
                      size='small'
                      fullWidth
                      onChange={(e) => this.handleChange('minUsdSpread', e.target.value)}
                      disabled={!enabled}
                      type='number'
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.rightField}>
                    <TextField
                      label='Maximum'
                      id='maxUsdSpread'
                      value={maxUsdSpread || ''}
                      variant='outlined'
                      size='small'
                      fullWidth
                      onChange={(e) => this.handleChange('maxUsdSpread', e.target.value)}
                      disabled={!enabled}
                      type='number'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid item xs={12} style={{
                textAlign: 'right'
              }}>
                <Button
                  onClick={this.handleClose}
                  color='primary'
                >
                  Done
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    filters: state.user.filters,
  }
}

const mapDispatchToProps = {
  setFilters
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FiltersModal))
