import {
  SET_SETS,
  SET_SET_ENTRIES,
  SET_SET_ENTRIES_ERROR,
  SET_SELECTED_SETS
} from './actionType'

export const initialState = {
  data: {},
  entries: {
    0: {},
    1: {},
  },
  loaded: false,
  error: false,
  selectedSets: [],
}

export default function setsReducer (state = initialState, action = {}) {
  switch (action.type) {
  case SET_SELECTED_SETS: {
    const { sets } = action
    const newState = {
      ...state,
      selectedSets: sets
    }
    return newState
  }
  case SET_SET_ENTRIES_ERROR: {
    const { error } = action
    const newState = {
      ...state,
      error
    }
    return newState
  }
  case SET_SETS: {
    const { sets } = action
    const newState = {
      ...state,
      data: sets,
      loaded: true,
    }
    return newState
  }
  case SET_SET_ENTRIES: {
    const {
      setName,
      foil,
      entries,
    } = action
    const newState = {
      ...state,
      entries: {
        ...state.entries,
        [foil]: {
          ...state.entries[foil],
          [setName]: entries
        }
      }
    }
    return newState
  }
  // case SET_SET_CARDS: {
  //   const {
  //     setName, foil, data, error
  //   } = action.payload
  //   let set = setName.toLowerCase()
  //   let foilInt = parseInt(foil)
  //   if (state[set]) {
  //     let newState = {
  //       ...state,
  //       [set]: {
  //         ...state[set][foilInt],
  //         [foilInt]: {
  //           data: data,
  //           loaded: true,
  //           error: error,
  //         }
  //       }
  //     }
  //     return newState
  //   } else {
  //     let newState = {
  //       ...state,
  //       [set]: {
  //         [foilInt]: {
  //           data: data,
  //           loaded: true,
  //           error: error,
  //         }
  //       }
  //     }
  //     return newState
  //   }
  // }
  default:
    return state
  }
}
