import React from 'react'
import { connect } from 'react-redux'
import { object } from 'prop-types'
import Grid from '@material-ui/core/Grid'
import {
  Typography, Switch
} from '@material-ui/core'
import { updateUserSetting } from '../../../store/user/actionCreator'

class UserSetting extends React.Component {
  static propTypes = {
    classes: object,
  }

  render () {
    const {
      setting, userSettings, updateUserSetting
    } = this.props
    const {
      id, name, description
    } = setting
    const value = userSettings[id] === 1 ? true : false

    return (
      <Grid item xs={12}>
        <Grid container style={{
          height : 38
        }}>
          <Grid item xs={4}>
            <Typography>
              {name}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='caption'>
              {description}
            </Typography>
          </Grid>
          <Grid item xs={2} style={{
            textAlign: 'right'
          }}>
            <Switch
              checked={value}
              onChange={() => updateUserSetting(id, value === true ? 0 : 1)}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  userSettings: state.user.userSettings,
})

const mapDispatchToProps = {
  updateUserSetting
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSetting)