import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import { withStyles } from '@material-ui/core/styles'
import {
  Button, Grid
} from '@material-ui/core'

import {
  setBreadcrumbs, setImportColumns
} from '../../../store/layout/actionCreator'
import {
  postDryRun, saveListEntries, updateImportStatus
} from '../../../store/collection/actionCreator'
import { listsSelector } from '../../../store/reselect'

import { EMPTY_LIST } from '../../../constants'
import {
  csvToJSON, consolidateList
} from './helpers'

import ListEditor from '../../shared/ListEditor'
import CollectionNavigation from '../../shared/CollectionNavigation'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'

const styles = {
  topNav: {
    marginBottom: 20,
    textAlign: 'center',
  },
  button: {
    textTransform: 'none',
  },
}

// card_name
// set_name
// foil
// quantity
// condition
// scryfall_id
// tcgplayer_id
// set_code

class ImportListPage extends React.Component {
  static propTypes = {
    classes: object,
  }

  constructor(props) {
    super(props)
    props.setBreadcrumbs([
      {
        text: 'Trader Tools',
        path: '/'
      },
      {
        text: 'Collection',
        path: '/collection'
      },
      {
        text: 'Import'
      },
    ])
    this.state={
      step: 1,
      selectedListId: 'no_list',
      convertedList: {},
      errorText: '',
    }
  }

  componentDidUpdate = (prevProps) => {
    const { lists } = this.props
    if (prevProps.lists && lists && prevProps.lists.length !== lists.length) {
      const { id } = lists[0]
      this.setState({
        selectedListId: id,
      })
    }
  }

  handleNewListToggle() {
    this.setState({
      listOpen: true
    })
  }

  handleUpload(importListCsvValue) {
    try {
      this.setState({
        errorText: '',
      })
      let converted = csvToJSON(importListCsvValue, this.props.importColumns)
      if (converted) {
        this.setState({
          convertedList: converted,
          step: 2
        })
        this.props.postDryRun(converted)
      } else {
        this.setState({
          errorText: 'There was an error converting your CSV. Ensure the columns are separated by tabs or semi-colons and each row is a new line.',
        })
      }
    } catch (_err) {
      this.setState({
        errorText: 'There was an error converting your CSV. Ensure the columns are separated by tabs or semi-colons and each row is a new line.',
      })
    }
  }

  handleImport() {
    this.setState({
      step: 3
    })
  }

  handleSelectList = (id) => {
    this.setState({
      selectedListId: id,
    })
  }

  // TODO: eventually remove gatherer_id once we're off legacy list support, fully on scryfall_id
  uploadList = () => {
    let consolidatedList = consolidateList(this.props.list)
    let payload = consolidatedList.map(v => {
      return {
        gid: parseInt(v.data.gatherer_id),
        scryfallId: v.data.scryfall_id,
        foil: parseInt(v.foil),
        qty: parseInt(v.quantity)
      }
    })

    this.props.saveListEntries(this.state.selectedListId, payload)
  }

  render () {
    const {
      listOpen,
      step,
      selectedListId,
      errorText,
    } = this.state

    const {
      classes,
      list,
      lists,
      loaded,
      error,
      valid,
      importLoading,
      importSuccess,
      importError,
      importColumns
    } = this.props

    return (
      <div>
        <Helmet>
          <title>Import List - Trader Tools | Quiet Speculation</title>
        </Helmet>
        <Grid container spacing={1}>
          <CollectionNavigation
            currentPage='import'
            externalListOpen={listOpen}
            setExternalListOpen={() => {
              this.setState({
                listOpen: false
              })
            }}
          />
          <Grid item xs={12}>
            <Step1
              hidden={step !== 1}
              onHandleUpload={(e) => this.handleUpload(e)}
              importColumns={importColumns}
              onUpdateColumns={(e) => this.props.setImportColumns(e)}
              errorText={errorText}
            />
          </Grid>
          <Grid item xs={12}>
            <Step2
              hidden={step !== 2}
              onGoBack={() => this.setState({
                step: step - 1
              })}
              loaded={loaded}
              error={error}
              valid={valid}
              onHandleImport={() => this.handleImport()}
            />
          </Grid>
          <Grid item xs={12}>
            <Step3
              hidden={step !== 3}
              onGoBack={() => this.setState({
                step: step - 1
              })}
              onHandleNewListToggleloaded={() => this.handleNewListToggle()}
              onHandleSelectList={(e) => this.handleSelectList(e)}
              importLoading={importLoading}
              importSuccess={importSuccess}
              importError={importError}
              list={list}
              lists={lists}
              selectedListId={selectedListId}
              onUploadList={() => this.uploadList()}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  let list = state.collection.dryRunList
  let loaded = state.collection.dryRunLoaded
  let error = state.collection.dryRunError
  return {
    list,
    lists: listsSelector(state),
    valid: !error && loaded && list.length > 0 && !(list.filter(v => v.success === false).length > 0),
    loaded,
    error,
    importLoading: state.collection.importLoading,
    importSuccess: state.collection.importSuccess,
    importError: state.collection.importError,
    importColumns: state.layout.importColumns
  }
}

const mapDispatchToProps = {
  setBreadcrumbs,
  postDryRun,
  saveListEntries,
  updateImportStatus,
  setImportColumns,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ImportListPage))
