import { v4 as uuidv4 } from 'uuid'

function splitCsvRow(row) {
  if (row.includes('\t')) {
    let newRow = row.split('\t')
    return newRow.map(v => v.toLowerCase().trim())
  }
  if (row.includes(';')) {
    let newRow = row.split(';')
    return newRow.map(v => v.toLowerCase().trim())
  }
}

export function csvToJSON(csv, headers){
  try {
    var lines = csv.split('\n')
    var result = []

    // ION Scanner payload: cardname edition foil quantity

    var firstRow = splitCsvRow(lines[0])
    var startPos = (firstRow.some(r=> ['Cardname', 'name', 'card', 'card_name', 'cardname'].indexOf(r) >= 0)) ? 1 : 0

    for( var i = startPos; i < lines.length; i++ ){
      if (lines[i] && lines[i] !== '\n') {
        var obj = {}
        var currentline = splitCsvRow(lines[i])
        for( var j=0; j<headers.length; j++ ){
          obj[headers[j]] = currentline[j]
        }

        if (obj.foil !== 0 || obj.foil !== 1) {
          const validFoilOptions = [ 'true', true, 'foil', 'f', 't', 'yes', 'y', 1, '1']
          const validNonfoilOptions = [ 'false', false, 'nonfoil', 'n', 'non', 'no', ' ', '', 0, '0']
          if (validFoilOptions.includes(obj.foil)) {
            obj.foil = 1
          } else if (validNonfoilOptions.includes(obj.foil)) {
            obj.foil = 0
          } else {
            obj.foil = 0
          }
        }

        if (obj.card_name !== '' && obj.set_name !== '' && obj.foil !== '') {
          obj.quantity = parseInt(obj.quantity)
          obj.key = uuidv4()
          result.push(obj)
        }
      }
    }

    return result
  } catch (err) {
    return false
  }
}

// TODO: Base this off of scryfall_id
export function consolidateList(list) {
  let consolidated = list.reduce((acc, entry) => {
    let {
      card_name, set_name, quantity, foil
    } = entry
    let key = `${card_name}_${set_name}_${foil}`
    acc[key] = acc[key] ? {
      ...entry,
      quantity: acc[key].quantity + quantity,
    } : {
      ...entry,
      quantity: quantity
    }
    return acc
  }, {})
  return Object.keys(consolidated).map(key => {
    return {
      ...consolidated[key],
      key,
    }
  })
}