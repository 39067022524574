import React from 'react'
import { connect } from 'react-redux'
import PropTypes, { object } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Skeleton from 'react-loading-skeleton'
import { getBestCardPrices } from '../../../utils/helpers'
import ImagesGrid from '../../shared/ImagesGrid'
import { disabledMerchantsSelector } from '../../../store/reselect'
import EnhancedTablePagination from '../../shared/EnhancedTablePagination'

import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import Row from './Row'

const styles = {}

const columnData = [
  {
    id: 'card_name',
    align: 'left',
    disablePadding: false,
    label: 'Card Name',
    minWidth: 215
  },
  {
    id: 'set_name',
    align: 'left',
    disablePadding: false,
    label: 'Set Name',
    minWidth: 180
  },
  {
    id: 'rarity',
    align: 'left',
    disablePadding: false,
    label: 'Rarity'
  },
  {
    id: 'foil',
    align: 'left',
    disablePadding: false,
    label: 'Foil'
  },
  {
    id: 'collector_number',
    align: 'left',
    disablePadding: false,
    label: '#'
  },
  {
    id: 'buy_merchant_name',
    align: 'left',
    textAlign: 'right',
    disablePadding: false,
    label: 'Buyer'
  },
  {
    id: 'buy_volume',
    align: 'left',
    textAlign: 'center',
    disablePadding: false,
    label: 'Qty'
  },
  {
    id: 'buy_best',
    align: 'left',
    textAlign: 'center',
    disablePadding: false,
    label: 'Best Buylist'
  },
  {
    id: 'spread_best_usd',
    align: 'left',
    textAlign: 'center',
    disablePadding: false,
    label: 'Spread $'
  },
  {
    id: 'spread_best_pct',
    align: 'left',
    textAlign: 'center',
    disablePadding: false,
    label: 'Spread %'
  },
  {
    id: 'retail_best',
    align: 'left',
    textAlign: 'center',
    disablePadding: false,
    label: 'Best Retail'
  },
  {
    id: 'retail_merchant_name',
    align: 'left',
    paddingLeft: 3,
    disablePadding: false,
    label: 'Seller'
  },
]

const filterData = (data, orderBy, order) => {
  if (['buy_volume', 'buy_best', 'spread_best_usd', 'spread_best_pct', 'retail_best'].includes(orderBy)) {
    const result = (order === 'desc')
      ? data.sort((a, b) => (parseFloat(b[orderBy] || 0) < parseFloat(a[orderBy] || 0) ? -1 : 1))
      : data.sort((a, b) => (parseFloat(a[orderBy] || 0) < parseFloat(b[orderBy] || 0) ? -1 : 1))

    return result
  } else {
    const result = (order === 'desc')
      ? data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
      : data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1))

    return result
  }
}

class EnhancedTableHead extends React.Component {

  createSortHandler = (event, property) => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const {
      order, orderBy
    } = this.props

    return (
      <TableHead>
        <TableRow style={{
          height: 36
        }}>
          {columnData.map(column => { return (
            <TableCell
              key={column.id}
              align={column.align}
              padding={'none'}
              sortDirection={orderBy === column.id ? order : false}
              style={{
                minWidth: column.minWidth,
                textAlign: (column.textAlign) ? column.textAlign: 'left',
                paddingLeft: (column.paddingLeft) ? column.paddingLeft : 0
              }}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={order}
                onClick={(e) => this.createSortHandler(e, column.id)}
              >
                {column.label}
              </TableSortLabel>
            </TableCell>
          )}, this)}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

class MerchantTable extends React.Component {
  static propTypes = {
    classes: object,
  }

  constructor(props) {
    super(props)
    this.state ={
      seed: '',
      lastSeed: '',
      dt: null,
      imagesMode: false,
    }
  }

  handleChangePage = (event, page) => {
    const { pagination } = this.props
    const { numPages } = pagination
    let actualPage = page + 1
    if (actualPage <= numPages) {
      this.props.onChangeData({
        ...pagination,
        page: actualPage,
      })
    }
  }

  handleChangeRowsPerPage = (event) => {
    const { pagination } = this.props
    this.props.onChangeData({
      ...pagination,
      perPage: event.target.value,
    })
  }

  handleRequestSort = (event, orderBy) => {
    const { pagination } = this.props
    if (orderBy !== pagination.orderBy) {
      this.props.onChangeData({
        ...pagination,
        orderBy,
        order: 'ASC',
      })
    } else {
      const order = (pagination.order === 'ASC') ? 'DESC' : 'ASC'
      this.props.onChangeData({
        ...pagination,
        orderBy,
        order,
      })
    }
  }


  handleUpdateSeed = seed => {
    let date = Date.now()
    this.setState({
      seed,
      dt: date
    }, () => {
      setTimeout(() => {
        if (this.state.dt === date) {
          const {
            pagination, onChangeData
          } = this.props
          const { lastSeed } = this.state

          this.setState({
            lastSeed: seed,
          })

          if (lastSeed === '') {
            onChangeData({
              ...pagination,
              seed,
              page: 1,
            })
          } else {
            onChangeData({
              ...pagination,
              seed,
            })}
        }
      }, 300)
    })
  }

  render () {
    const {
      pagination, data
    } = this.props
    const {
      numRows,
      perPage,
      loaded,
      orderBy,
      order,
      page,
    } = pagination
    const {
      seed, imagesMode
    } = this.state

    let tableData = filterData(data, orderBy, order.toLowerCase())

    return (
      <Grid container>
        <Grid item xs={12} style={{
          paddingBottom: 16,
          paddingTop: 16
        }}>
          <TextField
            label='Search Card and Set Names'
            variant='outlined'
            value={seed}
            onChange={(e) => this.handleUpdateSeed(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <Button variant='outlined' color='primary' size='small' onClick={() => this.setState({
            imagesMode: !imagesMode
          })} >
            {imagesMode ? 'Table' : 'Images'}
          </Button>
        </Grid>
        <Grid item xs={8} style={{
          paddingBottom: 16,
          textAlign: 'right',
          fontSize: '.8rem'
        }}>
          <em>
            Note: The results of this table are calculated excluding marketplace prices. <br/>
            If you have any marketplace merchants enabled in your settings, they will still show up as the best offer below.
          </em>
        </Grid>
        <Grid item xs={12}>
          <Table style={{
            tableLayout: 'auto'
          }}>
            <EnhancedTableHead
              order={order.toLowerCase()}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={numRows}
            />
            <TableBody>
              {imagesMode ? (
                <TableRow>
                  <TableCell
                    padding={'none'}
                    colSpan={columnData.length}
                  >
                    <ImagesGrid data={tableData} />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {loaded ? (
                    tableData.map((v, i) => {
                      return (
                        <Row card={v} key={i + '_' + v.card_id} />
                      )
                    })
                  ) : (
                    <TableRow >
                      <TableCell colSpan={columnData.length} style={{
                        padding: 0
                      }}>
                        <Skeleton height={32} count={perPage} />
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>
          <EnhancedTablePagination
            filteredCount={numRows}
            totalCount={numRows}
            rowsPerPage={perPage}
            page={page - 1}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
          />
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let entries = ownProps.entries ? ownProps.entries : []
  let cards = state.cards.entries
  let merchants = state.merchants.data
  let disabledMerchants = disabledMerchantsSelector(state)

  let data = entries.map(cardId => {
    return cards[cardId] || null
  })
    .filter(v => v != null)
    .map(card => {
      let {
        buy_merchant_name,
        buy_merchant_code,
        buy_volume,
        buy_best,
        spread_best_usd,
        spread_best_pct,
        retail_best,
        retail_merchant_name,
        retail_merchant_code
      } = getBestCardPrices(card.buylist_prices, card.retail_prices, merchants, disabledMerchants)
      return {
        set_name: card.set_name,
        card_name: card.card_name,
        foil: card.foil,
        set_code: card.set_code,
        rarity: card.rarity,
        buy_merchant_name,
        buy_merchant_code,
        buy_volume,
        buy_best,
        spread_best_usd,
        spread_best_pct,
        retail_best,
        retail_merchant_name,
        retail_merchant_code,
        scryfall_id: card.scryfall_id,
        collector_number: card.collector_number,
        variant: card.variant,
      }
    })

  return {
    merchants,
    data
  }
}

export default connect(mapStateToProps)(withStyles(styles)(MerchantTable))
