import React from 'react'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { setBreadcrumbs } from '../../../store/layout/actionCreator'
import YouTube from 'react-youtube'
import Auth from '../../shared/Auth'

import Widget from '../../shared/Widget'

import { URLS } from '../../../constants'

import ScannerLoginImg from '../../../assets/images/scanner-login.png'
import ScannerTraderToolsImg from '../../../assets/images/scanner-trader-tools-list.png'

import {
  Typography,
  Grid,
  Button,
  Divider
} from '@material-ui/core'

let instructions = [
  {
    title: 'Download and run the ION Scanner installer on your PC.',
    body: 'Then use the registration key on the download page to activate your ION Core software. ION Core has been tested with Windows versions 8 and higher.'
  }
]

const styles = {}

class IONScannerPage extends React.Component {
  static propTypes = {
    classes: object,
  }

  constructor(props) {
    super(props)
    this.props.setBreadcrumbs([
      {
        text: 'Trader Tools',
        path: '/'
      },
      {
        text: 'ION Scanner'
      }
    ])
  }

  render () {

    const {
      email, registrationKey
    } = this.props

    return (
      <div>
        <Helmet>
          <title>ION Scanner - Trader Tools | Quiet Speculation</title>
        </Helmet>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Widget header={'ION Scanner'}>
              <div style={{
                textAlign: 'center',
                paddingTop: 24,
                paddingBottom: 24
              }}>
                <YouTube
                  videoId='gXOS8Q71Jj0'
                  opts={{
                    height: '390',
                    width: '640',
                  }}
                  onReady={this._onReady}
                />
              </div>

              <Auth
                Base={
                  <Grid item xs={12}>
                    <Typography variant='h5' color='primary' gutterBottom>
                      Become Insider and get instant access to ION Scanner
                    </Typography>
                    <Typography style={{
                      paddingBottom: 24
                    }}>
                      Along with unlocked price information, collection management, and an exclusive Discord channel, Insiders get access to ION Scanner. Inventory your collection today.
                    </Typography>
                    <div style={{
                      textAlign: 'center',
                      paddingBottom: 24
                    }}>
                      <Button variant='contained' size='large' color='primary' href='https://www.quietspeculation.com/membership/signup'>
                        Become an Insider Today
                      </Button>
                    </div>
                  </Grid>
                }
                LoggedIn={
                  <>
                    <Grid item xs={12}>
                      <Grid container style={{
                        textAlign: 'center',
                        paddingBottom: 24
                      }}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='h5' color='primary'>
                            Email/Username
                          </Typography>
                          <Typography variant='h6' style={{
                            fontFamily: 'monospace'
                          }}>
                            {email}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='h5' color='primary'>
                            Password
                          </Typography>
                          <Typography variant='h6' style={{
                            fontFamily: 'monospace'
                          }}>
                            Your QS Password
                          </Typography>
                        </Grid>
                      </Grid>
                      <div style={{
                        textAlign: 'center',
                        paddingBottom: 24
                      }}>
                        <Button size='large' variant='contained' color='primary' href={URLS.DOWNLOAD_SCANNER}>
                        Download ION Scanner
                        </Button>
                      </div>
                    </Grid>
                    <Grid item xs={12} style={{
                      paddingBottom: 24
                    }}>
                      <div style={{
                        width: '100%',
                        textAlign: 'center',
                      }}>
                        {ScannerLoginImg && <img src={ScannerLoginImg} alt='scanner-login'></img>}
                      </div>
                      <Divider />
                    </Grid>
                  </>
                }
              />

              <Grid item xs={12}>
                <Typography variant='h5' color='primary' gutterBottom>
                  Download and run the ION Scanner installer on your PC.
                </Typography>
                <Typography style={{
                  paddingBottom: 24
                }}>
                  Log in using your Quiet Speculation login. Windows 7 and higher are supported.
                </Typography>

                <Typography variant='h5' color='primary' gutterBottom>
                  Plug in your USB webcam, then launch the ION Scanner.
                </Typography>
                <Typography style={{
                  paddingBottom: 24
                }}>
                  Point the camera straight down at your light-colored table or playmat. The scanner works best when the background color is a light, solid, non-textured surface. At QS we use light gray playmats (since they are different colors than both black- and white-border cards).
                </Typography>

                <Typography variant='h5' color='primary' gutterBottom>
                  Scan some cards!
                </Typography>
                <Typography style={{
                  paddingBottom: 24
                }}>
                  Place a card under the camera, let it scan, remove the card, repeat.
                </Typography>

                <Typography variant='h5' color='primary' gutterBottom>
                  Import and Export your Trader Tools lists!
                </Typography>
                <Typography style={{
                  paddingBottom: 24
                }}>
                  You can import a list from your collection that you've set up in Trader Tools directly into ION Scanner--and you can send your newly scanned cards directly to a list from it as well.
                </Typography>
                <div style={{
                  width: '100%',
                  textAlign: 'center',
                }}>
                  {ScannerTraderToolsImg && <img src={ScannerTraderToolsImg} alt='scanner-trader-tools'></img>}
                </div>
                <Typography variant='h5' color='primary' gutterBottom>
                  Figure out what to do with all the time you just saved.
                </Typography>
                <Typography style={{
                  paddingBottom: 24
                }}>
                  Maybe head over to our <a href='https://www.quietspeculation.com/discord/'>Insider-only Discord server</a> and come talk about Magic cards with the rest of us :)
                </Typography>
              </Grid>
            </Widget>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let email = state.user.email
  let registrationKey = state.user.registrationKey
  return {
    email,
    registrationKey
  }
}

const mapDispatchToProps = {
  setBreadcrumbs,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(IONScannerPage)))
