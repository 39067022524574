import React, { Component } from 'react'
import { CSVLink } from 'react-csv'
import moment from 'moment'
import {
  array, string
} from 'prop-types'

import IconButton from '@material-ui/core/IconButton'
import { GetApp } from '@material-ui/icons'

class DownloadCSV extends Component {

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.forceUpdate()
    }
  }

  render() {
    const {
      data, filename
    } = this.props

    return (
      <CSVLink
        data={data}
        filename={filename + moment().format('YYYY-MM-DDTHH:mm:ss') + '.csv'}
      >
        <IconButton color='primary' >
          <GetApp />
        </IconButton>
      </CSVLink>
    )
  }
}

DownloadCSV.propTypes = {
  data: array,
  filename: string
}

export default DownloadCSV
