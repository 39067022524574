import React from 'react'
import moment from 'moment'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { getFeatureImageStyle } from '../../utils/tcgplayer'

const styles = {
  wrap: {
    position: 'relative',
    paddingBottom: 8,
    float: 'left',
    textAlign: 'left',
    width: '100%',
    backgroundColor: '#fff',
    borderWidth: 0,
    marginBottom: 20,
    overflow: 'hidden',
    boxShadow: '0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%)',
    transition: 'all .2s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
      boxShadow: '0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%)',
    }
  },
  readMore: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 2000,
    padding: 12,
    background: 'linear-gradient(to right,rgba(255,255,255,0),#fff 45%)',
    width: 200,
    textAlign: 'right',
    fontSize: 12,
    lineHeight: '12px',
    paddingTop: 0,
    color: '#7986cb',
  },
  top: {
    height: 220,
    overflow: 'hidden',
  },
  featureImage: {
    top: 0,
    left: 0,
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    minHeight: 220,
  },
  bottom: {
    height: 184,
    overflow: 'hidden',
    padding: 6,
    lineHeight: '24px',
  },
  title: {
    color: '#7986cb',
    textDecoration: 'none',
    fontSize: '24px',
    marginTop: 12,
    marginBottom: 12,
    lineHeight: 1.2,
    fontWeight: 500,
    paddingLeft: 15,
    paddingRight: 15,
  },
  teaser: {
    fontSize: 13.6,
    paddingLeft: 15,
    paddingRight: 15,
    lineHeight: '24px',
  },
  byline: {
    display: 'block',
    position: 'absolute',
    top: 220,
    left: 0,
    width: '100%',
  },
  bylineImage: {
    height: 66,
    width: 66,
    position: 'absolute',
    bottom: 0,
    boxShadow: '0 0 0 0 rgb(0 0 0 / 26%)',
    zIndex: 100,
  },
  bylineText: {
    fontSize: '.8em',
    textAlign: 'right',
    padding: 5,
    backgroundColor: 'rgba(0,0,0,.6)',
    color: '#fff',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    paddingLeft: 0,
    marginLeft: -10,
    paddingRight: 10,
  }
}

class Article extends React.Component {

  render () {
    const {
      classes, authorName, imageURL, authorImageURL, dateTime, title, teaser, scryfallID, url
    } = this.props

    const featureImageStyle = getFeatureImageStyle(imageURL, scryfallID)

    return (
      <a className={classes.wrap} href={url} target='_blank' rel="noreferrer">
        <div className={classes.readMore}>Read More...</div>
        <div className={classes.top}>
          <div className={classes.featureImage} style={featureImageStyle}>
            <div className={classes.byline}>
              {authorImageURL && <img src={authorImageURL} className={classes.bylineImage} />}
              <div className={classes.bylineText}>
                By {authorName} | {moment(new Date(dateTime)).format('ddd M/D/YY')}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.bottom}>
          <Typography variant='h3' className={classes.title}>
            {title}
          </Typography>
          <Typography className={classes.teaser}>
            {teaser}
          </Typography>
        </div>
      </a>
    )
  }
}

export default withStyles(styles)(Article)
