import React, { Component } from 'react'
import {
  object, array
} from 'prop-types'
import { connect } from 'react-redux'
import { updateTradeRoutesCard } from '../../../store/tradeRoutes/actionCreator'
import CardOffersSelect from './CardOffersSelect'
import CardImage from '../../shared/CardImage'
import { findMerchant } from '../../../utils/helpers'

import {
  Typography,
  Grid,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Table
} from '@material-ui/core'

class CardOffers extends Component {
  handleSelectedOffersChange (merchantIds) {
    const {
      card, updateTradeRoutesCard
    } = this.props
    const { card_id } = card
    updateTradeRoutesCard(card_id, merchantIds || [])
  }

  render() {
    const {
      card, allOffers, selectedOffers
    } = this.props
    const {
      card_id, scryfall_id, card_name
    } = card

    return (
      <Grid container>
        <Grid item xs={12} sm={4}>
          <CardImage scryfallId={scryfall_id} cardName={card_name} disableHover={true} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant='h6' color='primary'>
            Selected Offers
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Count
                </TableCell>
                <TableCell>
                  Price
                </TableCell>
                <TableCell>
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedOffers.map((offer, i) => {
                const {
                  merchant_name, selected, price
                } = offer
                return (
                  <TableRow key={i}>
                    <TableCell>
                      {merchant_name}
                    </TableCell>
                    <TableCell>
                      {selected}
                    </TableCell>
                    <TableCell>
                      ${price.toFixed(2)}
                    </TableCell>
                    <TableCell>
                      ${(selected * price).toFixed(2)}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant='h6' color='primary'>
            Offers
          </Typography>
          <CardOffersSelect
            options={allOffers}
            cardId={card_id}
            selectedOffers={selectedOffers}
            onChange={(e) => this.handleSelectedOffersChange(e)}
          />
        </Grid>
      </Grid>
    )
  }
}

CardOffers.propTypes = {
  card: object,
  allOffers: array,
  selectedOffers: array,
}

const mapStateToProps = (state, ownProps) => {
  var cardId = ownProps.cardId
  var { offers } = state.tradeRoutes.data[cardId]
  var offersKeys =  Object.keys(offers)
  var merchants = state.merchants.data
  var offersArray =  offersKeys.map(v => {
    let { merchant_id } = offers[v]
    let merchant = findMerchant(merchant_id, merchants)
    return {
      ...merchant,
      ...offers[v]
    }
  })

  var selectedOffers = offersArray.filter(v => v.selected > 0)

  return {
    tradeRoutes: state.tradeRoutes,
    card: state.cards.entries[cardId],
    allOffers: offersArray || [],
    selectedOffers: selectedOffers ||  [],
  }
}

const mapDispatchToProps = {
  updateTradeRoutesCard
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardOffers)
