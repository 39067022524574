import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import WebFont from 'webfontloader'
// import 'typeface-roboto'
import configureStore from './store/configureStore'
import App from './components/App'
import { CookiesProvider } from 'react-cookie'
import { SnackbarProvider } from 'notistack'
import { Helmet } from 'react-helmet'

import {
  QueryClient,
  QueryClientProvider
} from 'react-query'

const queryClient = new QueryClient()
const store = configureStore()
const rootEl = document.getElementById('root')
const root = createRoot(rootEl)

WebFont.load({
  custom: {
    families: ['keyrune'],
    urls: ['//cdn.jsdelivr.net/npm/keyrune@latest/css/keyrune.css']
  }
})

root.render(
  <CookiesProvider>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider
          maxSnack={6}
        >
          <Helmet>
            <script src="https://accepted-adventure.quietspeculation.com/script.js" data-site="PEJRLOJU" data-spa="auto" defer></script>
          </Helmet>
          <App />
        </SnackbarProvider>
      </QueryClientProvider>
    </Provider>
  </CookiesProvider>
)


if (module.hot) {
  module.hot.accept('./components/App', () => {
    const NextApp = require('./components/App').default
    root.render(
      <Provider store={store}>
        <NextApp />
      </Provider>
    )
  })
}
