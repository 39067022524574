import {
  SET_MERCHANTS, SET_ENTRIES, SET_PAGINATION
} from './actionType'

export const initialState = {
  data: [],
  loaded: false,
  entries: [],
  pagination: {
    numRows: 0,
    numPages: 0,
    perPage: 25,
    previous: null,
    next: null,
    type: 'buy',
    loaded: false,
    orderBy: 'card_name',
    order: 'ASC',
    page: 1,
  }
}

export default function merchantsReducer (state = initialState, action = {}) {
  switch (action.type) {
  case SET_MERCHANTS: {
    const { merchants } = action
    const newState = {
      ...state,
      data: merchants.sort((a, b) => {
        var textA = a.merchant_name.toUpperCase()
        var textB = b.merchant_name.toUpperCase()
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
      }),
      loaded: true
    }
    return newState
  }
  case SET_ENTRIES: {
    const { entries } = action
    const newState = {
      ...state,
      entries
    }
    return newState
  }
  case SET_PAGINATION: {
    const { pagination } = action
    const newState = {
      ...state,
      pagination
    }
    return newState
  }
  default:
    return state
  }
}
