import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  array, number, string
} from 'prop-types'

import {
  tradeRoutesTotalOffersSelector,
  tradeRoutesTotalOffersCSVSelector,
  tradeRoutesMerchantsListSelector
} from '../../../store/reselect'
import { convertToUSD } from '../../../utils/helpers'

import DownloadCSV from '../../shared/DownloadCSV'

import {
  Typography,
  Grid
} from '@material-ui/core'

class TotalOffersContainer extends Component {
  render() {
    const {
      csvData, count, price, listName, merchantOffersLength
    } = this.props

    return (
      <Grid container style={{
        textAlign: 'center'
      }}>
        <Grid item xs={6} sm={3}>
          <Typography variant='h5' color='primary'>
            Total Offers
          </Typography>
          <Typography variant='h6' style={{
            lineHeight: '48px'
          }}>
            {count}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant='h5' color='primary'>
            Total Merchants
          </Typography>
          <Typography variant='h6' style={{
            lineHeight: '48px'
          }}>
            {merchantOffersLength}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant='h5' color='primary'>
            Total Price
          </Typography>
          <Typography variant='h6' style={{
            lineHeight: '48px'
          }}>
            {convertToUSD(price)}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant='h5' color='primary'>
            Download Full Report
          </Typography>
          <span>
            <DownloadCSV
              data={csvData}
              filename={`TradeRoutes-${listName}-`}
            />
          </span>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    count, price
  } = tradeRoutesTotalOffersSelector(state)

  return {
    count: count || 0,
    price: price || 0,
    csvData: tradeRoutesTotalOffersCSVSelector(state),
    merchantOffersLength: tradeRoutesMerchantsListSelector(state).length,
  }
}

TotalOffersContainer.propTypes = {
  csvData: array,
  count: number,
  price: string,
  listName: string
}

export default connect(
  mapStateToProps
)(TotalOffersContainer)
