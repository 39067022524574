import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import IconButton from '@material-ui/core/IconButton'

import {
  TableRow,
  TableCell,
  makeStyles
} from '@material-ui/core'

import {
  LIST_TYPES, FORMATS
} from '../../../constants'

const useStyles = makeStyles({
  row: {
    height: 36
  },
  nameCell: {
    width: 250
  },
  listCellSpan: {
    textAlign: 'left',
    fontSize: '.8rem'
  },
  listCellIcon: {
    fontSize: '.8rem'
  }
})

export const ListTableRow = ({ v }) => {
  const classes = useStyles()
  return (
    <TableRow className={classes.row}>
      <TableCell padding={'none'} className={classes.nameCell}>
        <Link to={`/collection/lists/${v.id}`}>
          {v.name}
        </Link>
      </TableCell>
      <TableCell padding={'none'}>
        <span className={classes.listCellSpan}>{LIST_TYPES[v.list_type_id]}</span>
      </TableCell>
      <TableCell padding={'none'}>
        <span className={classes.listCellSpan}>{v.format_id ? FORMATS[v.format_id]['name'] : ''}</span>
      </TableCell>
      <TableCell padding={'none'}>
        <span className={classes.listCellSpan}>
          {v.is_public === 1 ? <CheckIcon className={classes.listCellIcon} /> : ''}
        </span>
      </TableCell>
      <TableCell padding={'none'}>
        <span className={classes.listCellSpan}>
          {v.created_at !== null && moment(v.created_at).local().format('MMMM Do YYYY, h:mm:ss a')}
        </span>
      </TableCell>
      <TableCell padding={'none'}>
        <span className={classes.listCellSpan}>
          {v.updated_at !== null && moment(v.updated_at).local().format('MMMM Do YYYY, h:mm:ss a')}
        </span>
      </TableCell>
      <TableCell padding={'none'}>
        <IconButton aria-label='Delete' size='small' onClick={() => this.handleWidgetToggle(v)}>
          <EditIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default ListTableRow
