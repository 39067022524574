import React from 'react'
import { Link } from 'react-router-dom'
import CardImageModal from '../../shared/CardImageModal'
import Keyrune from '../../shared/Keyrune'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'


export const CardsPageTable = (props) => {
  const {
    data, cardName
  } = props

  return (
    <Table style={{
      tableLayout: 'auto'
    }}>
      <TableHead>
        <TableRow style={{
          height: 36
        }}>
          <TableCell>
            Card
          </TableCell>
          <TableCell>
            Set
          </TableCell>
          <TableCell>
            Foil
          </TableCell>
          <TableCell>
            Collector Number
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((card) => {
          const {
            set_name, set_code, foil, scryfall_id, variant, collector_number
          } = card
          const variantURI = `?variant=${scryfall_id}`

          return (
            <TableRow
              key={JSON.stringify(card)}
              style={{
                height: 36
              }}
            >
              <TableCell>
                <CardImageModal
                  scryfallId={scryfall_id}
                  style={{
                    paddingRight: 6,
                  }}
                />
                <Link
                  to={`/prices/sets/${encodeURIComponent(set_name)}/${encodeURIComponent(cardName)}${(foil === 1) ? '/foil' : ''}${variantURI}`}
                  style={{
                    textTransform: 'capitalize'
                  }}
                >
                  {cardName} {variant === 1 && '(Variant)'}
                </Link>
              </TableCell>
              <TableCell>
                <Link to={`/prices/sets/${encodeURIComponent(set_name)}${(foil === 1) ? '/foil' : ''}`}>
                  <Keyrune set={set_code} /> {set_name}
                </Link>
              </TableCell>
              <TableCell>
                {foil === 1 ? 'Foil' : ''}
              </TableCell>
              <TableCell>
                {collector_number}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default CardsPageTable