import axios from 'axios'
import { useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import {
  generate, decrypt
} from './encrypt'

export function fetch (req) {
  return new Promise((resolve, reject) => {
    let obj = generate()
    axios({
      ...req,
      headers: {
        ...req.headers,
        Obj: obj
      }
    })
      .then(response => {
        try {
          let {
            result, error
          } = decrypt(response.data, obj)
          if (!error) {
            return resolve(JSON.parse(result))
          } else {
            return reject(error)
          }

        } catch (err) {
          console.log('error in fetch: ', err)
        }
      })
      .catch(error => {
        return reject(error)
      })
  })
}

export function useAuthMutation () {
  const accessToken = useSelector((state) => {
    return state.user.jwt
  })
  return useMutation(async ({
    url, payload
  }) => {
    // const { data: response } = await axios.post('/api/collections/merge', data)
    return await fetch({
      url,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': accessToken,
      },
      data: payload,
      baseURL: '/api',
      timeout: 120000,
    })
  })
}