import { createTheme } from '@material-ui/core/styles'
import {
  indigo, grey, red, green
} from '@material-ui/core/colors'
require ('typeface-average-sans')
require ('typeface-open-sans')

// The default praxis theme, this is not used
// export const praxis = {
//   50: '#f1e4e5',
//   100: '#ddbcbe',
//   200: '#c68f93',
//   300: '#af6268',
//   400: '#9d4147',
//   500: '#8c1f27',
//   600: '#841b23',
//   700: '#79171d',
//   800: '#6f1217',
//   900: '#5c0a0e',
//   A100: blueGrey['400'],
//   A200: blueGrey['500'],
//   A400: blueGrey['600'],
//   A700: blueGrey['700'],
//   'contrastDefaultColor': 'light',
// }

// var color = '#BDA067'

// Solana's theme
const praxisTheme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Open Sans, Roboto, Helvetica, sans-serif',
    color: 'rgb(51, 51, 51)',
  },
  palette: {
    primary: indigo,
    secondary: {
      main: green[500],
    },
    type: 'light',
    common: {
      black: '#282c34',
      white: '#fff',
    },
    error: red,
    grey: grey,

  },
})

export default praxisTheme
