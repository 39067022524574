import { createSelector } from '@reduxjs/toolkit'

const userMerchantOptionsSelector = state => state.user.merchantOptions.data
const cardsEntriesSelector = state => state.cards.entries
const merchantsLoadedSelector = state => state.merchants.loaded
const merchantsSelector = state => state.merchants.data
const setsLoadedSelector = state => state.sets.loaded
const setsDataSelector = state => state.sets.data
const tradeRoutesDataSelector = state => state.tradeRoutes.data
const collectionDataSelector = state => state.collection.data

export const archivedListsSelector = createSelector(
  [ collectionDataSelector ],
  (lists) => {
    let orderBy = 'updated_at'
    return Object.keys(lists)
      .filter(v => lists[v].is_deleted === 1)
      .map(v => lists[v])
      .sort((a, b) => (b[orderBy] && new Date(b[orderBy])) - (a[orderBy] && new Date(a[orderBy])))
  }
)

export const listsObjectSelector = createSelector(
  [ collectionDataSelector ],
  (lists) => {
    let orderBy = 'updated_at'
    return Object.keys(lists)
      .filter(v => lists[v].is_deleted === 0)
      .reduce((acc, id) => {
        acc[id] = lists[id]
        return acc
      }, {})
  }
)

export const listsSelector = createSelector(
  [ collectionDataSelector ],
  (lists) => {
    let orderBy = 'updated_at'
    return Object.keys(lists)
      .filter(v => lists[v].is_deleted === 0)
      .map(v => lists[v])
      .sort((a, b) => (b[orderBy] && new Date(b[orderBy])) - (a[orderBy] && new Date(a[orderBy])))
  }
)

export const initializedSelector = createSelector(
  [
    merchantsLoadedSelector,
    setsLoadedSelector
  ],
  (merchantsLoaded, setsLoaded) => merchantsLoaded && setsLoaded
)

export const setNamesSelector = createSelector(
  [
    setsDataSelector
  ],
  (sets) => {
    let setsArr = Object.keys(sets).map(key => sets[key])
    if (setsArr && setsArr.length > 0) {
      return setsArr
        .sort((a, b) => new Date(b.publish_date) - new Date(a.publish_date))
        .map(v => v.set_name.toLowerCase())
    }
    return Object.keys(sets)
  }
)

export const setsDataObjSelector = createSelector(
  [
    setsDataSelector
  ],
  (sets) => {
    return Object.keys(sets).reduce((obj, item) => {
      obj[item] = sets[item]
      return obj
    }, {})
  }
)

// export const setsEntriesObjSelector = createSelector(
//   [
//     setsDataSelector,
//     setNamesSelector
//   ],
//   (sets, setNames) => {
//     return setNames.reduce((obj, item) => {
//       obj[item] = sets[item].entries
//       return obj
//     }, {})
//   }
// )

// TODO: Make selector for set.set_type, alphabetized and ordered by alphabet

export const setsDataArraySelector = createSelector(
  [
    setsDataSelector,
    setNamesSelector
  ],
  (sets, setNames) => {
    return setNames.map(v => sets[v])
  }
)

export const disabledMerchantsSelector = createSelector(
  [
    userMerchantOptionsSelector,
    merchantsSelector
  ],
  (userMerchantOptions, merchants) => {
    let options = userMerchantOptions.map(v => {
      let merchant = merchants.find(m => m.settings_id === v.merchant_id) || null
      if (!merchant) {
        return null
      }
      return {
        ...v,
        merchant_id: merchant.merchant_id
      }
    }).filter(v => v !== null)
    return {
      buylist: options.filter(v => v.is_buylist_visible === 0).map(v => v.merchant_id),
      retail: options.filter(v => v.is_retail_visible === 0).map(v => v.merchant_id),
      options,
      userMerchantOptions
    }
  }
)

// This selector gets all of the selected offers, sorted by merchant
export const tradeRoutesTotalOffersSelector = createSelector(
  [
    tradeRoutesDataSelector,
  ],
  (data) => {
    let totalCount = 0
    let totalPrice = parseFloat(0)
    let merchantOffers = Object.keys(data).slice().reduce((obj, cardId) => {
      let { offers } = data[cardId]

      Object.keys(offers).slice().forEach(offer => {
        const {
          merchant_id, price, selected, volume
        } = offers[offer]

        if (selected > 0) {
          totalCount = totalCount + selected
          totalPrice = parseFloat(totalPrice) + (parseFloat(price) * selected)

          let entry = {
            card_id: cardId,
            price,
            selected,
            volume,
          }
          obj[merchant_id] = obj[merchant_id] ? [ ...obj[merchant_id], entry ] : [ entry ]
        }
      })

      return obj

    }, {})

    return {
      offers: merchantOffers,
      count: totalCount,
      price: totalPrice.toFixed(2),
    }
  }
)

// This selector returns the CSV formatting for all selected offers
export const tradeRoutesTotalOffersCSVSelector = createSelector(
  [
    tradeRoutesDataSelector,
    cardsEntriesSelector,
    merchantsSelector,
  ],
  (data, cards, merchants) => {
    return Object.keys(data).slice().reduce((arr, cardId) => {
      let { offers } = data[cardId]

      Object.keys(offers).slice().forEach(offer => {
        const {
          merchant_id, price, selected
        } = offers[offer]

        if (selected > 0) {
          let card = cards[cardId]
          let {
            card_name, set_name, rarity, foil, gatherer_id, card_id
          } = card
          let merchant = merchants.find(m => m.merchant_id === parseInt(merchant_id))
          let { merchant_name } = merchant

          let entry = {
            card_name: card_name,
            set_name: set_name,
            rarity: rarity,
            foil: foil,
            gatherer_id: gatherer_id,
            // retail_id: card.retail_id,
            trader_tools_id: card_id,
            merchant_name,
            count: selected,
            price: '$' + price.toFixed(2),
            total: '$' + (price * selected).toFixed(2)
          }

          arr = [ ...arr, entry ]
        }
      })

      return arr

    }, [])

  }
)

// This selector gets all of the selected offers, sorted by merchant
export const tradeRoutesListSelector = createSelector(
  [
    tradeRoutesDataSelector,
    cardsEntriesSelector,
  ],
  (tradeRoutesOffers, cards) => {
    return Object.keys(tradeRoutesOffers).slice().map(cardId => {
      let {
        card_id,
        card_name,
        foil,
        gatherer_id,
        rarity,
        set_code,
        set_name,
        scryfall_id,
        variant
      } = cards[cardId]

      let {
        quantity,
        remaining,
        offers,
        id
      } = tradeRoutesOffers[cardId]

      let totalPrice = parseFloat(0)
      let totalMerchants = 0

      Object.keys(offers).slice().forEach(offer => {
        const {
          selected, price
        } = offers[offer]
        if (selected > 0) {
          totalMerchants++
          totalPrice = parseFloat(totalPrice) + (parseFloat(price) * selected)
        }
      })

      return {
        card_id,
        card_name,
        set_name,
        set_code,
        foil,
        rarity,
        gid: gatherer_id,
        id,
        qty: quantity,
        qty_remain: remaining,
        offers_merchant_count: totalMerchants,
        offers_total_price: totalPrice,
        offers,
        offers_accepted: [],
        offers_selected: [],
        offers_set: true,
        retail_id: 'retailId',
        scryfall_id,
        variant
      }
    })
  }
)

// This selector gets all of the selected offers, sorted by merchant
export const tradeRoutesMerchantsListSelector = createSelector(
  [
    tradeRoutesListSelector,
    merchantsSelector,
  ],
  (list, merchants) => {
    let merchantsObj = list.slice().reduce((obj, entry) => {
      const { offers } = entry
      Object.keys(offers).forEach(key => {
        let offer = offers[key]
        let {
          merchant_id, price, selected
        } = offer
        if (selected > 0) {
          let value = (parseFloat(price) * selected).toFixed(2)
          obj[merchant_id] = obj[merchant_id] ? {
            ...obj[merchant_id],
            offers: [ ...obj[merchant_id].offers, entry ],
            totalCount: obj[merchant_id].totalCount + selected,
            totalPrice: (parseFloat(obj[merchant_id].totalPrice) + parseFloat(value)).toFixed(2),
          } : {
            offers: [ entry ],
            totalCount: selected,
            totalPrice: value,
          }
        }
      })
      return obj
    }, {})
    let merchantsArr = Object.keys(merchantsObj).map(merchantId => {
      let merchant = merchants.find(m => m.merchant_id === parseInt(merchantId)) || null
      return {
        ...merchant,
        ...merchantsObj[merchantId]
      }
    })
    return merchantsArr
  }
)